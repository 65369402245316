import spinner from '../assets/icons/spinner.svg'

const Spinner = () => {

    return (
        <div className='spinner'>
            <img src={spinner} alt='' />
        </div>
    )
}

export default Spinner