import ClipboardInput from './ClipboardInput'
import successIcon from '../assets/icons/payment-status-success.svg'

const SharePaymentSuccess = ({ data }) => {

    return (
        <article className='share-payment-success'>
            <div className='share-payment-success__container'>
                <div className='share-payment-success__inner'>
                    <figure className='share-payment-success__image'>
                        <img src={successIcon} alt='' />
                    </figure>
                    <h1 className='share-payment-success__title'>Payment shared successfully</h1>
                    <p className='share-payment-success__confirmation'>
                        <span>An email has been sent to:</span>
                        {data.firstName} {data.surname} ({data.email})
                    </p>
                    <p className='share-payment-success__note'>For security reasons, {data.firstName} will have to enter the PIN below to access the payments page.</p>
                    <form>
                        <ClipboardInput
                            defaultValue={data.pin}
                            label={`Share this pin with ${data.firstName}`}
                            size='large'
                        />
                    </form>
                </div>
            </div>
            <div className='share-payment-success__footer'>
                <a href={`/quotes-and-bookings/quotes`} className='button button--white'>Back to Quotes & Bookings</a>
            </div>
        </article>
    )
}

export default SharePaymentSuccess