import React, {useEffect, useRef, useState} from 'react'
import {SwitchTransition, CSSTransition} from 'react-transition-group'
import Button from './Button'
import {NumericFormat} from 'react-number-format'
import Share from './Share'
import Modal from './Modal'
import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router'
import {getMeasurements} from '../utilities/string'
import {isFloatingFleet} from '../utilities/aircraft'

// Icons
import luggage from '../assets/icons/luggage-gold.svg'
import toilet from '../assets/icons/toilet.svg'
// import catering from '../assets/icons/catering.svg'
import cateringIcon from '../assets/icons/catering-alt.svg'
import bed from '../assets/icons/bed.svg'
import wifi from '../assets/icons/wifi.svg'
import cabinCrew from '../assets/icons/cabin-crew.svg'

import pilots from '../assets/icons/pilots.svg'
import subjectToWeight from '../assets/icons/subject-to-weight.svg'
import refurbished from '../assets/icons/refurbished.svg'
import cabinWidth from '../assets/icons/cabin-width.svg'
import cabinLength from '../assets/icons/cabin-length.svg'
import smoking from '../assets/icons/smoking.svg'
import pets from '../assets/icons/pets.svg'
import subjectToConditions from '../assets/icons/subject-to-conditions.svg'
import fuelStop from '../assets/icons/fuel-stop.svg'
import ownerApproval from '../assets/icons/owner-approval.svg'

const AircraftCardExtraContent = ({aircraftData, sharedPage, legId}) => {

    // State
    const [content, setContent] = useState()
    const [showSharePanel, setShowSharePanel] = useState(false)

    // Use ref
    const swapFade = useRef()
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    // Go to booking page
    const goToBookingPage = () => {
        navigate(`/book?lo=${legId}&name=${queryParams.get('name')}`, {
            state: {
                legid: legId,
                arfq: content.id,
                tailNum: content.tailNumber
            }
        })
    }

    // Remember aircraft data
    useEffect(() => {
        if (aircraftData) {
            setContent(aircraftData)
        }
    }, [aircraftData])

    return (
        <>
            {content &&
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={content.id}
                        timeout={500}
                        unmountOnExit
                        mountOnEnter
                        nodeRef={swapFade}
                        classNames='aircraft-card-extra-content-'
                    >
                        <div ref={swapFade} className='aircraft-card-extra-content aircraft-card-extra-content--exit-complete'>

                            {/* Column 1 */}
                            <div className='aircraft-card-extra-content__column aircraft-card-extra-content__column--full-width'>
                                    {/* Aircraft section */}
                                    <div className="aircraft-card-extra-content__aircraft">
                                        <div className='aircraft-card-extra-content__column__title'>AIRCRAFT</div>
                                        
                                        <div className="aircraft-card-extra-content__aircraft-inline-content">
                                            <strong>{content.aircraftName}, </strong>
                                            <p>{content.category}
                                            {isFloatingFleet(aircraftData) &&
                                                <p> / *Floating fleet</p>
                                            }
                                            </p>
                                        </div>

                                        <div className="aircraft-card-extra-content__aircraft-inline-content">
                                            <strong>Manufactured: </strong>
                                            <p>{content.yom} / Refurbished: {content.refurbishedYear}</p>
                                        </div>
                                    </div>
                                    
                                    {/* Notes */}
                                    <div>
                                        <div className='aircraft-card-extra-content__column__title'>NOTES</div>
                                        {content.notes &&
                                            <p className='aircraft-card-extra-content__column__small-print'>{content.notes}</p>
                                        }
                                        <p className='aircraft-card-extra-content__column__small-print'><em>All times shown in local time. Aircraft are subject to continued availability of aircraft and crew, slots and permits.</em></p>
                                        {isFloatingFleet(aircraftData) &&
                                            <p className='aircraft-card-extra-content__column__small-print'><strong>"Floating Fleet" - <em>Floating fleet is a supplier with multiple of the same OR similar category of aircraft (Interior and exterior aesthetics are subject to change)</em></strong></p>
                                        }
                                    </div>
                            </div>

                            {/* Column 2 */}
                            <div className='aircraft-card-extra-content__column aircraft-card-extra-content__column--double-width'>

                                
                                <div className='aircraft-card-extra-content__column__title'>AIRCRAFT FEATURES</div>
                                <div className='aircraft-card-extra-content__column-content'>
                                    <div className='aircraft-card-extra-content__column__feature'>
                                        <figure className='aircraft-card-extra-content__feature__image'>
                                            <img src={luggage} alt=''/>
                                        </figure>
                                        <div className='aircraft-card-extra-content__column__feature__name'>Luggage</div>
                                        <div className='aircraft-card-extra-content__column__feature__detail'>{content.luggage} item{content.luggage === 1 ? '' : 's'}, 20kg max per item</div>
                                    </div>

                                    <div className='aircraft-card-extra-content__column__feature'>
                                        <figure className='aircraft-card-extra-content__feature__image'>
                                            <img src={toilet} alt=''/>
                                        </figure>
                                        <div className='aircraft-card-extra-content__column__feature__name'>Enclosed WC</div>
                                        <div className='aircraft-card-extra-content__column__feature__detail'>{content.toilet ? 'Yes' : 'No'}</div>
                                    </div>

                                    <div className='aircraft-card-extra-content__column__feature'>
                                        <figure className='aircraft-card-extra-content__feature__image'>
                                            <img src={cateringIcon} alt=''/>
                                        </figure>
                                        <div className='aircraft-card-extra-content__column__feature__name'>Catering</div>
                                        <div className='aircraft-card-extra-content__column__feature__detail'>
                                            {content.cateringOption}
                                        </div>
                                    </div>

                                    <div className='aircraft-card-extra-content__column__feature'>
                                        <figure className='aircraft-card-extra-content__feature__image'>
                                            <img src={bed} alt=''/>
                                        </figure>
                                        <div className='aircraft-card-extra-content__column__feature__name'>Bed</div>
                                        <div className='aircraft-card-extra-content__column__feature__detail'>{content.bed ? 'Yes' : 'No'}</div>
                                    </div>

                                    <div className='aircraft-card-extra-content__column__feature'>
                                        <figure className='aircraft-card-extra-content__feature__image'>
                                            <img src={wifi} alt=''/>
                                        </figure>
                                        <div className='aircraft-card-extra-content__column__feature__name'>WiFi</div>
                                        <div className='aircraft-card-extra-content__column__feature__detail'>{content.wifi ? 'Yes' : 'No'}</div>
                                    </div>

                                    <div className='aircraft-card-extra-content__column__feature'>
                                        <figure className='aircraft-card-extra-content__feature__image'>
                                            <img src={cabinCrew} alt=''/>
                                        </figure>
                                        <div className='aircraft-card-extra-content__column__feature__name'>Flight Attendant</div>
                                        <div className='aircraft-card-extra-content__column__feature__detail'>{content.flightAttendants === 'Y' ? 'Yes' : 'No'}</div>
                                    </div>

                                    {content.numberOfPilots && (
                                        <div className='aircraft-card-extra-content__column__feature'>
                                            <figure className='aircraft-card-extra-content__feature__image'>
                                                <img src={pilots} alt=''/>
                                            </figure>
                                            <div className='aircraft-card-extra-content__column__feature__name'>Pilots</div>
                                            <div className='aircraft-card-extra-content__column__feature__detail'>{content.numberOfPilots}</div>
                                        </div>
                                    )}

                                    {content.subjectToWeight === 'Y' &&
                                        <div className='aircraft-card-extra-content__column__feature'>
                                            <figure className='aircraft-card-extra-content__feature__image'>
                                                <img src={subjectToWeight} alt=''/>
                                            </figure>
                                            <div className='aircraft-card-extra-content__column__feature__name'>Subject to Weight</div>
                                            <div className='aircraft-card-extra-content__column__feature__detail'>{content.subjectToWeight === 'Y' ? 'Yes' : 'No'}</div>
                                        </div>
                                    }
                                </div>
                                <div className='aircraft-card-extra-content__column-content'>

                                    {/* <div className='aircraft-card-extra-content__column__feature'>
                                        <figure className='aircraft-card-extra-content__feature__image'>
                                            <img src={refurbished} alt=''/>
                                        </figure>
                                        <div className='aircraft-card-extra-content__column__feature__name'>Refurbished</div>
                                        <div className='aircraft-card-extra-content__column__feature__detail'>{content.refurbishedYear}</div>
                                    </div> */}

                                    {content.cabinWidth && (
                                        <div className='aircraft-card-extra-content__column__feature'>
                                            <figure className='aircraft-card-extra-content__feature__image'>
                                                <img src={cabinWidth} alt=''/>
                                            </figure>
                                            <div className='aircraft-card-extra-content__column__feature__name'>Cabin Width</div>
                                            <div className='aircraft-card-extra-content__column__feature__detail'>{content.cabinWidth}</div>
                                        </div>
                                    )}

                                    {content.cabinLength && (
                                        <div className='aircraft-card-extra-content__column__feature'>
                                            <figure className='aircraft-card-extra-content__feature__image'>
                                                <img src={cabinLength} alt=''/>
                                            </figure>
                                            <div className='aircraft-card-extra-content__column__feature__name'>Cabin Length</div>
                                            <div className='aircraft-card-extra-content__column__feature__detail'>{content.cabinLength}</div>
                                        </div>
                                    )}

                                    <div className='aircraft-card-extra-content__column__feature'>
                                        <figure className='aircraft-card-extra-content__feature__image'>
                                            <img src={smoking} alt=''/>
                                        </figure>
                                        <div className='aircraft-card-extra-content__column__feature__name'>Smoking</div>
                                        <div className='aircraft-card-extra-content__column__feature__detail'>{content.smokingOption === 'Y' ? 'Yes' : 'No'}</div>
                                    </div>

                                    {content.pets !== 0 &&
                                    <div className='aircraft-card-extra-content__column__feature'>
                                        <figure className='aircraft-card-extra-content__feature__image'>
                                            <img src={pets} alt=''/>
                                        </figure>
                                        <div className='aircraft-card-extra-content__column__feature__name'>Pets</div>
                                        <div className='aircraft-card-extra-content__column__feature__detail'>{content.pets}</div>
                                    </div>
                                    }

                                    {content.subjectToConditions !== 'None' &&
                                        <div className='aircraft-card-extra-content__column__feature'>
                                            <figure className='aircraft-card-extra-content__feature__image'>
                                                <img src={subjectToConditions} alt=''/>
                                            </figure>
                                            <div className='aircraft-card-extra-content__column__feature__name'>Subject to Conditions</div>
                                            <div className='aircraft-card-extra-content__column__feature__detail'>{content.subjectToConditions}</div>
                                        </div>
                                    }

                                    {content.fuelStop > 0 &&
                                        <div className='aircraft-card-extra-content__column__feature'>
                                            <figure className='aircraft-card-extra-content__feature__image'>
                                                <img src={fuelStop} alt=''/>
                                            </figure>
                                            <div className='aircraft-card-extra-content__column__feature__name'>Fuel Stop</div>
                                            <div className='aircraft-card-extra-content__column__feature__detail'>{content.fuelStop}</div>
                                        </div>
                                    }

                                    {content.ownerApproval === 'Y' &&
                                        <div className='aircraft-card-extra-content__column__feature'>
                                            <figure className='aircraft-card-extra-content__feature__image'>
                                                <img src={ownerApproval} alt=''/>
                                            </figure>
                                            <div className='aircraft-card-extra-content__column__feature__name'>Owner Approval</div>
                                            <div className='aircraft-card-extra-content__column__feature__detail'>{content.ownerApproval === 'Y' ? 'Yes' : 'No'}</div>
                                        </div>
                                    }
                                </div>
                            </div>

                            {/* Column 3 */}
                            <div className='aircraft-card-extra-content__column aircraft-card-extra-content__column--double-width'>
                                {content.floorPlan[0] &&
                                    <div className='aircraft-card-extra-content__floor-plan aircraft-card-extra-content__floor-plan--desktop'>
                                        <div className='aircraft-card-extra-content__column__title'>FLOOR PLAN</div>
                                        <div className='aircraft-card-extra-content__floor-plan-images'>
                                            {content.floorPlan.map((image) => {
                                                return <figure className='aircraft-card-extra-content__floor-plan-image'>
                                                    <img src={image['url']} alt={image['alt']}/>
                                                </figure>
                                            })}
                                        </div>
                                        {content.cabinLength &&
                                            <p>Length: {getMeasurements(content.cabinLength)}</p>
                                        }
                                        {content.headRoom &&
                                            <p>Height: {getMeasurements(content.headRoom)}</p>
                                        }
                                        {content.cabinWidth &&
                                            <p>Width: {getMeasurements(content.cabinWidth)}</p>
                                        }
                                    </div>
                                }
                            </div>

                            {/* Column 4*/}
                            <div className='aircraft-card-extra-content__column aircraft-card-extra-content__column--full-width'>
                                {content.floorPlan[0] &&
                                    <div className='aircraft-card-extra-content__floor-plan aircraft-card-extra-content__floor-plan--mobile'>
                                        <div className='aircraft-card-extra-content__column__title'>FLOOR PLAN</div>
                                        <div className='aircraft-card-extra-content__floor-plan-images'>
                                            {content.floorPlan.map((image) => {
                                                return <figure className='aircraft-card-extra-content__floor-plan-image'>
                                                    <img src={image['url']} alt={image['alt']}/>
                                                </figure>
                                            })}
                                        </div>
                                        {content.cabinLength &&
                                            <p>Length: {getMeasurements(content.cabinLength)}</p>
                                        }
                                        {content.headRoom &&
                                            <p>Height: {getMeasurements(content.headRoom)}</p>
                                        }
                                        {content.cabinWidth &&
                                            <p>Width: {getMeasurements(content.cabinWidth)}</p>
                                        }
                                    </div>
                                }


                                
                                <div className='aircraft-card-extra-content__column__actions-block'>

                                    <div className='aircraft-card-extra-content__column__actions-block__price'>
                                        <div className='aircraft-card-extra-content__column__actions-block__price__from'>Price:</div>

                                        {content.operatorCurrency === "GBP" && <>GBP</>}
                                        {content.operatorCurrency === "USD" && <>USD</>}
                                        {content.operatorCurrency === "EUR" && <>EUR</>}
                                        &nbsp;
                                        <NumericFormat
                                            displayType='text'
                                            value={content.price[content.operatorCurrency.toLowerCase()]}
                                            thousandSeparator
                                            decimalScale={0}
                                        />
                                    </div>

                                    {!sharedPage &&
                                        <>
                                            <Button onClick={goToBookingPage} classNames={['button--full']}>Reserve Now</Button>
                                            <div className='aircraft-card-extra-content__column__actions-block__price__buttons'>
                                                <Button onClick={() => setShowSharePanel(true)} classNames={['button--secondary']} icon='share'>Share</Button>
                                            </div>
                                        </>
                                    }
                                </div>

                            </div>



                            <Modal
                                classNames={['modal__content--thin']}
                                modalVisible={showSharePanel}
                                closeModal={() => {setShowSharePanel(false)}}
                            >
                                <Share
                                    type='flight'
                                    endpoint={'/api/frontend/share-quote'}
                                    shareRef={[content.id]}
                                    close={() => {setShowSharePanel(false)}}
                                    tailNumbers={[content.tailNumber]}
                                />
                            </Modal>

                        </div>
                    </CSSTransition>
                </SwitchTransition>
            }
        </>
    )
}

export default AircraftCardExtraContent