import useGet from './useGet'
import {useEffect, useState} from 'react'

export const useGetCacheContent = (contentRef, url, delay) => {

    // State
    const [content, setContent] = useState(JSON.parse(sessionStorage.getItem(contentRef)))
    const [getApi, setGetApi] = useState(false)

    // Get content if none was found
    const {loading, error, returnData} = useGet(getApi, delay)

    // Save content in session
    useEffect(() => {

        // If there was no content
        if (!content) {
            console.log("No cached content found")
            setGetApi(url)
        } else {
            console.log("Cached content found")
        }

        // Data returned
        if (returnData) {
            setContent(returnData)
            sessionStorage.setItem(contentRef, JSON.stringify(returnData))
        }
    }, [content, contentRef, returnData, url])

    return ({
        loading,
        error,
        content
    })
}