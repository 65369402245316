import AircraftCard from '../components/AircraftCard'
import React, {useRef, useState, useEffect} from 'react'
import {useLocation} from 'react-router'
import useClickOutside from '../hooks/useClickOutside'
import QuoteHeader from '../components/QuoteHeader'
import QuoteFilters from '../components/QuoteFilters'
import QuoteCtaBar from '../components/QuoteCtaBar'
import {CSSTransition} from 'react-transition-group'
import AircraftCardMoreLessTray from '../components/AircraftCardMoreLessTray'
import useGet from '../hooks/useGet'
import LoadingOverlay from '../components/LoadingOverlay'
import {useNavigate} from 'react-router-dom'
import ErrorModal from '../components/ErrorModal'
import usePost from '../hooks/usePost'
import SEO from '../components/SEO'
import CompareButtonsBar from '../components/CompareButtonsBar'
import Compare from '../components/Compare'
import Button from '../components/Button'
import CloseCross from '../components/CloseCross'
import ReturnMessage from '../components/ReturnMessage'

const Quote = ({sharedPage, showQuoteOrCompare}) => {
    
    // Hooks
    const navigate = useNavigate()
    const {postData, sending, returnMessage} = usePost()
    
    // State
    const [aircraftData, setAircraftData] = useState(null)
    const [showFilters, setShowFilters] = useState(false)
    const [showCompareBar, setShowCompareBar] = useState(false)
    const [compareSelection, setCompareSelection] = useState([])
    const [filterScrollPosition, setFilterScrollPosition] = useState('')
    const [expanded, setExpanded] = useState(null)
    const [countFilters, setCountFilters] = useState(0)
    const [dimAircraft, setDimAircraft] = useState(null)
    const [exitAircraft, setExitAircraft] = useState(false)
    const [enterAircraft, setEnterAircraft] = useState(false)
    const [currentRow, setCurrentRow] = useState(false)
    const [currentColumn, setCurrentColumn] = useState(false)
    const [loading, setLoading] = useState(true)
    const [getSearchResultsApi, setGetSearchResultsApi] = useState(false)
    const [searchResultsHeaderApi, setSearchResultsHeaderApi] = useState(false)
    const [sharedDataApi, setSharedDataApi] = useState(false)
    const [error, setError] = useState(false)
    const [thumbs, setThumbs] = useState([])
    const [sharedMessage, setSharedMessage] = useState(null)
    
    // Use ref
    const extraContent = useRef()
    const confirmApproved = useRef()
    
    // Get parameters from url
    const {search} = useLocation()
    const queryParams = new URLSearchParams(search)
    const approvalRequested = queryParams.get('approvalRequested')?.toLowerCase()
    const approverId = queryParams.get('approverId')
    const sharerName = queryParams.get('sharerName')
    const name = queryParams.get('name')
    const rfqId = queryParams.get('qi')
    
    const [legId, setLegId] = useState(queryParams.get('lo'))
    
    // Create link back to this page from compare page
    const backLink = search
    
    // Get quote data
    const {loading: searchResultsLoading, error: searchError, returnData: searchResults} = useGet(getSearchResultsApi)
    const {loading: searchResultsHeaderLoading, error: headerError, returnData: searchResultsHeader} = useGet(searchResultsHeaderApi)
    const {loading: sharedDataLoading, error: sharedDataError, returnData: sharedData} = useGet(sharedDataApi)
    
    // Use Effect
    useEffect(() => {
         
        // Redirect if there's no legId and we aren't sharing the quote
        if (!legId) {
            if (sharedPage) {
                setSharedDataApi(`/api/frontend/get-shared-flights-from-journeyid?journeyId=${rfqId}&approverId=${approverId}`)
            } else {
                navigate('/')
            }
        } else {
            // Set endpoint apis
            setGetSearchResultsApi(`/api/frontend/v2/get-search-results?LegId=${legId}`)
            setSearchResultsHeaderApi(`/api/frontend/get-search-results-header?LegId=${legId}`)
        }

        // Redirect if approved flights have successfully been submitted
        if (returnMessage.success && returnMessage.postRef === 'postApproval') {
            navigate('/approve-flights-success')
        }

        if (searchResultsHeader.expired || sharedData.sharedQuoteIsExpired) {
            setError('The page you have clicked to view has now expired or you have already completed this step.')

            setLoading(false)
        }

        // Quote data has loaded
        if (!searchResultsLoading && !searchResultsHeaderLoading && searchResults && searchResultsHeader) {

            if(searchResults[0]) {
                if(!aircraftData) {
                    resetAircraftData(searchResults)
                }
            } else {
                setError("Sorry, no quote was found. It may be that your broker hasn't put it together yet, please check back in a few hours. Alternatively you may be signed in with a different account.")
            }

            setLoading(false)
        }

        // Show server errors
        if(searchError || headerError) {
            setError(headerError ? headerError : searchError)
            setLoading(false)
        }

        // Set share message
        if (sharedPage && sharerName && aircraftData && (aircraftData.length !== 0)) {
            let message
            if (aircraftData.length === 1) {
                message = `${sharerName} has shared this flight with you`
            }
            if (aircraftData.length > 1) {
                message = `${sharerName} has shared these flights with you`
            }
            if (approvalRequested === 'true') {
                message = `${message} and requests your approval`
            }
            setSharedMessage(message)
        }

        // Listen for window resize events
        let resizeTimeout

        const resizeHandler = () => {
            clearTimeout(resizeTimeout)
            resizeTimeout = setTimeout(() => {
                // setSize({
                //     width: window.innerWidth,
                //     height: window.innerHeight,
                // })
            }, 100)
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [aircraftData, approvalRequested, headerError, legId, navigate, returnMessage, searchError, searchResults, searchResultsHeader, searchResultsHeaderLoading, searchResultsLoading, sharedPage, sharerName])

    const resetAircraftData = (data) => {
        if (sharedData) {
            if (sharedData.sharedFlights) {
                if (sharedData.sharedFlights.length > 0) {
                    let tailNumbers = []

                    sharedData.sharedFlights.forEach(flight => {
                        tailNumbers.push(flight.tailNumber)
                    })

                    data = data.filter(item => {
                        return tailNumbers.indexOf(item.tailNumber) > -1
                    })
                }
            }
        }

        setAircraftData(data)
    }

    useEffect(() => {
        if (sharedData) {
            if (sharedData.sharedFlights) {
                if (sharedData.sharedFlights.length > 0) {
                    setLegId(sharedData.sharedFlights[0].legId)
                }
            }
        }
    }, [sharedData])

    // Show filters handler
    const showFiltersHandler = () => {
        setShowFilters(!showFilters)
        setShowCompareBar(false)
    }

    // Show compare bar handler
    const showCompareBarHandler = () => {
        setShowCompareBar(!showCompareBar)
    }

    // Expand more / less handler
    const expandedHandler = (aircraftId, index) => {
        if (aircraftId === expanded) {
            setExpanded(false)
            setCurrentColumn(false)
            setCurrentRow(false)
            setDimAircraft(false)
        } else {
            setExpanded(aircraftId)
            setDimAircraft(aircraftId)
            setExtraContentPosition(aircraftId, index)
        }
    }

    // Thumbs up
    const thumbsHandler = (status, tailNumber) => {
        let updatedSelection = thumbs
        if (!thumbs.includes(tailNumber)) {
            setThumbs([...updatedSelection, tailNumber])
        } else {
            setThumbs(updatedSelection.filter(selected => selected !== tailNumber))
        }
    }

    // Filter aircraft
    const aircraftFilterSpeed = 500; // match variable in style/settings/_animation-speeds
    const filterOptionsHandler = (filterOptions) => {

        expandedHandler(false)

        // Count and show applied filters number
        const filterCount = Object.values(filterOptions).filter(item => item === true).length
        setCountFilters(filterCount)

        // Exit aircraft before filtering new results
        animateEnterExitAircraft('exit', aircraftFilterSpeed, () => {

            // Scroll page back to the top result (on mobile)
            if (!window.matchMedia('(min-width: 550px)').matches) {
                window.scrollTo({top: filterScrollPosition, behavior: 'smooth'})
            }

            if (filterCount === 0) {
                resetAircraftData(searchResults)

                // Enter new results
                animateEnterExitAircraft('enter', aircraftFilterSpeed)

            } else{

                // Reset aircraft array
                let updatedAircraft = []

                // Iterate filter options
                Object.keys(filterOptions).forEach(function(key) {
                    if (filterOptions[key]){

                        // Filter matching aircraft
                        const filterAircraft = searchResults.filter(aircraft => (aircraft[key] === true))

                        // Add them to the updated aircraft array
                        updatedAircraft = updatedAircraft.concat(filterAircraft)
                    }
                })

                //Remove duplicates
                const uniq = {}
                const aircraftNoDuplicates = updatedAircraft.filter(obj => !uniq[obj.id] && (uniq[obj.id] = true))

                // Set aircraft data
                setAircraftData(aircraftNoDuplicates)

                // Enter new results
                animateEnterExitAircraft('enter', aircraftFilterSpeed)
            }
        })
    }

    // Animate enter / exit of aircraft after filters applied
    const animateEnterExitAircraft = (direction, timing, callback) => {
        if (direction === 'exit') {
            setExitAircraft(true)
            setTimeout(() => {
                callback()
            }, timing)
        }

        if (direction === 'enter') {
            setExitAircraft(false)
            setEnterAircraft(true)
            setTimeout(() => {
                setEnterAircraft(false)
            }, timing)
        }
    }

    // Detect click outside of filters
    const filtersButtonRef = useRef(null)
    const filterBarRef = useRef(null)
    useClickOutside([filtersButtonRef, filterBarRef], () => {
        if (showFilters) {
            setShowFilters(false)
        }
    })

    // Detect click outside of expanded tray
    const cardRef = useRef(null)
    useClickOutside([extraContent, cardRef], () => {
        setExpanded(false)
        setCurrentColumn(false)
        setCurrentRow(false)
        setDimAircraft(false)
    })

    // Handle the current selection to be compared
    const compareSelectionHandler = (value, id) => {
        let updatedSelection = compareSelection
        if (value === true) {
            setCompareSelection([...updatedSelection, id])
        } else {
            setCompareSelection(updatedSelection.filter(selected => selected !== id))
        }
    }

    // Select all flights to compare
    const selectAllHandler = () => {
        let allFlights = []
        aircraftData.map(flight => {
            allFlights.push(`${flight.id}___${flight.tailNumber}`)
            return false
        })

        setCompareSelection(allFlights)
    }

    // Clear all flights to compare
    const compareClearAllHandler = () => {
        setCompareSelection([])
    }

    // Clear all thumbs up
    const thumbsClearAllHandler = () => {
        setThumbs([])
    }

    // Send approved flights
    const sendApprovedFlightsHandler = () => {

        window.scrollTo({top: 0, behavior: 'instant'})

        const data = {
            approverId: approverId,
            approvedFlights: thumbs
        }

        postData(data, '/api/frontend/approve-shared-flights', 0, 'postApproval')
    }

    // Define where the extra content will live within the cards, depending on which card is active and how many cards are shown per row
    const setExtraContentPosition = (airCraftId, index) => {
        let row
        let column

        let perRow = 1
        row = index

        if (window.matchMedia('(min-width: 765px)').matches) {
            perRow = 2
            row = index

            const diff = (index + 1) % perRow

            if (diff) {
                row += perRow - diff
            }
        }

        if (window.matchMedia('(min-width: 1024px)').matches) {
            perRow = 3
            row = index

            const diff = (index + 1) % perRow

            if (diff) {
                row += perRow - diff
            }
        }

        if (row > aircraftData.length - 1) {
            row = aircraftData.length - 1
        }

        column = `${perRow}_${index % perRow}`

        // Set current column and row
        setCurrentColumn(column)
        setCurrentRow(row)
    }

    return (
        <>
            <SEO title='Quote' />
            <div className='quote'>
                {(loading || sending) ?
                    <LoadingOverlay clear={true} />
                    :
                    error ? <ErrorModal errorMessage={error} /> :
                        <>
                            <ReturnMessage inline returnMessage={returnMessage} />

                            {(sharedMessage && !returnMessage.error) &&
                                <div id='header-message' className='quote__header-message'>{sharedMessage}</div>
                            }

                            <QuoteHeader
                                data={searchResultsHeader}
                                filterScrollPosition={(position) => setFilterScrollPosition(position)}
                            />

                            {(showQuoteOrCompare === 'quote') ?
                                <>
                                    <QuoteCtaBar
                                        filtersButtonRef={filtersButtonRef}
                                        showFilters={showFilters}
                                        showCompareBar={showCompareBar}
                                        showFiltersHandler={showFiltersHandler}
                                        showCompareBarHandler={showCompareBarHandler}
                                        filterCount={countFilters}
                                        compareSelection={compareSelection}
                                        backLink={backLink}
                                        legId={legId}
                                        name={name}
                                    />

                                    <div className='quote__filter-and-compare-content'>
                                        <QuoteFilters
                                            showFilters={showFilters}
                                            filterBarRef={filterBarRef}
                                            selectedFilter={(filterOptions) => filterOptionsHandler(filterOptions)}
                                        />

                                        <CompareButtonsBar
                                            showCompare={showCompareBar}
                                            numberOfOptions={aircraftData.length}
                                            showCompareBarHandler={showCompareBarHandler}
                                            compareSelection={compareSelection}
                                            selectAllHandler={selectAllHandler}
                                            clearAllHandler={compareClearAllHandler}
                                            closeCompare={() => setShowCompareBar(false)}
                                            sharedPage={sharedPage}
                                            backLink={backLink}
                                        />

                                        <CSSTransition
                                            nodeRef={confirmApproved}
                                            in={(thumbs.length > 0)}
                                            timeout={500}
                                            unmountOnExit
                                            classNames={{
                                                enterDone: 'quote__confirm-approve--active',
                                                enterActive: 'quote__confirm-approve--transition-in',
                                                exitActive: 'quote__confirm-approve--transition-out',
                                            }}
                                        >
                                            <div ref={confirmApproved} className='quote__confirm-approve'>
                                                <div className='quote__confirm-approve__inner'>

                                                    <div className='quote__confirm-approve__inner__title'>{thumbs.length} flight{(thumbs.length === 1 ? null : 's')} selected</div>
                                                    <div className='quote__confirm-approve__inner__buttons-wrapper'>
                                                        <Button onClick={sendApprovedFlightsHandler}>Send approval to {sharerName}</Button>
                                                    </div>
                                                    <div className='quote__confirm-approve__inner__close-cross'><CloseCross onClick={thumbsClearAllHandler} /></div>
                                                </div>
                                            </div>
                                        </CSSTransition>
                                    </div>

                                    <div className='wrap wrap--sm-no-padding'>
                                        <div className='quote__aircraft-grid'>
                                            {aircraftData.length === 0 &&
                                                <p className='quote__no-results'>No results matching your search</p>
                                            }
                                            {aircraftData.map((airCraft, index) => {
                                                return (
                                                    <React.Fragment key={airCraft.tailNumber}>
                                                        <AircraftCard
                                                            key={airCraft.tailNumber}
                                                            ref={airCraft.tailNumber === expanded ? cardRef : null}
                                                            id={airCraft.id}
                                                            legId={legId}
                                                            recommended={airCraft.recommended}
                                                            category={airCraft.category}
                                                            aircraftName={airCraft.aircraftName}
                                                            photoData={airCraft.photoData}
                                                            seats={airCraft.seats}
                                                            departureTime={airCraft.departureTime}
                                                            arrivalTime={airCraft.arrivalTime}
                                                            headRoom={airCraft.headRoom}
                                                            co2={airCraft.co2}
                                                            yom={airCraft.yom}
                                                            price={airCraft.price}
                                                            currency={airCraft.preferredCurrency}
                                                            operatorCurrency={airCraft.operatorCurrency}
                                                            expandedId={expanded}
                                                            expandedHandler={() => expandedHandler(airCraft.tailNumber, index)}
                                                            dimAircraft={dimAircraft && dimAircraft !== airCraft.tailNumber}
                                                            exitAircraft={exitAircraft}
                                                            enterAircraft={enterAircraft}
                                                            sharedPage={sharedPage}
                                                            sharerName={sharerName}
                                                            approvalRequested={approvalRequested}
                                                            thumbsStatus={thumbs.includes(airCraft.tailNumber)}
                                                            updateThumbsStatus={(status) => thumbsHandler(status, airCraft.tailNumber)}
                                                            tailNumber={airCraft.tailNumber}
                                                            showCheckbox={showCompareBar}
                                                            compareSelectionHandler={(value) => compareSelectionHandler(value, `${airCraft.id}___${airCraft.tailNumber}`)}
                                                            compareSelected={compareSelection.includes(`${airCraft.id}___${airCraft.tailNumber}`)}
                                                        />

                                                        {/* Include Extra content if applicable */}
                                                        <CSSTransition
                                                            nodeRef={extraContent}
                                                            in={index === currentRow}
                                                            timeout={500}
                                                            unmountOnExit={true}
                                                        >
                                                            <AircraftCardMoreLessTray
                                                                ref={extraContent}
                                                                column={currentColumn}
                                                                dimAircraft={dimAircraft === 'all'}
                                                                expanded={index === currentRow}
                                                                aircraftData={aircraftData.filter(aircraft => aircraft.tailNumber === expanded)[0]}
                                                                expandedHandler={() => expandedHandler(expanded)}
                                                                sharedPage={sharedPage}
                                                                legId={legId}
                                                            />
                                                        </CSSTransition>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                                :
                                <Compare
                                    aircraftData={aircraftData}
                                    compareSelection={compareSelection}
                                    legId={legId}
                                    clearAllHandler={compareClearAllHandler}
                                    sharedPage={sharedPage}
                                    backLink={backLink}
                                />
                            }
                        </>
                }
            </div>
        </>
    )
}

export default Quote