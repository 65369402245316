import {useContext, useEffect, useState} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Pagination} from 'swiper'
import BlogArticlePreview from './BlogArticlePreview'
import poweredByContext from '../store/PoweredByContext'
import {replaceShyName} from '../utilities/string'
import createHeading from '../utilities/createHeading'

const BlockArticles = ({ title, subtitle, relatedArticles, mainHeading, subHeading }) => {

    // State
    const [useSwiper, setUseSwiper] = useState(true)

    // Context
    const poweredByCtx = useContext(poweredByContext)

    /*
    * Replace Shy Aviation with the tenant name, if necessary
    */
    const replaceName = (str) => {
        if (str) {
            return replaceShyName(str, poweredByCtx.isPoweredBy, poweredByCtx?.tenant?.tenantName)
        }
    }

    // Listen for window resize events
    useEffect(() => {
        const resizeHandler = () => {
            setUseSwiper(!window.matchMedia('(min-width: 765px)').matches)
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    return (
        <section className='block-articles'>
            <div className='wrap'>
                <header className='block-header block-header--light'>
                    {createHeading(subHeading, replaceName(subtitle), true)}
                    {createHeading(mainHeading, replaceName(title), false)}
                </header>
                {relatedArticles &&
                    <div className='block-articles__content'>
                        {useSwiper
                            ?
                                <Swiper
                                    modules={[Pagination]}
                                    className='block-articles__swiper'
                                    slidesPerView='auto'
                                    speed={600}
                                    pagination={{
                                        dot: true
                                    }}
                                >
                                    {relatedArticles.map((article, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <BlogArticlePreview data={article} key={article.id} inContentBlock />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            :
                                <div className='block-articles__grid'>
                                    {relatedArticles.map((article, i) => {
                                        return <BlogArticlePreview data={article} key={article.id} inContentBlock />
                                    })}
                                </div>
                        }
                    </div>
                }
            </div>
        </section>
    )
}

export default BlockArticles