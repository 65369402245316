import React, {useContext, useEffect, useState} from 'react'
import {NumericFormat} from 'react-number-format'
import getClassName from '../utilities/getClassName'
import headRoom from '../assets/icons/head-room.svg'
import cabinCrew from '../assets/icons/cabin-crew-dark.svg'
import seats from '../assets/icons/seats.svg'
import luggage from '../assets/icons/luggage-dark.svg'
import carbonEmissions from '../assets/icons/co2.svg'
import toilet from '../assets/icons/toilet-dark.svg'
import catering from '../assets/icons/catering-dark.svg'
import bed from '../assets/icons/bed-dark.svg'
import wifi from '../assets/icons/wifi-dark.svg'
import tick from '../assets/icons/tick.svg'
import cross from '../assets/icons/cross.svg'
import {useNavigate} from 'react-router-dom'
import Button from './Button'
import QuoteCtaBar from './QuoteCtaBar'
import Share from './Share'
import Modal from './Modal'
import poweredByContext from '../store/PoweredByContext'

import pilots from '../assets/icons/pilots-dark.svg'
import subjectToWeight from '../assets/icons/subject-to-weight-dark.svg'
import refurbished from '../assets/icons/refurbished-dark.svg'
import cabinWidth from '../assets/icons/cabin-width-dark.svg'
import cabinLength from '../assets/icons/cabin-length-dark.svg'
import smoking from '../assets/icons/smoking-dark.svg'
import pets from '../assets/icons/pets-dark.svg'
import subjectToConditions from '../assets/icons/subject-to-conditions-dark.svg'
import fuelStop from '../assets/icons/fuel-stop-dark.svg'
import ownerApproval from '../assets/icons/owner-approval-dark.svg'

const Compare = ({aircraftData, compareSelection, legId, clearAllHandler, sharedPage, backLink}) => {

    // State
    const [showSharePanel, setShowSharePanel] = useState(false)
    const [tailNumbers, setTailNumbers] = useState([])

    // Context
    const poweredByCtx = useContext(poweredByContext)

    // Hooks
    const navigate = useNavigate()

    // Build selected flights array
    const selectedFlights = []

    compareSelection.map(chosenFlight => {
        chosenFlight = chosenFlight.split('___')
        const addThis = aircraftData.filter(aircraft => aircraft.id === chosenFlight[0] && aircraft.tailNumber === chosenFlight[1])[0]
        selectedFlights.push(addThis)
        return false
    })

    const getShareRef = () => {
        let shareRef = []

        compareSelection.forEach(item => {
            item = item.split('___')
            shareRef.push(item[0])
        })

        return shareRef
    }

    const getTailNumbers = () => {
        let flightTailNumbers = []

        selectedFlights.forEach(flight => {
            flightTailNumbers = tailNumbers
            flightTailNumbers.push(flight.tailNumber)
        })

        return flightTailNumbers
    }

    if (selectedFlights.length > 0 && tailNumbers.length === 0) {
        setTailNumbers(getTailNumbers())
    }

    // Set back link
    let link = `/quote${backLink}`
    if (sharedPage) {
        link = `/shared-quote${backLink}`
    }

    // Navigate to quote
    const navigateBackToQuoteHandler = () => {
        clearAllHandler()
        navigate(link)
    }

    useEffect(() => {
        if (!compareSelection.length) {
            navigate(link)
        }
    }, [compareSelection.length, link, navigate])

    return(
        <>
            <div className='compare'>

                <QuoteCtaBar
                    page='compare'
                    compareSelection={compareSelection}
                    legId={legId}
                    clearAllHandler={clearAllHandler}
                    showShare={() => setShowSharePanel(true)}
                    sharedPage={sharedPage}
                    backLink={backLink}
                />

                <div className='compare__scroll-wrapper'>

                    <div className='compare__table-fixed-header'>
                        <div className='compare__table-backgrounds'>
                            <div className='compare__table-backgrounds__background1' />
                            <div className='compare__table-backgrounds__background2' />

                            <div className='compare__table'>
                                <div className='compare__table__cell compare__table__cell--title'>Aircraft Type</div>
                                <div title='Head room' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Head room</div>
                                    <img src={headRoom} alt='' />
                                </div>
                                <div title='Seats' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Seats</div>
                                    <img src={seats} alt='' />
                                </div>
                                <div title='Luggage' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Luggage</div>
                                    <img src={luggage} alt='' />
                                </div>
                                <div title='Carbon Emissions' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Carbon Emissions</div>
                                    <img src={carbonEmissions} alt='' />
                                </div>
                                <div title='Toilet' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Toilet</div>
                                    <img src={toilet} alt='' />
                                </div>
                                <div title='Flight attendants' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Flight attendants</div>
                                    <img src={cabinCrew} alt='' />
                                </div>
                                <div title='Catering' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Catering</div>
                                    <img src={catering} alt='' />
                                </div>
                                <div title='Bed' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Bed</div>
                                    <img src={bed} alt='' />
                                </div>
                                <div title='Wifi' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Wifi</div>
                                    <img src={wifi} alt='' />
                                </div>
                                <div title='Pilots' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Pilots</div>
                                    <img src={pilots} alt='' />
                                </div>
                                <div title='Subject to Weight' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Subject to Weight</div>
                                    <img src={subjectToWeight} alt='' />
                                </div>
                                <div title='Refurbished' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Refurbished</div>
                                    <img src={refurbished} alt='' />
                                </div>
                                <div title='Cabin width' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Cabin Width</div>
                                    <img src={cabinWidth} alt='' />
                                </div>
                                <div title='Cabin length' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Cabin length</div>
                                    <img src={cabinLength} alt='' />
                                </div>
                                <div title='Smoking' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Smoking</div>
                                    <img src={smoking} alt='' />
                                </div>
                                <div title='Pets' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Pets</div>
                                    <img src={pets} alt='' />
                                </div>
                                <div title='Subject to conditions' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Subject to conditions</div>
                                    <img src={subjectToConditions} alt='' />
                                </div>
                                <div title='Fuel stop' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Fuel stop</div>
                                    <img src={fuelStop} alt='' />
                                </div>
                                <div title='Owner approval' className='compare__table__cell compare__table__cell--header'>
                                    <div className='compare__table__cell__text'>Owner approval</div>
                                    <img src={ownerApproval} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='compare__table-backgrounds'>
                        <div className='compare__table-backgrounds__background1' />
                        <div className='compare__table-backgrounds__background2' />
                        <div className='compare__table-backgrounds__background-desktop' />

                        <div className='compare__table'>

                            {selectedFlights.map(aircraft => {
                                return(
                                    <React.Fragment key={aircraft.id}>
                                        <div className={getClassName(['compare__table__cell', 'compare__table__cell--title', aircraft.recommended ? 'compare__table__cell--gold' : null])}><b>{aircraft.aircraftName}</b><br />
                                            {aircraft.category}<br />
                                            {aircraft.operatorCurrency === "GBP" && <>£</>}
                                            {aircraft.operatorCurrency === "USD" && <>$</>}
                                            {aircraft.operatorCurrency === "EUR" && <>€</>}
                                            <NumericFormat
                                                displayType='text'
                                                value={aircraft.operatorCurrency ? aircraft.price[aircraft.operatorCurrency.toLowerCase()] : 0}
                                                thousandSeparator
                                                decimalScale={0}
                                            />
                                        </div>

                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.headRoom}m</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.seats}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.luggage}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>
                                            <NumericFormat
                                                displayType='text'
                                                value={aircraft.co2}
                                                decimalScale={2}
                                            />T
                                        </div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.toilet ? <img src={tick} alt='Yes' /> : <img src={cross} alt='No' />}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.flightAttendants === 'Y' ? <img src={tick} alt='Yes' /> : <img src={cross} alt='No' />}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.catering.provided ? <img src={tick} alt='Yes' /> : <img src={cross} alt='No' />}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.bed ? <img src={tick} alt='Yes' /> : <img src={cross} alt='No' />}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.wifi ? <img src={tick} alt='Yes' /> : <img src={cross} alt='No' />}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.numberOfPilots}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.subjectToWeight === 'Y' ? <img src={tick} alt='Yes' /> : <img src={cross} alt='No' />}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.refurbishedYear}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.cabinWidth}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.cabinLength}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.smokingOption === 'Y' ? <img src={tick} alt='Yes' /> : <img src={cross} alt='No' />}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.pets}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.subjectToConditions}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.fuelStop}</div>
                                        <div className={getClassName(['compare__table__cell', aircraft.recommended ? 'compare__table__cell--gold' : null])}>{aircraft.ownerApproval === 'Y' ? <img src={tick} alt='Yes' /> : <img src={cross} alt='No' />}</div>
                                    </React.Fragment>
                                )
                            })}

                        </div>
                    </div>
                </div>

                <div className='wrap'>
                    <div className='form-button-wrapper'>
                        <Button onClick={navigateBackToQuoteHandler} classNames={['button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary']} animate={true}>Back to Quote</Button>
                    </div>
                </div>

            </div>

            <Modal
                classNames={['modal__content--thin']}
                modalVisible={showSharePanel}
                closeModal={() => {setShowSharePanel(false)}}
            >
                <Share
                    type='flight'
                    endpoint={'/api/frontend/share-quote'}
                    shareRef={getShareRef()}
                    close={() => {setShowSharePanel(false)}}
                    tailNumbers={tailNumbers}
                />
            </Modal>
        </>
    )
}

export default Compare