const filters = [{
    title: 'Very Light',
    slug: 'Very Light Jet'
},{
    title: 'Light',
    slug: 'Light Jet'
},{
    title: 'Super Light',
    slug: 'Super Light Jet'
},{
    title: 'Mid-size',
    slug: 'Mid-Size Jet'
},{
    title: 'Super Mid-size',
    slug: 'Super Mid-Size Jet'
},{
    title: 'Heavy',
    slug: 'Heavy Jet'
},{
    title: 'Ultra Long Range',
    slug: 'Ultra-Long Range Jet'
}]

export default filters