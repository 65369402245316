import TitleBar from '../components/TitleBar'
import pendingIcon from '../assets/icons/tick-big.svg'
import Button from '../components/Button'
import {Link,} from 'react-router-dom'
import SEO from '../components/SEO'

const ApproveFlightsSuccess = () => {

    return (
        <>
            <SEO title='Selected Flights'  />
            <TitleBar title='Selected Flights' cta='Home' url={`/`}/>
            <div className='page'>
                <div className='wrap'>
                    <article className='big-message'>
                        <div className='big-message__container'>
                            <figure className='big-message__image'>
                                <img src={pendingIcon} alt='' />
                            </figure>
                            <h1 className='big-message__title'>Approval Sent</h1>
                            <div className='big-message__intro'>
                                <p>Thank you for your selection</p>
                            </div>
                            <div className='big-message__text'>
                                <p>Your choices have been sent to the booker for this trip. They will review them and come back to you.</p>
                            </div>
                        </div>
                        <div className='form-button-wrapper wrap'>
                            <Link to={`/`}><Button>Home</Button></Link>
                        </div>
                    </article>
                </div>
            </div>
        </>
    )
}

export default ApproveFlightsSuccess