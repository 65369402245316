import TitleBar from '../../components/TitleBar'
import background from '../../assets/images/carbon-emissions-background.jpeg'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Pagination} from "swiper";
import {useEffect, useState} from 'react'
import EnvironmentalProject from '../../components/EnvironmentalProject'
import JourneyLeg from '../../components/JourneyLeg'
import Modal from '../../components/Modal'
import useModal from '../../hooks/useModal'
import CarbonNeutral from '../../components/CarbonNeutral'
import {Link} from 'react-router-dom'
import Button from '../../components/Button'
import SEO from '../../components/SEO'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const CarbonEmissions = ({thanks}) => {

    // Hooks
    const {openModal, closeModal, modalVisible} = useModal()

    // State
    const [layout, setLayout] = useState(null)

    // Listen for window resize events
    useEffect(() => {
        let resizeTimeout
        const resizeHandler = () => {
            clearTimeout(resizeTimeout)
            resizeTimeout = setTimeout(() => {
                if (!window.matchMedia('(max-width: 1020px)').matches) {
                    setLayout(8)
                } else {
                    setLayout(9)
                }
            }, 100)
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    return (
        <>
            <SEO title='Carbon Emissions' />
            <TitleBar title='Carbon Emissions' cta='Home' url='/'/>

            <div className='carbon-emissions'>
                <div className='carbon-emissions__header' style={{backgroundImage: `url(${background})`}}>
                    <div className='wrap'>
                        <div className='carbon-emissions__header__thank-you'>Thank You</div>
                        <div className='carbon-emissions__header__info'>
                            {thanks ?
                                'Your donation of £30 to Kariba Forest Protection has saved another 0.95mt'
                                :
                                'Keep protecting our planet with SHY Flights'
                            }
                        </div>

                        <div className='carbon-emissions__header__you-saved-a-total-of'>
                            Since the launch of our new website Shy has saved a total of
                        </div>
                        <div className='carbon-emissions__header__mt-number'>X.X MT</div>
                        <div className='carbon-emissions__header__you-saved-a-total-of'>
                            {thanks ?
                                'Your new total is'
                                :
                                'and on your behalf has saved a total of'
                            }
                        </div>
                        <div className='carbon-emissions__header__mt-number'>X.X MT</div>
                        {!thanks && <button onClick={openModal} className='carbon-emissions__header__how-does-this-work'>Being Carbon Neutral</button>}
                        {/*<div className='carbon-emissions__header__see-how-you-saved'>See how you saved & who you have helped with each flight <a href='#your-flights'>here</a>.</div>*/}
                    </div>
                </div>
                <div className='page'>

                    <div className='carbon-emissions__projects'>

                        <div className='carbon-emissions__projects__title'>We offset carbon emissions by donating to these projects.</div>

                        <div className='carbon-emissions__projects__swiper-container'>
                            <Swiper
                                grabCursor={true}
                                speed={400}
                                loop={true}
                                modules={[Pagination]}
                                touchStartPreventDefault={false}
                                className='swiper'
                                slidesPerView='auto'
                                pagination={true}
                                breakpoints={{
                                    0: {
                                        grabCursor: true,
                                        noSwiping: false,
                                        slidesPerView: 1,
                                        spaceBetween: 0
                                    },
                                    700: {
                                        grabCursor: true,
                                        noSwiping: false,
                                        slidesPerView: 2,
                                        spaceBetween: 0
                                    },
                                    1024: {
                                        grabCursor: false,
                                        noSwipingClass: 'swiper-slide',
                                        noSwiping: true,
                                        slidesPerView: 3,
                                        spaceBetween: 0
                                    },
                                    1200: {
                                        noSwiping: true,
                                        noSwipingClass: 'swiper-slide',
                                        grabCursor: false,
                                        slidesPerView: 3,
                                        spaceBetween: 10
                                    }
                                }}
                            >
                                <SwiperSlide key={1}>
                                    <EnvironmentalProject
                                        projectNo={1}
                                        title='India Biolite Homestoves - £9/MT'
                                        writeUp='Improving health and access to affordable energy for Indian communities'
                                    />
                                </SwiperSlide>

                                <SwiperSlide key={2}>
                                    <EnvironmentalProject
                                        projectNo={2}
                                        title='Kariba Forest Protection - £12/MT'
                                        writeUp='Improving health and access to affordable energy for Indian communities'
                                    />
                                </SwiperSlide>

                                <SwiperSlide key={3}>
                                    <EnvironmentalProject
                                        projectNo={3}
                                        title='Tropical Conservation - £18/MT'
                                        writeUp='Improving health and access to affordable energy for Indian communities'
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </div>

                    </div>

                    {/*<div className='carbon-emissions__planet-hero'>*/}
                    {/*    <div className='wrap'>*/}
                    {/*        <div className='carbon-emissions__planet-hero__title'>Be a Planet Hero</div>*/}
                    {/*        <div className='carbon-emissions__planet-hero__sub-title'>Offset more carbon emissions</div>*/}
                    {/*        <div className='carbon-emissions__planet-hero__button-wrapper'>*/}
                    {/*            <Link to='/carbon-emissions/purchase-more'><Button classNames={['button--gold-border', 'button--full']} >Buy More Offset</Button></Link>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/* Anchor point to scroll to */}
                    {/*<div id='your-flights' style={{transform: 'translate(0, -30px)'}} />*/}

                    {/*<div className='carbon-emissions__journeys'>*/}

                    {/*    <div className='journeys__table'>*/}

                    {/*        <div className='journeys__table__backgrounds'>*/}
                    {/*            <div className='journeys__table__backgrounds__blue' />*/}
                    {/*            <div className='journeys__table__backgrounds__blue' />*/}
                    {/*            <div className='journeys__table__backgrounds__green' />*/}
                    {/*        </div>*/}

                    {/*        <div className='journeys__table__header'>*/}
                    {/*            <div className='journeys__table__header__title'>*/}
                    {/*                Flight Details*/}
                    {/*            </div>*/}

                    {/*            <div className='journeys__table__header__title'>*/}
                    {/*                Aircraft Type*/}
                    {/*            </div>*/}

                    {/*            <div className='journeys__table__header__title journeys__table__header__title--carbon-emissions'>*/}
                    {/*                Carbon Emissions*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className='journeys__table__journey'>*/}
                    {/*            <div className='journeys__table__journey-leg'>*/}
                    {/*                <JourneyLeg*/}
                    {/*                    journeyType='jets'*/}
                    {/*                    layout={layout}*/}
                    {/*                    fromLong='London Luton Airport'*/}
                    {/*                    fromShort='LTN'*/}
                    {/*                    toLong='Paris Charles de gualle'*/}
                    {/*                    toShort='CDH'*/}
                    {/*                    departureDate='2022-10-10'*/}
                    {/*                    departureTime='08:15 GMT'*/}
                    {/*                    arrivalDate='2022-10-10'*/}
                    {/*                    arrivalTime='11:45 CET'*/}
                    {/*                    luggage='3'*/}
                    {/*                    passengers='4'*/}
                    {/*                    isSimple*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div className='journeys__table__aircraft-details'>*/}
                    {/*                <div className='journeys__table__aircraft-details__title'>Aircraft type:</div>*/}
                    {/*                <div className='journeys__table__aircraft-details__info'>Citation Bravo</div>*/}

                    {/*                <div className='journeys__table__aircraft-details__title'>Max speed:</div>*/}
                    {/*                <div className='journeys__table__aircraft-details__info'>746km/h</div>*/}
                    {/*            </div>*/}
                    {/*            <div className='journeys__table__aircraft-details'>*/}
                    {/*                <div className='journeys__table__aircraft-details__title journeys__table__aircraft-details__title--carbon-emissions'>Carbon Emissions:</div>*/}
                    {/*                <div className='journeys__table__aircraft-details__info journeys__table__aircraft-details__info--carbon-emissions'>*/}
                    {/*                    <div className='journeys__table__aircraft-details__info__carbon-number'>1.79</div>*/}
                    {/*                    metric ton*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className='journeys__table__journey'>*/}
                    {/*            <div className='journeys__table__journey-leg'>*/}
                    {/*                <JourneyLeg*/}
                    {/*                    journeyType='jets'*/}
                    {/*                    layout={layout}*/}
                    {/*                    fromLong='London Luton Airport'*/}
                    {/*                    fromShort='LTN'*/}
                    {/*                    toLong='Paris Charles de gualle'*/}
                    {/*                    toShort='CDH'*/}
                    {/*                    departureDate='2022-10-10'*/}
                    {/*                    departureTime='08:15 GMT'*/}
                    {/*                    arrivalDate='2022-10-10'*/}
                    {/*                    arrivalTime='11:45 CET'*/}
                    {/*                    luggage='3'*/}
                    {/*                    passengers='4'*/}
                    {/*                    isSimple*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div className='journeys__table__aircraft-details'>*/}
                    {/*                <div className='journeys__table__aircraft-details__title'>Aircraft type:</div>*/}
                    {/*                <div className='journeys__table__aircraft-details__info'>Citation Bravo</div>*/}

                    {/*                <div className='journeys__table__aircraft-details__title'>Max speed:</div>*/}
                    {/*                <div className='journeys__table__aircraft-details__info'>746km/h</div>*/}
                    {/*            </div>*/}
                    {/*            <div className='journeys__table__aircraft-details'>*/}
                    {/*                <div className='journeys__table__aircraft-details__title journeys__table__aircraft-details__title--carbon-emissions'>Carbon Emissions:</div>*/}
                    {/*                <div className='journeys__table__aircraft-details__info journeys__table__aircraft-details__info--carbon-emissions'>*/}
                    {/*                    <div className='journeys__table__aircraft-details__info__carbon-number'>1.79</div>*/}
                    {/*                    metric ton*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className='journeys__table__journey'>*/}
                    {/*            <div className='journeys__table__journey-leg'>*/}
                    {/*                <JourneyLeg*/}
                    {/*                    journeyType='jets'*/}
                    {/*                    layout={layout}*/}
                    {/*                    fromLong='London Luton Airport'*/}
                    {/*                    fromShort='LTN'*/}
                    {/*                    toLong='Paris Charles de gualle'*/}
                    {/*                    toShort='CDH'*/}
                    {/*                    departureDate='2022-10-10'*/}
                    {/*                    departureTime='08:15 GMT'*/}
                    {/*                    arrivalDate='2022-10-10'*/}
                    {/*                    arrivalTime='11:45 CET'*/}
                    {/*                    luggage='3'*/}
                    {/*                    passengers='4'*/}
                    {/*                    isSimple*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div className='journeys__table__aircraft-details'>*/}
                    {/*                <div className='journeys__table__aircraft-details__title'>Aircraft type:</div>*/}
                    {/*                <div className='journeys__table__aircraft-details__info'>Citation Bravo</div>*/}

                    {/*                <div className='journeys__table__aircraft-details__title'>Max speed:</div>*/}
                    {/*                <div className='journeys__table__aircraft-details__info'>746km/h</div>*/}
                    {/*            </div>*/}
                    {/*            <div className='journeys__table__aircraft-details'>*/}
                    {/*                <div className='journeys__table__aircraft-details__title journeys__table__aircraft-details__title--carbon-emissions'>Carbon Emissions:</div>*/}
                    {/*                <div className='journeys__table__aircraft-details__info journeys__table__aircraft-details__info--carbon-emissions'>*/}
                    {/*                    <div className='journeys__table__aircraft-details__info__carbon-number'>1.79</div>*/}
                    {/*                    metric ton*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className='form-button-wrapper wrap'>
                        <Link to='/quotes-and-bookings'><Button>Quotes & Bookings</Button></Link>
                        <Link to='/'><Button classNames={['button--white']}>Home</Button></Link>
                    </div>
                </div>
            </div>
            <Modal
                modalVisible={modalVisible}
                closeModal={closeModal}
            >
                <CarbonNeutral closeModal={closeModal} />
            </Modal>
        </>
    )
}

export default CarbonEmissions