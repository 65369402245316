import {useContext, useEffect, useState} from 'react'
import Image from '../components/Image'
import {Parallax} from 'react-scroll-parallax'
import getClassName from '../utilities/getClassName'
import SEO from '../components/SEO'
import NewsletterForm from '../components/NewsletterForm'
import parse from 'html-react-parser'
import {useGetCacheContent} from '../hooks/useGetCacheContent'
import poweredByContext from '../store/PoweredByContext'
import LazyLoadingContext from '../store/LazyLoadingContext'
import {getUrl} from '../utilities/url'
import QuoteQueryJets from '../components/QuoteQueryJets'

const Careers = () => {

    // State
    const [animate, setAnimate] = useState([])

    // Get and cache content
    const {content: careersContent} = useGetCacheContent('careersContent', '/api/content/careers', 500)

    const poweredByCtx = useContext(poweredByContext)
    const lazyLoadingCtx = useContext(LazyLoadingContext)

    useEffect(() => {
        
        return () => {
            lazyLoadingCtx.reset('careers')
        }
    }, [])

    useEffect(() => {
        lazyLoadingCtx.updatePageCheck('careers', {
            careersContent: careersContent !== null
        })
    }, [careersContent])
    
    // Animate In Out with parallax
    const animateInOutHandler = (progress, percent, animRef) => {
        if (progress > percent && !animate[animRef]) {
            const updateAnim = {...animate, [animRef]: true}
            setAnimate(updateAnim)
        }

        if (progress === 0 && animate[animRef]) {
            const updateAnim = {...animate, [animRef]: false}
            setAnimate(updateAnim)
        }
    }

    // Function to dynamically create heading element
    const renderHeading = (title, tag, sub) => {
        const Tag = tag ? tag.toLowerCase() : (sub ? 'h2' : 'h1');
        if (sub) {
            return <Tag className='brochure__subtitle'>{title}</Tag>
        }
        return <Tag className='brochure__title'>{title}</Tag>
    }

    return (
        <>
            {careersContent &&
                <SEO
                    title={careersContent.titleStrip.metaTitle}
                    description={careersContent.titleStrip.metaDescription}
                    canonicalUrl={getUrl(poweredByCtx, window.location.pathname)}
                />
            }
            {!lazyLoadingCtx.pageReady || !careersContent 
                ?
                <>
                    <div className='wrap'>
                        <QuoteQueryJets onPage skeleton />
                    </div>
                    <div className='brochure brochure--skeleton'>
                        <section>
                            <div className='brochure__title-band'>
                                <h1>Careers</h1>
                            </div>

                            <div className='brochure__banner'></div>
                        </section>
                    </div>
                </>
                :
                <>
                    {careersContent?.stickyQuoteBool && (
                        <div className='wrap'>
                            <QuoteQueryJets onPage />
                        </div>
                    )}
                    
                    <div className='brochure'>

                        <section className={careersContent?.stickyQuoteBool ? '' : 'brochure__first-section2'}>

                            <div className='brochure__title-band'>
                                <h1 className='brochure__title'>Careers</h1>
                            </div>

                            <div className='brochure__banner'>
                                <Image src={careersContent.bannerImage} fillParent showLoading />
                            </div>
                        </section>

                        <div className='brochure__title-band brochure__title-band--grey'>
                            {renderHeading(careersContent.titleStrip.subTitle, careersContent.titleStrip.headingSubTitle, true)}
                            {renderHeading(careersContent.titleStrip.title, careersContent.titleStrip.headingTitle, false)}
                        </div>

                        {careersContent.sections.map((section, i) => {
                            return (
                                <section key={`section${i}`} className={getClassName([(i % 2 === 1) && 'brochure__section--white'])}>
                                    <div className='wrap brochure__inner'>
                                        <Parallax
                                            onProgressChange={(progress) => {
                                                animateInOutHandler(progress, 0.15, `title${i}`)
                                            }}
                                            className={getClassName(['fade-and-travel', animate[`title${i}`] && 'fade-and-travel--visible'])}
                                        >
                                            {renderHeading(section.subTitle, section.headingSubTitle, true)}
                                            {renderHeading(section.subTitle, section.headingTitle, false)}
                                        </Parallax>

                                        <div className={getClassName(['brochure__2-column-layout', (i % 2 === 1) && 'brochure__2-column-layout--flipped'])}>
                                            <Parallax
                                                onProgressChange={(progress) => {
                                                    animateInOutHandler(progress, 0.15, `image${i}`)
                                                }}
                                                className={getClassName(['brochure__2-column-layout__image', 'fade-and-travel', animate[`image${i}`] && 'fade-and-travel--visible'])}
                                            >
                                                <Image fillParent showLoading src={section.imageHolder.url} alt={section.imageHolder.alt} />
                                            </Parallax>

                                            <Parallax
                                                onProgressChange={(progress) => {
                                                    animateInOutHandler(progress, 0.2, `body${i}`)
                                                }}
                                                className={getClassName(['brochure__2-column-layout__text', 'fade-and-travel', animate[`body${i}`] && 'fade-and-travel--visible'])}
                                            >
                                                {parse(section.body)}
                                            </Parallax>
                                        </div>
                                    </div>
                                </section>
                            )
                        })}

                        <section>
                            <div className='wrap brochure__inner'>

                                {/* Title 3 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'title3')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.title3 && 'fade-and-travel--visible'])}
                                >
                                    <h2 className='brochure__subtitle'>Our Values</h2>
                                    <h2 className='brochure__title'>Our Moral Compass</h2>
                                </Parallax>

                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'homePanel1')
                                    }}
                                    className={getClassName(['brochure__panels', 'home-panels-anim', animate.homePanel1 && 'home-panels-anim--visible'])}
                                >
                                    {careersContent.cards.map((card, i) => {
                                        return (
                                            <Parallax
                                                key={`panel${i}`}
                                                onProgressChange={(progress) => {animateInOutHandler(progress, 0.15, `panel${i}`)}}
                                                className={getClassName(['brochure__panel', 'fade-and-travel--mobile', 'fade-and-travel', animate[`panel${i}`] && 'fade-and-travel--visible'])}
                                            >
                                                <div className='brochure__panel__title'>
                                                    {card.title}
                                                </div>
                                                <div className='brochure__panel__body'>
                                                    {card.body}
                                                </div>
                                            </Parallax>
                                        )
                                    })}
                                </Parallax>
                            </div>
                        </section>

                        <section className='brochure__section--white'>
                            <div className='wrap brochure__inner'>

                                {/* Title 4 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'title4')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.title4 && 'fade-and-travel--visible'])}
                                >
                                    <h2 className='brochure__subtitle'>Hiring Now</h2>
                                    <h2 className='brochure__title'>Browse Our Jobs</h2>
                                </Parallax>

                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'iframe')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.iframe && 'fade-and-travel--visible'])}
                                >
                                    <iframe data-preserve-html-node="true"
                                            src="https://careers.ikrut.com/b2382194-180c-4ad0-a36c-020db5a5ec17/0?embedded=true"
                                            width="100%"
                                            height="700px"
                                            title='Careers'
                                    />
                                </Parallax>

                            </div>
                        </section>
                    </div>
                    <NewsletterForm />
                </>
            }
        </>
    )
}

export default Careers