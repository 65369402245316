import {useEffect, useState} from 'react'
import DatePickerInput from './DatePickerInput'
import FromToInput from './FromToInput'
import TimePickerInput from './TimePickerInput'
import Button from './Button'
import Modal from './Modal'
import useModal from '../hooks/useModal'
import QuoteQueryPersonalDetails from './QuoteQueryPersonalDetails'
import getClassName from '../utilities/getClassName'
import parse from "date-fns/parse"
import format from "date-fns/format"

// Icons
import plusIcon from '../assets/icons/add-leg.svg'
import closeCrossDark from '../assets/icons/close-cross-dark-heavy.svg'
import exclamationIcon from '../assets/icons/exclamation-mark.svg'
import TextInputAlt from './TextInputAlt'

const QuoteQueryGroupCharter = () => {

    // State
    const [journeyCounter, setJourneyCounter] = useState(1)
    const [journeyData, setJourneyData] = useState([{id: journeyCounter, passengers: 1, luggage: 1, journeyType: 'group-charter'}])
    const [validateForm, setValidateForm] = useState(false)
    const [formIsValid, setFormIsValid] = useState(false)
    const [showError, setShowError] = useState(false)

    // Hooks
    const {modalVisible, openModal, closeModal} = useModal()

    // Add journey handler
    const addJourneyHandler = () => {
        setJourneyCounter(journeyCounter + 1)

        // Get last journey
        const lastJourney = journeyData[journeyData.length - 1]

        // Set date to 3 days time
        let newDate
        if (lastJourney['date']) {
            newDate = parse(lastJourney['date'], "dd/MM/yyyy", new Date())
            newDate.setDate(newDate.getDate() + 3)
            newDate = format(newDate, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
        }

        const newJourney = {
            journeyType: 'group-charter',
            id: (journeyCounter + 1),
            flightFrom: lastJourney['flightTo'],
            flightTo: lastJourney['flightFrom'],
            date: newDate,
            time: lastJourney['time'],
            luggage: lastJourney['luggage'],
            passengers: lastJourney['passengers']
        }

        const addJourney = [...journeyData, newJourney]

        setJourneyData(addJourney)
        setValidateForm(false)
        setFormIsValid(false)
    }

    // Remove journey handler
    const removeJourneyHandler = (index) => {
        let removeJourney = []
        for (let i = 0; i < journeyData.length; i++) {
            if (i !== index) {
                removeJourney = [...removeJourney, journeyData[i]]
            }
        }
        setJourneyData(removeJourney)
    }

    // Reset journey handler
    const resetJourneyHandler = () => {
        setValidateForm(false)
        setFormIsValid(false)
        setJourneyCounter(1)
        setJourneyData([{id: 1, passengers: null, luggage: 1}])
    }

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        if (formIsValid) {
            openModal()
        } else {
            setShowError(true)
        }
    }

    // Validate Form
    const validateFormHandler = () => {

        let errors = false

        journeyData.map((journey) => {

            if (!journey.flightFrom) {
                errors ++
            }

            if (!journey.flightTo) {
                errors ++
            }

            if (!journey.date) {
                errors ++
            }

            if (!journey.time) {
                errors ++
            }

            if (!journey.passengers) {
                errors ++
            }
            return false
        })

        if (!errors) {
            setShowError(false)
            setFormIsValid(true)
        } else {
            setFormIsValid(false)
        }
    }

    // Effect
    useEffect(() => {
        validateFormHandler()
    })

    // Update input handler
    const updateInputHandler = (index, key, value) => {
        const originalJourney = journeyData[index]
        const updatedJourney = {...originalJourney, [key]: value}
        const addToJourneys = journeyData
        addToJourneys.splice(index, 1, updatedJourney)
        setJourneyData(addToJourneys)
        validateFormHandler()
    }

    return (
        <>
            <div className='quote-query'>
                <form onSubmit={submitHandler}>
                    {journeyData.map((journey, i) => {

                        return (
                            <div key={journey.id} className={getClassName(['quote-query__inputs', (journeyData.length > 1) && 'quote-query__inputs--with-delete'])}>

                                <div className='quote-query__inputs__column'>
                                    <FromToInput
                                        value={(fromValue) => updateInputHandler(i, 'flightFrom', fromValue)}
                                        defaultValue={journeyData[i]['flightFrom']}
                                        direction='from'
                                        label='From'
                                        required={true}
                                        errorOnBlur={false}
                                        altErrorStyle={true}
                                        validateForm={validateForm}
                                    />
                                </div>
                                <div className='quote-query__inputs__column'>
                                    <FromToInput
                                        value={(toValue) => updateInputHandler(i, 'flightTo', toValue)}
                                        defaultValue={journeyData[i]['flightTo']}
                                        direction='to'
                                        label='To'
                                        required={true}
                                        errorOnBlur={false}
                                        validateForm={validateForm}
                                    />
                                </div>
                                <div className='quote-query__inputs__column quote-query__inputs__column--medium-width'>
                                    <DatePickerInput
                                        value={(dateValue) => updateInputHandler(i, 'date', dateValue)}
                                        defaultValue={journeyData[i]['date']}
                                        label='Date'
                                        required={true}
                                        errorOnBlur={false}
                                        validateForm={validateForm}
                                    />
                                </div>
                                <div className='quote-query__inputs__column quote-query__inputs__column--medium-width'>
                                    <TimePickerInput
                                        value={(timeValue) => updateInputHandler(i, 'time', timeValue)}
                                        defaultValue={journeyData[i]['time']}
                                        label='Time'
                                        required={true}
                                        errorOnBlur={false}
                                        validateForm={validateForm}
                                    />
                                </div>
                                <div className='quote-query__inputs__column quote-query__inputs__column--shared'>
                                    <TextInputAlt
                                        value={(fromValue) => updateInputHandler(i, 'passengers', fromValue)}
                                        placeholder='Passengers'
                                        defaultValue={journeyData[i]['passengers']}
                                        required={true}
                                        icon='person'
                                        maxLength={20}
                                        validateForm={validateForm}
                                    />
                                </div>

                                {(journeyData.length > 1) &&
                                    <button type="button" onClick={() => removeJourneyHandler(i)} className='quote-query__delete-button'><img src={closeCrossDark} alt='' />Delete</button>
                                }

                            </div>
                        )
                    })}
                </form>

                <div className='quote-query__actions'>
                    <button className='quote-query__actions__add-leg-button' onClick={addJourneyHandler}><img alt='' src={plusIcon}/> Add leg / return</button>

                    {showError &&
                        <div className='quote-query__error'><img src={exclamationIcon} alt='' />Please complete your journey details</div>
                    }

                    <div className='quote-query__submit-container'>
                        <Button onClick={submitHandler} classNames={['button--full']}>Get a Quote</Button>
                    </div>
                </div>
            </div>

            <Modal
                modalVisible={modalVisible}
                closeCrossColour='white'
                closeModal={closeModal}
            >
                <QuoteQueryPersonalDetails
                    journeyType='group-charter'
                    journeyData={journeyData}
                    resetJourney={resetJourneyHandler}
                    journeyIsValid={formIsValid}
                    closeModal={closeModal}
                />
            </Modal>
        </>
    )
}

export default QuoteQueryGroupCharter