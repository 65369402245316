const project1 = {
    name: 'India Biolite <br />Homestoves - £9/MT',
    details: 'Improving health and access to affordable energy for Indian communities',
    projectPrice: 9,
}

const project2 = {
    name: 'Kariba Forest <br />Protection - £12/MT',
    details: 'Saving forests, protecting wildlife, and changing lives',
    projectPrice: 12,
}

const project3 = {
    name: 'Tropical <br />Conservation - £18/MT',
    details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit',
    projectPrice: 18,
}

export {project1, project2, project3}