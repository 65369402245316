import React from 'react'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import {useEffect, useState} from 'react'
import AircraftCardExtraContent from './AircraftCardExtraContent'
import OverlayFade from './OverlayFade'
import CloseCross from './CloseCross'
import getClassName from '../utilities/getClassName'
import MoreLessButton from './MoreLessButton'

const AircraftCardMoreLessTray = React.forwardRef(({column, expanded, aircraftData, dimAircraft, expandedHandler, sharedPage, legId}, ref) => {

    // State
    const [rememberColumn, setRememberColumn] = useState()

    // Update column if it changes
    useEffect(() => {
        if (column) {
            setRememberColumn(column)
        }
    }, [column])

    return (
        <SlideDown
            ref={ref}
            className={['aircraft-card-more-less-tray', `aircraft-card-more-less-tray--${rememberColumn}`].join(" ")}
            closed={!expanded}
        >
            <>
                <div className='aircraft-card-more-less-tray__join'>
                    <div className={getClassName([
                        'aircraft-card-more-less-tray__join__curve',
                        'aircraft-card-more-less-tray__join__curve--left',
                        dimAircraft ? 'aircraft-card-more-less-tray__join__curve--dim' : null
                    ])} />
                    <div className={getClassName([
                        'aircraft-card-more-less-tray__join__curve',
                        'aircraft-card-more-less-tray__join__curve--right',
                        dimAircraft ? 'aircraft-card-more-less-tray__join__curve--dim' : null
                    ])} />
                    <OverlayFade show={dimAircraft} />
                </div>
                <article className='aircraft-card-more-less-tray__inner'>
                    <AircraftCardExtraContent dimAircraft={dimAircraft} aircraftData={aircraftData} sharedPage={sharedPage} legId={legId} />
                    <OverlayFade show={dimAircraft} />
                    <MoreLessButton onClick={expandedHandler} selected={expanded} />
                </article>
                <div className='aircraft-card-more-less-tray__close-container'>
                    <CloseCross onClick={expandedHandler} />
                </div>
            </>
        </SlideDown>
    )
});

export default AircraftCardMoreLessTray