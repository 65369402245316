import {useRef} from 'react'
import DatePickerInput from './DatePickerInput'
import getClassName from '../utilities/getClassName'
import arrowIcon from '../assets/icons/chevron-down-dark.svg'
import tick from '../assets/icons/tick-gold.svg'
import useClickOutside from '../hooks/useClickOutside'

const Filter = ({type, title, filter, options, filterOptions, filterSelections, updateFilterSelections, active, updateActive}) => {

    const filterRef = useRef(null)

    useClickOutside([filterRef], () => {
        updateActive(null)
    })

    const updateFilter = (filter,option) => {
        let action = 'add'
        if (filterSelections[filter]) {
            if (filterSelections[filter].indexOf(option) > -1) {
                action = 'remove'
            }
        }
        updateFilterSelections(filter, option, action)
    }

    const filterIsActive = (filter, option) => {
        if (filterSelections[filter]) {
            return filterSelections[filter].indexOf(option) > -1
        }
    }

    const updateDateFilter = (filter, limit, value) => {
        updateFilterSelections(filter, {limit: limit, value: value}, 'add')
    }

    const resetFrom = (filter) => {
        if (filterSelections) {
            if (filterSelections[filter]) {
                if (filterSelections[filter].from) {
                    return false
                }
            }
        }
        return true
    }

    const resetTo = (filter) => {
        if (filterSelections) {
            if (filterSelections[filter]) {
                if (filterSelections[filter].to) {
                    return formatDate(filterSelections[filter].from) > formatDate(filterSelections[filter].to)
                }
            }
        }
        return true
    }

    const formatDate = (value) => {
        if (value) {
            value = value.split('/')

            return parseInt(`${value[2]}${value[1]}${value[0]}`)
        }
    }

    const getMinDate = (filter) => {
        if (filterSelections) {
            if (filterSelections[filter]) {
                if (filterSelections[filter].from) {
                    let date = filterSelections[filter].from.split('/')

                    return new Date(date[2], date[1] - 1, date[0])
                }
            }
        }
    }

    const toggleVisibility = () => {
        updateActive(active ? null : filter)
    }

    return <div className={getClassName(['filter', `filter--${type}`, active ? `filter--active` : ''])} ref={active ? filterRef : null}>
        <div className='filter__container'>
            <button className='filter__toggle' onClick={toggleVisibility}>
                {title}
                <img src={arrowIcon} alt='' />
            </button>
            <div className='filter__content'>
                <div className='filter__dropdown'>
                    {type === 'date' &&
                        <div className='filter__options'>
                            <div className='filter__option'>
                                <DatePickerInput
                                    value={(dateValue) => updateDateFilter(filter, 'from', dateValue)}
                                    label='From'
                                    required={true}
                                    errorOnBlur={false}
                                    altLayout={true}
                                    reset={resetFrom(filter)}
                                    pastAllowed={true}
                                />
                            </div>
                            <div className='filter__option'>
                                <DatePickerInput
                                    value={(dateValue) => updateDateFilter(filter, 'to', dateValue)}
                                    label='To'
                                    required={true}
                                    errorOnBlur={false}
                                    altLayout={true}
                                    reset={resetTo(filter)}
                                    pastAllowed={true}
                                    minDate={getMinDate(filter)}
                                />
                            </div>
                        </div>
                    }
                    {type === 'checkbox' &&
                        <div className='filter__options'>
                            {options.map((option, i) => {
                                return <button className='filter__option' key={i} onClick={updateFilter.bind(this, filter, option)}>
                                    <span className='filter__status'>
                                        {filterIsActive(filter, option) &&
                                            <img src={tick} alt='' />
                                        }
                                    </span>
                                    {option}
                                </button>
                            })}
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
}

export default Filter