const howHeardList = [
    {value: 'Web Search', label: 'Web Search'},
    {value: 'Instagram', label: 'Instagram'},
    {value: 'Facebook', label: 'Facebook'},
    {value: 'LinkedIn', label: 'LinkedIn'},
    {value: 'Referral', label: 'Referral'},
    {value: 'Email Campaign', label: 'Email Campaign'},
]

const howMuchTimeList = [
    {value: '-None-', label: '-None-'},
    {value: 'Full time', label: 'Full time'},
    {value: 'A few hours per day', label: 'A few hours per day'},
    {value: 'A few hours per week', label: 'A few hours per week'},
    {value: 'A few hours per month', label: 'A few hours per month'},
]

export {howHeardList,howMuchTimeList}