import getClassName from '../utilities/getClassName'

const Fieldset = ({classNames, children, marginBottom, skeleton}) => {

    let className = ['fieldset']
    if (marginBottom) {
        className = ['fieldset', 'fieldset--margin-bottom']
    }
    if (skeleton) {
        className.push('fieldset--skeleton')
    }
    if (classNames) {
        className = className.concat(classNames)
    }

    return (
        <fieldset className={getClassName(className)}>
            {children}
        </fieldset>
    )
}

export default Fieldset