import parse from 'html-react-parser'
import getClassName from '../utilities/getClassName'

const BlogContentBlock = ({ data }) => {
    if (data) {

        let className = ['blog-content-block']

        if (data.sectionBgImage) {
            className.push('blog-content-block--has-background')
        }

        if (data.preferredImagePosition) {
            className.push(`blog-content-block--align-${data.preferredImagePosition}`)
        }

        return (
            <section className={getClassName(className)}>
                {data.sectionBgImage &&
                    <figure className='blog-content-block__background'>
                        <img src={data.sectionBgImage} alt='' />
                    </figure>
                }
                <div className='blog-content-block__container'>
                    {data.contentImage &&
                        <div className='blog-content-block__image'>
                            <img src={data.contentImage} alt='' />
                        </div>
                    }
                    <div className='blog-content-block__content'>
                        {parse(data.mainContent)}
                    </div>
                </div>
            </section>
        )
    }
}

export default BlogContentBlock