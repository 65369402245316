import Button from './Button'
import chevron from '../assets/icons/chevron-down-grey.svg'
import downloadIcon from '../assets/icons/download.svg'
import React, {useEffect, useState} from 'react'
import SlideDown from 'react-slidedown/lib/slidedown'
import getClassName from '../utilities/getClassName'
import {NumericFormat} from 'react-number-format'
import {isFloatingFleet} from '../utilities/aircraft'

const OrderSummary = ({template, productInfo, invoiceUrl, showExtraContent, classNames, isOpen, isOpenOnLoad}) => {

    // State
    const [summaryOpen, setSummaryOpen] = useState(!!isOpenOnLoad)

    useEffect(() => {
        if (isOpen) {
            setSummaryOpen(true)
        }
    }, [isOpen])

    useEffect(() => {
        if (isOpenOnLoad) {
            setSummaryOpen(true)
        }
    }, [isOpenOnLoad])

    let className = ['order-summary']

    if (classNames) {
        if (typeof classNames === 'object') {
            className = className.concat(classNames)
        }
    }

    return (
        <aside className={getClassName(className)}>

            {/* Flight booking template */}
            {(template === 'flight-booking') &&
                <>
                    <button onClick={() => {
                        if (!isOpen) {
                            setSummaryOpen(!summaryOpen)
                        }
                    }} className='order-summary__title'>
                        Flight Details
                        <img className={getClassName(['order-summary__title__chevron', summaryOpen && 'order-summary__title__chevron--rotated'])} src={chevron} alt='' />
                    </button>
                    <div className='order-summary__line'/>

                    <SlideDown
                        closed={!summaryOpen}
                        transitionOnAppear={false}
                    >
                        <div>
                            {productInfo.flights.map((flight, i) => {
                                return <React.Fragment key={i}>
                                    {productInfo.flights.length > 1 &&
                                        <p className='order-summary__item-group-title'>{i === 0 ? 'Outbound' : 'Inbound'}</p>
                                    }
                                    <div className='order-summary__item'>
                                        <div className='order-summary__item__gold'>Departure</div>
                                        <div className='order-summary__item__bold'>{flight.flightFromIcao} {flight.flightFromLong} {flight.flightFromCountryCode &&
                                            <>, {flight.flightFromCountryCode}</>
                                        }</div>
                                        <div>
                                            {flight.departureTime} {flight.departureTimeZone}
                                        </div>
                                    </div>
                                    <div className='order-summary__item'>
                                        <div className='order-summary__item__gold'>Arrival</div>
                                        <div className='order-summary__item__bold'>{flight.flightToIcao} {flight.flightToLong} {flight.flightToCountryCode &&
                                            <>, {flight.flightToCountryCode}</>
                                        }</div>
                                        <div>
                                            {flight.arrivalTime} {flight.arrivalTimeZone}
                                        </div>
                                    </div>
                                </React.Fragment>
                            })}
                            <div className='order-summary__item'>
                                <div className='order-summary__item__gold'>Details</div>

                                <div className='order-summary__item__detail'>
                                    <div className='order-summary__item__detail__title'>Adults:</div>
                                    <div className='order-summary__item__detail__quantity'>{productInfo.passengers}</div>
                                </div>

                                <div className='order-summary__item__detail'>
                                    <div className='order-summary__item__detail__title'>Luggage:</div>
                                    <div className='order-summary__item__detail__quantity'>25kg x{productInfo.luggage}</div>
                                </div>

                                <div className='order-summary__item__detail'>
                                    <div className='order-summary__item__detail__title'>Aircraft:</div>
                                    <div className='order-summary__item__detail__quantity'>{productInfo.aircraftType}{isFloatingFleet(productInfo) ? ' (Floating Fleet)' : ''}</div>
                                </div>

                                <div className='order-summary__item__detail'>
                                    <div className='order-summary__item__detail__title'>Distance:</div>
                                    <div className='order-summary__item__detail__quantity'>{productInfo.distance}</div>
                                </div>

                                <div className='order-summary__item__detail'>
                                    <div className='order-summary__item__detail__title'>Flight time:</div>
                                    <div className='order-summary__item__detail__quantity'>{productInfo.flightTime}</div>
                                </div>

                                <div className='order-summary__item__detail'>
                                    <div className='order-summary__item__detail__title'>PAX:</div>
                                    <div className='order-summary__item__detail__quantity'>{productInfo.pax}</div>
                                </div>

                                <div className='order-summary__item__detail'>
                                    <div className='order-summary__item__detail__title'>Carbon emissions:</div>
                                    <div className='order-summary__item__detail__quantity'>{
                                        <NumericFormat
                                            displayType='text'
                                            value={productInfo.carbonEmissions}
                                            thousandSeparator
                                            decimalScale={2}
                                        />}T
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SlideDown>

                </>
            }

            {showExtraContent &&
                <>
                    {invoiceUrl &&
                        <div className='order-summary__button-wrapper'>
                            <a href={invoiceUrl} target='_blank' className='button button--secondary'>
                                <img src={downloadIcon} alt='' />
                                Download invoice
                            </a>
                        </div>
                    }

                    <div className='order-summary__whatsapp'>
                        Any questions or difficulties please contact your broker via WhatsApp.
                    </div>
                </>
            }
        </aside>
    )

}

export default OrderSummary