import ReactDOM from 'react-dom';
import {useEffect, useRef} from 'react'
import {CSSTransition} from 'react-transition-group'
import getClassName from '../utilities/getClassName'
import CloseCross from './CloseCross'
import useClickOutside from '../hooks/useClickOutside'

const Modal = ({children, restricted, modalVisible, closeModal, modalClassNames, classNames, closeCrossColour}) => {

    // Focus trap
    const FocusTrap = require('focus-trap-react')

    // Ref
    const nodeRef = useRef(null)
    const modalContent = useRef(null)

    // Click outside
    useClickOutside([modalContent], () => {
        if (!restricted) {
            closeModal()
        }
    })

    useEffect(() => {

        // Add modal open class to body
        if(modalVisible){
            document.body.classList.add('body--model-open')
        }

        // Escape key press
        const close = (e) => {
            if(e.key === 'Escape'){
                if (!restricted) {
                    closeModal()
                }
            }
        }
        if (!restricted) {
            window.addEventListener('keydown', close)
        }

        // Clean up
        return(() => {
            if (!restricted) {
                window.removeEventListener('keydown', close)
            }
            document.body.classList.remove('body--model-open')
        })

    }, [closeModal, modalVisible, restricted])

    return (
        ReactDOM.createPortal (
            <CSSTransition
                nodeRef={nodeRef}
                in={modalVisible}
                timeout={500}
                unmountOnExit
                classNames={{
                    enterActive: 'modal--transition-in',
                    exitActive: 'modal--transition-out',
                }}
            >
                <FocusTrap
                    focusTrapOptions={{ initialFocus: false}}
                    active={!restricted}
                >
                    <div ref={nodeRef} className={getClassName(['modal', modalClassNames])}>
                        <div className={getClassName(['modal__backdrop', restricted && 'modal__backdrop--semi-opaque'])}/>
                        <div className='modal__scroll'>
                            <div className='modal__content-wrapper'>
                                <div ref={modalContent} className={getClassName(['modal__content', classNames])}>
                                    {children}
                                    {!restricted &&
                                        <div className='modal__close-cross-wrapper'>
                                            <CloseCross colour={closeCrossColour} onClick={closeModal} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            </CSSTransition>
            , document.body)
    )
}

export default Modal