import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import {forwardRef, useEffect, useRef, useState} from 'react'
import chevronDown from '../assets/icons/chevron-down-big.svg'
import format from "date-fns/format"
import parse from "date-fns/parse"
import Fieldset from './Fieldset'
import getClassName from '../utilities/getClassName'

// Icons
import calendarIcon from '../assets/icons/calendar.svg'
import calendarIconRed from '../assets/icons/calendar-red.svg'
import Label from './Label'
import InputError from './InputError'

const CustomInput = forwardRef(({ placeholderText, value, onClick, onChange, onFocus, noKeyboard}, ref) => (
        <input
            placeholder={placeholderText}
            value={value}
            onClick={onClick}
            onChange={onChange}
            onFocus={onFocus}
            readOnly={noKeyboard ? noKeyboard : null}
            type='text'
            className={'date-picker-input__input'}
        />
    ),
)

const DatePickerInput = ({value, pastAllowed, futureDatesRemoved, altLayout, defaultValue, label, year, validateForm, required, errorOnBlur, onFocus, allowYearsIntoFuture, reset, minDate, copiedProperty, inModal, skeleton}) => {

    // State
    const [date, setDate] = useState(null)
    const [error, setError] = useState('')
    const [focussed, setFocussed] = useState(false)
    const [readOnly, setReadOnly] = useState(false)

    // Set max future date
    let maxFutureDate
    if (futureDatesRemoved) {
        maxFutureDate = new Date()
    }
    if (allowYearsIntoFuture) {
        maxFutureDate = new Date()
        maxFutureDate.setFullYear(maxFutureDate.getFullYear() + allowYearsIntoFuture)
    }

    // Hook
    const ref = useRef()

    useEffect(() => {
        if (copiedProperty) {
            setFocussed(false)
            const formatDate = format(copiedProperty ? copiedProperty : date, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
            validationHandler(copiedProperty ? copiedProperty : date)
            setDate(copiedProperty ? copiedProperty : date)
            value(formatDate)
        }
    }, [])

    // Handle date change
    const dateChangeHandler = date => {
        setFocussed(false)
        const formatDate = format(date, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
        validationHandler(date)
        setDate(date)
        value(formatDate)
    }

    // Validate input
    const validationHandler = validateDate => {
        const today = new Date
        today.setHours(0, 0, -1)

        switch (true) {
            case (required && !validateDate):
                setError(`${label.replace('*', '')} is required`)
                return
            case (required && validateDate.length === 0):
                setError(`${label.replace('*', '')} is required`)
                return
            case (copiedProperty && validateDate < today):
                setError(`The copied date is in the past. Please pick a new date`)
                return
            default:
                setError('')
                return
        }
    }

    //Force validation
    useEffect(() => {
        if (validateForm) {
            validationHandler(date)
        }
    })

    // Set default value
    useEffect(() => {
        if (defaultValue) {
            if (!date) {
                const parseDate = parse(defaultValue, "dd/MM/yyyy", new Date())
                setDate(parseDate)
            }
        } else {
            setDate(null)
        }
    }, [defaultValue])

    // On blur handler
    const onBlurHandler = (date) => {
        if (errorOnBlur) {
            validationHandler(date)
        }
        if (altLayout) {
            validationHandler(date)
        }

        setFocussed(false)
    }

    // Listen for window resize events
    useEffect(() => {
        const resizeHandler = () => {
            if (!window.matchMedia('(max-width: 550px)').matches) {
                setReadOnly(false)
            } else {
                setReadOnly(true)
            }
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    // Style
    let className = ['date-picker-input']
    if (error) {
        className.push('date-picker-input--error')
    }
    if (altLayout) {
        className.push('date-picker-input--form')
    }
    if (focussed && altLayout) {
        className.push('date-picker-input--form--selected')
    }

    const focus = () => {
        setFocussed(true)
        if (onFocus) {
            onFocus()
        }
    }

    const setMinDate = () => {
        if (minDate) {
            return minDate
        }
        
        return !pastAllowed && new Date()
    }

    return (
        <Fieldset classNames={error && inModal ? 'fieldset--has-error' : ''} skeleton={skeleton}>
            <div style={{backgroundImage: `url(${altLayout ? calendarIcon : error ? calendarIconRed : calendarIcon})`}}
                 className={getClassName(className)}
            >
                {(error && (altLayout || inModal)) && <InputError>{error}</InputError>}
                {!altLayout && <img className='date-picker-input__chevron' src={chevronDown} alt='' />}
                <DatePicker
                    selected={reset ? false : date ? date : copiedProperty}
                    showYearDropdown={!!year}
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    onFocus={focus}
                    minDate={setMinDate()}
                    maxDate={maxFutureDate}
                    onChange={dateChangeHandler}
                    onBlur={() => onBlurHandler(date)}
                    dateFormat={"dd/MM/yyyy"}
                    customInput={<CustomInput noKeyboard={readOnly} ref={ref} placeholderText={altLayout ? '' : 'Date'} />}
                />
            </div>
            {altLayout && <Label minimised={true}>{label}</Label>}

        </Fieldset>
    )
}

export default DatePickerInput