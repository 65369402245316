import {useContext} from 'react'
import ModalFormWrapper from './ModalFormWrapper'
import Button from './Button'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Pagination} from 'swiper'
import {useGetCacheContent} from '../hooks/useGetCacheContent'
import parse from 'html-react-parser'
import poweredByContext from '../store/PoweredByContext'
import {replaceShyName} from '../utilities/string'

const CarbonNeutral = ({closeModal}) => {

    const poweredByCtx = useContext(poweredByContext)

    // Get and cache content
    const {content: carbonNeutralContent} = useGetCacheContent('carbonNeutralContent', '/api/content/carbonneutral', 0)

    const replaceName = (str) => {
        return replaceShyName(str, poweredByCtx.isPoweredBy, poweredByCtx?.tenant?.tenantName)
    }

    if (carbonNeutralContent) {
        return (
            <ModalFormWrapper
                title={carbonNeutralContent.title}
                bigTitle
            >
                <div className='carbon-neutral'>

                    {parse(replaceName(carbonNeutralContent.body))}

                    <div className='carbon-neutral__swiper-wrapper'>
                        <div className='carbon-neutral__swiper-wrapper__inner'>
                            <Swiper
                                grabCursor={true}
                                speed={400}
                                loop={true}
                                modules={[Pagination]}
                                touchStartPreventDefault={false}
                                className='swiper'
                                slidesPerView='auto'
                                pagination={true}
                                breakpoints={{
                                    0: {
                                        grabCursor: true,
                                        slidesPerView: 1,
                                        spaceBetween: 0
                                    },
                                    765: {
                                        grabCursor: true,
                                        slidesPerView: 3,
                                        spaceBetween: 0
                                    }
                                }}
                            >
                                {carbonNeutralContent.imageUrls.map((image, i) => {
                                    return (
                                        <SwiperSlide key={i}>
                                            <img className='swiper__image' src={image} alt='' />
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>

                    <div className='carbon-neutral__button-wrapper'>
                        <Button onClick={closeModal}>Close</Button>
                    </div>
                </div>
            </ModalFormWrapper>
        )
    }
}

export default CarbonNeutral