import {CSSTransition} from 'react-transition-group'
import React, {useContext, useEffect, useRef, useState} from 'react'
import Spinner from './Spinner'
import poweredByContext from '../store/PoweredByContext'

const OverlayFade = ({onClick, show, spinner, opacityPercentage}) => {
    
    const poweredByCtx = useContext(poweredByContext)

    // State
    const [opacity, setOpacity] = useState(poweredByCtx.isPoweredBy ? 0.12 : 0.4)

    // Use ref
    const fadeOverlay = useRef()

    // Use effect
    useEffect(() => {
        if (opacityPercentage) {
            setOpacity(opacityPercentage)
        }
    }, [opacityPercentage])

    return (
        <div onClick={onClick}>
            {/* Overlay for dimming aircraft */}
            <CSSTransition
                nodeRef={fadeOverlay}
                in={show}
                timeout={500}
                unmountOnExit
                classNames={{
                    enterActive: 'overlay-fade--enter',
                    exitActive: 'overlay-fade--exit',
                }}
            >
                <div ref={fadeOverlay} className='overlay-fade' style={{backgroundColor: `rgba(0, 0, 0, ${opacity})`}}>
                    {spinner && <Spinner />}
                </div>
            </CSSTransition>
        </div>
    )
}

export default OverlayFade