import {useContext, useState} from 'react'
import axios from 'axios'
import AuthContext from '../store/AuthContext'
import envVariables from '../data/envVariables'

const usePost = () => {

    // Auth context
    const authCtx = useContext(AuthContext)

    // State
    const [sending, setSending] = useState(false)
    const [returnData, setReturnData] = useState('')
    const [returnMessage, setReturnMessage] = useState({message: '', error: false, count: 1})

    const postData = (postedData, url, delay, postRef, headers, mockResponse, put) => {

        setSending(true)
        setReturnData(false)
        setReturnMessage({message: false, error: false, count: 1, postRef: ''})

        setTimeout(async () => {

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }

            if (authCtx.id) {
                config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${authCtx.token}`,
                    },
                }
            }

            // Custom headers
            if (headers) {
                config = {
                    headers
                }
            }

            // Mock response for testing
            if (!mockResponse) {

                // Change Post to Put if put is set
                axios[put ? 'put' : 'post'](envVariables.apiEndpoint + url, postedData, config).then(response => {

                    if (response.status === 200) {
                        setSending(false)
                        setReturnMessage({
                            message: response.data.message,
                            error: false,
                            count: returnMessage.count + 1,
                            success: true,
                            postRef: postRef
                        })
                        setReturnData(response.data)
                    } else {
                        setSending(false)
                        setReturnMessage({
                            message: `There's been a problem on the server. Please refresh this page and try again.`,
                            error: true,
                            postRef: postRef
                        })
                    }
                }).catch((e) => {

                    console.log(e)

                    if (e.response.status === 400 || e.response.status === 404) {

                        let message = `There's been a problem on the server. Please refresh this page and try again.`
                        if (e.response.data && String(e.response.data) === e.response.data) {
                            message = `There's been an issue. The server's response is: "${e.response.data.substring(0, 200)}..."`
                        }

                        if (e.response.data.errors && e.response.data.errors[0]) {
                            message = `${e.response.data.errors[0].substring(0, 200)}`
                        }

                        setReturnMessage({
                            message: message,
                            error: true,
                            count: returnMessage.count + 1,
                            postRef: postRef
                        })
                    }

                    if (e.response.status === 401) {
                        setReturnMessage({
                            message: 'Incorrect username or password',
                            error: true,
                            count: returnMessage.count + 1,
                            postRef: postRef
                        })
                    }

                    if (e.response.status === 500) {
                        setReturnMessage({
                            message: `There's been a problem on the server. Please refresh this page and try again.`,
                            error: true,
                            count: returnMessage.count + 1,
                            postRef: postRef
                        })
                    }

                    setSending(false)
                })
            } else {

                let status = Math.round(Math.random())
                if (status) {
                    setSending(false)
                    setReturnMessage({
                        message: false,
                        error: false,
                        count: returnMessage.count + 1,
                        success: true,
                        postRef: postRef
                    })
                } else {
                    setSending(false)
                    setReturnMessage({
                        message: 'Could not connect to the server. Please check your connection and refresh the page.',
                        error: true,
                        count: returnMessage.count + 1,
                        postRef: postRef
                    })
                }
            }
        }, delay)
    }

    return {
        postData,
        sending,
        returnMessage,
        returnData
    }
}

export default usePost