import React, {useContext, useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {useNavigate} from 'react-router-dom'
import TitleBar from '../../components/TitleBar'
import AircraftPreview from '../../components/AircraftPreview'
import Button from '../../components/Button'
import OrderSummary from '../../components/OrderSummary'
import useGet from '../../hooks/useGet'
import getClassName from '../../utilities/getClassName'
import getDenomination from '../../utilities/getDenomination'
import LoadingOverlay from '../../components/LoadingOverlay'
import usePost from '../../hooks/usePost'
import ReturnMessage from '../../components/ReturnMessage'
import authContext from '../../store/AuthContext'
import {NumericFormat} from 'react-number-format'
import SEO from '../../components/SEO'

const Book = () => {

    // Hooks
    const navigate = useNavigate()
    const location = useLocation()
    const {sending, returnMessage, postData} = usePost()
    const authCtx = useContext(authContext)

    // State
    const [openOrderSummary, setOpenOrderSummary] = useState(null)
    const [currency, setCurrency] = useState(null)
    const [getAircraftUrl, setGetAircraftUrl] = useState(null)
    const [getDetailsUrl, setGetDetailsUrl] = useState(null)

    // Get
    const {loading: aircraftLoading, returnData: airCraft} = useGet(getAircraftUrl)
    const {loading: detailsLoading, returnData: details} = useGet(getDetailsUrl)

    let legId
    let arfq
    let tailNum

    // Set flight from state or URL
    if (location.state === null) {
        const urlParams = new URLSearchParams(window.location.search)
        legId = urlParams.get('lo')
        arfq = urlParams.get('arfq')
        tailNum = urlParams.get('tailNum')
    } else {
        legId = location.state.legid
        arfq = location.state.arfq
        tailNum = location.state.tailNum
    }

    // Redirect
    if (!legId || !arfq) {
        navigate('/')
    }

    useEffect(() => {

        // Daisy chain server requests
        setGetAircraftUrl(`/api/frontend/get-single-searchresult?legid=${legId}&arfq=${arfq}&tailNum=${tailNum}`)
        if (airCraft) {
            setGetDetailsUrl(`/api/frontend/get-price-confirmation-details?legId=${legId}&arfq=${arfq}&tailNum=${tailNum}`)
        }

        // Detect success
        if (returnMessage.success)
        {
            // Redirect
            navigate('/booking-success', {
                state: {
                    legId: legId,
                    tailNum: tailNum
                }
            })
        }

        // Listen for window resize events
        const resizeHandler = () => {
            setOpenOrderSummary(window.matchMedia('(min-width: 765px)').matches)
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [airCraft, arfq, legId, navigate, returnMessage.success])

    // Choose Currency
    const chooseCurrency = (e) => {
        setCurrency(e.target.value)
    }

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()

        // Form complete
        if (currency) {

            // Set data
            let formData = {
                MemberId: authCtx.id,
                currency: currency,
                legid: legId,
                arfq: arfq,
                tailNumber: tailNum
            }

            // Post
            postData(formData, '/api/frontend/add-booking-to-user', 1000)
        }
    }

    return (
        <>
            <SEO title='Confirm Reservation' />
            <TitleBar url={`/quote?lo=${legId}`} cta='Quote' title='Confirm Reservation' />
            <ReturnMessage returnMessage={returnMessage} />

            <div className='page'>
                {(aircraftLoading || detailsLoading) ?
                    <LoadingOverlay />
                    :
                    (airCraft && details) &&
                    <section className='book'>
                        <div className='wrap'>
                            <OrderSummary
                                template='flight-booking'
                                productInfo={details}
                                classNames={['order-summary--flush', 'order-summary--simple']}
                                isOpen={openOrderSummary}
                                isOpenOnLoad={openOrderSummary}
                            />
                            <form className='book__form' onSubmit={submitHandler}>
                                <div className='book__aircraft'>
                                    <AircraftPreview
                                        recommended={airCraft.recommended}
                                        category={airCraft.category}
                                        aircraftName={airCraft.aircraftName}
                                        photoData={airCraft.photoData}
                                        yom={airCraft.yom}
                                    />
                                </div>
                                <p className='book__subtitle'>Select currency</p>
                                <div className='book__text'>
                                    <p>After this step the <strong>currency will be locked</strong> to your selection. You will then be able to complete payment in your selected currency.</p>
                                </div>
                                <div className='book__prices'>
                                    {Object.keys(airCraft.price).map((key, i) => {
                                        let className = ['book__price']
                                        let primary = false;
                                        if (airCraft.operatorCurrency.toLowerCase() === key) {
                                            primary = true
                                        }
                                        if (primary) {
                                            className.push('book__price--primary')
                                        }
                                        return <label className={getClassName(className)} key={i}>
                                            {primary &&
                                                <p>Best Option</p>
                                            }

                                            <input
                                                type='radio'
                                                id={key}
                                                name='currency'
                                                value={key}
                                                data-price={airCraft.price[key]}
                                                onChange={chooseCurrency.bind(this)}
                                            />
                                            <div className='book__price-checkbox'></div>
                                            <sub>{key.toUpperCase()}</sub> {getDenomination(key)}
                                            {
                                                <NumericFormat
                                                    displayType='text'
                                                    value={airCraft.price[key]}
                                                    thousandSeparator
                                                    decimalScale={0}
                                                />
                                            }
                                            <span className='book__small-print'> {primary ? '(Operator currency)' : '(FX conversion applied)'}</span>
                                        </label>
                                    })}

                                </div>
                                <Button disabled={!currency} classNames={['button--full']}>Confirm Selection</Button>
                            </form>
                        </div>

                        {sending &&
                            <LoadingOverlay />
                        }
                    </section>
                }
            </div>
        </>
    )
}

export default Book