import {useLocation} from 'react-router'
import {useEffect, useLayoutEffect} from 'react'
import { scrollToTop } from '../utilities/scrolltoTop'

const ScrollOnMount = ({children}) => {

    // Hooks
    const {pathname, hash} = useLocation()

    // Html
    const html = document.querySelector('html')

    // Jump to top
    useLayoutEffect(() => {

        // Change scroll to jump
        html.style.scrollBehavior = 'auto'

        // Home page exempt
        if (pathname !== '/' && !pathname.includes('home')) {
            scrollToTop()
        }

        // Change scroll to smooth
        setTimeout(() => {
            html.style.scrollBehavior = 'smooth'
        }, 100)

    }, [html.style, pathname])

    // Scroll to anchor
    useEffect(() => {
        const element = document.getElementById(hash.replace("#", ""));
        window.scrollTo({
            top: element ? element.offsetTop : 0
        });
    }, [hash])

    return (
        children
    )
}

export default ScrollOnMount