import ReturnMessage from './ReturnMessage'
import TextInput from './TextInput'
import Button from './Button'
import {useContext, useEffect, useState} from 'react'
import usePost from '../hooks/usePost'
import authContext from '../store/AuthContext'
import poweredByContext from '../store/PoweredByContext'
import LoadingOverlay from './LoadingOverlay'
import format from "date-fns/format"
import {Link} from 'react-router-dom'

const LoginForm = ({loginComplete, forgottenPassword}) => {

    // State
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [validateForm, setValidateForm] = useState(false)

    // Hooks
    const {sending, returnMessage, returnData: loginData, postData} = usePost()
    const authCtx = useContext(authContext)
    const poweredByCtx = useContext(poweredByContext)

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        // Form complete
        if (email && password) {

            const formData = {
                userName: email,
                password: password,
                tenantID: poweredByCtx.tenantID
            }

            // Post
            postData(formData,'/api/auth/login', 500)
        }
    }

    // Use effect
    useEffect(() => {
        if (loginData) {

            // Set login date and time
            const formatDate = format(new Date(), "dd-MM-yyyy", { awareOfUnicodeTokens: true })
            const formatTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" })
            const lastLoginDateTime = `Last login on ${formatDate} at ${formatTime}`

            // Login context
            authCtx.login(loginData.token, loginData.tokenValidTo, loginData.memberId, loginData.fullName, loginData.firstName, loginData.surname, lastLoginDateTime, loginData.isActAs)

            // Login complete
            loginComplete()
        }
    }, [authCtx, loginComplete, loginData])

    return (
        <>
            <div className='login-form'>
                <ReturnMessage inline={true} returnMessage={returnMessage} />

                <div className='login-form__body'>

                    <div className='login-form__body__title'>
                        Sign in to view your account
                    </div>
                    <div className='login-form__body__intro'>
                        Please provide your password to proceed
                    </div>

                    <form onSubmit={submitHandler}>
                        <TextInput
                            id='email'
                            validateForm={validateForm}
                            label='Email*'
                            required
                            disabled={sending}
                            value={(value) => setEmail(value)}
                            marginBottom
                            validation='email'
                        />
                        <TextInput
                            id='password'
                            validateForm={validateForm}
                            label='Password*'
                            required
                            type='password'
                            maxLength='30'
                            disabled={sending}
                            value={(value) => setPassword(value)}
                        />

                        <button onClick={() => forgottenPassword()} type='button' className='login-form__body__forgot-password'>Forgot password?</button>

                        <div className='login-form__body__submit-container'>
                            <Button disabled={sending} classNames={['button--full']}>Sign In</Button>
                            <Link to='/'><Button disabled={sending} classNames={['button--full button--white']}>Home Page</Button></Link>
                        </div>

                    </form>
                </div>
            </div>

            {sending && <LoadingOverlay />}
        </>
    )
}

export default LoginForm