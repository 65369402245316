import {useEffect} from 'react'

const useClickOutside = (refs, runFunction) => {

    useEffect(() => {
        function handleClickOutside(event) {
            let valid = true

            if (event.target.closest('.modal')) {
                valid = false
            }

            refs.forEach(ref => {
                if (ref.current) {
                    if (ref.current.contains(event.target)) {
                        valid = false
                    }
                }
            })

            if (valid) {
                runFunction()
            }
        }

        // Bind the event listener
        document.addEventListener('touchstart', handleClickOutside)
        document.addEventListener('mousedown', handleClickOutside)

        // Cleanup
        return () => {
            document.removeEventListener('touchstart', handleClickOutside)
            document.removeEventListener('mousedown', handleClickOutside)
        }

    }, [refs, runFunction])
}

export default useClickOutside