import getClassName from '../utilities/getClassName'
import tick from '../assets/icons/tick-thick-white.svg'

const AddPassengerSteps = ({step}) => {

    return (
        <div className='add-passenger-steps'>

            <div className={getClassName(['add-passenger-steps__step', (step === 1) && 'add-passenger-steps__step--selected', (step > 1) && 'add-passenger-steps__step--complete'])}>
                <div className='add-passenger-steps__step__number'>
                    {(step <= 1) && 1}
                    {(step > 1) && <img src={tick} alt='' />}
                </div>
                <div className='add-passenger-steps__step__title'>Passenger details</div>
            </div>

            <div className={getClassName(['add-passenger-steps__line', (step > 1) && 'add-passenger-steps__line--previous'])} />

            <div className={getClassName(['add-passenger-steps__step', (step === 2) && 'add-passenger-steps__step--selected', (step > 2) && 'add-passenger-steps__step--complete'])}>
                <div className='add-passenger-steps__step__number'>
                    {(step <= 2) && 2}
                    {(step > 2) && <img src={tick} alt='' />}
                </div>
                <div className='add-passenger-steps__step__title'>Identification document</div>
            </div>

            <div className={getClassName(['add-passenger-steps__line', (step > 2) && 'add-passenger-steps__line--previous'])} />

            <div className={getClassName(['add-passenger-steps__step', (step === 3) && 'add-passenger-steps__step--selected', (step > 3) && 'add-passenger-steps__step--complete'])}>
                <div className='add-passenger-steps__step__number'>
                    {(step <= 3) && 3}
                    {(step > 3) && <img src={tick} alt='' />}
                </div>
                <div className='add-passenger-steps__step__title'>Entry requirements</div>
            </div>
        </div>
    )

}

export default AddPassengerSteps