import {NumericFormat} from 'react-number-format'
import getDenomination from '../utilities/getDenomination'

const CreditBar = () => {

    const currency = 'gbp'
    const amount = 1300

    return (
        <section className='credit-bar'>
            <p>
                <span>
                    {getDenomination(currency)}
                    <NumericFormat
                        displayType='text'
                        value={amount}
                        thousandSeparator
                    />
                </span>
                in credit
            </p>
        </section>
    )
}

export default CreditBar