import getClassName from '../utilities/getClassName'

const Label = ({children, minimised, skipAnimation}) => {

    const classNames = ['label']

    if (minimised) {
        classNames.push('label--minimised')
    }

    if (skipAnimation) {
        classNames.push('label--skip-animation')
    }

    return (
        <label className={getClassName(classNames)}>
            {children}
        </label>
    )
}

export default Label