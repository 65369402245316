import {useEffect, useState} from 'react'
import tickLightIcon from '../assets/icons/tick-thick-white.svg'
import tickDarkIcon from '../assets/icons/tick-thick-dark.svg'
import InputError from './InputError'
import getClassName from '../utilities/getClassName'

const Checkbox = ({children, defaultValue, locked, disabled, classNames, required, validateForm, value, id, tickDark}) => {

    // State
    const [error, setError] = useState('')
    const [checked, setChecked] = useState(false)
    const [interacted, setInteracted] = useState(false)

    const toggleCheckedHandler = () => {
        value(!checked)
        setChecked(!checked)
        setInteracted(true)
    }

    // Validate input
    const validationHandler = () => {
        if (value) {
            value(false)

            switch (true) {
                case (required && !checked):
                    setError(`Please confirm`)
                    return
                default:
                    setError('')
                    value(checked)
                    return
            }
        }
    }

    // Force validation
    useEffect(() => {
        if (validateForm) {
            validationHandler()
        }
    })

    // Set default value
    useEffect(() => {
        if (defaultValue) {
            setInteracted(true)
            setChecked(defaultValue)
        } else {
            setChecked(false)
        }
    }, [defaultValue])

    let className = ['checkbox']
    if (typeof classNames === 'object') {
        className = className.concat(classNames)
    }
    if (locked) {
        className = className.concat('checkbox--locked')
    }

    return (
        <div className={getClassName(className)}>
            <button
                disabled={disabled || locked}
                type='button'
                onClick={toggleCheckedHandler}
                className={getClassName(['checkbox__box', error && 'checkbox__box--error', (interacted && checked) && 'checkbox__box--success'])}
                id={id}>
                {checked && <img src={tickDark ? tickDarkIcon : tickLightIcon} alt='' />}
            </button>
            {children &&
                <label className='checkbox__label' htmlFor={id}>{children}</label>
            }
            {error && <InputError>{error}</InputError>}
        </div>
    )
}

export default Checkbox