import tick from '../assets/icons/tick-thick.svg'
import {useRef, useState} from 'react'
import getClassName from '../utilities/getClassName'
import {CSSTransition} from 'react-transition-group'

const QuoteFilters = ({showFilters, selectedFilter, filterBarRef}) => {

    // State
    const [filterOptions, setFilterOptions] = useState(false)

    // Filter toggle handler
    const filterToggleHandler = (filter) => {

        // Update selected filters
        const updatedFilters = {...filterOptions, [filter]: !filterOptions[filter]}
        setFilterOptions(updatedFilters)
        selectedFilter(updatedFilters)
    }

    // Ref
    const quoteFilters = useRef(null)

    return (
        <CSSTransition
            nodeRef={quoteFilters}
            in={showFilters}
            timeout={500}
            classNames={{
                enterDone: 'quote-filters--active',
                enterActive: 'quote-filters--transition-in',
                exitActive: 'quote-filters--transition-out',
            }}
        >
            <div ref={quoteFilters} className={getClassName(['quote-filters', 'wrap wrap--sm-no-padding'])}>
                <div ref={filterBarRef} className='quote-filters__row'>
                    <button onClick={() => filterToggleHandler('wifi')} className='quote-filters__filter'>
                        <span className='quote-filters__filter__check-box'>{filterOptions.wifi && <img src={tick} alt='' />}</span>
                        Wifi
                    </button>
                    <button onClick={() => filterToggleHandler('toilet')} className='quote-filters__filter'>
                        <span className='quote-filters__filter__check-box'>{filterOptions.toilet && <img src={tick} alt='' />}</span>
                        Toilets
                    </button>
                    <button onClick={() => filterToggleHandler('hotGalley')} className='quote-filters__filter'>
                        <span className='quote-filters__filter__check-box'>{filterOptions.hotGalley && <img src={tick} alt='' />}</span>
                        Hot Galley
                    </button>
                    <button onClick={() => filterToggleHandler('bed')} className='quote-filters__filter'>
                        <span className='quote-filters__filter__check-box'>{filterOptions.bed && <img src={tick} alt='' />}</span>
                        Bed
                    </button>
                </div>
            </div>
        </CSSTransition>
    )
}

export default QuoteFilters