import {useState} from "react";

const useModal = () => {

    // State
    const [modalVisible, setModalVisible] = useState(false)

    // Toggle modal
    function openModal() {
        setModalVisible(true)
    }

    // Hide modal
    function closeModal () {
        setModalVisible(false)
    }

    return {
        modalVisible,
        openModal: openModal,
        closeModal: closeModal
    }
};

export default useModal;