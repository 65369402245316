import {useEffect, useState} from 'react'
import {NumericFormat} from 'react-number-format'
import getClassName from '../utilities/getClassName'
import getDenomination from '../utilities/getDenomination'
import chevronIcon from '../assets/icons/chevron-right-dark-grey.svg'

const TransactionPreview = ({ data, large, keyData = 'ref', showTransaction, active }) => {

    const [isPending, setIsPending] = useState(false)

    let className = ['transaction-preview']

    useEffect(() => {
        setIsPending(data.amountPaid < data.amount)
    }, [data])

    if (isPending) {
        className.push('transaction-preview--pending')
    }

    if (large) {
        className.push('transaction-preview--large')
    }

    if (active) {
        className.push('transaction-preview--active')
    }

    const getTitle = () => {
        if (keyData === 'amount') {
            return <>
                {getDenomination(data.currency)}
                <NumericFormat
                    displayType='text'
                    value={data.amount}
                    thousandSeparator
                />
            </>
        } else {
            return data[keyData]
        }
    }

    const getStatus = () => {
        if (isPending) {
            return <>
                {getDenomination(data.currency)}
                <NumericFormat
                    displayType='text'
                    value={data.amount - data.amountPaid}
                    thousandSeparator
                /> due
            </>
        } else {
            return 'Paid fully'
        }
    }

    const getProgress = () => {
        return (data.amountPaid / data.amount) * 100
    }

    const show = () => {
        if (showTransaction) {
            showTransaction(data)
        }
    }

    return (
        <article className={getClassName(className)} onClick={show}>
            {!large &&
                <figure className='transaction-preview__icon'>
                    <img src={chevronIcon} alt='' />
                </figure>
            }
            <div className='transaction-preview__content'>
                <div className='transaction-preview__row'>
                    <h3 className='transaction-preview__ref'>{getTitle()}</h3>
                    <p className='transaction-preview__status'>{getStatus()}</p>
                </div>
                <div className='transaction-preview__row'>
                    <p className='transaction-preview__airports'>{data.departureAirport} - {data.arrivalAirport}</p>
                    {!large &&
                        <>
                            {isPending &&
                                <p className='transaction-preview__value'>
                                    {getDenomination(data.currency)}<NumericFormat
                                        displayType='text'
                                        value={data.amountPaid}
                                        thousandSeparator
                                    /> of {getDenomination(data.currency)}<NumericFormat
                                        displayType='text'
                                        value={data.amount}
                                        thousandSeparator
                                    /> paid
                                </p>
                            }
                            {!isPending &&
                                <p className='transaction-preview__value'>
                                    -
                                    {getDenomination(data.currency)}
                                    <NumericFormat
                                        displayType='text'
                                        value={data.amountPaid}
                                        thousandSeparator
                                    />
                                </p>
                            }
                        </>
                    }
                </div>
            </div>
            {isPending &&
                <>
                    <div className='transaction-preview__progress'>
                        <div style={{width: `${getProgress()}%`}} />
                    </div>
                    {large &&
                        <p className='transaction-preview__value'>
                            {getDenomination(data.currency)}<NumericFormat
                                displayType='text'
                                value={data.amountPaid}
                                thousandSeparator
                            /> of {getDenomination(data.currency)}<NumericFormat
                                displayType='text'
                                value={data.amount}
                                thousandSeparator
                            /> paid
                        </p>
                    }
                </>
            }
        </article>
    )
}

export default TransactionPreview