import TitleBar from '../../components/TitleBar'
import background from '../../assets/images/carbon-emissions-background.jpeg'
import EnvironmentalProject from '../../components/EnvironmentalProject'
import Button from '../../components/Button'
import {Link, useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import NumberInputAlt from '../../components/NumberInputAlt'
import {project1, project2, project3} from '../../data/environmentalProjectData'
import usePost from '../../hooks/usePost'
import LoadingOverlay from '../../components/LoadingOverlay'
import ReturnMessage from '../../components/ReturnMessage'
import SEO from '../../components/SEO'

const CarbonEmissionsPurchaseMore = () => {

    // State
    const [validateForm, setValidateForm] = useState(false)
    const [selectedProject, setSelectedProject] = useState(1)
    const [projectPrice, setProjectPrice] = useState(project1.projectPrice)
    const [megaTons, setMegaTons] = useState(0.95)
    const [totalToPay, setTotalToPay] = useState(0)

    // Hooks
    const {sending, returnMessage, postData} = usePost()
    const navigate = useNavigate()

    // Switch project handler
    const switchProjectHandler = (projectNo) => {
        if (projectNo === 1) {
            setProjectPrice(project1.projectPrice)
        }
        if (projectNo === 2) {
            setProjectPrice(project2.projectPrice)
        }
        if (projectNo === 3) {
            setProjectPrice(project3.projectPrice)
        }

        setSelectedProject(projectNo)
    }

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        // Form complete
        if (totalToPay > 0) {

            // Set data
            let formData = {
                totalToPay: totalToPay,
                projectNo: selectedProject,
            }

            //TODO remove mock response
            // Post
            postData(formData, '/api/add-to-cart', 1000, null, false, true)
        }
    }

    // Use effect
    useEffect(() => {

        // Update total to pay
        setTotalToPay(megaTons * projectPrice)

        // Redirect after success
        if (returnMessage.success) {
            navigate('/payment')
        }

    }, [megaTons, navigate, projectPrice, returnMessage.success, totalToPay])

    return (
        <>
            <SEO title='Carbon Offset' />
            <TitleBar title='Carbon Offset' cta='Carbon Emissions' url='/carbon-emissions'/>
            <ReturnMessage returnMessage={returnMessage} />

            <div className='carbon-emissions'>
                <div className='carbon-emissions__header' style={{backgroundImage: `url(${background})`}}>
                    <div className='wrap'>
                        <div className='carbon-emissions__header__thank-you'>Last Flight Saved</div>
                        <div className='carbon-emissions__header__mt-number'>0.95 MT</div>
                        <br />
                        <div className='carbon-emissions__header__info'>How much will you give back to the planet?</div>

                        <div className='carbon-emissions__header__inputs'>
                            <NumberInputAlt
                                defaultValue={megaTons}
                                label='MT'
                                disabled={sending}
                                decimals={2}
                                value={(value) => {setMegaTons(value)}}
                                incrementNumber={0.1}
                                validateForm={validateForm}
                            />

                            <div className='carbon-emissions__header__at'>at</div>

                            <NumberInputAlt
                                defaultValue={projectPrice}
                                label='£'
                                disabled={sending}
                                decimals={0}
                                thousandSeparator
                                incrementNumber={5}
                                value={(value) => {setProjectPrice(value)}}
                                validateForm={validateForm}
                            />

                        </div>

                    </div>
                </div>

                <div className='page'>


                    <div className='carbon-emissions__projects'>

                        <div className='wrap'>
                            <div className='carbon-emissions__projects__title'>Which project would you like to donate to?</div>
                            <p>Please select which project you would like to help by selecting a tick box.</p>
                        </div>

                        <div className='carbon-emissions__projects__projects-container'>

                            <EnvironmentalProject
                                projectNo={1}
                                selectable
                                currentlySelected={selectedProject === 1}
                                select={(projectNo) => switchProjectHandler(projectNo)}
                                title='India Biolite Homestoves - £9/MT'
                                writeUp='Improving health and access to affordable energy for Indian communities'
                            />

                            <EnvironmentalProject
                                projectNo={2}
                                selectable
                                currentlySelected={selectedProject === 2}
                                select={(projectNo) => switchProjectHandler(projectNo)}
                                title='Kariba Forest Protection - £12/MT'
                                writeUp='Improving health and access to affordable energy for Indian communities'
                            />

                            <EnvironmentalProject
                                projectNo={3}
                                selectable
                                currentlySelected={selectedProject === 3}
                                select={(projectNo) => switchProjectHandler(projectNo)}
                                title='Tropical Conservation - £18/MT'
                                writeUp='Improving health and access to affordable energy for Indian communities'
                            />
                        </div>

                        <div className='form-button-wrapper wrap'>
                            <Button disabled={sending} onClick={submitHandler}>Buy Now</Button>
                            <Link to='/carbon-emissions'><Button disabled={sending} classNames={['button--white']}>Back</Button></Link>
                        </div>

                        {sending && <LoadingOverlay />}

                    </div>
                </div>
            </div>
        </>
    )
}

export default CarbonEmissionsPurchaseMore