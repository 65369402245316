import getClassName from '../utilities/getClassName'
import leftArrow from '../assets/icons/arrow-left-white.svg'
import CloseCross from './CloseCross'
import {Swiper, SwiperSlide} from 'swiper/react'
import chatIcon from '../assets/icons/chat-gold.svg'
import whatsAppIcon from '../assets/icons/whatsapp.svg'
import callUsIcon from '../assets/icons/phone-gold.svg'
import callRequest from '../assets/icons/phone-outgoing-gold.svg'
import RequestCallForm from './RequestCallForm'
import chatbotTail from '../assets/icons/chatbot-tail.svg'
import {useEffect, useRef, useState} from 'react'
import {CSSTransition} from 'react-transition-group'
import useClickOutside from '../hooks/useClickOutside'
import FocusTrap from 'focus-trap-react'

const ContactWidget = ({showConnectWidget, closeConnectWidget, useClickOutsideException, title, subTitle, classNames}) => {

    // State
    const [swiperRef, setSwiperRef] = useState()
    const [showRequestCall, setShowRequestCall] = useState(false)
    const [chatAvailable, setChatAvailable] = useState(false)

    // Ref
    const connectWidget = useRef(null)

    // Click outside of contact options
    useClickOutside([connectWidget, useClickOutsideException], () => {
        closeConnectWidget()
    })

    // Attach chatbot script
    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://cht-srvc.net/api/lc.js?client=sa_shyaviation'
        script.async = true
        document.body.appendChild(script)

        // Check chat is actually available
        setTimeout(() => {
            const element = document.getElementById('chat-widget')
            if(typeof(element) != 'undefined' && element != null){
                setChatAvailable(true)
            }
        }, 1000)

    }, [])

    // Chatbot button click
    const chatbotClickHandler = () => {

        // try and open chat by clicking eye-catcher
        const chatEyeCatcher = document.getElementById('livechat-eye-catcher')

        if(typeof(chatEyeCatcher) != 'undefined' && chatEyeCatcher != null){
            chatEyeCatcher.click()
            closeConnectWidget()
        } else {

            // try and open chat by clicking element's aria-label
            var iframe = document.getElementById('chat-widget-minimized');
            var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
            const chatWidgetButton = innerDoc.querySelector('[aria-label="Open LiveChat chat widget"]')

            if(typeof(chatWidgetButton) != 'undefined' && chatWidgetButton != null){
                chatWidgetButton.click()
                closeConnectWidget()
            } else {
                alert('Chat is not available at this time. Please contact us via email or WhatsApp.')
                setChatAvailable(false)
            }
        }
    }

    // Show request call handler
    const showRequestCallHandler = () => {
        if (!showRequestCall) {
            setShowRequestCall(true)
            swiperRef.slideNext()
        } else {
            setShowRequestCall(false)
            swiperRef.slidePrev()
        }
    }

    // Set title and subtitle
    let widgetTitle = 'Welcome to SHY Aviation'
    let widgetSubTitle = 'Here\'s a few ways to connect with us'
    if (title) {
        widgetTitle = title
    }
    if (subTitle) {
        widgetSubTitle = subTitle
    }

    let className = ['contact-widget']

    if (typeof classNames === 'object') {
        className = className.concat(classNames)
    }

    return(
        <CSSTransition
            in={showConnectWidget}
            nodeRef={connectWidget}
            timeout={400}
            unmountOnExit
            classNames={{
                enterActive: 'contact-widget--open',
                enterDone: 'contact-widget--open'
            }}
        >
            <FocusTrap
                focusTrapOptions={{
                    clickOutsideDeactivates: true
                }}
            >
                <div ref={connectWidget} className={getClassName(className)}>
                    <div className={getClassName(['contact-widget__header', showRequestCall && 'contact-widget__header--short'])}>

                        <div className={getClassName(['contact-widget__header__inner', !showRequestCall && 'contact-widget__header__inner--visible'])}>
                            <div className='contact-widget__header__title'>{widgetTitle}</div>
                            <div className='contact-widget__header__text'>{widgetSubTitle}</div>
                        </div>
                        <div className={getClassName(['contact-widget__header__inner', showRequestCall && 'contact-widget__header__inner--visible'])}>
                            <button onClick={showRequestCallHandler} className='contact-widget__header__back'><img src={leftArrow} alt='' />Request a call</button>
                        </div>

                        <div className='contact-widget__close-wrapper'>
                            <CloseCross onClick={closeConnectWidget} colour='white' />
                        </div>
                    </div>

                    <div className='contact-widget__body'>

                        <Swiper
                            onSwiper={setSwiperRef}
                            grabCursor={false}
                            speed={350}
                            autoHeight={true}
                            loop={true}
                            touchStartPreventDefault={false}
                            className='swiper'
                            slidesPerView='auto'
                            noSwiping={true}
                            noSwipingClass={'swiper-slide'}
                        >
                            <SwiperSlide key={1}>

                                {chatAvailable &&
                                    <button onClick={() => chatbotClickHandler()} className='contact-widget__button'>
                                        <img src={chatIcon} alt='' />
                                        Chat with us
                                    </button>
                                }

                                <a target='_blank' href='https://wa.me/+447387265712' rel='noreferrer' className='contact-widget__button'>
                                    <img src={whatsAppIcon} alt='' />
                                    WhatsApp us
                                </a>

                                <a href='tel:+4402086168821' tabIndex='0' className='contact-widget__button'>
                                    <img src={callUsIcon} alt='' />
                                    <span>Call us<br /><span className='contact-widget__button__number'>+44 (0) 2086 168 821</span><span className='contact-widget__button__247'>(24/7)</span></span>
                                </a>

                                <button onClick={showRequestCallHandler} tabIndex='0' className='contact-widget__button'>
                                    <img src={callRequest} alt='' />
                                    Request a call
                                </button>
                            </SwiperSlide>

                            <SwiperSlide key={2}>
                                <RequestCallForm />
                            </SwiperSlide>

                        </Swiper>
                    </div>

                    <img src={chatbotTail} className='contact-widget__tail' alt='' />
                </div>
            </FocusTrap>
        </CSSTransition>
    )
}

export default ContactWidget