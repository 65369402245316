import {useCallback, useEffect, useState} from 'react'
import InputError from './InputError'
import {FileUploader} from 'react-drag-drop-files'
import cross from '../assets/icons/close-cross-black.svg'

const FileUploadInput = ({defaultValue, acceptedFileTypes, value, required, validateForm, fileRemoveHandler, classNames, overwriteText}) => {

    // State
    const [file, setFile] = useState(null)
    const [error, setError] = useState('')
    const [currentFileName, setCurrentFileName] = useState(defaultValue)

    // Validate
    const validateHandler = useCallback((fileToValidate) =>
    {
        switch(true) {
            case (required && !fileToValidate):
                setError('Please upload a file')
                return
            case (required && (fileToValidate.length === 0)):
                setError('Please upload a file')
                return
            default:
                setError('')
                return
        }
    }, [required])

    // Update selected option
    const fileChangeHandler = (file) => {
        setCurrentFileName(file.name)
        setFile(file)
        validateHandler(file)
        value(file)
    }

    // Remove file
    const removeFileHandler = () => {
        setCurrentFileName(null)
        setFile(null)
        setError('')
        if (fileRemoveHandler) {
            fileRemoveHandler()
        }
        value(null)
    }

    // Force validation
    useEffect(() => {
        if (validateForm){
            validateHandler(file)
        } else {
            setError('')
        }
    },[file, validateForm, validateHandler])

    // Style
    let className = 'file-upload-input '
    if (typeof classNames === 'object') {
        className = className.concat(classNames)
    }

    return (
        <div className={className}>
            {currentFileName ?
                <div className='file-upload-input__drop-zone'>
                    <div className='file-upload-input__drop-zone__text'>{currentFileName}</div>
                    <button onClick={removeFileHandler} className='file-upload-input__drop-zone__remove-button'>
                        <img src={cross} alt='' />
                        Remove File
                    </button>
                </div>
                :
                <FileUploader
                    handleChange={file => fileChangeHandler(file)}
                    name="file"
                    types={acceptedFileTypes}
                    className='file-upload-input__input'
                    multiple={false}
                    maxSize={10}
                    disabled={currentFileName}
                >
                    <div className='file-upload-input__drop-zone file-upload-input__drop-zone--active'>
                        {overwriteText ? overwriteText : 'Select a file...'}
                    </div>
                </FileUploader>
            }
            {error && <InputError>{error}</InputError>}
        </div>
    )
}

export default FileUploadInput