import JourneyLeg from './JourneyLeg'
import Button from './Button'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import getClassName from '../utilities/getClassName'
import format from "date-fns/format"
import parse from "date-fns/parse"
import chevron from '../assets/icons/chevron-right-dark-grey.svg'
import exclamationMark from '../assets/icons/exclamation-mark-dark-grey.svg'
import daysFromDate from '../utilities/daysFromDate'
import {getDate} from '../utilities/date'
import ContactWidget from './ContactWidget'

const Listing = ({
                     type,
                     id,
                     quoteRef,
                     tailNum,
                     requestedDate,
                     expiryDate,
                     flightFromIcao,
                     flightFromLong,
                     flightToIcao,
                     flightToLong,
                     departureDate,
                     departureTime,
                     departureTimeZone,
                     arrivalDate,
                     arrivalTime,
                     arrivalTimeZone,
                     passengers,
                     luggage,
                     pendingSelection,
                     pendingPayment,
                     pendingDocument,
                     pendingContract,
                     pendingConfirmation,
                     contractReady,
                     pendingFinalBrief,
                     pendingPassengers,
                     pendingPreliminaryBrief,
                     finalBriefReady,
                     expired,
                     cancelled,
                     isReturn,
                     copyHandler
                 }) => {

    // State
    const [layout, setLayout] = useState(null)
    const [showContactWidget, setShowContactWidget] = useState(false)
    const [showContactWidgetAfterLoad, setShowContactWidgetAfterLoad] = useState(false)

    // Format dates
    let formatDate = null
    if (type === 'quote') {
        const parseDate = parse(getDate(requestedDate, true), "yyyy-MM-dd", new Date())
        formatDate = format(parseDate, "do MMM yyyy", { awareOfUnicodeTokens: true })
    }
    if (type === 'booking') {
        const parseDate = parse(getDate(departureDate, true), "yyyy-MM-dd", new Date())
        formatDate = format(parseDate, "do MMM yyyy", { awareOfUnicodeTokens: true })
    }

    // Check if in preparation
    let inPreparation = false
    if (!pendingSelection && !pendingDocument && !pendingPayment && !pendingConfirmation && !pendingContract && !contractReady) {
        inPreparation = true
    }

    // Set booking status
    let bookingStatus = 'ready'
    if (pendingPreliminaryBrief) {
        bookingStatus = 'preliminary-brief-pending'
    }
    if (pendingFinalBrief) {
        bookingStatus = 'final-brief-pending'
    }
    if (finalBriefReady) {
        bookingStatus = 'final-brief-ready'
    }
    if (pendingPassengers) {
        bookingStatus = 'passengers'
    }
    if (cancelled) {
        bookingStatus = 'cancelled'
    }

    // Set days until expiry
    let diffDays
    if (type === 'quote' && !expired) {
        diffDays = daysFromDate(null, getDate(expiryDate, true))
    }

    // Listen for window resize events
    useEffect(() => {
        const resizeHandler = () => {
            if (!window.matchMedia('(max-width: 1020px)').matches) {
                setLayout(8)
            } else {
                setLayout(9)
            }
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    useEffect(() => {
        if (!showContactWidget) {
            setTimeout(() =>{
                setShowContactWidgetAfterLoad(false)
            }, 300)
        } else {
            setShowContactWidgetAfterLoad(true)
        }
    }, [showContactWidget])

    let className = ['listing']
    if (pendingSelection && expired) {
        className = className.concat(['listing--expired'])
    }
    if (pendingSelection && diffDays === 'Today') {
        className = className.concat(['listing--expiring'])
    }

    const copyThis = () => {
        if (typeof copyHandler === 'function') {
            copyHandler(id)
        }
    }

    return (
        <>
            <div className={getClassName(className)}>
                <div className='listing__flight-details'>
                    {(type === 'quote') &&
                        <>
                            {(!inPreparation && !expired && pendingSelection) &&
                                <div className='listing__flight-details__date-info listing__flight-details__date-info--expiring'>Expires <b>{diffDays}</b></div>
                            }
                            <div className='listing__flight-details__requested'>Requested {formatDate}</div>
                        </>
                    }
                    {(type === 'booking') &&
                        <>
                            <div className='listing__flight-details__date-info'>Date of flight {formatDate}</div>
                        </>
                    }
                    {isReturn &&
                        <div className='listing__flight-details__trip-type'>Return trip</div>
                    }
                    {quoteRef &&
                        <div className='listing__flight-details__shy-ref'>{quoteRef}</div>
                    }
                </div>

                <div className='listing__journey-leg'>
                    <JourneyLeg
                        journeyType='private-jet'
                        layout={layout}
                        toLong={flightToLong}
                        toShort={flightToIcao}
                        fromLong={flightFromLong}
                        fromShort={flightFromIcao}
                        departureDate={getDate(departureDate, true)}
                        departureTime={departureTime}
                        arrivalDate={getDate(arrivalDate, true)}
                        arrivalTime={arrivalTime}
                        luggage={luggage}
                        passengers={passengers}
                        isSimple
                        arrivalTimeZone={arrivalTimeZone}
                        departureTimeZone={departureTimeZone}
                        isReturnTrip={isReturn}
                    />
                </div>

                <div className='listing__ctas'>

                    {(!expired && (type === 'quote') && inPreparation) &&
                        <div className='listing__ctas__in-preparation'>
                            <div className='listing__ctas__in-preparation__text'>Your Quote is being prepared</div>
                        </div>
                    }

                    {(!expired && (type === 'quote') && pendingSelection) &&
                        <div className='listing__ctas__pending-selection'>
                            <div className='listing__ctas__pending-selection__text'>Pending Selection</div>
                            <Link to={`/quote?lo=${id}`}><Button classNames={['button--full']}>Choose Aircraft</Button></Link>
                        </div>
                    }

                    {(!expired && (type === 'quote') && (pendingContract && !contractReady)) &&
                        <div className='listing__ctas__in-preparation'>
                            <div className='listing__ctas__in-preparation__text'>Your Contract is being prepared</div>
                        </div>
                    }

                    {(!expired && (type === 'quote') && contractReady) &&
                        <div className='listing__ctas__pending-payment'>
                            <div className='listing__ctas__pending-selection__text'><img src={exclamationMark} alt='' />Awaiting Signature</div>
                            <Link to={`/sign-contract/${id}/${tailNum}`}><Button classNames={['button--full']}>View Contract</Button></Link>
                        </div>
                    }

                    {(!expired && (type === 'quote') && !pendingSelection && pendingPayment) &&
                        <div className='listing__ctas__pending-payment'>
                            <div className='listing__ctas__pending-selection__text'><img src={exclamationMark} alt='' />Awaiting Payment</div>
                            <Link to={`/payment/bank-transfer/${id}/${tailNum}`}><Button classNames={['button--full']}>Pay Now</Button></Link>
                        </div>
                    }

                    {(!expired && (type === 'quote') && !pendingSelection && pendingConfirmation) &&
                        <div className='listing__ctas__in-preparation'>
                            <div className='listing__ctas__in-preparation__text'>Payment Confirmation Pending</div>
                        </div>
                    }

                    {(!expired && pendingDocument && (type === 'booking')) &&
                        <div className='listing__ctas__pending-document'>
                            <Link to='/quote-dashboard/1'>Pending Document Upload</Link>
                        </div>
                    }

                    {(!expired && (type === 'booking')) &&
                        <>
                            {(bookingStatus === 'passengers') &&
                                <div className='listing__ctas__pending-payment'>
                                    <div className='listing__ctas__pending-selection__text'><img src={exclamationMark} alt='' />Awaiting Passenger Details</div>
                                    <Link to={`/add-passengers-to-booking/${id}`}><Button classNames={['button--full']}>Add Passengers</Button></Link>
                                </div>
                            }

                            {(bookingStatus === 'preliminary-brief-pending') &&
                                <div className='listing__ctas__in-preparation'>
                                    <div className='listing__ctas__in-preparation__text'>Your Preliminary Brief is being prepared</div>
                                </div>
                            }

                            {/*(bookingStatus === 'preliminary-brief-ready') &&
                                <div className='listing__ctas__pending-selection'>
                                    <div className='listing__ctas__pending-selection__text'>Preliminary Brief</div>
                                    <Link to={`/flight-brief?lo=${id}`}><Button classNames={['button--full']}>View Brief</Button></Link>
                                </div>
                            */}

                            {(bookingStatus === 'final-brief-pending') &&
                                <div className='listing__ctas__in-preparation'>
                                    <div className='listing__ctas__in-preparation__text'>Your Final Brief is being prepared</div>
                                </div>
                            }

                            {(bookingStatus === 'final-brief-ready') &&
                                <div className='listing__ctas__pending-selection'>
                                    <div className='listing__ctas__pending-selection__text'>Final Brief Ready</div>
                                    <Link to={`/flight-brief?lo=${id}`}><Button classNames={['button--full']}>View Brief</Button></Link>
                                </div>
                            }

                            {(bookingStatus === 'ready') &&
                                <div className='listing__ctas__buttons-wrapper'>
                                    <div className={getClassName(['listing__ctas__contact-widget-wrapper', (showContactWidget || showContactWidgetAfterLoad) && 'listing__ctas__contact-widget-wrapper--loaded'])}>
                                        <ContactWidget
                                            classNames={['contact-widget--blue']}
                                            showConnectWidget={showContactWidget}
                                            closeConnectWidget={() => setShowContactWidget(false)}
                                            useClickOutsideException={false}
                                            title='Add services to your booking'
                                            subTitle='Please request a service from your SHY account manager'
                                        />
                                    </div>
                                    <Button onClick={() => setShowContactWidget(!showContactWidget)} classNames={['button--full']}>Add Services</Button>
                                </div>
                            }

                        </>

                    }

                    {cancelled &&
                        <div className='listing__ctas__pending-payment'>
                            <div className='listing__ctas__pending-selection__text'>Cancelled</div>
                        </div>
                    }

                    {(!pendingSelection) &&
                        <div className='listing__ctas__copy-quote'>
                            <Button classNames={['button--full', 'button--gold-border']} icon='clipboard' onClick={copyThis}>Re-quote</Button>
                        </div>
                    }

                </div>

                {!expired &&
                    <>
                        {((type === 'quote' && !inPreparation) || type === 'booking') &&
                            <div className='listing__more-details'>
                                <Link to={`/quote-dashboard/${type}/${id}`}>
                                    More Details
                                    <img src={chevron} alt='' />
                                </Link>
                            </div>
                        }
                    </>
                }

            </div>
            <div className={getClassName(['listing__cover', showContactWidget && 'listing__cover--visible'])} />
        </>
    )
}

export default Listing