import {useEffect} from 'react'
import {Link, useParams, useNavigate} from 'react-router-dom'
import TitleBar from '../../components/TitleBar'
import QuoteHeader from '../../components/QuoteHeader'
import QuoteDashboardArticle from '../../components/QuoteDashboardArticle'
import QuoteDashboardArticleAlt from '../../components/QuoteDashboardArticleAlt'
import LoadingOverlay from '../../components/LoadingOverlay'
import useGet from '../../hooks/useGet'
import SEO from '../../components/SEO'

import downloadIcon from '../../assets/icons/download.svg'

const QuoteDashboard = () => {
    
    const navigate = useNavigate()
    const type = useParams().type
    const legId = useParams().legid

    const getSearchResultsHeaderUrl = `/api/frontend/get-search-results-header?LegId=${legId}`
    const {loading: searchResultsHeaderLoading, error: searchResultsHeaderError, returnData: searchResultsHeader} = useGet(getSearchResultsHeaderUrl)

    const endpoint = type === 'quote' ? 'get-dashboard-message-by-id' : 'get-booking-message-by-id'
    const messagesUrl = `/api/frontend/${endpoint}?legId=${legId}`
    const {loading: messagesLoading, error: messagesError, returnData: messages} = useGet(messagesUrl)

    const buttonsUrl = `/api/frontend/get-booking-action-buttons-by-id?legId=${legId}`
    const {loading: buttonsLoading, error: buttonsError, returnData: buttons} = useGet(buttonsUrl)

    const briefUrl = `/api/frontend/final-briefing?legId=${legId}`
    const {loading: briefLoading, error: briefError, returnData: brief} = useGet(briefUrl)
    
    useEffect(() => {
        if (messages && type === 'booking') {
            let servicesComplete = false

            messages.forEach((message) => {
                if (message.title === 'Final Brief' && message.complete) {
                    servicesComplete = true
                }
            })

            messages.forEach((message) => {
                if (message.title === 'Your services' && servicesComplete) {
                    message.complete = true
                }
            })
        }
    }, [messages])

    const quoteIsComplete = () => {
        let isComplete = false

        if (type === 'quote' && messages) {
            messages.forEach((message) => {
                if (message.icon === 'quote-complete') {
                    isComplete = true
                }
            })
        }

        return isComplete
    }

    const showTabs = type === 'booking' || quoteIsComplete()

    let currentStatus

    if (type === 'booking' && messages) {
        messages.forEach((message, i) => {
            if (message.complete || message.cancelled) {
                currentStatus = i
            }
        })
    }

    const getTabClassname = (tabMode) => {
        let classNames = ['quote-dashboard__tab']

        if (type === tabMode) {
            classNames.push('quote-dashboard__tab--active')
        }

        return classNames.join(' ')
    }

    const getIcon = (iconType) => {
        switch (iconType) {
            case 'full-brief':
                return require('../../assets/icons/flight-documents-full-brief.svg').default
                break
            case 'preliminary-brief':
                return require('../../assets/icons/flight-documents-preliminary-brief.svg').default
                break
            case 'preflight-notice':
                return require('../../assets/icons/flight-documents-preflight-notice.svg').default
                break
            case 'driving-directions':
                return require('../../assets/icons/flight-documents-driving-directions.svg').default
                break
            case 'flight-itinerary':
                return require('../../assets/icons/flight-documents-flight-itinerary.svg').default
                break
        }
    }

    const getTitle = () => {
        if (searchResultsHeader) {
            if (searchResultsHeader.legs) {
                const leg = searchResultsHeader.legs[0]
                const date = leg.departureTime.split(' ')

                return `${leg.departureAirport.shortName}-${leg.arrivalAirport.shortName} ${date[0]}`
            }
        }
    }

    useEffect(() => {
        if (searchResultsHeader && messages) {
            if (!searchResultsHeader.legs || messages.length === 0) {
                navigate(`/quotes-and-bookings/${type === 'booking' ? 'bookings' : 'quotes'}`)
            }
        }
    }, [searchResultsHeader, messages])

    return (
        <>
            <SEO title='Quote Dashboard' />
            <TitleBar url={`/quotes-and-bookings/${type === 'booking' ? 'bookings' : 'quotes'}`} cta={type === 'booking' ? 'Bookings' : 'Quotes'} title={getTitle()} />

            <div className='page'>
                {(searchResultsHeaderLoading || messagesLoading || buttonsLoading || briefLoading)
                    ?
                    <LoadingOverlay />
                    :
                    <>
                        {searchResultsHeader && messages &&
                            <>
                            {searchResultsHeader.legs && messages.length > 0 &&
                                <div className='quote-dashboard'>

                                    <QuoteHeader
                                        data={searchResultsHeader}
                                        filterScrollPosition={() => {}}
                                        classNames={['quote-header--simple']}
                                        isSimple={true}
                                    />

                                    <div className='wrap'>

                                        {showTabs && 
                                            <div className='quote-dashboard__tabs'>
                                                <Link to={`/quote-dashboard/quote/${legId}`} className={getTabClassname('quote')}>Booking</Link>
                                                |
                                                <Link to={`/quote-dashboard/booking/${legId}`} className={getTabClassname('booking')}>Before you fly</Link>
                                            </div>
                                        }

                                        {messages &&
                                            <>
                                                {type === 'quote' &&
                                                    <div className='quote-dashboard__content'>
                                                        {messages.map((data, index) => {
                                                            // We need to check if the icon is set otherwise an error is thrown when switching types
                                                            if (data.icon) {
                                                                return (
                                                                    <QuoteDashboardArticle
                                                                        key={index}
                                                                        date={data.date}
                                                                        iconName={data.icon}
                                                                        icon={require(`../../assets/icons/${data.icon}.svg`)}
                                                                        title={data.title}
                                                                        warning={data.warning}
                                                                        text={data.text}
                                                                        url={data.url}
                                                                        buttonUrl={data.buttonUrl}
                                                                        cta={data.cta}
                                                                        note={data.note}
                                                                        noteUrl={data.noteUrl}
                                                                    />
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                }

                                                {type === 'booking' &&
                                                    <div className='quote-dashboard__content quote-dashboard__content--has-sidebar'>
                                                        <div className='quote-dashboard__checklist'>
                                                            {messages.map((data, index) => {
                                                                return (
                                                                    <QuoteDashboardArticleAlt
                                                                        key={index}
                                                                        title={data.title}
                                                                        text={data.text}
                                                                        url={data.url}
                                                                        buttonUrl={data.buttonUrl}
                                                                        cta={data.cta}
                                                                        complete={data.complete}
                                                                        current={index === currentStatus}
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                        <div className='quote-dashboard__sidebar'>
                                                            <h3 className='quote-dashboard__sidebar-title'>Your Flight Documents</h3>
                                                            {buttons.length > 0 && brief &&
                                                                    <div className='quote-dashboard__sidebar-buttons'>
                                                                    <a href={buttons[0].url} className='button button--full button--secondary button--light-border button--small-text'>
                                                                            <img src={getIcon(buttons[0].type)} alt='' />
                                                                            {buttons[0].text}
                                                                            <img src={downloadIcon} alt='' />
                                                                    </a>
                                                                </div>
                                                            }
                                                            {buttons.length === 1 &&
                                                                <div className='quote-dashboard__sidebar-text'>
                                                                    <p>This list will be updated as you get closer to your flight.</p>
                                                                </div>
                                                            }
                                                            {buttons.length > 1 &&
                                                                <>
                                                                    <h3 className='quote-dashboard__sidebar-title'>More Details</h3>
                                                                    <div className='quote-dashboard__sidebar-buttons'>
                                                                        {buttons.filter((button, i) => {
                                                                            return i !== 0
                                                                        }).map((button, i) => {
                                                                            return <a href={button.url} className='button button--full button--secondary button--light-border button--small-text' key={i}>
                                                                                <img src={getIcon(button.type)} alt='' />
                                                                                {button.text}
                                                                                <img src={downloadIcon} alt='' />
                                                                            </a>
                                                                        })}
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            </>
                        }
                    </>
                }
            </div>
        </>
    )
}

export default QuoteDashboard