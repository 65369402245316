import {useEffect, useContext} from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import poweredByContext from '../store/PoweredByContext'
import {replaceShyName} from '../utilities/string'
import {getUrl} from '../utilities/url'
import {getSchema} from '../utilities/poweredBy'
import reactManifest from 'react-manifest'

const SEO = ({
		title,
		description,
		type,
		image,
		date,
		canonicalUrl,
		isHomepage
	}) => {

    // Hooks
    const poweredByCtx = useContext(poweredByContext)

    const siteName = poweredByCtx.isPoweredBy ? poweredByCtx.tenant.tenantName : 'Shy Aviation'
	const url = getUrl(poweredByCtx, isHomepage ? '/' : window.location.pathname)

	type = type || 'website'

    /*
    * Replace Shy Aviation with the tenant name, if necessary
    */
    const replaceName = (str) => {
        if (str) {
			if(poweredByCtx.tenant){
				return replaceShyName(str, poweredByCtx.isPoweredBy, poweredByCtx.tenant.tenantName)
			}

			return str
        }
    }

	const getTitle = () => {
		if (title) {
			return replaceName(title)
		} else {
			const el = document.querySelector('title');

			if (el) {
				return replaceName(el.innerHTML)
			}
		}
	}

	useEffect(() => {
		if (description) {
			replaceDescription()
		}
	}, [description])

	const replaceDescription = () => {
		const metaDisc = document.querySelector('meta[name="description"]')

		if (metaDisc) {
			if (description) {
				metaDisc.setAttribute('content', description)
			}
		}
	}

	useEffect(() => {
		const icon = document.querySelector('link[rel="icon"]');
		const touchIcon = document.querySelector('link[rel="apple-touch-icon"]');
		const schemaTag = document.querySelector('script[type="application/ld+json"]');

		if (poweredByCtx.isPoweredBy && icon) {
			// Update favicon
			if ( poweredByCtx?.tenant.favIcon) {
				icon.href = poweredByCtx.tenant.favIcon
			} else {
				console.log(`favicon not available for ${poweredByCtx.tenantID}`)
			}
		}
		if (poweredByCtx.isPoweredBy && touchIcon) {
			// Update touch icon
			if (poweredByCtx?.tenant.appleTouchIcon) {
				touchIcon.href = poweredByCtx.tenant.appleTouchIcon
			} else {
				console.log(`apple-touch-icon not available for ${poweredByCtx.tenantID}`)
			}
		}
		
		// Update schema
		if (schemaTag) {
			schemaTag.textContent = JSON.stringify(getSchema(poweredByCtx))
		} else {
			const script = document.createElement('script')
			script.setAttribute('type', 'application/ld+json')
			script.textContent = JSON.stringify(getSchema(poweredByCtx))
			document.body.appendChild(script)
		}

		// Update manifest
		if (poweredByCtx.isPoweredBy) {
			const manifestConfig = {
				short_name: poweredByCtx?.tenant?.tenantName,
				name: poweredByCtx?.tenant?.tenantName,
				icons: {
					'48': `favicon-${poweredByCtx.tenantID}.ico`,
					'180': `apple-touch-icon-${poweredByCtx.tenantID}.png`
				}
			}

			reactManifest.update(manifestConfig, '#manifest-placeholder')
		}
	}, [poweredByCtx])


	return <HelmetProvider>
		<Helmet>

			{/* Title */}
			<title>{getTitle()}</title>

			{/* Description (optional) */}
			{description &&
				<meta itemprop='description' value={description} />
			}

			{/* Twitter */}
			<meta name='twitter:title' content={getTitle()} />
			<meta name='twitter:url' content={url} />
			<meta name='twitter:card' content='summary' />
			{description &&
				<meta property='twitter:description' value={description} />
			}
			{image &&
				<meta property='twitter:image' value={image} />
			}

			{/* Facebook */}
			<meta property='og:site_name' content={siteName} />
			<meta property='og:title' content={getTitle()} />
			<meta property='og:url' content={url} />
			<meta property='og:type' content={type} />
			{canonicalUrl &&
				<link rel='canonical' href={canonicalUrl} />
			}
			{description &&
				<meta property='og:description' value={description} />
			}
			{image &&
				<meta property='og:image' value={image} />
			}

			{/* Generic */}
			<meta itemprop='name' content={getTitle()} />
			<meta itemprop='url' content={url} />
			{description &&
				<meta itemprop='description' value={description} />
			}
			{image &&
				<meta itemprop='image' value={image} />
			}
			{image &&
				<meta itemprop='thumbnailUrl' value={image} />
			}
			{type === 'article' &&
				<meta itemprop='author' content={siteName} />
			}
			{date &&
				<meta itemprop='datePublished' content={date} />
			}

		</Helmet>
	</HelmetProvider>
}

export default SEO