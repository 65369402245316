import {useEffect, useState} from 'react'
import {NumericFormat} from 'react-number-format'
import format from "date-fns/format"
import parse from "date-fns/parse"
import getDenomination from '../utilities/getDenomination'
import {getDate, getTime} from '../utilities/date'
import SlideDown from 'react-slidedown/lib/slidedown'
import getClassName from '../utilities/getClassName'
import chevron from '../assets/icons/chevron-down-grey.svg'

const TransactionHistory = ({ data, isOpen, isOpenOnLoad }) => {

    const [historyOpen, setHistoryOpen] = useState(false)

    useEffect(() => {
        if (isOpen) {
            setHistoryOpen(true)
        }
    }, [isOpen])

    useEffect(() => {
        if (isOpenOnLoad) {
            setHistoryOpen(true)
        }
    }, [isOpenOnLoad])

    return (
        <article className='transaction-history'>
            <button onClick={() => {
                if (!isOpen) {
                    setHistoryOpen(!historyOpen)
                }
            }} className='transaction-history__title'>
                Transaction History
                <img className={getClassName(['transaction-history__icon', historyOpen && 'transaction-history__icon--rotated'])} src={chevron} alt='' />
            </button>
            <div className='order-summary__line'/>
            <div className='transaction-history__list'>
                <SlideDown
                    closed={!historyOpen}
                >
                    {data.map((item, i) => {
                        const parseDate = parse(getDate(item.date), "yyyy-MM-dd", new Date())
                        const formatDate = format(parseDate, "d MMM yyyy", { awareOfUnicodeTokens: true })

                        return <article className='transaction-history__item' key={i}>
                            <div className='transaction-history__row'>
                                <h3 className='transaction-history__ref'>{item.ref}</h3>
                                <p className='transaction-history__amount'>
                                    -
                                    {getDenomination(item.currency)}
                                    <NumericFormat
                                        displayType='text'
                                        value={item.amount}
                                        thousandSeparator
                                    />
                                </p>
                            </div>
                            <div className='transaction-history__row'>
                                <h3 className='transaction-history__method'>{item.method}</h3>
                                <p className='transaction-history__date'>{formatDate}</p>
                            </div>
                        </article>
                    })}
                </SlideDown>
            </div>
        </article>
    )
}

export default TransactionHistory