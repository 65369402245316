import ReturnMessage from '../components/ReturnMessage'
import TextInput from '../components/TextInput'
import Button from '../components/Button'
import LoadingOverlay from '../components/LoadingOverlay'
import usePost from '../hooks/usePost'
import {useContext, useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import format from "date-fns/format"
import authContext from '../store/AuthContext'
import poweredByContext from '../store/PoweredByContext'
import {Link, useNavigate} from 'react-router-dom'
import ModalFormWrapper from '../components/ModalFormWrapper'
import tick from '../assets/icons/tick-big.svg'
import SEO from '../components/SEO'

const SetNewPassword = ({closeModal}) => {

    // State
    const [validateForm, setValidateForm] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState(false)
    const [validateReset, setValidateReset] = useState(false)
    const [resetSuccess, setResetSuccess] = useState(false)

    // Hooks
    const {sending: loading, returnMessage, postData} = usePost()
    const {sending, returnData: loginData, returnMessage: resetPasswordMessage, postData: postPassword} = usePost()
    const authCtx = useContext(authContext)
    const poweredByCtx = useContext(poweredByContext)
    const navigate = useNavigate()

    // Get parameters from url
    const {search} = useLocation()
    const queryParams = new URLSearchParams(search)
    const email = queryParams.get('email')
    const token = queryParams.get('token')

    // Use effect
    useEffect(() => {

        // Check expiry link
        if (!validateReset) {
            // Set data
            let formData = {
                encryptedEmail: email,
                token: token
            }
            postData(formData, '/api/auth/validate-reset-password-token', 1000)
            setValidateReset(true)
        }

        if (loginData && !resetSuccess) {

            // Set login date and time
            const formatDate = format(new Date(), "dd-MM-yyyy", { awareOfUnicodeTokens: true })
            const formatTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" })
            const lastLoginDateTime = `Last login on ${formatDate} at ${formatTime}`

            // Login context
            authCtx.login(loginData.token, loginData.tokenValidTo, loginData.memberId, loginData.fullName, loginData.firstName, loginData.surname, lastLoginDateTime, loginData.isActAs)

            // Login complete
            setResetSuccess(true)
            navigate('/')
        }

    }, [authCtx, closeModal, email, loginData, navigate, postData, resetSuccess, token, validateReset])

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        // Form complete
        if (password === passwordConfirm) {

            // Set data
            let formData = {
                password: password,
                encryptedEmail: email,
                token: token,
                tenantID: poweredByCtx.tenantID
            }

            // Post
            postPassword(formData, '/api/auth/reset-password-and-login', 1000)
        }
    }

    return (
        <ModalFormWrapper
            title='New Password'
        >
            <ReturnMessage returnMessage={resetPasswordMessage} />

            {(loading || sending) ?
                <LoadingOverlay />
                :
                returnMessage.error ?
                    <div className='login-form'>

                        <div className='login-form__body'>
                            <div className='login-form__success'>
                                <div className='login-form__body__title'>
                                    The link to reset your password has expired
                                </div>
                                <br /><br/>
                                <div className='login-form__body__submit-container'>
                                    <Link to='/'>
                                        <Button onClick={closeModal} disabled={sending} classNames={['button--full']}>Go to Homepage</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    resetSuccess ?
                        <div className='login-form'>
                            <div className='login-form__success'>
                                <img className='login-form__success__tick' src={tick} alt='' />
                                <div className='login-form__success__message'>Your password has been successfully updated.</div>
                                <Button onClick={closeModal}>Continue to Website</Button>
                            </div>
                        </div>
                        :
                        <div className='login-form'>

                            <div className='login-form__body'>

                                <div className='login-form__body__title'>
                                    You requested to set a new password
                                </div>
                                <div className='login-form__body__intro'>
                                    Please provide a new password
                                </div>

                                <form onSubmit={submitHandler}>

                                    <TextInput
                                        id='password'
                                        validateForm={validateForm}
                                        label={'Password*'}
                                        required
                                        disabled={loading}
                                        value={(value) => setPassword(value)}
                                        type='password'
                                    />

                                    <TextInput
                                        id='password_confirm'
                                        validateForm={validateForm}
                                        label={'Confirm Password*'}
                                        required
                                        disabled={loading}
                                        type='password'
                                        matchPassword={password}
                                        value={(value) => setPasswordConfirm(value)}
                                    />

                                    <div className='login-form__body__submit-container'>
                                        <Button disabled={sending} classNames={['button--full']}>Save</Button>
                                    </div>

                                </form>

                                {sending && <LoadingOverlay />}

                            </div>
                        </div>
            }
        </ModalFormWrapper>
    )
}

export default SetNewPassword