import {useState} from 'react'
import QuoteQueryJets from './QuoteQueryJets'
import LoadingOverlay from './LoadingOverlay'

const ModalCopyQuoteBooking = ({closeQuoteModal, quoteOrBookingToCopy}) => {

    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)

    const onModalSuccess = () => {
        setSuccess(true)
    }

    return (
        <div className='modal-copy-quote-booking'>
            {!success &&
                <div className='modal-copy-quote-booking__header'>
                    <p className='modal-copy-quote-booking__title'>Copy quote</p>
                    <p>A new quote will be created with the copied/ edited information.</p>
                </div>
            }
            <div className='modal-copy-quote-booking__content'>
                <QuoteQueryJets inModal onModalSuccess={onModalSuccess} closeQuoteModal={closeQuoteModal} updateSending={(value) => {setSending(value)}} quoteOrBookingToCopy={quoteOrBookingToCopy} />
            </div>
            {sending && <LoadingOverlay />}
        </div>
    )
}

export default ModalCopyQuoteBooking