export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const stringsMatch = (a, b) => {
    a = cleanString(a)
    b = cleanString(b)

    return a === b
}

export const cleanString = (string) => {
    return deconstructString(string).join(' ')
}

export const deconstructString = (string) => {
    return string.toLowerCase().split(' ').filter(part => {
        return part !== ''
    })
}

export const replaceShyName = (str, isPoweredBy, tenantName) => {
    if (isPoweredBy && tenantName) {
        return str.replace(/shy aviation|shy/gi, tenantName)
    }
    return str
}

export const getMeasurements = (metric) => {
    const imperial = (metric * 3.2808).toString().split('.')
    let feet = imperial[0] || 0
    let inches = imperial[1]?.slice(0, 2) || 0
    
    return <>
        {metric}m - <small>({feet}'{inches}")</small>
    </>
}