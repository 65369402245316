import PassengerList from './PassengerList'
import getClassName from '../utilities/getClassName'

const ManagePassengerLayout = ({passengersData, children, selectedPassengerId, keepBodyOnMobile, passengerListStyle, legId, multiSelectPassengersHandler, multiSelectPassengersData, allPassengersComplete, markCompleteHandler, checkAll}) => {

    return (
        <>
            {(passengersData && (passengersData.length > 0)) ?
                <div className='manage-passenger-layout'>

                    <div className={getClassName(['manage-passenger-layout__sub-menu', keepBodyOnMobile && 'manage-passenger-layout__sub-menu--hide-on-mobile'])}>
                        <PassengerList
                            passengersData={passengersData}
                            selectedPassengerId={selectedPassengerId}
                            passengerListStyle={passengerListStyle}
                            legId={legId}
                            multiSelectPassengersHandler={(id)=>multiSelectPassengersHandler(id)}
                            multiSelectPassengersData={multiSelectPassengersData}
                            allPassengersComplete={allPassengersComplete}
                            markCompleteHandler={markCompleteHandler}
                            checkAll={checkAll}
                        />
                    </div>

                    <div className={getClassName(['manage-passenger-layout__body', keepBodyOnMobile && 'manage-passenger-layout__body--keep-on-mobile'])}>
                        {children}
                    </div>
                </div>
                :
                <div className='manage-passenger-layout'>
                    <div className='manage-passenger-layout__none-found'>
                        <div className='wrap'>
                            <div className='manage-passenger-layout__none-found__title'>No Passengers Found</div>
                            <div className='manage-passenger-layout__none-found__sub-title'>When you add passengers to your booking you can choose to save them here for future use.</div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ManagePassengerLayout