import Modal from './Modal'
import ModalError from './ModalError'

const ErrorModal = ({title, errorMessage, ctaOptions}) => {

    return (
        <Modal
            classNames={['modal__content--medium']}
            modalVisible={true}
            restricted={true}
        >
            <ModalError title={title} errorText={errorMessage} ctaOptions={ctaOptions} />
        </Modal>
    )

}

export default ErrorModal