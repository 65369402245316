import {useContext, useEffect, useState} from 'react'
import envVariables from '../data/envVariables'
import axios from 'axios'
import AuthContext from '../store/AuthContext'

const useGet = (url, delay) => {

    // Auth context
    const authCtx = useContext(AuthContext)

    // State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [returnData, setReturnData] = useState('')
    const [savedUrl, setSavedUrl] = useState('')

    useEffect(() => {

        if (url && url !== savedUrl) {
            setLoading(true)
            setReturnData(false)
            setSavedUrl(url)

            let config = {}

            if (authCtx.id) {
                config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${authCtx.token}`,
                    },
                }
            }

            axios.get(envVariables.apiEndpoint + url, config).then(response => {
                setTimeout(() => {
                    if (response.status === 200) {
                        setLoading(false)
                        setReturnData(response.data)
                        setError(false)
                    } else {
                        setLoading(false)
                        setError(`There's been a problem on the server. Please refresh the page and try again.`)
                    }
                }, delay)
            }).catch((error) => {
                if (error) {
                    setLoading(false)
                    setError(`There's been a problem on the server. Please refresh the page and try again.`)
                }
            })
        }
    }, [delay, savedUrl, url])

    return ({
        loading,
        error,
        returnData
    })
}

export default useGet