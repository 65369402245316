import {useContext} from 'react'
import poweredByContext from '../store/PoweredByContext'

const NewsletterForm = () => {
    const poweredByCtx = useContext(poweredByContext)

    if (!poweredByCtx.isPoweredBy) {
        return <iframe src='../newsletter.html' className="newsletter" />
    }
}

export default NewsletterForm