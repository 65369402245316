import Loading from './Loading'
import getClassName from '../utilities/getClassName'

const LoadingOverlay = ({ clear, hideLoader, loadingText }) => {

    let className = ['loading-overlay']

    if (clear) {
        className.push('loading-overlay--clear')
    }

    return (
        <div className={getClassName(className)}>
            {!hideLoader &&
                <Loading big={true} loadingText={loadingText} />
            }
        </div>
    )
}

export default LoadingOverlay