import PaymentAmount from './PaymentAmount'
import {useEffect, useState} from 'react'
import usePost from '../hooks/usePost'
import Button from './Button'
import SecureReassure from './SecureReassure'
import RadioButton from './RadioButton'
import {NumericFormat} from 'react-number-format'
import getDenomination from '../utilities/getDenomination'
import {Link} from 'react-router-dom'
import Switch from './Switch'
import infoIcon from '../assets/icons/info.svg'
import LoadingOverlay from './LoadingOverlay'

// import Form from './Form'
// import LoadingOverlay from './LoadingOverlay'
// import NumberInput from './NumberInput'
// import EditCta from './EditCta'
// import TextInput from './TextInput'

const CardPayment = ({legId, balance, totalOwed, currency, paymentType, depositData, returnMessageHandler, paymentRef, paymentCompleteHandler, subInvoiceNumber, isHoldingPayment, tailNum}) => {

    // State
    const [baseAmount, setBaseAmount] = useState(totalOwed)
    const [amount, setAmount] = useState(totalOwed)
    const [feeAmount, setFeeAmount] = useState(0)
    const [creditAmount, setCreditAmount] = useState(0)
    const [addPercentage, setAddPercentage] = useState(0)
    const [cardType, setCardType] = useState(null)
    const [useCredit, setUseCredit] = useState(false)
    const [additionalInstruction, setAdditionalInstruction] = useState()
    const [paymentCompleteData, setPaymentCompleteData] = useState()

    // const [validateForm, setValidateForm] = useState(false)
    // const [name, setName] = useState(null)
    // const [cardNumber, setCardNumber] = useState(null)
    // const [expiryDate, setExpiryDate] = useState(null)
    // const [securityCode, setSecurityCode] = useState(null)
    // const [postCode, setPostCode] = useState(null)

    // Hooks
    const {sending, returnMessage, postData} = usePost()

    // Set denomination
    const denomination = getDenomination(currency)

    // Submit
    const submitHandler = () => {

        //ADDED BY T - NEEDS VERIFYING!!
        let paymentData = {
            paymentType: paymentType,
            paymentMethod: 'card',
            cardType: cardType,
            currency: currency,
            baseAmount: baseAmount,
            feeAmount: feeAmount,
            creditAmount: creditAmount,
            totalAmount: amount,
            feePercentage: addPercentage,
            useCredit: useCredit,
            legId: legId,
            subInvoiceNumber: subInvoiceNumber,
            invoiceReference: paymentRef
        }
        postData(paymentData, '/api/frontend/payment-card');

        //postData({legId: legId}, '/api/frontend/mark-payment-complete', 1000)

        if (returnMessage.success) {
            const successData = {
                value: amount,
                paymentDate: new Date(),
                cardType: cardType,
            }
            setPaymentCompleteData(successData)
        }
    }

    // Send error and success data to parent component
    useEffect(() => {
        returnMessageHandler(returnMessage)
        paymentCompleteHandler(paymentCompleteData)
    }, [paymentCompleteData, paymentCompleteHandler, returnMessage, returnMessageHandler])

    // Use Effect
    useEffect(()=> {

        // Add 0%
        let addPercent = 0
        let calculationText = null
        setAdditionalInstruction(null)

        if (cardType || (useCredit === true)) {
            calculationText = `${denomination}${totalOwed}`
        }

        // Use credit
        if (useCredit) {
            calculationText = `${calculationText} - ${denomination}${balance} balance`
        }

        // Add 3%
        if (cardType === 'Credit/Debit card') {
            addPercent = 3
            calculationText = `${calculationText} + 3% fee`
        }

        // Add 3%
        if (cardType === 'AMEX') {
            addPercent = 3
            calculationText = `${calculationText} + 3% fee`
        }

        // Add percentage
        setAddPercentage(addPercent)
        let baseAmount
        let feeAmount
        let calculatedAmount
        if (paymentType === 'deposit') {
            baseAmount = depositData.depositToPay
            feeAmount = (addPercent / 100) * depositData.depositToPay
            calculatedAmount = (addPercent / 100) * depositData.depositToPay + depositData.depositToPay
        } else {
            baseAmount = totalOwed
            feeAmount = (addPercent / 100) * totalOwed
            calculatedAmount = (addPercent / 100) * totalOwed + totalOwed
        }

        // Use credit
        if (useCredit) {
            calculatedAmount = ((calculatedAmount * 100) - (balance * 100)) / 100
            setCreditAmount(balance)
        } else {
            setCreditAmount(0)
        }

        setBaseAmount(baseAmount)
        setFeeAmount(feeAmount)
        setAmount(calculatedAmount)

        if (calculationText) {
            setAdditionalInstruction(`(${calculationText})`)
        }

        // Payment successful
        if (returnMessage.success) {
            const successData = {
                value: amount,
                cardType: cardType,
                paymentDate: new Date(),
            }
            setPaymentCompleteData(successData)
        }

    }, [amount, balance, cardType, denomination, depositData.depositToPay, paymentType, returnMessage.success, totalOwed, useCredit])

    const getTitle = () => {
        switch (paymentType) {
            case 'deposit':
                return 'Deposit Amount'
            case 'hold':
                return 'Amount To Hold On Card'
            default:
                return 'Payment Amount'
        }
    }

    return (
        <div className='card-payment wrap'>

            <SecureReassure>Secure Card Payment</SecureReassure>

            <PaymentAmount
                instruction={getTitle()}
                additionalInstruction={additionalInstruction}
                totalOwed={amount}
                depositData={depositData}
                currency={currency}
                paymentType={paymentType}
            />

            {(balance > totalOwed) && !isHoldingPayment &&
                <div className='use-credit-to-pay__credit-amount'>You have a credit of <b>
                    {denomination}
                    <NumericFormat
                        displayType='text'
                        value={balance}
                        thousandSeparator
                    />
                </b>
                    <br />
                    <Link to={`/payment/use-credit/${legId}/${tailNum}`} className='use-credit-to-pay__link'>Pay with your balance</Link>
                </div>
            }

            {((!!balance && (balance > 0) && (balance < totalOwed)) && paymentType !== 'hold') &&
                <div className='use-credit-to-pay__credit-amount--partial-pay'>
                    <div>Use your credit of <b>
                        {denomination}
                        <NumericFormat
                            displayType='text'
                            value={balance}
                            thousandSeparator
                        />
                    </b>
                    </div>
                    <Switch
                        value={() => setUseCredit(!useCredit)}
                    />
                </div>
            }

            {paymentType === 'hold' &&
                <div className='card-payment__notice'>
                    <img src={infoIcon} alt='' />
                    <p>Amount will be released in full once full payment is confirmed from your SHY account manager.</p>
                </div>
            }

            <div className='card-payment__select-card'>
                Select card type to proceed
                <p>Handling fees apply when using a card payment</p>

                <RadioButton
                    id='credit-card'
                    defaultValue={(cardType === 'Credit/Debit card')}
                    value={(value) => value ? setCardType('Credit/Debit card') : setCardType(false)}
                >
                    Credit Card <b>+3%</b>
                </RadioButton>

                <RadioButton
                    id='amex'
                    defaultValue={(cardType === 'AMEX')}
                    value={(value) => value ? setCardType('AMEX') : setCardType(false)}
                >
                    American Express <b>+3%</b>
                </RadioButton>

                {/*<>*/}
                {/*    <p>Paying with {cardType} <b>+{addPercentage}%</b></p>*/}
                {/*    <EditCta onClick={() => setCardType(null)}>Change card type</EditCta>*/}
                {/*</>*/}

            </div>

            <Button onClick={submitHandler} classNames={['button--centered']} disabled={!cardType}>Confirm Selection</Button>

            {cardType &&
                <>
                    {/*    <Form classNames={['form--max-350']}>*/}

                    {/*        <TextInput*/}
                    {/*            id='cardholder-name'*/}
                    {/*            validateForm={validateForm}*/}
                    {/*            label='Cardholder name*'*/}
                    {/*            required*/}
                    {/*            maxLength={200}*/}
                    {/*            disabled={sending}*/}
                    {/*            value={(value) => setName(value)}*/}
                    {/*            defaultValue={name}*/}
                    {/*        />*/}

                    {/*        <NumberInput*/}
                    {/*            id='card-number'*/}
                    {/*            validateForm={validateForm}*/}
                    {/*            label='Card number*'*/}
                    {/*            required*/}
                    {/*            maxLength={200}*/}
                    {/*            disabled={sending}*/}
                    {/*            value={(value) => setCardNumber(value)}*/}
                    {/*            defaultValue={cardNumber}*/}
                    {/*        />*/}

                    {/*        <TextInput*/}
                    {/*            id='expiry-date'*/}
                    {/*            validateForm={validateForm}*/}
                    {/*            label='Expiry Date (MM/YYYY)*'*/}
                    {/*            required*/}
                    {/*            maxLength={7}*/}
                    {/*            disabled={sending}*/}
                    {/*            value={(value) => setExpiryDate(value)}*/}
                    {/*            defaultValue={expiryDate}*/}
                    {/*        />*/}

                    {/*        <NumberInput*/}
                    {/*            id='security-code'*/}
                    {/*            validateForm={validateForm}*/}
                    {/*            label='Security code*'*/}
                    {/*            required*/}
                    {/*            maxLength={4}*/}
                    {/*            disabled={sending}*/}
                    {/*            value={(value) => setSecurityCode(value)}*/}
                    {/*            defaultValue={securityCode}*/}
                    {/*        />*/}

                    {/*        <TextInput*/}
                    {/*            id='post-code'*/}
                    {/*            validateForm={validateForm}*/}
                    {/*            label='ZIP / Postcode*'*/}
                    {/*            required*/}
                    {/*            maxLength={20}*/}
                    {/*            disabled={sending}*/}
                    {/*            value={(value) => setPostCode(value)}*/}
                    {/*            defaultValue={postCode}*/}
                    {/*        />*/}

                    {/*        <div className='form-button-wrapper'>*/}
                    {/*            <Button*/}
                    {/*                onClick={submitHandler}*/}
                    {/*            >Pay {denomination}<NumericFormat*/}
                    {/*                displayType='text'*/}
                    {/*                value={amount}*/}
                    {/*                thousandSeparator*/}
                    {/*            />*/}
                    {/*            </Button>*/}
                    {/*        </div>*/}

                    {/*        {sending &&*/}
                    {/*            <LoadingOverlay />*/}
                    {/*        }*/}

                    {/*    </Form>*/}
                </>
            }

            {sending &&
                <LoadingOverlay />
            }

        </div>
    )
}

export default CardPayment