import shyLogo from '../assets/icons/shy-logo.svg'
import ProfilePanel from './ProfilePanel'
import Nav from './Nav'
import getClassName from '../utilities/getClassName'
import { Link, useLocation } from 'react-router-dom'
import ProfileButton from './ProfileButton'
import { useContext, useEffect, useState } from 'react'
import Modal from './Modal'
import LoginForgotPasswordSteps from './LoginForgotPasswordSteps'
import authContext from '../store/AuthContext'
import poweredByContext from '../store/PoweredByContext'
import toggleIcon from '../assets/icons/header-toggle.svg'
import toggleIconPoweredBy from '../assets/icons/header-toggle-powered-by.svg'
import {isPelorus} from '../utilities/poweredBy'

const Header = ({classNames, altHeader2}) => {

    // Hooks
    const authCtx = useContext(authContext)
    const poweredByCtx = useContext(poweredByContext)
    const { search } = useLocation()
    const queryParams = new URLSearchParams(search)

    // State
    const [showProfile, setShowProfile] = useState(false)
    const [showLoginPanel, setShowLoginPanel] = useState(false)
    const [showNav, setShowNav] = useState(false)
    const [scrollTop, setScrollTop] = useState(0)
    const [navScrolled, setNavScrolled] = useState(false)

    // Alt logo position
    let className = ['header']

    if (navScrolled) {
        className.push('header--scrolled')
    }

    // Classes
    if (typeof classNames === 'object') {
        className = className.concat(classNames)
    }

    useEffect(() => {
        const handleScroll = (event) => {
            setScrollTop(window.scrollY)
        };

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        setNavScrolled(scrollTop > 0)
    }, [scrollTop])

    useEffect(() => {
        setShowLoginPanel(queryParams.get('login') !== null)
    }, [queryParams.get('login')])

    // Open panel handler
    const showPanelHandler = () => {
        if (authCtx.token) {
            setShowProfile(true)
        } else {
            setShowLoginPanel(true)
        }
    }

    const getLogo = () => {
        let image

        if (poweredByCtx?.tenant.logoImage) {
            image = poweredByCtx.tenant.logoImage
        } else {
            console.log(`Logo image not available for ${poweredByCtx.tenantID}`)
        }

        if (image) {
            return image
        }
    }

    const getIcon = () => {
        let icon = toggleIcon; // Default to light icon
    
        if (poweredByCtx.tenant) {
            const isDarkHeader = poweredByCtx.tenant.isDarkHeader === 'True' || poweredByCtx.tenant.isDarkHeader === true;
            // console.log("tenant.isDarkHeader:", poweredByCtx.tenant.isDarkHeader);
            
            if (isDarkHeader) {
                icon = toggleIconPoweredBy;
                // console.log(`Icon set to dark for tenant ${poweredByCtx.tenantID}`);
            } else {
                icon = toggleIcon;
                // console.log(`Icon set to light for tenant ${poweredByCtx.tenantID}`);
            }
        } else {
            // console.log("No tenant found, using default icon");
        }
    
        // console.log("Final icon:", icon);
        return icon;
    };

    const closeLoginPanel = () => {
        setShowLoginPanel(false)

        const searchParams = new URLSearchParams(window.location.search)

        let params = '?'

        for (const p of searchParams) {
            if (p[0] !== 'login') {
                params += `${p[0]}=${p[1]}&`
            }
        }

        window.history.replaceState({}, '', window.location.href.replace(window.location.search, params.replace(/[\&\?]$/, '')))
    }
    return (
        <>
            <header className={getClassName(className)}>
                <div className='wrap header__wrap'>
                    <Link to='/'>
                        <img
                            className={getClassName(['header__logo', altHeader2 && 'header__logo--on-top'])}
                            src={poweredByCtx.isPoweredBy ? getLogo() : shyLogo}
                            alt={`${poweredByCtx.isPoweredBy ? poweredByCtx?.tenant?.tenantName : 'SHY'} Logo`}
                        />
                    </Link>
                    <ProfileButton altHeader2={altHeader2} name={authCtx.firstName} onClick={showPanelHandler} />
                    {!isPelorus(poweredByCtx) &&
                        <button className='header__toggle' onClick={() => setShowNav(true)}>
                            <img src={(poweredByCtx.isPoweredBy) ? getIcon() : toggleIcon} alt='' />
                        </button>
                    } 
                </div>
            </header>

            {authCtx.token &&
                <ProfilePanel
                    closePanel={() => setShowProfile(false)}
                    showPanel={showProfile}
                />
            }
            
            <Nav
                closeNav={() => setShowNav(false)}
                showNav={showNav}
            />

            <Modal
                classNames={['modal__content--thin']}
                modalVisible={showLoginPanel}
                closeModal={closeLoginPanel}
            >
                <LoginForgotPasswordSteps
                    closePanel={closeLoginPanel}
                />
            </Modal>
        </>
    )
}

export default Header