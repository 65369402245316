import emailIcon from '../assets/icons/email.svg'
import phoneIcon from '../assets/icons/phone.svg'
import websiteIcon from '../assets/icons/website.svg'

const LinkWithIcon = ({link, children, icon, target}) => {

    // Icon options
    if (icon === 'email') {
        icon = emailIcon
    }

    if (icon === 'phone') {
        icon = phoneIcon
    }

    if (icon === 'website') {
        icon = websiteIcon
    }

    return(
        <a target={target} className='link-with-icon' href={link}>
            <img src={icon} alt='' />
            <span>{children}</span>
        </a>
    )
}

export default LinkWithIcon