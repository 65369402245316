import React, {useContext, useEffect, useState} from 'react'
import TitleBar from '../../components/TitleBar'
import ReturnMessage from '../../components/ReturnMessage'
import LoadingOverlay from '../../components/LoadingOverlay'
import useGet from '../../hooks/useGet'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import usePost from '../../hooks/usePost'
import SwitchItem from '../../components/SwitchItem'
import authContext from '../../store/AuthContext'
import poweredByContext from '../../store/PoweredByContext'
import {Link} from 'react-router-dom'
import SEO from '../../components/SEO'

const Settings = () => {

    // Auth
    const authCtx = useContext(authContext)
    const poweredByCtx = useContext(poweredByContext)

    // Get user settings
    const {loading, returnData: userSettings} = useGet(`api/member/get-member?MemberId=${authCtx.id}&TenantID=${poweredByCtx.tenantID}`, 1000)

    // Password states
    const [showSubmitButton, setShowSubmitButton] = useState(false)
    const [validatePasswordForm, setValidatePasswordForm] = useState(false)
    const [currentPassword, setCurrentPassword] = useState(null)
    const [password, setPassword] = useState(null)
    const [passwordConfirm, setPasswordConfirm] = useState(null)
    const [resetForm, setResetForm] = useState(null)

    // Post Hooks
    const {sending, returnMessage: updatePasswordMessage, postData: postPassword} = usePost()
    const {postData: postNewsletterPref} = usePost()

    // Update newsletter preference handler
    const setNewsLetterPrefHandler = (newsletterRef, value) => {

        const data = {
            memberId: authCtx.id,
            shyAviationNewsletter: (newsletterRef === 'shyAviationNewsletter' ? value : userSettings.shyAviationNewsletter),
            shyLifestyleNewsletter: (newsletterRef === 'shyLifestyleNewsletter' ? value : userSettings.shyLifestyleNewsletter),
        }

        postNewsletterPref(data, '/api/member/update-communication-preferences', 0, false, false, false, true)
    }

    // Set manage data messages
    const shyEmail = 'support@shyaviation.com'
    const requestDataSubject = `Data request for ${authCtx.name}`
    const requestDataBody = `Dear SHY, %0D%0A %0D%0A I am writing to request all of my data. My name is ${authCtx.name} and my user ID is ${authCtx.id}. %0D%0A %0D%0A Regards.`
    const closeAccountSubject = `Close ${authCtx.name} Account`
    const closeAccountBody = `Dear SHY, %0D%0A %0D%0A I am writing to request that you close my account and delete my data. My name is ${authCtx.name} and my user ID is ${authCtx.id}. %0D%0A %0D%0A Regards.`

    // Update password handler
    const updatePasswordHandler = (e) => {
        e.preventDefault()
        setValidatePasswordForm(true)

        // Form complete
        if (currentPassword && password && passwordConfirm && (password === passwordConfirm)) {

            // Set data
            let formData = {
                memberId: authCtx.id,
                currentPassword: currentPassword,
                newPassword: password
            }

            // Put data
            postPassword(formData, '/api/auth/update-password', 1000, false, false, false, true)
        }
    }

    // Check password form is complete and show submit button
    useEffect(() => {
        if (currentPassword && password && passwordConfirm && (password === passwordConfirm)) {
            setShowSubmitButton(true)
        } else {
            setShowSubmitButton(false)
        }
    }, [currentPassword, password, passwordConfirm])

    // Reset form on password update success
    useEffect(() => {
        if (updatePasswordMessage.success) {
            setValidatePasswordForm(false)
            setResetForm(Math.random())
        }
    }, [updatePasswordMessage])

    return(
        <>
            <SEO title='Settings' />
            <TitleBar title='Settings' cta='Home' url='/' />

            <ReturnMessage returnMessage={updatePasswordMessage} />

            <div className='page'>
                <div className='wrap'>
                    {(loading) ?
                        <LoadingOverlay/>
                        :
                        <div className='settings'>

                            <section>
                                <h1>Change Password</h1>
                                <div className='settings__border'>

                                    <b>Choose a password that will be hard for others to guess</b>

                                    <form onSubmit={updatePasswordHandler}>

                                        <TextInput
                                            id='current-password'
                                            validateForm={validatePasswordForm}
                                            label={'Current password*'}
                                            required
                                            disabled={loading}
                                            value={(value) => setCurrentPassword(value)}
                                            type='password'
                                            key={`current-password-${resetForm}`}
                                        />

                                        <TextInput
                                            id='password'
                                            validateForm={validatePasswordForm}
                                            label={'New password*'}
                                            required
                                            disabled={loading}
                                            value={(value) => setPassword(value)}
                                            type='password'
                                            key={`password-${resetForm}`}
                                        />

                                        <TextInput
                                            id='password-confirm'
                                            validateForm={validatePasswordForm}
                                            label={'Confirm password*'}
                                            required
                                            disabled={loading}
                                            type='password'
                                            matchPassword={password}
                                            value={(value) => setPasswordConfirm(value)}
                                            key={`password-confirm-${resetForm}`}
                                        />
                                        <Button disabled={!showSubmitButton || sending} classNames={['button--full']}>Update</Button>
                                    </form>
                                </div>
                                {sending && <LoadingOverlay />}
                            </section>

                            {!poweredByCtx.isPoweredBy &&
                                <section>
                                    <h1>Communication</h1>
                                    <div className='settings__border'>
                                        <SwitchItem
                                            title='SHY Aviation Newsletter'
                                            isOn={userSettings.shyAviationNewsletter}
                                            value={(value) => setNewsLetterPrefHandler('shyAviationNewsletter', value)}
                                        >
                                            Receive occasional emails from us including news and offers.
                                        </SwitchItem>

                                        <SwitchItem
                                            title='SHY Lifestyle Newsletter'
                                            isOn={userSettings.shyLifestyleNewsletter}
                                            value={(value) => setNewsLetterPrefHandler('shyLifestyleNewsletter', value)}
                                        >
                                            SHY Lifestyle newsletter is a monthly email with the latest news, offers and events from SHY Lifestyle.
                                        </SwitchItem>

                                    </div>
                                </section>
                            }

                            <section className='settings__manage-data'>
                                <h1>Manage My Data</h1>
                                <div className='settings__border'>
                                    <a href={`mailto: ${shyEmail}?subject=${requestDataSubject}&body=${requestDataBody}`}>
                                        <Button classNames={['button--secondary', 'button--full', 'button--small-text ']}>Request my personal data</Button>
                                    </a>
                                    <a href={`mailto: ${shyEmail}?subject=${closeAccountSubject}&body=${closeAccountBody}`}>
                                        <Button classNames={['button--secondary', 'button--full', 'button--small-text ']}>Delete my account</Button>
                                    </a>
                                </div>
                            </section>

                            <div className='form-button-wrapper'>
                                <Link to='/'><Button classNames={['button--full']}>Home</Button></Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Settings