import {useContext, useEffect, useState} from 'react'
import getClassName from '../utilities/getClassName'
import Button from './Button'
import TextInput from './TextInput'
import usePost from '../hooks/usePost'
import LoadingOverlay from './LoadingOverlay'
import Switch from './Switch'
import authContext from '../store/AuthContext'
import ReturnMessage from './ReturnMessage'

const Share = ({shareRef, endpoint, close, type, tailNumbers}) => {

    // State
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [surname, setSurname] = useState('')
    const [validateForm, setValidateForm] = useState(false)
    const [recipients, setRecipients] = useState([])
    const [viewing, setViewing] = useState(0)
    const [dataSentCounter, setDataSentCounter] = useState(null)

    // Hooks
    const {sending, returnMessage, postData} = usePost()
    const authCtx = useContext(authContext)

    // Set messaging specific to thing being shared
    let title = 'Sharing'
    let inlineText = 'this'
    if (type === 'flight') {
        inlineText = 'this flight'
        title = 'Share Flight'
        if (shareRef.length > 1) {
            title = 'Share Flights'
            inlineText = 'these flights'

        }
    }

    // Add recipient
    const addRecipientHandler = (e) => {
        e.preventDefault()
        setValidateForm(true)

        // Form complete
        if (email && firstName && surname) {

            const recipientData = {
                firstName: firstName,
                surname: surname,
                email: email,
                requestApproval: false
            }

            let _recipients = JSON.parse(JSON.stringify(recipients))
            _recipients.push(recipientData)
            setRecipients(_recipients)
            setViewing(1)
        }
    }

    // Add another recipient
    const addAnotherRecipientHandler = () => {
        resetFormHandler()
        setViewing(0)
    }

    const getFlightInfo = () => {
        let flights = []

        shareRef.forEach((ref, i) => {
            flights.push({
                id: ref,
                tailNumber: tailNumbers[i]
            })
        })

        return flights
    }

    //Post data
    const postHandler = (e) => {
        e.preventDefault()
            
        const shareData = {
            sharerId: authCtx.id || '',
            sharees: recipients,
            sharedFlights: getFlightInfo()
        }

        postData(shareData, endpoint, 2000)
    }

    // Reset form
    const resetFormHandler = () => {
        setEmail('')
        setFirstName('')
        setSurname('')
        setValidateForm(false)
        setViewing(0)
    }

    // Reset recipients
    const resetRecipientsHandler = () => {
        setRecipients([])
        resetFormHandler()
    }

    // Remove recipient
    const removeRecipient = (i, e) => {
        e.preventDefault()
        let _recipients = JSON.parse(JSON.stringify(recipients))
        _recipients.splice(i, 1)
        setRecipients(_recipients)
    }

    // Update request approval
    const updateRequestApproval = (i, value) => {
        let _recipients = JSON.parse(JSON.stringify(recipients))
        _recipients[i].requestApproval = value
        setRecipients(_recipients)
    }

    // Get title
    const getTitle = () => {
        if (viewing === 2) {
            return 'Shared successfully'
        } else {
            return title
        }
    }

    // Use effect
    useEffect(() => {

        // Success (check it's a new post and not an old one)
        if (returnMessage.count !== dataSentCounter) {
            if (returnMessage.success && recipients.length) {
                setViewing(2)
                setDataSentCounter(returnMessage.count)
            }
        }

        // Return to view 0 if there are no recipients
        if ((recipients.length === 0) && (viewing === 1)) {
            resetFormHandler()
            setViewing(0)
        }

    }, [dataSentCounter, recipients.length, returnMessage.count, returnMessage.success, viewing])

    // Set class names
    let className = ['share', `share--${viewing}`]

    return (
        <>
            <ReturnMessage returnMessage={returnMessage} />
            <article className={getClassName(className)}>
                <h2 className='share__title'>{getTitle()}</h2>
                {viewing === 0 &&
                    <>
                        <p className='share__intro'>Please provide the details of the person you want to share {inlineText} with</p>
                        <form className='share__form' onSubmit={addRecipientHandler}>
                            <TextInput
                                id='first-name'
                                validateForm={validateForm}
                                label='First name*'
                                required
                                maxLength={200}
                                disabled={sending}
                                value={(value) => setFirstName(value)}
                                defaultValue={firstName}
                            />

                            <TextInput
                                id='last-name'
                                validateForm={validateForm}
                                label='Surname*'
                                required
                                maxLength={200}
                                disabled={sending}
                                value={(value) => setSurname(value)}
                                defaultValue={surname}
                            />

                            <TextInput
                                id='email'
                                validateForm={validateForm}
                                label='Email*'
                                required
                                disabled={sending}
                                value={(value) => setEmail(value)}
                                marginBottom
                                validation='email'
                            />
                            <div className='share__footer'>
                                <Button classNames={['button--full']}>Next</Button>
                                <Button classNames={['button--full', 'button--white']} onClick={() => close()}>Cancel</Button>
                            </div>
                        </form>
                    </>
                }
                {viewing === 1 &&
                    <form className='share__form' onSubmit={postHandler}>
                        <div className='share__form-intro'>
                            <p>Sharing with:</p>
                            <button type='button' onClick={addAnotherRecipientHandler} className='share__add-recipient'>Add recipient</button>
                        </div>
                        <div className='share__recipients'>
                            {recipients.map((recipient, i) => {
                                return <div className='share-recipient' key={i}>
                                    <p className='share-recipient__name'>{recipient.firstName} {recipient.surname}</p>
                                    <p className='share-recipient__email'>{recipient.email}</p>
                                    <button type='button' onClick={removeRecipient.bind(this, i)} className='share-recipient__remove-recipient'>Remove recipient</button>
                                    <div className='share-recipient__request-approval'>
                                        <label>Would you like to request approval for the shared flight from the recipient?</label>
                                        <Switch
                                            callback={(value) => {
                                                updateRequestApproval(i, value)
                                            }}
                                            isOn={recipients[i].requestApproval}
                                            value={()=> {}}
                                            classNames={['switch--large']}
                                        />
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className='share__footer'>
                            <Button classNames={['button--full']}>Share</Button>
                            <Button classNames={['button--full', 'button--white']} onClick={() => close()}>Cancel</Button>
                        </div>
                    </form>
                }
                {viewing === 2 &&
                    <div className='share__form'>
                        <div className='share__form-intro share__form-intro--center'>
                            <p>An email has been sent to:</p>
                        </div>
                        <div className='share__recipients'>
                            {recipients.map((recipient, i) => {
                                return <div className='share-recipient' key={i}>
                                    <p className='share-recipient__name'>{recipient.firstName} {recipient.surname}</p>
                                    <p className='share-recipient__email'>{recipient.email}</p>
                                </div>
                            })}
                        </div>
                        <button type='button' onClick={resetRecipientsHandler} className='share__add-recipient'>Add recipient</button>
                        <div className='share__footer'>
                            <Button classNames={['button--full']} onClick={() => close()}>Close</Button>
                        </div>
                    </div>
                }

                {sending && <LoadingOverlay />}
            </article>
        </>
    )
}

export default Share