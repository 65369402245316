import getClassName from '../utilities/getClassName'

const Tab = ({selected, children, onClick}) => {

    let className = ['tab']
    if (selected) {
        className = ['tab tab--selected']
    }

    return (
        <button onClick={onClick} className={getClassName(className)}>
            {children}
        </button>
    )
}

export default Tab