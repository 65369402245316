import {useContext} from 'react'
import getClassName from '../utilities/getClassName'
import poweredByContext from '../store/PoweredByContext'
import {getIcon, getRawIcon} from '../utilities/icon'

const Loading = ({big, loadingText, generic}) => {

    // Hooks
    const poweredByCtx = useContext(poweredByContext)

    let className = ['loading']

    if (big) {
        className.push('loading--big')
    }

    if (loadingText) {
        className.push('loading--with-text')
    }

    if (poweredByCtx.isPoweredBy || generic) {
        className.push('loading--spin')
    }

    if (generic) {
        className.push('loading--generic')
    }

    const icon = big ? getIcon('loading--big') : getIcon('loading')

    return (
        <>
            <div className={getClassName(className)}>
                {poweredByCtx.isPoweredBy || generic
                    ?
                        <>
                            {getRawIcon(big ? 'loading--big' : 'loading')}
                        </>
                    :
                        <>
                            <div className='loading__image' style={{
                                 maskImage: `url(${icon})`
                            }}>
                                <img src={icon} alt='' />
                                {loadingText &&
                                    <div className='loading__text'>{loadingText}</div>
                                }
                            </div>

                            <div className='loading__fill'>
                                <div />
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default Loading