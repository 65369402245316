import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import {forwardRef, useEffect, useRef, useState} from 'react'
import chevronDown from '../assets/icons/chevron-down-big.svg'
import format from "date-fns/format"
import parse from "date-fns/parse"
import Fieldset from './Fieldset'
import getClassName from '../utilities/getClassName'

// Icons
import timeIcon from '../assets/icons/time.svg'
import timeIconRed from '../assets/icons/time-red.svg'

const CustomInput = forwardRef(({value, onClick, onChange, onFocus, noKeyboard}, ref) => (
        <input
            placeholder='Time'
            value={value}
            onClick={onClick}
            onChange={onChange}
            onFocus={onFocus}
            readOnly={noKeyboard ? noKeyboard : null}
            type='text'
            className={'date-picker-input__input'}
        />
    ),
)

const TimePickerInput = ({value, defaultValue, label, validateForm, required, errorOnBlur, onFocus, copiedProperty, altLayout, skeleton, is24Hour}) => {

    // State
    const [time, setTime] = useState(null)
    const [error, setError] = useState('')
    const [readOnly, setReadOnly] = useState(false)

    // Hook
    const ref = useRef()

    const getTimeFormat = () => {
        if (is24Hour) {
            return 'HH:mm'
        } else {
            return "hh:mmaaaaa'm'"
        }
    }

    useEffect(() => {
        if (copiedProperty) {
            const formatTime = format(copiedProperty ? copiedProperty : time, getTimeFormat(), { awareOfUnicodeTokens: true })
            validationHandler(copiedProperty ? copiedProperty : time)
            setTime(copiedProperty ? copiedProperty : time)
            value(formatTime)
        }
    }, [])

    // Handle time change
    const timeChangeHandler = time => {
        const formatTime = format(time, getTimeFormat(), { awareOfUnicodeTokens: true })
        validationHandler(time)
        setTime(time)
        value(formatTime)
    }

    // Validate input
    const validationHandler = validateTime => {
        switch (true) {
            case (required && !validateTime):
                setError(`${label} is required`)
                return
            case (required && validateTime.length === 0):
                setError(`${label} is required`)
                return
            default:
                setError('')
                return
        }
    }

    // Listen for window resize events
    useEffect(() => {
        const resizeHandler = () => {
            if (!window.matchMedia('(max-width: 550px)').matches) {
                setReadOnly(false)
            } else {
                setReadOnly(true)
            }
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    //Force validation
    useEffect(() => {
        if (validateForm) {
            validationHandler(time)
        }
    })

    // Set default value
    useEffect(() => {
        if (defaultValue) {
            const parseTime = parse(defaultValue, getTimeFormat(), new Date())
            setTime(parseTime)
        } else {
            setTime(null)
        }
    }, [defaultValue])

    const focus = () => {
        if (onFocus) {
            onFocus()
        }
    }

    return (
        <Fieldset skeleton={skeleton}>
            <div style={{backgroundImage: `url(${error ? timeIconRed : timeIcon})`}}
                 className={getClassName(['date-picker-input', error && 'date-picker-input--error', altLayout && 'date-picker-input--form'])}
            >
                <img className='date-picker-input__chevron' src={chevronDown} alt='' />
                <DatePicker
                    selected={time}
                    onFocus={focus}
                    onChange={(time) => timeChangeHandler(time)}
                    onBlur={errorOnBlur ? () => validationHandler(time) : ()=>{}}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption='Time'
                    dateFormat={getTimeFormat()}
                    timeFormat={getTimeFormat()}
                    customInput={<CustomInput noKeyboard={readOnly} ref={ref} />}
                />
            </div>
        </Fieldset>
    )
}

export default TimePickerInput