import getClassName from '../utilities/getClassName'

const Form = ({children, onSubmit, classNames}) => {

    let className = ['form']

    if (typeof classNames === 'object') {
        className = className.concat(classNames)
    }

    return (
        <form onSubmit={onSubmit} className={getClassName(className)} action=''>
            {children}
        </form>
    )
}

export default Form