import {NumericFormat, useNumericFormat} from 'react-number-format'
import getDenomination from '../utilities/getDenomination'
import getClassName from '../utilities/getClassName'
import parse from 'html-react-parser'
import format from "date-fns/format"
import {default as parseDateFunction} from "date-fns/parse"
import SlideDown from 'react-slidedown/lib/slidedown'

const PaymentAmount = ({totalOwed, paymentType, depositData, instruction, currency, additionalInstruction, crypto}) => {

    const denomination = getDenomination(currency)

    // Set deposit remainder with thousand separator
    let depositRemainder = 0
    if (paymentType === 'deposit') {
        depositRemainder = depositData.remainderToPay
    }
    const depositWithThousandSeparator = useNumericFormat({
        displayType: 'text',
        value: depositRemainder,
        thousandSeparator: true
    })

    // Set the deposit remainder payment date
    let formatDate = null
    if (paymentType === 'deposit') {
        const parseDate = parseDateFunction(depositData.remainderDueDate, "yyyy-MM-dd", new Date())
        formatDate = format(parseDate, "do MMM yyyy", { awareOfUnicodeTokens: true })
    }

    // Set additional instruction for deposit amounts
    let depositInstruction
    if (paymentType === 'deposit') {
        depositInstruction = `<b>${denomination + depositWithThousandSeparator.value}</b> to be paid by ${formatDate}`
    }

    return (
        <>
            {!crypto ?
                <div className='payment-amount'>
                    <div className='payment-amount__instruction'>
                        {instruction}
                    </div>
                    <div className='payment-amount__total'>
                        {denomination}<NumericFormat
                        displayType='text'
                        value={totalOwed.toFixed(2)}
                        thousandSeparator
                    />
                    </div>

                    <SlideDown
                        closed={!additionalInstruction}
                        className='payment-amount__slide-down'
                    >
                        <div className={getClassName(['payment-amount__additional-instruction'])}>
                            {additionalInstruction}
                        </div>
                    </SlideDown>


                    {depositInstruction &&
                        <div className={getClassName(['payment-amount__additional-instruction', 'payment-amount__additional-instruction--red'])}>
                            {depositInstruction ?  parse(depositInstruction) : parse(additionalInstruction)}
                        </div>
                    }
                </div>
                :
                <div className='payment-amount'>
                </div>
            }
        </>
    )
}

export default PaymentAmount