export const Icon = () => {
	return <svg width="92" height="84" viewBox="0 0 92 84" fill="none" stroke="#D4BC87">
		<path d="M45.5,9.3V75"/>
		<path d="M12.9,42h64.8"/>
		<path d="M77.6,42c0,18-14.4,32.5-32.1,32.5S13.4,60,13.4,42S27.8,9.5,45.5,9.5S77.6,24,77.6,42z"/>
		<path d="M39.4,9.9L39.4,9.9C19.1,26,18.7,56.8,38.7,73.3l0.7,0.6"/>
		<path d="M51.6,9.9L51.6,9.9c20.5,16,20.9,47,0.7,63.5l-0.7,0.5"/>
		<path d="M66.8,16.6l-0.4,0.2c-12.9,7.9-29,8-42,0.3l0,0"/>
		<path d="M24.6,67l0.4-0.2c12.9-7.9,29-8,42-0.3l0,0"/>
		</svg>
}