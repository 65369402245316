import SelectInput from './SelectInput'
import chevronDown from '../assets/icons/chevron-down-big.svg'
import TextInput from './TextInput'
import {useContext, useEffect, useState} from 'react'
import Form from './Form'
import DatePickerInput from './DatePickerInput'
import {genderList, genderListBasic} from '../data/genderList'
import {countryList} from '../data/countryList'
import {speciesList} from '../data/speciesList'
import Button from './Button'
import usePost from '../hooks/usePost'
import {Link, useNavigate} from 'react-router-dom'
import LoadingOverlay from './LoadingOverlay'
import AddPassengerSteps from './AddPassengerSteps'
import {capitalizeFirstLetter} from '../utilities/string'
import format from "date-fns/format"
import parse from "date-fns/parse"
import authContext from '../store/AuthContext'

const PassengerDetailsForm = ({currentPassengerData, returnMessageHandler, addPassengerDetailsHandler, savedPassengers, legId, managedSavedPassengers, pagePath}) => {

    // State
    const [validateForm, setValidateForm] = useState(false)
    const [firstName, setFirstName] = useState(currentPassengerData.firstName)
    const [surname, setSurname] = useState(currentPassengerData.surname)
    const [dob, setDob] = useState(null)
    const [gender, setGender] = useState(currentPassengerData.gender)
    const [nationality, setNationality] = useState(currentPassengerData.nationality)
    const [email, setEmail] = useState(currentPassengerData.email)
    const [phoneNumber, setPhoneNumber] = useState(currentPassengerData.phoneNumber)
    const [species, setSpecies] = useState(currentPassengerData.species)
    const [breed, setBreed] = useState(currentPassengerData.breed)
    const [microchipNumber, setMicrochipNumber] = useState(currentPassengerData.microchipNumber)
    const [vaccinationDate, setVaccinationDate] = useState(null)
    const [passengerDataToPost, setPassengerDataToPost] = useState(null)
    const [savedPassenger, setSavedPassenger] = useState(null)

    // Hooks
    const {sending, returnMessage, returnData, postData} = usePost()
    const authCtx = useContext(authContext)
    const navigate = useNavigate()

    // Type passenger by default
    const type = currentPassengerData.travellerType || 'passenger'

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)
        let validForm = false

        // Check form complete
        if (type === 'passenger') {
            if (firstName && surname && dob && gender && nationality && email && phoneNumber) {
                validForm = true
            }
        }

        if (type === 'pet') {
            if (firstName && dob && gender && species && breed && microchipNumber) {
                if (species === 'Dog')
                {
                    if (vaccinationDate) {
                        validForm = true
                    }
                } else {
                    validForm = true
                }
            }
        }

        // Valid form
        if (validForm) {

            // Format dob
            const parseDob = parse(dob, "dd/MM/yyyy", new Date())
            const formattedDob = format(parseDob, "yyyy-MM-dd", { awareOfUnicodeTokens: true })

            let formData
            if (type === 'pet') {

                // Format vaccination date
                let formattedVaccinationDate = ''
                if (vaccinationDate) {
                    const parseVaccinationDate = parse(vaccinationDate, "dd/MM/yyyy", new Date())
                    formattedVaccinationDate = format(parseVaccinationDate, "yyyy-MM-dd", { awareOfUnicodeTokens: true })
                }

                // Set pet data
                formData = {
                    legId: legId,
                    firstName: firstName,
                    dob: formattedDob,
                    gender: gender,
                    species: species,
                    breed: breed,
                    microchipNumber: microchipNumber,
                    vaccinationDate: formattedVaccinationDate
                }
            } else {
                formData = {
                    legId: legId,
                    firstName: firstName,
                    surname: surname,
                    dob: formattedDob,
                    gender: gender,
                    nationality: nationality,
                    phoneNumber: phoneNumber,
                    email: email
                }
            }

            // Either create new passenger, or update an existing one
            if (!currentPassengerData.newPassenger)
            {
                // Set endpoint (different for update saved pax)
                let apiUpdatePassenger = '/api/frontend/update-passenger'
                if (managedSavedPassengers) {
                    apiUpdatePassenger = `/api/frontend/update-savedpax?memberId=${authCtx.id}`
                }

                // Update passenger / pet
                let updatedPassenger = {...currentPassengerData, ...formData}
                setPassengerDataToPost(formData)
                if (currentPassengerData.newId) {
                    console.log("Update passenger")
                    updatedPassenger = {...updatedPassenger, id: currentPassengerData.newId}
                    delete updatedPassenger.newId
                }
                if (currentPassengerData.travellerType === 'pet') {
                    console.log("Update pet")
                    console.log(updatedPassenger)
                    postData(updatedPassenger, '/api/frontend/update-pet', 1000, 'post-details')
                } else {
                    postData(updatedPassenger, apiUpdatePassenger, 1000, 'post-details')
                }

            } else {

                console.log("Add new passenger / pet")

                if (currentPassengerData.travellerType === 'pet') {
                    // New pet
                    setPassengerDataToPost(formData)
                    postData(formData, '/api/frontend/create-pet', 1000, 'post-details')
                } else {
                    // New passenger
                    console.log(formData)
                    setPassengerDataToPost(formData)
                    postData(formData, '/api/frontend/create-passenger', 1000, 'post-details')
                }
            }
        }
    }

    // Use effect
    useEffect(() => {

        // Format dob on load
        if (currentPassengerData.dob) {
            const parseDob = parse(currentPassengerData.dob, "yyyy-MM-dd", new Date())
            const formattedDob = format(parseDob, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
            setDob(formattedDob)
        }

        // Format vaccination date on load
        if (currentPassengerData.vaccinationDate) {
            const parseVaccinationDate = parse(currentPassengerData.vaccinationDate, "yyyy-MM-dd", new Date())
            const formattedVaccinationDate = format(parseVaccinationDate, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
            setVaccinationDate(formattedVaccinationDate)
        }

        returnMessageHandler(returnMessage)
        if (returnMessage.success) {
            let updatedPassengerDetail = {...passengerDataToPost}
            if (returnData && returnData.id) {
                updatedPassengerDetail = {...passengerDataToPost, ...{
                        newId: returnData.id.trim(),
                        newPassenger: false
                    }}
            }
            addPassengerDetailsHandler(updatedPassengerDetail)
            navigate(`/${pagePath}/add-passenger-document/${currentPassengerData.id}`)
        }

    }, [addPassengerDetailsHandler, currentPassengerData.dob, currentPassengerData.id, currentPassengerData.vaccinationDate, navigate, pagePath, passengerDataToPost, returnData, returnMessage, returnMessageHandler])

    // Build saved passenger options
    let savedPassengersOptions = []
    if (savedPassengers && savedPassengers.length) {
        savedPassengers.map(savedPassenger => {
            let name = `${savedPassenger.firstName} ${savedPassenger.surname}`
            if (savedPassenger.travellerType === 'pet') {
                // name = `Pet - ${savedPassenger.firstName} the ${savedPassenger.species}`
            } else {
                savedPassengersOptions.push({
                    label: name,
                    value: savedPassenger.id
                })
            }
            return false
        })
    }

    // Autofill saved passenger
    const autofillSavedPassenger = (id) => {
        setSavedPassenger(id)
        let autofillPassenger = savedPassengers.filter(savedPassenger => savedPassenger.id === id)[0]
        autofillPassenger = {...autofillPassenger,
            id: currentPassengerData.id,
            order: currentPassengerData.order,
            travellerType: currentPassengerData.travellerType,
            breed: '',
            species: '',
            documentScan: '',
            microchipNumber: '',
            vaccinationDate: '',
            documentType: autofillPassenger.documentType ? autofillPassenger.documentType : '',
            countryOfIssue: autofillPassenger.countryOfIssue ? autofillPassenger.countryOfIssue : '',
            documentNumber: autofillPassenger.documentNumber ? autofillPassenger.documentNumber : '',
            informationComplete: currentPassengerData.informationComplete
        }
        if (currentPassengerData.newId) {
            autofillPassenger = {...autofillPassenger, id: currentPassengerData.newId}
        }

        setFirstName(autofillPassenger.firstName)
        setSurname(autofillPassenger.surname)
        setGender(autofillPassenger.gender)
        setNationality(autofillPassenger.nationality)
        setEmail(autofillPassenger.email)
        setPhoneNumber(autofillPassenger.phoneNumber)
        setSpecies(autofillPassenger.species)
        setBreed(autofillPassenger.breed)
        setMicrochipNumber(autofillPassenger.microchipNumber)
        const parseDob = parse(autofillPassenger.dob, "yyyy-MM-dd", new Date())
        const formattedDob = format(parseDob, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
        setDob(formattedDob)
        if (autofillPassenger.vaccinationDate) {
            const parseVaccinationDate = parse(autofillPassenger.vaccinationDate, "yyyy-MM-dd", new Date())
            const formattedVaccinationDate = format(parseVaccinationDate, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
            setVaccinationDate(formattedVaccinationDate)
        }

        addPassengerDetailsHandler(autofillPassenger)
    }

    return (
        <div className='passenger-details-form wrap'>

            <div className='passenger-details-form__title'>{capitalizeFirstLetter(type)} details</div>

            <AddPassengerSteps step={1} />

            <div className='passenger-details-form__title passenger-details-form__title--tablet'>{capitalizeFirstLetter(type)} details</div>

            {(!managedSavedPassengers && savedPassengers && savedPassengers.length && currentPassengerData.newPassenger && currentPassengerData.travellerType !== 'pet') ?
                <>
                    <div className='passenger-details-form__instruction'>Pre-filled information from your saved {type}s</div>
                    <div className='passenger-details-form__select-passenger-wrapper'>
                        <SelectInput
                            placeholder={'Select name'}
                            options={savedPassengersOptions}
                            value={(value) => autofillSavedPassenger(value)}
                            chevron={chevronDown}
                            label={`Pre-fill ${type} details`}
                            disabled={sending}
                            defaultValue={savedPassenger}
                            classNames={['select-input--full select-input--form']}
                        />
                    </div>
                    <div className='passenger-details-form__or'><span />or<span /></div>
                </> : null
            }

            <div className='passenger-details-form__instruction'>Fill out the {type} information below as listed on their travel ID</div>

            <Form onSubmit={submitHandler}>
                {type === 'passenger' &&
                    <>
                        <TextInput
                            id='first-name'
                            validateForm={validateForm}
                            label='All given names*'
                            required
                            maxLength={200}
                            disabled={sending}
                            value={(value) => setFirstName(value)}
                            defaultValue={firstName}
                        />

                        <TextInput
                            id='last-name'
                            validateForm={validateForm}
                            label='Last name*'
                            required
                            maxLength={200}
                            disabled={sending}
                            value={(value) => setSurname(value)}
                            defaultValue={surname}
                        />

                        <div className='passenger-details-form__shared-wrapper'>
                            <div className='passenger-details-form__column'>
                                <DatePickerInput
                                    altLayout
                                    required
                                    year
                                    pastAllowed={true}
                                    futureDatesRemoved
                                    validateForm={validateForm}
                                    label='Date of Birth*'
                                    disabled={sending}
                                    value={(value) => setDob(value)}
                                    defaultValue={dob}
                                />
                            </div>
                            <div className='passenger-details-form__column'>
                                <SelectInput
                                    options={genderList}
                                    value={(value) => setGender(value)}
                                    label='Gender*'
                                    required
                                    key={gender}
                                    chevron={chevronDown}
                                    disabled={sending}
                                    validateForm={validateForm}
                                    classNames={['select-input--full select-input--form']}
                                    defaultValue={gender}
                                />
                            </div>
                        </div>

                        <SelectInput
                            options={countryList}
                            value={(value) => setNationality(value)}
                            label='Nationality*'
                            required
                            key={nationality}
                            chevron={chevronDown}
                            disabled={sending}
                            validateForm={validateForm}
                            classNames={['select-input--full select-input--form']}
                            defaultValue={nationality}
                        />

                        <TextInput
                            id='email'
                            validateForm={validateForm}
                            label='Email*'
                            required
                            validation='email'
                            maxLength={100}
                            disabled={sending}
                            value={(value) => setEmail(value)}
                            defaultValue={email}
                        />

                        <TextInput
                            id='phoneNumber'
                            validateForm={validateForm}
                            label='Phone number*'
                            required
                            disabled={sending}
                            maxLength={50}
                            value={(value) => setPhoneNumber(value)}
                            defaultValue={phoneNumber}
                        />
                    </>
                }

                {type === 'pet' &&
                    <>
                        <TextInput
                            id='first-name'
                            validateForm={validateForm}
                            label='Name*'
                            required
                            maxLength={200}
                            disabled={sending}
                            value={(value) => setFirstName(value)}
                            defaultValue={firstName}
                        />

                        <div className='passenger-details-form__shared-wrapper'>
                            <div className='passenger-details-form__column'>
                                <DatePickerInput
                                    altLayout
                                    required
                                    year
                                    pastAllowed={true}
                                    futureDatesRemoved
                                    validateForm={validateForm}
                                    label='Date of Birth*'
                                    disabled={sending}
                                    value={(value) => setDob(value)}
                                    defaultValue={dob}
                                />
                            </div>
                            <div className='passenger-details-form__column'>
                                <SelectInput
                                    options={genderListBasic}
                                    value={(value) => setGender(value)}
                                    label='Gender*'
                                    required
                                    chevron={chevronDown}
                                    disabled={sending}
                                    validateForm={validateForm}
                                    classNames={['select-input--full select-input--form']}
                                    defaultValue={gender}
                                />
                            </div>
                        </div>

                        <SelectInput
                            options={speciesList}
                            value={(value) => setSpecies(value)}
                            label='Species*'
                            required
                            chevron={chevronDown}
                            disabled={sending}
                            validateForm={validateForm}
                            classNames={['select-input--full select-input--form']}
                            defaultValue={species}
                        />

                        <TextInput
                            id='breed'
                            validateForm={validateForm}
                            label='Breed*'
                            required
                            maxLength={200}
                            disabled={sending}
                            value={(value) => setBreed(value)}
                            defaultValue={breed}
                        />

                        <TextInput
                            id='microchip-number'
                            validateForm={validateForm}
                            label='Microchip number*'
                            required
                            maxLength={200}
                            disabled={sending}
                            value={(value) => setMicrochipNumber(value)}
                            defaultValue={microchipNumber}
                        />

                        {species === 'Dog' &&
                            <DatePickerInput
                                altLayout
                                required
                                year
                                pastAllowed={true}
                                futureDatesRemoved
                                validateForm={validateForm}
                                label='Latest rabies vaccination date*'
                                disabled={sending}
                                value={(value) => setVaccinationDate(value)}
                                defaultValue={vaccinationDate}
                            />
                        }
                    </>
                }

                <div className='form-button-wrapper'>
                    <Button>Next</Button>
                    {managedSavedPassengers ?
                        <Link to={`/manage-passengers`}><Button classNames={['button--white']}>Cancel</Button></Link>
                        :
                        <Link to={`/add-passengers-to-booking/${legId}`}><Button classNames={['button--white']}>Cancel</Button></Link>
                    }
                </div>
            </Form>
            {sending && <LoadingOverlay />}
        </div>
    )
}

export default PassengerDetailsForm