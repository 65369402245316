export const getIcon = (icon) => {
    let src = []

    if (icon) {
        if (typeof icon === 'string') {
            src.push(require(`../assets/icons/${icon}.svg`))
        } else {
            icon.forEach((item, i) => {
                if (i < 2) {
                    src.push(require(`../assets/icons/${item}.svg`))
                }
            })
        }
    }

    return src
}

export const getRawIcon = (name) => {
    const {Icon} = require(`../assets/icons/raw/${name}.js`)
    return <Icon />
}