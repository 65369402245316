import {titleList} from '../data/titleList'
import {currencyList} from '../data/currencyList'
import SelectInput from './SelectInput'
import TextInput from './TextInput'
import Button from './Button'
import Form from './Form'
import {useContext, useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router'
import usePost from '../hooks/usePost'
import TextAreaInput from './TextAreaInput'
import Checkbox from './Checkbox'
import ReturnMessage from './ReturnMessage'
import LoadingOverlay from './LoadingOverlay'
import {Link} from 'react-router-dom'
import format from "date-fns/format"
import parse from "date-fns/parse"
import TagManager from 'react-gtm-module'
import poweredByContext from '../store/PoweredByContext'
import {replaceShyName} from '../utilities/string'

// Icons
import bigTick from '../assets/icons/tick-big.svg'
import JourneyLeg from './JourneyLeg'
import SlideDown from 'react-slidedown/lib/slidedown'
import chevronDown from '../assets/icons/chevron-down-white.svg'
import chevronDownBig from '../assets/icons/chevron-down-big.svg'
import ContactPreferencesInput from './ContactPreferencesInput'
import authContext from '../store/AuthContext'

const QuoteQueryPersonalDetails = ({journeyType, journeyData, journeyIsValid, resetJourney, closeModal, inModal, is24Hour}) => {

    // State
    const [validateForm, setValidateForm] = useState(false)
    const [firstName, setFirstName] = useState(titleList[0]['value'])
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [requests, setRequests] = useState('')
    const [preferredCurrency, setPreferredCurrency] = useState('')
    const [contactPreferences, setContactPreferences] = useState({whatsApp: false, email: true})
    const [gdprConsentGiven, setGdprConsentGiven] = useState(false)
    const [layout, setLayout] = useState('')
    const [slideDownOpen, setSlideDownOpen] = useState(false)
    const [rememberJourneyData, setRememberJourneyData] = useState(false)

    // Hooks
    const {sending, returnMessage, postData} = usePost()
    const slideDown = useRef()
    const { search } = useLocation()
    const queryParams = new URLSearchParams(search)
    const poweredByCtx = useContext(poweredByContext)

    // Auth context
    const authCtx = useContext(authContext)

    // Variables
    const legCount = rememberJourneyData.length

    const replaceName = (str) => {
        return replaceShyName(str, poweredByCtx.isPoweredBy, ' ')
    }

    const checkUtmProperties = () => {
        const properties = [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_term',
            'utm_content'
        ]
        let values = {}

        properties.forEach(property => {
            const value = queryParams.get(property)

            if (value) {
                values[property] = value
            }
        })

        return values
    }

    const getTime = (time) => {
        if (is24Hour) {
            return time
        } else {
            // Format time
            const parseTime = parse(time, "hh:mmaa", new Date())
            return format(parseTime, "HH:mm", { awareOfUnicodeTokens: true })
        }
    }

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        // Check form complete
        let formComplete = false
        if (authCtx.token) {
            formComplete = true
        } else {
            if (firstName && surname && email && phone && preferredCurrency && gdprConsentGiven) {
                formComplete = true
            }
        }

        // Form complete
        if (formComplete) {

            // Format journey data
            let formattedJourneyData = []
            rememberJourneyData.map(flight => {

                // Format date
                const parseDate = parse(flight['date'], "dd/MM/yyyy", new Date())
                const formatDate = format(parseDate, "yyyy-MM-dd", { awareOfUnicodeTokens: true })

                const addFlights = {
                    flightFrom: (journeyType === 'helicopters') ? flight['flightFrom'] : flight['flightFrom']['icao'],
                    flightTo: (journeyType === 'helicopters') ? flight['flightTo'] : flight['flightTo']['icao'],
                    date: formatDate,
                    time: getTime(flight['time']),
                    numberOfPassengers: flight['passengers'],
                    itemsOfLuggage: flight['luggage'],
                    journeyType: flight['journeyType']
                }
                formattedJourneyData = [...formattedJourneyData, addFlights]
                return false
            });

            // Set data
            let formData = {}
            const gclid = document.querySelector('#zc_gad')

            if (authCtx.token) {
                formData = {
                    journeys: formattedJourneyData,
                    id: authCtx.id,
                    additionalRequests: requests,
                    gclid: gclid ? gclid.value : null,
                    ...checkUtmProperties(),
                    tenantID: poweredByCtx.tenantID
                }

                // Post
                postData(formData, '/api/flight/search-flights-by-member', 1000)
            } else {
                formData = {
                    journeys: formattedJourneyData,
                    firstName: firstName,
                    lastName: surname,
                    email: email,
                    phoneNumber: phone,
                    additionalRequests: requests,
                    currency: preferredCurrency,
                    "contactPreferences": {
                        "email": true,
                        "whatsApp": contactPreferences['whatsApp']
                    },
                    gdprConsentGiven: gdprConsentGiven,
                    gclid: gclid ? gclid.value : null,
                    ...checkUtmProperties(),
                    tenantID: poweredByCtx.tenantID
                }

                // Post
                postData(formData, '/api/flight/search-flights', 1000)
            }
        }
    }

    // Remember journey data
    useEffect(() => {
        if(journeyIsValid && journeyData) {
            setRememberJourneyData(journeyData)
        }
    }, [journeyData, journeyIsValid])

    // Reset journey on successful submit
    useEffect(() => {
        if (returnMessage.success && journeyIsValid) {
            TagManager.dataLayer({
                dataLayer: {
                    event: '/thank-you-for-your-request'
                }
            });
            resetJourney()
        }
    }, [journeyIsValid, resetJourney, returnMessage.success])

    // Listen for window resize events
    useEffect(() => {

        let resizeTimeout

        const resizeHandler = () => {
            clearTimeout(resizeTimeout)
            resizeTimeout = setTimeout(() => {

                // Choose journey leg layout
                if (journeyType === 'helicopters') {
                    if (!window.matchMedia('(max-width: 730px)').matches) {
                        setLayout(3)
                    } else {
                        setLayout(7)
                    }
                } else {
                    if (!window.matchMedia('(max-width: 730px)').matches && !inModal) {
                        setLayout(3)
                    } else {
                        setLayout(2)
                    }
                }
            }, 100)
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [journeyType, rememberJourneyData.length])

    let journeyDetails = []
    if( rememberJourneyData ) {
        journeyDetails = rememberJourneyData.map(journey => {

            // Format date
            const parseDate = parse(journey.date, "dd/MM/yyyy", new Date())
            const formatDate = format(parseDate, "yyyy-MM-dd", { awareOfUnicodeTokens: true })

            return (
                <JourneyLeg
                    journeyType={journeyType}
                    layout={layout}
                    key={journey.id}
                    fromLong={(journeyType === 'helicopters') ? journey.flightFrom : journey.flightFrom.displayName}
                    fromShort={(journeyType === 'helicopters') ? null : journey.flightFrom.icao}
                    toLong={(journeyType === 'helicopters') ? journey.flightTo : journey.flightTo.displayName}
                    toShort={(journeyType === 'helicopters') ? null : journey.flightTo.icao}
                    departureDate={formatDate}
                    departureTime={journey.time}
                    luggage={journey.luggage}
                    passengers={journey.passengers}
                />
            )
        })
    }

    return (
        <div className='quote-query-personal-details'>

            <div className='quote-query-personal-details__header'>
                {(journeyType === 'helicopters') && <div className='quote-query-personal-details__header__legs-count'>Helicopter</div>}
                {(journeyType === 'group-charter') && <div className='quote-query-personal-details__header__legs-count'>Group Charter</div>}

                {(legCount > 2) && <div className='quote-query-personal-details__header__legs-count'>{legCount} Legs Selected</div>}

                {journeyDetails.slice(0, 2)}

                {(legCount > 2) &&
                    <>
                        <SlideDown
                            ref={slideDown}
                            closed={!slideDownOpen}
                        >
                            {journeyDetails.slice(2)}
                        </SlideDown>

                        {
                            slideDownOpen ?
                                <button onClick={() => setSlideDownOpen(!slideDownOpen)} className='quote-query-personal-details__header__more quote-query-personal-details__header__more--less'><img src={chevronDown} alt='' />
                                    <span>Less legs</span>
                                    <span>See less legs</span>
                                </button>
                                :
                                <button onClick={() => setSlideDownOpen(!slideDownOpen)} className='quote-query-personal-details__header__more'><img src={chevronDown} alt='' />
                                    <span>More legs</span>
                                    <span>See more legs</span>
                                </button>
                        }
                    </>
                }
            </div>

            <ReturnMessage returnMessage={returnMessage.success ? {} : returnMessage} inline />

            {(returnMessage.success || inModal) &&
                <div className='quote-query-personal-details__success'>

                    <img src={bigTick} className='quote-query-personal-details__success__big-tick' alt='' />
                    <h2 className='quote-query-personal-details__success__thanks'>Thank you for your request!</h2>
                    <div className='quote-query-personal-details__success__be-in-touch'>We'll be in touch shortly</div>

                    <div className='quote-query-personal-details__new-quote-button-container'>
                        <Button onClick={() => closeModal()}  classNames={['button--full']}>{inModal ? 'Close' : 'New Request'}</Button>
                    </div>
                    {!poweredByCtx.isPoweredBy &&
                        <div className='quote-query-personal-details__success__shy-lifestyle'>While you wait for your enquiry, have a look at <a rel='noreferrer' target='_blank' href='https://www.shylifestyle.com'>Shy Lifestyle</a> for more luxury and comfort, before, during and after your flight. </div>
                    }
                </div>
            }

            {(!returnMessage.success && !inModal) &&
                <div className='quote-query-personal-details__form'>

                    <div className='quote-query-personal-details__intro'>
                        {!authCtx.token ? `Before we submit your request, please provide some more information:` : `Thanks ${authCtx.firstName}, let us know if you have any other requests:`}
                    </div>

                    <Form
                        classNames={authCtx.token && ['form--full']}
                        onSubmit={submitHandler}
                    >

                        {!authCtx.token &&
                            <>
                                <TextInput
                                    id='first-name'
                                    validateForm={validateForm}
                                    label='First name*'
                                    required
                                    disabled={sending}
                                    maxLength={50}
                                    value={(value) => setFirstName(value)}
                                />

                                <TextInput
                                    id='surname'
                                    validateForm={validateForm}
                                    label='Surname*'
                                    required
                                    disabled={sending}
                                    maxLength={50}
                                    value={(value) => setSurname(value)}
                                />

                                <TextInput
                                    id='email'
                                    validateForm={validateForm}
                                    label='Email*'
                                    required
                                    validation='email'
                                    disabled={sending}
                                    maxLength={100}
                                    value={(value) => setEmail(value)}
                                />

                                <TextInput
                                    id='phone'
                                    validateForm={validateForm}
                                    label='Phone number*'
                                    required
                                    disabled={sending}
                                    maxLength={50}
                                    value={(value) => setPhone(value)}
                                />
                            </>
                        }

                        <TextAreaInput
                            id='requests'
                            label='Requests e.g. pets… (optional)'
                            disabled={sending}
                            value={(value) => setRequests(value)}
                        />

                        <div className='quote-query-personal-details__form-group'>
                            {!authCtx.token &&
                                <>
                                    <SelectInput
                                        options={currencyList}
                                        value={(value) => setPreferredCurrency(value)}
                                        chevron={chevronDownBig}
                                        label='Preferred currency*'
                                        required
                                        disabled={sending}
                                        validateForm={validateForm}
                                        classNames={['select-input--full select-input--form']}
                                    />

                                    <ContactPreferencesInput
                                        disabled={sending}
                                        value={(value) => setContactPreferences(value)}
                                    />
                                </>
                            }
                        </div>

                        {!authCtx.token &&
                            <div className='quote-query-personal-details__text-area-container'>
                                <Checkbox
                                    id='gdpr-consent'
                                    required
                                    value={(value) => setGdprConsentGiven(value)}
                                    validateForm={validateForm}
                                >I consent to the processing of my personal data in accordance with the <Link target='_blank' to={'/privacy-policy'}>{replaceName('Shy Aviation Privacy Policy')}</Link>.</Checkbox>
                            </div>
                        }

                        <div className='form-button-wrapper'>
                            <Button disabled={sending} classNames={['button--full']}>Submit Request</Button>
                        </div>

                    </Form>

                    {sending && <LoadingOverlay />}

                </div>
            }
        </div>
    )
}

export default QuoteQueryPersonalDetails