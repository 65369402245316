import {useLocation} from 'react-router'
import {useEffect, useRef} from 'react'

export const useScrollToHash = () => {
    const scrolledRef = useRef(false);
    const { hash } = useLocation();
    useEffect(() => {
      if (hash && !scrolledRef.current) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth' });
                scrolledRef.current = true;
            }, 1000)
        }
      }
    });
  };