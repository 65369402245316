import {useEffect, useContext, useState} from 'react'
import {Route, Routes} from 'react-router-dom'
import NotFound from './pages/NotFound'
import Layout from './components/Layout'
import Invoice from './pages/Invoice'
import RouteSwitcher from './components/RouteSwitcher'
import Quote from './pages/Quote'
import Book from './pages/members-area/Book'
import YourProfile from './pages/members-area/YourProfile'
import QuoteDashboard from './pages/members-area/QuoteDashboard'
import Home from './pages/Home'
import FlightBrief from './pages/FlightBrief'
import Payment from './pages/members-area/Payment'
import SignContract from './pages/members-area/SignContract'
import ContractSigned from './pages/members-area/ContractSigned'
import ViewContract from './pages/members-area/ViewContract'
import CarbonEmissions from './pages/members-area/CarbonEmissions'
import CarbonEmissionsPurchaseMore from './pages/members-area/CarbonEmissionsPurchaseMore'
import QuotesAndBookings from './pages/members-area/QuotesAndBookings'
import PaymentSuccess from './pages/members-area/PaymentSuccess'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsConditions from './pages/TermsConditions'
import MovidaTermsConditions from './pages/MovidaTermsConditions'
import SharePayment from './pages/members-area/SharePayment'
import Transactions from './pages/members-area/Transactions'
import BookingSuccess from './pages/members-area/BookingSuccess'
import Settings from './pages/members-area/Settings'
import ManagePassengers from './pages/members-area/ManagePassengers'
import Careers from './pages/Careers'
import TagManager from 'react-gtm-module'
import {gtmId} from './utilities/settings'
import Ambassadors from './pages/Ambassadors'
import Blog from './pages/Blog'
import BlogArticle from './pages/BlogArticle'
import ActAsUser from './pages/ActAsUser'
import ApproveFlightsSuccess from './pages/ApproveFlightsSuccess'
import PoweredByContext from './store/PoweredByContext'
import LazyLoadingContext from './store/LazyLoadingContext'
import LandingPage from './pages/LandingPage'
import QuotePdf from './pages/QuotePdf'

function App() {

    // Check if user is trying to access a private page and is NOT logged in
    const [privateRoutePage, setPrivateRoutePage] = useState(false)

    // Powered By context
    const poweredByCtx = useContext(PoweredByContext)
    const lazyLoadingCtx = useContext(LazyLoadingContext)

    const getScripts = () => {
        let scripts = [{
            id: 'zcga',
            src: 'https://crm.zoho.com/crm/javascript/zcga.js'
        }]

        if (window.location.hostname === 'booking.shyaviation.com') {
            scripts.push({
                id: 'cookieyes',
                src: 'https://cdn-cookieyes.com/client_data/7e426da0db7a4e576565babd/script.js'
            })
        }

        return scripts
    }

    useEffect(() => {
        if (lazyLoadingCtx.pageReady) {
            console.log('All necessary internal endpoints have been fetched')
            TagManager.initialize({
                gtmId: poweredByCtx.tenant?.gtmId ? poweredByCtx.tenant?.gtmId : gtmId
            })

            const scripts = getScripts()

            scripts.forEach((script) => {
                const check = document.querySelector(`script[src="${script.src}"]`)
                if (!check) {
                    const el = document.createElement('script');
                    el.id = script.id
                    el.src = script.src

                    document.body.appendChild(el);
                }
            })
        }
    }, [lazyLoadingCtx.pageReady])

    // ObPrivate
    const applyFontObPrivate = () => {
        const root = document.querySelector(':root');
        document.querySelector('body').classList.add('obPrivate');
    };

    // If this site is Powered By SHY, apply the theme
    if (poweredByCtx.isPoweredBy) {
        poweredByCtx.applyTheme(poweredByCtx.theme);
        poweredByCtx.applyTheme(poweredByCtx.themeDefaults);

        var isObPrivate = false;
        if(poweredByCtx.tenant.tenantId == "OBTR"){
            isObPrivate = true;
        }
        if (isObPrivate) {
            applyFontObPrivate();
        }
    }


    return (
        <Routes>

            <Route path='/invoice' element={<Invoice />} />

            <Route element={<Layout privateRoutePage={privateRoutePage} />}>
                <Route path='/' element={<Home />} />
                <Route path='/home' element={<Home />} />
                <Route path='/set-new-password' element={<Home showPasswordReset />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/terms-conditions' element={<TermsConditions />} />
                <Route path='/movida-terms-conditions' element={<MovidaTermsConditions />} />
                <Route path='/quote/' element={<Quote showQuoteOrCompare='quote' />} />
                <Route path='/quote/compare/' element={<Quote showQuoteOrCompare='compare' />} />
                <Route path='/shared-quote' element={<Quote sharedPage showQuoteOrCompare='quote' />} />
                <Route path='/shared-quote/compare' element={<Quote sharedPage showQuoteOrCompare='compare' />} />
                <Route path='/approve-flights-success' element={<ApproveFlightsSuccess />} />
                <Route path='/act-as-user/:clientId/:mimicValidator' element={<ActAsUser />} />

                <Route path='/shared-payment/:paymentMethod/:legId/:tailNum' element={<Payment paymentType='payment' shared />} />
                <Route path='/shared-payment/:paymentMethod/:legId/:tailNum/:subInvoiceNumber' element={<Payment paymentType='payment' shared />} />
                <Route path='/shared-deposit' element={<Payment paymentType='deposit' shared />} />
                <Route path='/shared-deposit/:paymentMethod' element={<Payment paymentType='deposit' shared />} />
                <Route path='/shared-hold/:legId/:tailNum' element={<Payment paymentType='hold' shared />} />
                <Route path='/shared-payment-success/:paymentMethod/:legId' element={<PaymentSuccess />} />

                <Route path='/fly/:page' element={<LandingPage />} />
                <Route path='/fly/:page/request' element={<LandingPage showModalOnLoad />} />

                {/* these pages should not be available for PBS */}
                {!poweredByCtx.isPoweredBy &&
                    <>
                        <Route path='/careers' element={<Careers />} />
                        <Route path='/ambassador' element={<Ambassadors />} />
                        <Route path='/blog' element={<Blog />} />
                        <Route path='/blog/:article' element={<BlogArticle />} />
                    </>
                }

                <Route path='/preview-brief' element={<FlightBrief />} />
                <Route path='/flight-brief' element={<FlightBrief />} />
                <Route path='/preview-quote' element={<QuotePdf />} />

                <Route path='/sign-contract/:legId/:tailNum/:view' element={<SignContract step={'read-contract'} />} />
                <Route path='/view-contract/:legId/:view' element={<ViewContract />} />

                <Route element={<RouteSwitcher privateRoutePage={(set) => setPrivateRoutePage(set)}  />} >
                    <Route path='/book' element={<Book />} />
                    <Route path='/your-profile' element={<YourProfile />} />
                    <Route path='/settings' element={<Settings />} />
                    <Route path='/quote-dashboard/:type/:legid' element={<QuoteDashboard />} />
                    <Route path='/quotes-and-bookings' element={<QuotesAndBookings />} />
                    <Route path='/quotes-and-bookings/:type' element={<QuotesAndBookings />} />
                    <Route path='/add-passengers-to-booking/:legId' element={<ManagePassengers step='intro' />} />
                    <Route path='/add-passengers-to-booking/:legId/add-passenger-details/:passengerId' element={<ManagePassengers step='passenger-details' />} />
                    <Route path='/add-passengers-to-booking/:legId/add-passenger-document/:passengerId' element={<ManagePassengers step='passenger-document' />} />
                    <Route path='/add-passengers-to-booking/:legId/add-passenger-visa/:passengerId' element={<ManagePassengers step='passenger-visa' />} />
                    <Route path='/add-passengers-to-booking/:legId/add-passenger-summary/:passengerId' element={<ManagePassengers step='passenger-summary' />} />
                    <Route path='/manage-passengers' element={<ManagePassengers  key='managedSavedPassengers' managedSavedPassengers step='manage-passengers' passengerListStyle='checkbox' />} />
                    <Route path='/manage-passengers/add-passenger-details/:passengerId' element={<ManagePassengers key='managedSavedPassengers' managedSavedPassengers step='passenger-details' passengerListStyle='checkbox' />} />
                    <Route path='/manage-passengers/add-passenger-document/:passengerId' element={<ManagePassengers key='managedSavedPassengers' managedSavedPassengers step='passenger-document' passengerListStyle='checkbox' />} />
                    <Route path='/manage-passengers/add-passenger-visa/:passengerId' element={<ManagePassengers key='managedSavedPassengers' managedSavedPassengers step='passenger-visa' passengerListStyle='checkbox' />} />
                    <Route path='/manage-passengers/add-passenger-summary/:passengerId' element={<ManagePassengers key='managedSavedPassengers' managedSavedPassengers step='passenger-summary' passengerListStyle='checkbox' />} />
                    <Route path='/carbon-emissions' element={<CarbonEmissions />} />
                    <Route path='/carbon-emissions/purchase-more' element={<CarbonEmissionsPurchaseMore />} />
                    <Route path='/carbon-emissions/thanks' element={<CarbonEmissions thanks />} />
                    <Route path='/sign-contract/:legId/:tailNum' element={<SignContract step={'read-contract'} />} />
                    <Route path='/sign-contract/add-signature/:legId/:tailNum' element={<SignContract step={'sign-contract'} />} />
                    <Route path='/sign-contract/carrier-terms/:legId/:tailNum' element={<SignContract step={'carrier-terms'} />} />
                    <Route path='/sign-contract/tenant-terms/:legId/:tailNum' element={<SignContract step={'tenant-terms'} />} />
                    <Route path='/contract-signed' element={<ContractSigned />} />
                    <Route path='/view-contract/:legId' element={<ViewContract />} />
                    <Route path='/payment/:paymentMethod/:legId/:tailNum' element={<Payment paymentType='payment' />} />
                    <Route path='/payment/:paymentMethod/:legId/:tailNum/:subInvoiceNumber' element={<Payment paymentType='payment' />} />
                    <Route path='/deposit' element={<Payment paymentType='deposit' />} />
                    <Route path='/deposit/:paymentMethod' element={<Payment paymentType='deposit' />} />
                    <Route path='/hold/:legId/:tailNum' element={<Payment paymentType='hold' />} />
                    <Route path='/payment-success/:paymentMethod/:legId' element={<PaymentSuccess />} />
                    <Route path='/booking-success' element={<BookingSuccess />} />
                    <Route path='/share-payment/:legId/:tailNum' element={<SharePayment />} />
                    <Route path='/transactions' element={<Transactions />} />
                </Route>

                <Route path='*' element={<NotFound/>} />
            </Route>
        </Routes>
    )
}

export default App