import {useContext} from 'react'
import PhotoCarousel from './PhotoCarousel'
import PoweredByContext from '../store/PoweredByContext'

const AircraftPreview = ({
                             recommended,
                             category,
                             aircraftName,
                             photoData,
                             yom
                         }) => {
    
    // Hooks
    const poweredByCtx = useContext(PoweredByContext)

    return (
        <div className='aircraft-card aircraft-card--preview'>
            <div className='aircraft-card__content'>
                <div className='aircraft-card__photos-browser'>
                    <PhotoCarousel
                        photoData={photoData}
                        bannerTitle={recommended ? poweredByCtx.isPoweredBy ? 'Recommended' : 'Shy Recommends' : null}
                    />

                    <div className='aircraft-card__gradient'>
                        <div className='aircraft-card__category'>{category} - {yom}</div>
                        <div className='aircraft-card__aircraft-name'>{aircraftName}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AircraftPreview