import {useState} from 'react'
import getClassName from '../utilities/getClassName'
import Fieldset from './Fieldset'
import clipboardIcon from '../assets/icons/clipboard.svg'
import tickIcon from '../assets/icons/tick-gold.svg'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ClipboardInput = ({id, defaultValue, label, size}) => {

    // State
    const [copied, setCopied] = useState(false)

    let className = ['clipboard-input']

    if (size) {
        className.push(`clipboard-input--${size}`)
    }

    return (
        <Fieldset>
            <CopyToClipboard
                text={defaultValue}
                onCopy={() => {
                    setCopied(true)
                }}
            >
                <div className={getClassName(className)}>
                    {label &&
                        <label className='clipboard-input__label'>{label}</label>
                    }
                    <input
                        defaultValue={defaultValue}
                        type='text'
                        id={id}
                        name={id}
                        className='clipboard-input__input'
                        disabled={true}
                    />
                    <img src={copied ? tickIcon : clipboardIcon} alt='' />
                </div>
            </CopyToClipboard>
        </Fieldset>
    )
}

export default ClipboardInput