import {useRef} from 'react'
import DatePickerInput from './DatePickerInput'
import getClassName from '../utilities/getClassName'
import arrowIcon from '../assets/icons/chevron-down-dark.svg'
import useClickOutside from '../hooks/useClickOutside'

const Sort = ({options, sortSelection, updateSortSelection, updateActive, active}) => {

    const sortRef = useRef(null)

    useClickOutside([sortRef], () => {
        updateActive(null)
    })

    const sortIsActive = (option) => {
        return sortSelection === option
    }

    const toggleVisibility = () => {
        updateActive(active ? null : 'sort')
    }

    return <div className={getClassName(['sort', active ? `sort--active` : ''])} ref={active ? sortRef : null}>
        <div className='sort__container'>
            <button className='sort__toggle' onClick={toggleVisibility}>
                Sort by
                <img src={arrowIcon} alt='' />
            </button>
            <div className='sort__content'>
                <div className='sort__dropdown'>
                    <div className='sort__options'>
                        {options.map((option, i) => {
                            return <button className={getClassName(['sort__option', sortIsActive(option.value) ? 'sort__option--active' : ''])} key={i} onClick={updateSortSelection.bind(this, option.value)}>
                                {option.label}
                            </button>
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Sort