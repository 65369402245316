import Fieldset from './Fieldset'
import Checkbox from './Checkbox'
import {useState} from 'react'

const ContactPreferencesInput = ({disabled, value}) => {

    const [preferences, setPreferences] = useState({'email': true})

    const setPreferenceHandler = (preference, checked) => {

        const updatedPreferences = {...preferences, [preference] : checked}
        setPreferences(updatedPreferences)
        value(updatedPreferences)
    }

    return (
        <Fieldset>
            <div className='contact-preferences-input'>
                <label className='contact-preferences-input__label'>I prefer to be contacted via:</label>
                <Checkbox locked disabled={disabled} defaultValue={true} value={(value) => setPreferenceHandler('email', value)} id='cp-email'>Email (required)</Checkbox>
                <Checkbox disabled={disabled} value={(value) => setPreferenceHandler('whatsApp', value)} id='cp-whatsapp'>WhatsApp</Checkbox>
            </div>
        </Fieldset>
    )

}

export default ContactPreferencesInput