import TitleBar from '../components/TitleBar'
import {Link} from 'react-router-dom'
import Button from '../components/Button'
import React, {useContext} from 'react'
import SEO from '../components/SEO'
import poweredByContext from '../store/PoweredByContext'
import {getUrl} from '../utilities/url'

const NotFound = () => {

    const poweredByCtx = useContext(poweredByContext)

    return (
        <>
            <SEO
                title='Page Not Found'
                canonicalUrl={getUrl(poweredByCtx, window.location.pathname)}
            />
            <TitleBar title='Page Not Found' cta='Home' url='/'/>
            <div className='page'>
                <div className='wrap wrap--1040'>
                    <div className='not-found'>
                        <p>I'm sorry, the page you're looking for is missing or expired.</p>
                        <div className='not-found__links'>
                            <p>Are you looking for:</p>
                            <Link to='/fly/private-jets'>Private Jets</Link>
                            <Link to='/fly/helicopters'>Helicopters</Link>
                            <Link to='/fly/group-charter'>Group Charter</Link>
                        </div>
                    </div>
                    <div className='form-button-wrapper'>
                        <Link to='/'><Button classNames={['button--full']}>Home</Button></Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFound