import SlideDown from 'react-slidedown/lib/slidedown'

import chevronDown from '../assets/icons/chevron-down-gold.svg'
import getClassName from '../utilities/getClassName'
import {useEffect, useState} from 'react'

const MoreInfoTray = ({buttonText, children, open, close, isReportView}) => {

    // State
    const [trayOpen, setTrayOpen] = useState(false)

    // Set tray from parent
    useEffect(() => {

        setTrayOpen(open)

    }, [open, close])

    return (
        <div className='more-info-tray'>


            <button onClick={() => setTrayOpen(!trayOpen)} className={getClassName(['more-info-tray__button', trayOpen && 'more-info-tray__button--open'])}>
                {buttonText}

                {!isReportView &&
                    <img src={chevronDown} alt='' />
                }
            </button>

            <SlideDown
                closed={!trayOpen}
            >
                <div className='more-info-tray__content'>
                    {children}
                </div>
            </SlideDown>
        </div>
    )
}

export default MoreInfoTray