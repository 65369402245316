import {useParams} from 'react-router-dom'
import BlockTextAndImage from './BlockTextAndImage'
import getClassName from '../utilities/getClassName'

const BlockHero = ({ service, image, title, subtitle, text, mainHeading, subHeading }) => {
    const pageSlug = useParams().page

    const getUrl = () => {
        if (service !== 'Private Jets') {
            return `/fly/${pageSlug}/request`
        }
    }

    return (
        <section className={getClassName(['block-hero'])}>
            <BlockTextAndImage
                service={service}
                image={image}
                title={title}
                subtitle={subtitle}
                text={text}
                ctaUrl={getUrl()}
                ctaText='Start a quote'
                mainHeading={mainHeading}
                subHeading={subHeading}
            />
        </section>
    )
}

export default BlockHero