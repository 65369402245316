import {useContext, useEffect, useState} from 'react'
import TitleBar from '../../components/TitleBar'
import Tab from '../../components/Tab'
import useGet from '../../hooks/useGet'
import LoadingOverlay from '../../components/LoadingOverlay'
import Button from '../../components/Button'
import FiltersBar from '../../components/FiltersBar'
import Filters from '../../components/Filters'
import FiltersSummary from '../../components/FiltersSummary'
import {Link, useParams, useNavigate} from 'react-router-dom'
import Listing from '../../components/Listing'
import SlideDown from 'react-slidedown/lib/slidedown'
import getClassName from '../../utilities/getClassName'
import chevron from '../../assets/icons/chevron-down-dark-grey.svg'
import CloseCross from '../../components/CloseCross'
import authContext from '../../store/AuthContext'
import SEO from '../../components/SEO'
import Modal from '../../components/Modal'
import ModalCopyQuoteBooking from '../../components/ModalCopyQuoteBooking'

const QuotesAndBookings = () => {

    const navigate = useNavigate()
    const type = useParams().type

    // State
    const [tab, setTab] = useState(type === 'bookings' ? 'bookings' : 'quotes')
    const [pageType, setPageType] = useState(type)
    const [loading, setLoading] = useState(true)
    const [activeQuotes, setActiveQuotes] = useState([])
    const [filteredActiveQuotes, setFilteredActiveQuotes] = useState([])
    const [filteredExpiredQuotes, setFilteredExpiredQuotes] = useState([])
    const [expiredQuotes, setExpiredQuotes] = useState([])
    const [showPastQuotes, setShowPastQuotes] = useState(false)
    const [showNoActiveQuotesMessage, setShowNoActiveQuotesMessage] = useState(true)
    const [activeBookings, setActiveBookings] = useState([])
    const [filteredActiveBookings, setFilteredActiveBookings] = useState([])
    const [filteredExpiredBookings, setFilteredExpiredBookings] = useState([])
    const [expiredBookings, setExpiredBookings] = useState([])
    const [showPastBookings, setShowPastBookings] = useState(false)
    const [showNoActiveBookingsMessage, setShowNoActiveBookingsMessage] = useState(true)
    const [quoteApi, setQuoteApi] = useState(null)
    const [bookingApi, setBookingApi] = useState(null)
    const [filterOptions, setFilterOptions] = useState({})
    const [filterSelections, setFilterSelections] = useState({})
    const [oldFilterSelections, setOldFilterSelections] = useState({})
    const [sortSelection, setSortSelection] = useState('departureDate')
    const [oldSortSelection, setOldSortSelection] = useState('departureDate')
    const [showFilters, setShowFilters] = useState(false)
    const [showFiltersInModal, setShowFiltersInModal] = useState(false)
    const [quoteOrBookingToCopy, setQuoteOrBookingToCopy] = useState(null)

    // Hooks
    const authCtx = useContext(authContext)

    // Get
    const {returnData: quotes} = useGet(quoteApi)
    const {returnData: bookings} = useGet(bookingApi)

    // Listen for window resize events
    useEffect(() => {
        const resizeHandler = () => {
            setShowFiltersInModal(!window.matchMedia('(min-width: 765px)').matches)
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    const sortResults = (a, b) => {
        if (a[sortSelection] && b[sortSelection]) {
            let dateA = a[sortSelection].split('/')
            let dateB = b[sortSelection].split('/')

            dateA = dateA[2] + dateA[0] + dateA[1]
            dateB = dateB[2] + dateB[0] + dateB[1]

            if (sortSelection === 'departureDate') {
                let timeA = a.departureTime.replace(':', '')
                let timeB = b.departureTime.replace(':', '')

                dateA += timeA
                dateB += timeB
            }

            if (dateA > dateB) {
                return 1
            }

            if (dateA < dateB) {
                return -1
            }
        }

        return 0
    }

    const checkQuoteStatus = (item) => {
        if (!item.expired) {
            let status
            let inPreparation = false

            if (!item.pendingSelection && !item.pendingDocument && !item.pendingPayment && !item.pendingConfirmation && !item.pendingContract && !item.contractReady) {
                inPreparation = true
            }
            if (inPreparation) {
                status = 'Your Quote is being prepared'
            }
            if (item.pendingSelection) {
                status = 'Pending Selection'
            }
            if (item.pendingContract && !item.contractReady) {
                status = 'Your Contract is being prepared'
            }
            if (item.contractReady) {
                status = 'Awaiting Signature'
            }
            if (!item.pendingSelection && item.pendingPayment) {
                status = 'Awaiting Payment'
            }
            if (!item.pendingSelection && item.pendingConfirmation) {
                status = 'Payment Confirmation Pending'
            }

            return status
        }
    }

    const checkBookingStatus = (item) => {
        if (!item.expired) {
            let status = 'Ready'

            if (item.pendingPreliminaryBrief) {
                status = 'Preliminary Brief Pending'
            }
            if (item.pendingFinalBrief) {
                status = 'Final Brief Pending'
            }
            if (item.finalBriefReady) {
                status = 'Final Brief Ready'
            }
            if (item.pendingPassengers) {
                status = 'Pending Passengers'
            }
            if (item.cancelled) {
                status = 'Cancelled'
            }

            return status
        }
    }

    const resetFilters = () => {
        let items
        let options = {
            flightFromLong: [],
            flightToLong: [],
            status: []
        }

        if (tab === 'quotes') {
            items = activeQuotes.concat(expiredQuotes)
        }
        if (tab === 'bookings') {
            items = activeBookings.concat(expiredBookings)
        }

        items.forEach((item) => {
            if (options.flightFromLong.indexOf(item.flightFromLong) === -1) {
                options.flightFromLong.push(item.flightFromLong)
            }

            if (options.flightToLong.indexOf(item.flightToLong) === -1) {
                options.flightToLong.push(item.flightToLong)
            }

            if (tab === 'quotes') {
                if (!item.expired) {
                    let inPreparation = false

                    if (!item.pendingSelection && !item.pendingDocument && !item.pendingPayment && !item.pendingConfirmation && !item.pendingContract && !item.contractReady) {
                        inPreparation = true
                    }
                    if (inPreparation) {
                        if (options.status.indexOf('Your Quote is being prepared') === -1) {
                            options.status.push('Your Quote is being prepared')
                        }
                    }
                    if (item.pendingSelection) {
                        if (options.status.indexOf('Pending Selection') === -1) {
                            options.status.push('Pending Selection')
                        }
                    }
                    if (item.pendingContract && !item.contractReady) {
                        if (options.status.indexOf('Your Contract is being prepared') === -1) {
                            options.status.push('Your Contract is being prepared')
                        }
                    }
                    if (item.contractReady) {
                        if (options.status.indexOf('Awaiting Signature') === -1) {
                            options.status.push('Awaiting Signature')
                        }
                    }
                    if (!item.pendingSelection && item.pendingPayment) {
                        if (options.status.indexOf('Awaiting Payment') === -1) {
                            options.status.push('Awaiting Payment')
                        }
                    }
                    if (!item.pendingSelection && item.pendingConfirmation) {
                        if (options.status.indexOf('Payment Confirmation Pending') === -1) {
                            options.status.push('Payment Confirmation Pending')
                        }
                    }
                }
            }

            if (tab === 'bookings') {
                if (!item.expired) {
                    let status = 'Ready'

                    if (item.pendingPreliminaryBrief) {
                        status = 'Preliminary Brief Pending'
                    }
                    if (item.pendingFinalBrief) {
                        status = 'Final Brief Pending'
                    }
                    if (item.finalBriefReady) {
                        status = 'Final Brief Ready'
                    }
                    if (item.pendingPassengers) {
                        status = 'Pending Passengers'
                    }
                    if (item.cancelled) {
                        status = 'Cancelled'
                    }

                    if (options.status.indexOf(status) === -1) {
                        options.status.push(status)
                    }
                }
            }
        })

        setFilterOptions(options)
    }

    const formatDate = (value, switchOrder) => {
        if (value) {
            value = value.split('/')

            if (switchOrder) {
                return parseInt(`${value[2]}${value[0]}${value[1]}`)
            } else {
                return parseInt(`${value[2]}${value[1]}${value[0]}`)
            }
        }
    }

    const filterResults = (result) => {
        let checks = []

        Object.keys(filterSelections).forEach((key) => {
            if (key === 'status') {
                if (tab === 'quotes') {
                    checks.push(filterSelections[key].indexOf(checkQuoteStatus(result)))
                }
                if (tab === 'bookings') {
                    checks.push(filterSelections[key].indexOf(checkBookingStatus(result)))
                }
            } else {
                if (key === 'departureDate' || key === 'arrivalDate') {
                    const fromDate = filterSelections[key].from
                    const toDate = filterSelections[key].to

                    const checkA = formatDate(result[key], true) >= formatDate(fromDate)
                    const checkB = formatDate(result[key], true) <= formatDate(toDate)

                    if (fromDate && toDate) {
                        checks.push(checkA && checkB ? 0 : -1)
                    }
                } else {
                    checks.push(filterSelections[key].indexOf(result[key]))
                }
            }
        })

        let passChecks = true

        checks.forEach((check) => {
            if (check === -1) {
                passChecks = false
            }
        })

        return passChecks
    }

    // Get Quotes / Bookings
    useEffect(() => {

        // Get quotes
        if (!quoteApi) {
            setQuoteApi(`/api/frontend/get-quotes-by-member?memberId=${authCtx.id}`)
        }

        // Once quotes are loaded, get bookings
        if (!bookingApi && quotes) {
            setBookingApi(`/api/frontend/get-bookings-by-member?memberId=${authCtx.id}`)
        }

        // Both loaded
        if(quotes.data && bookings.data) {

            //TODO Remove
            console.log("These are all the quotes found:")
            console.log(quotes.data)
            console.log("These are all the bookings found:")
            console.log(bookings.data)

            // Set active quotes
            const filterActiveQuotes = quotes.data.filter((quote) => !quote.expired && !quote.cancelled).sort((a, b) => {
                return sortResults(a, b)
            })

            const _filteredActiveQuotes = filterActiveQuotes.filter(filterResults)

            setFilteredActiveQuotes(_filteredActiveQuotes)
            setActiveQuotes(filterActiveQuotes)
            if (filterActiveQuotes.length === 0) {
                setShowPastQuotes(true)
            }

            // Set expired quotes
            const filterExpiredQuotes = quotes.data.filter((quote) => quote.expired || quote.cancelled).sort((a, b) => {
                return sortResults(a, b)
            })

            const _filteredExpiredQuotes = filterExpiredQuotes.filter(filterResults)

            setFilteredExpiredQuotes(_filteredExpiredQuotes)
            setExpiredQuotes(filterExpiredQuotes)

            // Set active bookings
            const filterActiveBookings = bookings.data.filter((booking) => !booking.expired && !booking.cancelled).sort((a, b) => {
                return sortResults(a, b)
            })

            const _filteredActiveBookings = filterActiveBookings.filter(filterResults)

            setFilteredActiveBookings(_filteredActiveBookings)
            setActiveBookings(filterActiveBookings)
            if (filterActiveBookings.length === 0) {
                setShowPastBookings(true)
            }

            // Set expired bookings
            const filterExpiredBookings = bookings.data.filter((booking) => booking.expired || booking.cancelled).sort((a, b) => {
                return sortResults(a, b)
            })

            const _filteredExpiredBookings = filterExpiredBookings.filter(filterResults)

            setFilteredExpiredBookings(_filteredExpiredBookings)
            setExpiredBookings(filterExpiredBookings)

            setLoading(false)

            let invalidPageType = false

            // If the pageType is not 'quotes' or 'bookings', mark as invalid and redirect
            if (pageType && pageType !== 'quotes' && pageType !== 'bookings') {
                invalidPageType = true
                navigate('/quotes-and-bookings')
            }

            // If there are active bookings and the pageType has not been specified, show the bookings tab
            if (filterActiveBookings.length > 0 && (!pageType || invalidPageType)) {
                setTab('bookings')
            }
        }
    }, [authCtx.id, bookingApi, bookings, quoteApi, quotes])

    useEffect(() => {
        const _filteredActiveQuotes = activeQuotes.filter(filterResults)
        const _filteredExpiredQuotes = expiredQuotes.filter(filterResults)
        const _filteredActiveBookings = activeBookings.filter(filterResults)
        const _filteredExpiredBookings = expiredBookings.filter(filterResults)

        setFilteredActiveQuotes(_filteredActiveQuotes)
        setFilteredExpiredQuotes(_filteredExpiredQuotes)
        setFilteredActiveBookings(_filteredActiveBookings)
        setFilteredExpiredBookings(_filteredExpiredBookings)
    }, [filterSelections])

    useEffect(() => {
        const _filteredActiveQuotes = JSON.parse(JSON.stringify(filteredActiveQuotes)).sort(sortResults)
        const _filteredExpiredQuotes = JSON.parse(JSON.stringify(filteredExpiredQuotes)).sort(sortResults)
        const _filteredActiveBookings = JSON.parse(JSON.stringify(filteredActiveBookings)).sort(sortResults)
        const _filteredExpiredBookings = JSON.parse(JSON.stringify(filteredExpiredBookings)).sort(sortResults)

        setFilteredActiveQuotes(_filteredActiveQuotes)
        setFilteredExpiredQuotes(_filteredExpiredQuotes)
        setFilteredActiveBookings(_filteredActiveBookings)
        setFilteredExpiredBookings(_filteredExpiredBookings)
    }, [sortSelection])

    useEffect(() => {
        if (activeQuotes.length > 0 || activeBookings.length > 0) {
            resetFilters()
        }
    }, [activeQuotes, activeBookings])

    useEffect(() => {
        if (activeQuotes.length > 0 || activeBookings.length > 0) {
            resetFilters()
            setFilterSelections({})
            setSortSelection('departureDate')
            setShowFilters(false)
        }
    }, [tab])

    const updateFilterSelections = (filter, option, action) => {
        let filters = JSON.parse(JSON.stringify(filterSelections))

        if (action === 'clear') {
            filters = {}
        } else if (action === 'add') {
            if (typeof option === 'object') {
                if (!filters[filter]) {
                    filters[filter] = {}
                }
                filters[filter][option.limit] = option.value
            } else {
                if (!filters[filter]) {
                    filters[filter] = []
                }
                if (filters[filter].indexOf(option) === -1) {
                    filters[filter].push(option)
                }
            }
        } else if (action === 'remove') {
            if (typeof option === 'object') {
                delete filters[filter]
            } else {
                const i = filters[filter].indexOf(option)

                filters[filter].splice(i, 1)

                if (filters[filter].length === 0) {
                    delete filters[filter]
                }
            }
        }

        setFilterSelections(filters)
    }

    const updateSortSelection = (option) => {
        setSortSelection(option)
    }

    // If we load the page with a pageType, then remove the pageType from the URL when updating the tabs, so that it doesn't look wrong when the tab changes
    const updateTab = (tab) => {
        if (pageType && (pageType === 'quotes' || pageType === 'bookings')) {
            window.history.replaceState({}, '', '/quotes-and-bookings')
            setPageType(null)
        }
        setTab(tab)
    }

    const getArrivalDate = (data) => {
        if (data.isReturn && data.legs.length > 1) {
            const date = data.legs[data.legs.length - 1].arrivalDate

            if (date) {
                return date
            } else {
                return '?'
            }
        }

        return data.arrivalDate
    }

    const areFiltersAvailable = () => {
        if (tab === 'quotes') {
            return activeQuotes.length > 0
        }
        if (tab === 'bookings') {
            return activeBookings.length > 0
        }
    }

    const copyHandler = (id) => {
        const searchArrays = activeQuotes.concat(expiredQuotes).concat(bookings.data).concat(expiredBookings)
        const copyItem = searchArrays.find(quoteOrBooking => {
            return quoteOrBooking.id === id
        })
        setQuoteOrBookingToCopy(copyItem)
    }

    return (
        <>
            <SEO title='Quotes and Bookings' />
            <TitleBar cta='Home' url='/'>
                <Tab onClick={() => updateTab('quotes')} selected={tab === 'quotes'}>Quotes</Tab>
                <Tab onClick={() => updateTab('bookings')} selected={tab === 'bookings'}>Bookings</Tab>
            </TitleBar>

            <FiltersBar
                filterSelections={filterSelections}
                updateShowFilters={() => {
                    setShowFilters(!showFilters)
                    if (showFiltersInModal) {
                        setOldFilterSelections(filterSelections)
                        setOldSortSelection(sortSelection)
                    }
                }}
                available={areFiltersAvailable()}
            >
                <Link to='/'><Button>New Quote</Button></Link>
            </FiltersBar>

            {showFiltersInModal
                ?
                <Modal
                    modalClassNames={['modal--full']}
                    classNames={['modal__content--thin']}
                    modalVisible={showFilters}
                    closeModal={() => {
                        setShowFilters(false)
                        setFilterSelections(oldFilterSelections)
                        setSortSelection(oldSortSelection)
                    }}
                >
                    <Filters filterOptions={filterOptions} filterSelections={filterSelections} sortSelection={sortSelection} updateFilterSelections={updateFilterSelections} updateSortSelection={updateSortSelection} showFilters={showFilters} updateShowFilters={() => { setShowFilters(!showFilters) }} showFiltersInModal={true} showSort={tab === 'quotes'} />
                </Modal>
                :
                <SlideDown
                    closed={!showFilters}
                >
                    <Filters filterOptions={filterOptions} filterSelections={filterSelections} sortSelection={sortSelection} updateFilterSelections={updateFilterSelections} updateSortSelection={updateSortSelection} showFilters={showFilters} updateShowFilters={() => { setShowFilters(!showFilters) }} showSort={tab === 'quotes'} />
                </SlideDown>
            }

            <FiltersSummary filterSelections={filterSelections} updateFilterSelections={updateFilterSelections} />


            <Modal
                classNames={['modal__content--thin']}
                modalVisible={quoteOrBookingToCopy !== null}
                closeModal={() => {
                    setQuoteOrBookingToCopy(null)
                }}
                closeCrossColour='white'
            >
                <ModalCopyQuoteBooking
                    quoteOrBookingToCopy={quoteOrBookingToCopy}
                    closeQuoteModal={() => {
                        setQuoteOrBookingToCopy(null)
                    }}
                />
            </Modal>

            <div className='page'>
                {loading ? <LoadingOverlay /> :
                    <>
                        {/* Quotes */}
                        {(tab === 'quotes') &&
                            <div>
                                <div className='wrap quotes-and-bookings'>

                                    {(filteredActiveQuotes.length > 0) ?
                                        <div className='quotes-and-bookings__title'>
                                            Active Quotes <b>({filteredActiveQuotes.length})</b>
                                        </div>
                                        :
                                        Object.keys(filterSelections).length > 0
                                            ?
                                            <div className='quotes-and-bookings__title'>
                                                {filteredExpiredQuotes.length > 0
                                                    ?
                                                    <b>No active quotes matched your criteria</b>
                                                    :
                                                    <b>No quotes matched your criteria</b>
                                                }
                                            </div>
                                            :
                                            <SlideDown
                                                transitionOnAppear={false}
                                                closed={!showNoActiveQuotesMessage}
                                            >
                                                <div className='quotes-and-bookings__none-found'>
                                                    <div className='quotes-and-bookings__none-found__title'>
                                                        No Active Quotes
                                                    </div>
                                                    <div className='quotes-and-bookings__none-found__sub-title'>
                                                        Where will you fly next?
                                                    </div>
                                                    <div className='quotes-and-bookings__none-found__close-cross-wrapper'>
                                                        <CloseCross
                                                            onClick={() => setShowNoActiveQuotesMessage(false)}
                                                        />
                                                    </div>
                                                </div>
                                            </SlideDown>
                                    }

                                    {(filteredActiveQuotes.length > 0) &&
                                        <div className='quotes-and-bookings__listing-wrapper'>
                                            {filteredActiveQuotes.map((quote, i) => {
                                                return <Listing
                                                    key={i}
                                                    type='quote'
                                                    id={quote.id}
                                                    quoteRef={quote.quoteRef}
                                                    tailNum={quote.tailNum}
                                                    requestedDate={quote.requestedDate}
                                                    expiryDate={quote.quoteExpiryDate ? quote.quoteExpiryDate : quote.expiryDate}
                                                    flightFromIcao={quote.flightFromIcao}
                                                    flightFromLong={quote.flightFromLong}
                                                    flightToIcao={quote.flightToIcao}
                                                    flightToLong={quote.flightToLong}
                                                    departureDate={quote.departureDate}
                                                    departureTime={quote.departureTime}
                                                    departureTimeZone={quote.departureTimeZone}
                                                    arrivalDate={getArrivalDate(quote)}
                                                    arrivalTime={quote.arrivalTime}
                                                    arrivalTimeZone={quote.arrivalTimeZone}
                                                    passengers={quote.passengers}
                                                    luggage={quote.luggage}
                                                    pendingSelection={quote.pendingSelection}
                                                    pendingPayment={quote.pendingPayment}
                                                    pendingContract={quote.pendingContract}
                                                    pendingConfirmation={quote.pendingConfirmation}
                                                    prelimBriefReady={quote.prelimBriefReady}
                                                    finalBriefReady={quote.finalBriefReady}
                                                    contractReady={quote.contractReady}
                                                    expired={quote.expired}
                                                    cancelled={quote.cancelled}
                                                    isReturn={quote.isReturn}
                                                    copyHandler={copyHandler}
                                                />
                                            })}
                                        </div>
                                    }

                                    {(filteredExpiredQuotes.length > 0 || Object.keys(filterSelections).length === 0) &&
                                        <div className='quotes-and-bookings__title'>
                                            <button onClick={() => {setShowPastQuotes(!showPastQuotes)}}>
                                                Past Quotes
                                                <b>({filteredExpiredQuotes.length})</b>
                                                <img className={getClassName(['quotes-and-bookings__title__chevron', showPastQuotes && 'quotes-and-bookings__title__chevron--rotated'])} src={chevron} alt='' />
                                            </button>
                                        </div>
                                    }

                                    <SlideDown
                                        transitionOnAppear={false}
                                        closed={!showPastQuotes}
                                    >
                                        {filteredExpiredQuotes.map((quote, i) => {
                                            return <Listing
                                                key={i}
                                                type='quote'
                                                id={quote.id}
                                                quoteRef={quote.quoteRef}
                                                tailNum={quote.tailNum}
                                                requestedDate={quote.requestedDate}
                                                expiryDate={quote.quoteExpiryDate ? quote.quoteExpiryDate : quote.expiryDate}
                                                flightFromIcao={quote.flightFromIcao}
                                                flightFromLong={quote.flightFromLong}
                                                flightToIcao={quote.flightToIcao}
                                                flightToLong={quote.flightToLong}
                                                departureDate={quote.departureDate}
                                                departureTime={quote.departureTime}
                                                departureTimeZone={quote.departureTimeZone}
                                                arrivalDate={getArrivalDate(quote)}
                                                arrivalTime={quote.arrivalTime}
                                                arrivalTimeZone={quote.arrivalTimeZone}
                                                passengers={quote.passengers}
                                                luggage={quote.luggage}
                                                pendingSelection={quote.pendingSelection}
                                                pendingPayment={quote.pendingPayment}
                                                pendingContract={quote.pendingContract}
                                                prelimBriefReady={quote.prelimBriefReady}
                                                finalBriefReady={quote.finalBriefReady}
                                                contractReady={quote.contractReady}
                                                expired={quote.expired}
                                                cancelled={quote.cancelled}
                                                isReturn={quote.isReturn}
                                                copyHandler={copyHandler}
                                            />
                                        })}
                                    </SlideDown>

                                </div>
                            </div>
                        }

                        {/* Bookings */}
                        {(tab === 'bookings') &&
                            <div>
                                <div className='wrap quotes-and-bookings'>

                                    {(filteredActiveBookings.length > 0) ?
                                        <div className='quotes-and-bookings__title'>
                                            Active Bookings <b>({filteredActiveBookings.length})</b>
                                        </div>
                                        :
                                        Object.keys(filterSelections).length > 0
                                            ?
                                            <div className='quotes-and-bookings__title'>
                                                {filteredExpiredBookings.length > 0
                                                    ?
                                                    <b>No active bookings matched your criteria</b>
                                                    :
                                                    <b>No bookings matched your criteria</b>
                                                }
                                            </div>
                                            :
                                            <SlideDown
                                                transitionOnAppear={false}
                                                closed={!showNoActiveBookingsMessage}
                                            >
                                                <div className='quotes-and-bookings__none-found'>
                                                    <div className='quotes-and-bookings__none-found__title'>
                                                        No Active Bookings
                                                    </div>
                                                    <div className='quotes-and-bookings__none-found__sub-title'>
                                                        Where will you fly next?
                                                    </div>
                                                    <div className='quotes-and-bookings__none-found__close-cross-wrapper'>
                                                        <CloseCross
                                                            onClick={() => setShowNoActiveBookingsMessage(false)}
                                                        />
                                                    </div>
                                                </div>
                                            </SlideDown>
                                    }

                                    {(filteredActiveBookings.length > 0) &&
                                        <div className='quotes-and-bookings__listing-wrapper'>
                                            {filteredActiveBookings.map((booking, i) => {
                                                return <Listing
                                                    type='booking'
                                                    key={i}
                                                    id={booking.id}
                                                    quoteRef={booking.bookingRef}
                                                    tailNum={booking.tailNum}
                                                    requestedDate={booking.requestedDate}
                                                    flightFromIcao={booking.flightFromIcao}
                                                    flightFromLong={booking.flightFromLong}
                                                    flightToIcao={booking.flightToIcao}
                                                    flightToLong={booking.flightToLong}
                                                    departureDate={booking.departureDate}
                                                    departureTime={booking.departureTime}
                                                    departureTimeZone={booking.departureTimeZone}
                                                    arrivalDate={getArrivalDate(booking)}
                                                    arrivalTime={booking.arrivalTime}
                                                    arrivalTimeZone={booking.arrivalTimeZone}
                                                    passengers={booking.passengers}
                                                    luggage={booking.luggage}
                                                    pendingFinalBrief={booking.pendingFinalBrief}
                                                    pendingPassengers={booking.pendingPassengers}
                                                    pendingPreliminaryBrief={booking.pendingPreliminaryBrief}
                                                    prelimBriefReady={booking.prelimBriefReady}
                                                    finalBriefReady={booking.finalBriefReady}
                                                    expired={booking.expired}
                                                    cancelled={booking.cancelled}
                                                    isReturn={booking.isReturn}
                                                    copyHandler={copyHandler}
                                                />
                                            })}
                                        </div>
                                    }

                                    {(filteredExpiredBookings.length > 0 || Object.keys(filterSelections).length === 0) &&
                                        <div className='quotes-and-bookings__title'>
                                            <button onClick={() => {setShowPastBookings(!showPastBookings)}}>
                                                Past Bookings
                                                <b>({expiredBookings.length})</b>
                                                <img className={getClassName(['quotes-and-bookings__title__chevron', showPastBookings && 'quotes-and-bookings__title__chevron--rotated'])} src={chevron} alt='' />
                                            </button>
                                        </div>
                                    }

                                    <SlideDown
                                        transitionOnAppear={false}
                                        closed={!showPastBookings}
                                    >
                                        {expiredBookings.map((booking, i) => {
                                            return <Listing
                                                type='booking'
                                                key={i}
                                                id={booking.id}
                                                quoteRef={booking.bookingRef}
                                                tailNum={booking.tailNum}
                                                requestedDate={booking.requestedDate}
                                                flightFromIcao={booking.flightFromIcao}
                                                flightFromLong={booking.flightFromLong}
                                                flightToIcao={booking.flightToIcao}
                                                flightToLong={booking.flightToLong}
                                                departureDate={booking.departureDate}
                                                departureTime={booking.departureTime}
                                                departureTimeZone={booking.departureTimeZone}
                                                arrivalDate={getArrivalDate(booking)}
                                                arrivalTime={booking.arrivalTime}
                                                arrivalTimeZone={booking.arrivalTimeZone}
                                                passengers={booking.passengers}
                                                luggage={booking.luggage}
                                                pendingFinalBrief={booking.pendingFinalBrief}
                                                pendingPassengers={booking.pendingPassengers}
                                                pendingPreliminaryBrief={booking.pendingPreliminaryBrief}
                                                prelimBriefReady={booking.prelimBriefReady}
                                                finalBriefReady={booking.finalBriefReady}
                                                expired={booking.expired}
                                                cancelled={booking.cancelled}
                                                isReturn={booking.isReturn}
                                                copyHandler={copyHandler}
                                            />
                                        })}
                                    </SlideDown>

                                </div>
                            </div>
                        }
                    </>
                }
            </div>
        </>
    )
}

export default QuotesAndBookings