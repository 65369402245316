import activeIcon from '../assets/icons/status-active.svg'
import inactiveIcon from '../assets/icons/status-inactive.svg'
import getClassName from '../utilities/getClassName'

const TimelinePoint = ({selected, children, offset}) => {

    return(
        <div className={getClassName(['timeline-point', offset && 'timeline-point--offset', selected && 'timeline-point--selected'])}>
            {selected ?
                <div className='timeline-point__unselected timeline-point__unselected--selected'><img src={activeIcon} alt=''/></div>
                :
                <div className='timeline-point__unselected'><img src={inactiveIcon} alt=''/></div>
            }
            <div className='timeline-point__line' />

            {children}
        </div>
    )
}

export default TimelinePoint