import {useState} from 'react'
import Filter from './Filter'
import Sort from './Sort'
import Button from './Button'
import getClassName from '../utilities/getClassName'

const Filters = ({filterOptions, filterSelections, sortSelection, updateFilterSelections, updateSortSelection, showFilters, updateShowFilters, showFiltersInModal, showSort}) => {

    const [active, setActive] = useState(null)

    const updateActive = (activeFilter) => {
        setActive(activeFilter)
    }

    const submit = () => {
        updateShowFilters()
    }

    return (
        <div className={getClassName(['filters', showFilters ? `filters--active` : ''])}>
            <div className='wrap'>
                <div className='filters__inner filters__inner--filters'>
                    <p className='filters__title'>Filter</p>
                    <div className='filters__options'>
                        <Filter
                            title='Departure Date'
                            type='date'
                            filter='departureDate'
                            filterOptions={filterOptions}
                            filterSelections={filterSelections}
                            updateFilterSelections={updateFilterSelections}
                            updateActive={updateActive}
                            active={active === 'departureDate'}
                        />
                        <Filter
                            title='Arrival Date'
                            type='date'
                            filter='arrivalDate'
                            filterOptions={filterOptions}
                            filterSelections={filterSelections}
                            updateFilterSelections={updateFilterSelections}
                            updateActive={updateActive}
                            active={active === 'arrivalDate'}
                        />
                        {filterOptions.flightFromLong &&
                            <Filter
                                title='Departure Airport'
                                type='checkbox'
                                options={filterOptions.flightFromLong}
                                filter='flightFromLong'
                                filterOptions={filterOptions}
                                filterSelections={filterSelections}
                                updateFilterSelections={updateFilterSelections}
                                updateActive={updateActive}
                                active={active === 'flightFromLong'}
                            />
                        }
                        {filterOptions.flightToLong &&
                            <Filter
                                title='Arrival Airport'
                                type='checkbox'
                                options={filterOptions.flightToLong}
                                filter='flightToLong'
                                filterOptions={filterOptions}
                                filterSelections={filterSelections}
                                updateFilterSelections={updateFilterSelections}
                                updateActive={updateActive}
                                active={active === 'flightToLong'}
                            />
                        }
                        {filterOptions.status &&
                            <Filter
                                title='Status'
                                type='checkbox'
                                options={filterOptions.status}
                                filter='status'
                                filterOptions={filterOptions}
                                filterSelections={filterSelections}
                                updateFilterSelections={updateFilterSelections}
                                updateActive={updateActive}
                                active={active === 'status'}
                            />
                        }
                    </div>
                </div>
                {showSort &&
                    <div className='filters__inner filters__inner--sort'>
                        <p className='filters__title'>Sort</p>
                        <div className='filters__options'>
                            <Sort
                                options={[{
                                    value: 'departureDate',
                                    label: 'Departure Date'
                                },{
                                    value: 'requestedDate',
                                    label: 'Requested Date'
                                }]}
                                sortSelection={sortSelection}
                                updateSortSelection={updateSortSelection}
                                updateActive={updateActive}
                                active={active === 'sort'}
                            />
                        </div>
                    </div>
                }
                {showFiltersInModal &&
                    <div className='filters__footer'>
                        <Button classNames={['button--full', 'button--secondary']} onClick={submit}>Apply</Button>
                    </div>
                }
            </div>
        </div>
    )
}

export default Filters