const genderList = [
    {value: 'Male', label: 'Male'},
    {value: 'Female', label: 'Female'},
    {value: 'X', label: 'X'},
    {value: 'T', label: 'T'},
    {value: 'O', label: 'O'},
]

const genderListBasic = [
    {value: 'Male', label: 'Male'},
    {value: 'Female', label: 'Female'},
]

export {genderList, genderListBasic}