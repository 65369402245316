import {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import parse from 'html-react-parser'
import Button from './Button'
import Checkbox from './Checkbox'
import TextAreaInput from './TextAreaInput'
import TextInput from './TextInput'
import usePost from '../hooks/usePost'
import ReturnMessage from './ReturnMessage'
import poweredByContext from '../store/PoweredByContext'
import {replaceShyName} from '../utilities/string'

// Icons
import bigTick from '../assets/icons/tick-big.svg'

const ServiceRequest = ({ service }) => {

    // State
    const [validateForm, setValidateForm] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [requests, setRequests] = useState('')
    const [gdprConsentGiven, setGdprConsentGiven] = useState(false)

    // Hooks
    const {sending, returnMessage, postData} = usePost()
    const poweredByCtx = useContext(poweredByContext)

    const content = {
        'Helicopters': {
            subtitle: 'Fly in a helicopter',
            title: 'Helicopters',
            text: '<p>Interested in finding out more about helicopters? <br />Fill out the form below, and our team will get back to you with all the details you need.</p>',
            placeholder: 'Helicopter request details (date, locations, passengers...)'
        },
        'Group Charter': {
            subtitle: 'Fly as a group',
            title: 'Group charter',
            text: '<p>Interested in finding out more about group charters? <br />Fill out the form below, and our team will get back to you with all the details you need.</p>',
            placeholder: 'Group request details (date, locations, passengers...)'
        }
    }

    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        // Check form complete
        let formComplete = false

        if (firstName && surname && email && phone && gdprConsentGiven) {
            formComplete = true
        }

        // Form complete
        if (formComplete) {

            const formData = {
                firstName: firstName,
                lastName: surname,
                email: email,
                phone: phone,
                requestDetails: requests,
                gdprConsentGiven: gdprConsentGiven,
                serviceType: service,
                tenantId: poweredByCtx.tenantID
            }

            // Post
            postData(formData, '/api/landingPages/quoterequest')
        }
    }

    const replaceName = (str) => {
        return replaceShyName(str, poweredByCtx.isPoweredBy, ' ')
    }

    return (
        <section className='service-request'>
            <ReturnMessage returnMessage={returnMessage.success ? {} : returnMessage} inline />
            {returnMessage.success
                ?
                    <div className='service-request__success'>
                        <img src={bigTick} className='service-request__success-tick' alt='' />
                        <h2 className='service-request__success-title'>Thank you for your request!</h2>
                        <div className='service-request__success-text'>We'll be in touch shortly</div>
                    </div>
                :
                    <>
                        <header className='service-request__header'>
                            <h2>{content[service].subtitle}</h2>
                            <h1>{content[service].title}</h1>
                        </header>
                        <div className='service-request__text'>
                            {parse(content[service].text)}
                        </div>
                        <form className='service-request__form' onSubmit={submitHandler}>
                            <TextInput
                                id='first-name'
                                validateForm={validateForm}
                                label='First name*'
                                required
                                disabled={sending}
                                maxLength={50}
                                value={(value) => setFirstName(value)}
                            />

                            <TextInput
                                id='surname'
                                validateForm={validateForm}
                                label='Surname*'
                                required
                                disabled={sending}
                                maxLength={50}
                                value={(value) => setSurname(value)}
                            />

                            <TextInput
                                id='email'
                                validateForm={validateForm}
                                label='Email*'
                                required
                                validation='email'
                                disabled={sending}
                                maxLength={100}
                                value={(value) => setEmail(value)}
                            />

                            <TextInput
                                id='phone'
                                validateForm={validateForm}
                                label='Phone number*'
                                required
                                disabled={sending}
                                maxLength={50}
                                value={(value) => setPhone(value)}
                            />

                            <TextAreaInput
                                id='requests'
                                label={content[service].placeholder}
                                disabled={sending}
                                value={(value) => setRequests(value)}
                            />

                            <Checkbox
                                id='gdpr-consent'
                                required
                                value={(value) => setGdprConsentGiven(value)}
                                validateForm={validateForm}
                            >I consent to the processing of my personal data in accordance with the <Link target='_blank' to={'/privacy-policy'}>{replaceName('Shy Aviation Privacy Policy')}</Link>.</Checkbox>

                            <div className='service-request__submit'>
                                <Button disabled={sending} classNames={['button--full']}>Submit Request</Button>
                            </div>
                        </form>
                    </>
            }
        </section>
    )
}

export default ServiceRequest