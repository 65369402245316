import {useContext} from 'react'
import Button from './Button'
import tickIcon from '../assets/icons/tick-welcome.svg'
import ModalFormWrapper from './ModalFormWrapper'
import PoweredByContext from '../store/PoweredByContext'

const Welcome = ({closePanel}) => {

    // Hooks
    const poweredByCtx = useContext(PoweredByContext)

    return (
        <ModalFormWrapper title={poweredByCtx.isPoweredBy ? 'Welcome' : 'Welcome to Shy'}>
            <div className='welcome'>
                <img src={tickIcon} alt='' />
                <div className='welcome__text'>
                    A Luxury aviation experience tailored to you is waiting
                </div>

                <Button onClick={closePanel} classNames={['button--secondary', 'button--light-border']}>Continue to quote</Button>
            </div>
        </ModalFormWrapper>
    )
}

export default Welcome