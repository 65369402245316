import {useContext, useState} from 'react'
import Button from './Button'
import {useNavigate} from 'react-router-dom'
import poweredByContext from '../store/PoweredByContext'
import envVariables from '../data/envVariables'

const QuoteCtaBar = ({page, filtersButtonRef, showFilters, showCompareBar, showFiltersHandler, showCompareBarHandler, filterCount, compareSelection, backLink, clearAllHandler, showShare, sharedPage, legId, name}) => {

    // Hooks
    const navigate = useNavigate()
    const poweredByCtx = useContext(poweredByContext)

    const getCompareIcon = () => {
        if (poweredByCtx.isPoweredBy) {
            if (poweredByCtx.tenant.isDarkButton) {
                return 'compare-black'
            } else {
                return 'compare-light'
            }
        }
    }

    const getFilterIcon = () => {
        if (poweredByCtx.isPoweredBy) {
            if (poweredByCtx.tenant.isDarkButton) {
                return 'filter-black'
            } else {
                return 'filter-light'
            }
        }
    }

    const getDownloadIcon = () => {
        if (poweredByCtx.isPoweredBy) {
            if (poweredByCtx.tenant.isDarkButton) {
                return 'download-black'
            } else {
                return 'download-light'
            }
        }
    }

    const [compareIcon, setCompareIcon] = useState(poweredByCtx.isPoweredBy ? getCompareIcon() : 'compare')
    const [filterIcon, setFilterIcon] = useState(poweredByCtx.isPoweredBy ? getFilterIcon() : 'filter')
    const [downloadIcon, setDownloadIcon] = useState(poweredByCtx.isPoweredBy ? getDownloadIcon() : 'download')

    // Navigate to quote
    const navigateBackToQuoteHandler = () => {
        let link = `/quote${backLink}`
        if (sharedPage) {
            link = `/shared-quote${backLink}`
        }

        clearAllHandler()
        navigate(link)
    }

    const compareClick = () => {
        if (typeof showCompareBarHandler === 'function') {
            showCompareBarHandler()
        }
        if (poweredByCtx.isPoweredBy) {
            setCompareIcon(showCompareBar ? getCompareIcon() : 'compare')
        }
    }

    const filterClick = () => {
        if (typeof showFiltersHandler === 'function') {
            showFiltersHandler()
        }
        if (poweredByCtx.isPoweredBy) {
            setFilterIcon(showFilters ? getFilterIcon() : 'filter')
        }
    }

    const downloadClick = () => {
        window.open(`${envVariables.documentBase}/QuotebuilderDownload/download-quotebrief?lo=${legId}&name=${name}`)
    }

    const getBackToQuoteIcon = () => {
        if (poweredByCtx.isPoweredBy) {
            if (poweredByCtx.tenant.isDarkButton) {
                return 'chevron-left-black'
            } else {
                return 'chevron-left-light'
            }
        } else {
            return 'chevron-left-light-grey'
        }
    }

    const getShareIcon = () => {
        if (poweredByCtx.isPoweredBy) {
            if (poweredByCtx.tenant.isDarkButton) {
                return 'share-black'
            } else {
                return 'share-light'
            }
        } else {
            return 'share'
        }
    }

    return (
        <div className='quote-cta-bar wrap wrap--sm-no-padding'>
            <div className='quote-cta-bar__padding'>
                {(page === 'compare') ?
                    <>
                        <Button
                            classNames={[!poweredByCtx.isPoweredBy ? 'button--white' : null, 'quote-cta-bar__button', 'quote-cta-bar__button--compare', sharedPage && 'quote-cta-bar__button--left-aligned']}
                            icon={getBackToQuoteIcon()}
                            onClick={navigateBackToQuoteHandler}
                        >
                            Back to Quote
                        </Button>
                        {!sharedPage &&
                            <>
                                <div className='quote-cta-bar__divide' />
                                <Button
                                    onClick={() => showShare()}
                                    classNames={[!poweredByCtx.isPoweredBy ? 'button--white' : null, 'quote-cta-bar__button']}
                                    icon={getShareIcon()}>
                                    Share {compareSelection.length} flight{(compareSelection.length > 1) && 's'}
                                </Button>
                            </>
                        }
                    </>
                    :
                    <>
                        <Button
                            onClick={compareClick}
                            active={showCompareBar}
                            classNames={[!poweredByCtx.isPoweredBy ? 'button--white' : null, 'quote-cta-bar__button']}
                            icon={compareIcon}>
                            Compare
                        </Button>
                        <div className="quote-cta-bar__divide" />
                        <Button
                            ref={filtersButtonRef}
                            onClick={filterClick}
                            active={showFilters}
                            classNames={[!poweredByCtx.isPoweredBy ? 'button--white' : null, 'quote-cta-bar__button', 'quote-cta-bar__button--filter']}
                            icon={filterIcon}>
                            Filter {(filterCount !== 0) && `(${filterCount})`}
                        </Button>
                        <div className="quote-cta-bar__divide"/>
                        <Button
                            onClick={downloadClick}
                            classNames={[!poweredByCtx.isPoweredBy ? 'button--white' : null, 'quote-cta-bar__button', 'quote-cta-bar__button--download']}
                            icon={downloadIcon}
                        >
                            Download
                        </Button>
                    </>
                }
            </div>
        </div>
    )
}

export default QuoteCtaBar