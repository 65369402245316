import {useContext, useEffect, useRef, useState} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Pagination} from 'swiper'
import quotes from '../../src/assets/icons/quote-left.svg'
import poweredByContext from '../store/PoweredByContext'
import {replaceShyName} from '../utilities/string'

const Testimonials = ({ testimonials }) => {

    const poweredByCtx = useContext(poweredByContext)

    /*
    * Replace Shy Aviation with the tenant name, if necessary
    */
    const replaceName = (str) => {
        if (str) {
            return replaceShyName(str, poweredByCtx.isPoweredBy, poweredByCtx?.tenant?.tenantName)
        }
    }

    return (
        <div className='testimonials'>
            <Swiper
                modules={[Pagination]}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{
                    dot: true,
                    clickable: true
                }}
            >
                {testimonials.map((testimonial, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <div className='testimonials__item'>
                                <figure className='testimonials__icon'>
                                    <img src={quotes} />
                                </figure>
                                <div className='testimonials__content'>
                                    <p>{replaceName(testimonial.text)}</p>
                                    <div className='testimonials__author'>{replaceName(testimonial.author)}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default Testimonials