import React from 'react'
import parse from 'html-react-parser'
import departureIcon from '../assets/icons/contract-departure.svg'
import arrivalIcon from '../assets/icons/contract-arrival.svg'
import {isFloatingFleet} from '../utilities/aircraft'

const Contract = ({mode, isReportView, contractData, hideTerms}) => {

    // Check the definition exists
    const checkExists = (definition) => {
        if (definition) {
            return definition
        } else {
            return false
        }
    }

    const getAdditionalTerms = (subjectToConditions, ownerApproval, subjectToWeight) => {
        const terms = []

        if (subjectToConditions && subjectToConditions !== 'None') {
            terms.push(subjectToConditions)
        }

        if (ownerApproval === 'Y') {
            terms.push(' Subject to owners approval')
        }

        if (subjectToWeight === 'Y') {
            terms.push(' Subject to weight')
        }

        if (terms.length > 0) {
            return terms.join(', ')
        } else {
            return 'n/a'
        }
    }

    // Contract data component
    const DataList = ({ data, className }) => {
        if (data) {
            return <dl className={`contract__data-list ${className}`}>
                {data.map((item, i) => {
                    return <React.Fragment key={item.term}>
                        {item.definition &&
                            <>
                                <dt>{item.term}</dt>
                                <dd className={item.className}>
                                    {parse(item.definition)}
                                    {item.smallPrint &&
                                        <>
                                            {parse(`<div class="dd--light">* ${item.smallPrint}</div>`)}
                                        </>
                                    }
                                </dd>
                            </>
                        }
                    </React.Fragment>
                })}
            </dl>
        }
    }

    const getContractDetails = (contractData) => {
        const terms = [{
            term: 'Print name:',
            definition: checkExists(contractData.cClientName)
        }]

        if (mode === 'sign' && isReportView) {
            terms.push({
                term: 'signature:',
                definition: '&nbsp;'
            })
            terms.push({
                term: 'Date:',
                definition: '&nbsp;'
            })
        }

        return terms
    }

    return (
        <div className='contract'>
            <section className='contract__section'>
                <h2 className='contract__title'>Aircraft Charter Agreement</h2>
                <div className='contract__grid'>
                    <DataList
                        data={[{
                            term: 'Date',
                            definition: checkExists(contractData.contractDate)
                        },{
                            term: 'Client',
                            definition: checkExists(contractData.cClientName)
                        },{
                            term: 'Shy Reference',
                            definition: checkExists(contractData.cShyReference)
                        }]}
                    />
                    <div className='contract__text'>
                        <address>{checkExists(contractData.uShyAddress)}</address>
                    </div>
                </div>
            </section>
            <section className='contract__section'>
                <h2 className='contract__title'>Flight information</h2>
                <DataList
                    className='contract__data-list--stack-mobile'
                    data={[{
                        term: 'Aircraft',
                        definition: `${checkExists(contractData.cAircraft)}${isFloatingFleet(contractData) ? ' (Floating Fleet)' : ''}`
                    },{
                        term: 'Aircraft Category',
                        definition: checkExists(contractData.cAircraftCategory)
                    },{
                        term: 'No. of Passengers',
                        definition: checkExists(contractData.zPassengersAndPets)
                    },{
                        term: 'Baggage',
                        definition: checkExists(contractData.cLuggage),
                        smallPrint: checkExists(contractData.uLuggageSmallPrint)
                    },{
                        term: 'Catering',
                        definition: checkExists(contractData.cateringOptionText),
                        smallPrint: checkExists(contractData.uCateringSmallPrint)
                    },{
                        term: 'Additional Terms',
                        className: 'dd--light dd--uppercase',
                        definition: getAdditionalTerms(contractData.subjectToConditions, contractData.ownerApproval, contractData.subjectToWeight)
                    },{
                        term: 'Special Remarks',
                        className: 'dd--light dd--uppercase',
                        definition: checkExists(contractData.uRemarks)
                    }]}
                />
            </section>


            <section className='contract__section'>
                <h2 className='contract__title'>Schedule Information</h2>
                <div className='contract__journeys'>
                    {contractData.schedule &&
                        <>{
                            contractData.schedule.map((journey, index) => {
                                return (
                                    <React.Fragment key={`schedule${index}`}>
                                        {contractData.schedule.length > 1 &&
                                            <p className='contract__group-title'>{index === 0 ? 'Return Trip - Outbound Flight' : 'Return Trip - Inbound Flight'}</p>
                                        }
                                        <article className='contract__journey'>
                                            <h3 className='contract__subtitle'>
                                                <img src={departureIcon} alt='' />
                                                Departure
                                            </h3>
                                            <DataList
                                                className='contract__data-list--journey'
                                                data={[{
                                                    term: 'Airport',
                                                    definition: checkExists(journey.cDepartureAirport)
                                                },{
                                                    term: 'Date',
                                                    definition: checkExists(journey.cDepartureDate)
                                                },{
                                                    term: 'Time',
                                                    definition: checkExists(journey.cDepartureTime)
                                                }]}
                                            />
                                        </article>
                                        <article className='contract__journey'>
                                            <h3 className='contract__subtitle'>
                                                <img src={arrivalIcon} alt='' />
                                                Arrival
                                            </h3>
                                            <DataList
                                                className='contract__data-list--journey'
                                                data={[{
                                                    term: 'Airport',
                                                    definition: checkExists(journey.cArrivalAirport)
                                                },{
                                                    term: 'Date',
                                                    definition: checkExists(journey.cArrivalDate)
                                                },{
                                                    term: 'Time',
                                                    definition: checkExists(journey.cArrivalTime)
                                                }]}
                                            />
                                        </article>
                                    </React.Fragment>
                                )
                            })
                        }
                        </>
                    }

                </div>
                <div className='contract__text contract__text--small'>
                    <p>* All times shown local time.</p>
                    <p>* Please note that this booking is subject to owner's approval, slots, de-icing, permits, overflights, parking, permissions, and continued aircraft and crew availability.</p>
                </div>
            </section>


            <section className='contract__section'>
                <h2 className='contract__title'>Payment Terms</h2>
                <DataList
                    className='contract__data-list--stack-mobile'
                    data={[{
                        term: 'Price',
                        definition: checkExists(contractData.cPrice)
                    },{
                        term: 'Payment Terms',
                        className: 'dd--uppercase',
                        definition: checkExists(contractData.uTerms)
                    }]}
                />

                {contractData.uCancellationTerms &&
                    <dl className='contract__data-list contract__data-list--stack-mobile'>
                        <dt>Cancellation Terms</dt>
                        <dd>
                            {contractData.uCancellationTerms.map((term, index) => {
                                return <p key={`cancellation-term${index}`}>{term.uTerm}</p>
                            })}
                        </dd>
                    </dl>
                }

                <div className='contract__text contract__text--small'>
                    <p>* {checkExists(contractData.deIceText)}</p>
                </div>
            </section>
            <section className='contract__section'>
                <div className='contract__name'>
                    <DataList
                        className='contract__data-list--stack-mobile'
                        data={getContractDetails(contractData)}
                    />
                </div>
            </section>
            {(!hideTerms && contractData.termsConditions) &&
                <section className='contract__section contract__section--flex-desktop'>
                    <h2 className='contract__title'>Terms & Conditions</h2>
                    <div className='contract__terms'>
                        {contractData.termsConditions.map((section, index) => {
                            return (
                                <div className='contract__terms__term' key={`term${index}`}>
                                    <h3>{section.order} {section.sectionTitle}</h3>
                                    <div className='contract__terms__term__number'>{parse(section.terms)}</div>
                                </div>
                            )
                        })}
                    </div>
                </section>
            }
        </div>
    )
}

export default Contract