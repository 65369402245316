const limit = 19

const buildList = () => {
    const list = []

    for (let i = 1; i < limit + 1; i++) {
        list.push({
            label: i,
            value: i
        })
    }

    return list
}

const numbersList = buildList()

export {numbersList}