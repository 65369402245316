import {useContext, useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {Link, useNavigate, useParams} from 'react-router-dom'
import TitleBar from '../../components/TitleBar'
import downloadIcon from '../../assets/icons/download.svg'
import successIcon from '../../assets/icons/payment-status-success.svg'
import pendingIcon from '../../assets/icons/payment-status-pending.svg'
import emailIcon from '../../assets/icons/payment-status-email.svg'
import Loading from '../../components/Loading'
import Timeline from '../../components/Timeline'
import TimelinePoint from '../../components/TimelinePoint'
import getDenomination from '../../utilities/getDenomination'
import useGet from '../../hooks/useGet'
import usePost from '../../hooks/usePost'
import {useNumericFormat} from 'react-number-format'
import envVariables from '../../data/envVariables'
import Button from '../../components/Button'
import SEO from '../../components/SEO'
import poweredByContext from '../../store/PoweredByContext'
import {replaceShyName} from '../../utilities/string'

const PaymentSuccess = () => {

    // Use parameters
    const params = useParams()
    const paymentMethod = params.paymentMethod
    const legId = params.legId

    // Use location state
    const location = useLocation()
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [amount, setAmount] = useState(0)
    const [paymentInfoUrl, setPaymentInfoUrl] = useState(null)
    const [cryptoInfoUrl, setCryptoInfoUrl] = useState(null)
    const poweredByCtx = useContext(poweredByContext)

    const {loading: paymentInfoLoading, error: paymentInfoError, returnData: paymentInfo} = useGet(paymentInfoUrl)

    const {loading: cryptoInfoLoading, error: cryptoInfoError, returnData: cryptoInfo} = useGet(cryptoInfoUrl)

    const {sending, returnMessage: postReturnMessage, postData} = usePost()

    // Parse carbon calculation
    const valueWithThousandSeparator = useNumericFormat({
        displayType: 'text',
        value: typeof amount === 'number' ? amount.toFixed(2) : null,
        thousandSeparator: true
    })

    const replaceName = (str) => {
        return replaceShyName(str, poweredByCtx.isPoweredBy, poweredByCtx?.tenant?.tenantName)
    }

    const getParam = (param) => {
        if (location) {
            if (location.search) {
                let match = null
                let search = location.search.replace('?', '')

                search = search.split('&')

                search.forEach((pair) => {
                    let newPair = pair.split('=')

                    if (newPair[0] === param) {
                        match = newPair[1]
                    }
                })

                return match
            }
        }
    }

    useEffect(() => {
        if (location.state) {
            setData({
                id: location.state.id,
                quoteRef: location.state.quoteRef,
                paymentDate: location.state.paymentDate,
                invoiceUrl: location.state.invoiceUrl,
                cardType: location.state.cardType,
                didHold: location.state.didHold,
                currency: getDenomination(location.state.currency)
            })
            setAmount(location.state.value)

        } else {
            if (paymentMethod !== 'crypto') {
                navigate('/')
            } else {
                const subInvoiceNumber = getParam('si') || 0

                setPaymentInfoUrl(`/api/frontend/get-payment-info?legId=${legId}&subInvoiceNumber=${subInvoiceNumber}`)

                if (paymentInfo) {
                    setCryptoInfoUrl(`/api/frontend/get-bpcrypto-value?legId=${legId}&subInvoiceNumber=${subInvoiceNumber}`)
                }
            }
        }
    }, [location.state, navigate, paymentInfo, cryptoInfo])

    useEffect(() => {
        if (paymentMethod === 'crypto') {
            const subInvoiceNumber = getParam('si') || 0

            setData({
                id: paymentInfo.paymentRef,
                quoteRef: paymentInfo.paymentRef,
                currency: getDenomination(paymentInfo.currency),
                invoiceUrl: `${envVariables.documentBase}/api/invoice/download-invoice-jsr?legId=${legId}&subInvoiceNumber=${subInvoiceNumber}`
            })
            setAmount(cryptoInfo)

            if (cryptoInfo) {
                postData({legId: legId}, '/api/frontend/mark-payment-complete', 1000)
            }
        } else if(paymentMethod === 'bank-transfer' && (location.state && location.state.wasHoldPayment)) {
            postData({legId: legId}, '/api/frontend/mark-payment-complete', 1000)
        }
    }, [paymentInfo, cryptoInfo, location.state])

    const getPaymentDate = (date) => {
        return date.split(' ')
    }

    const cryptoLoading = () => {
        if (paymentMethod === 'crypto' && !paymentInfo) {
            return true
        }
    }

    return (
        <>
            <SEO title='Payment Status' />
            <TitleBar title='Payment Status' cta='Quotes' url={`/quotes-and-bookings/quotes`} />
            <div className='page'>
                <div className='wrap'>
                    {(!data || cryptoLoading()) &&
                        <Loading />
                    }
                    {data && !cryptoLoading() &&
                        <>
                            {paymentMethod === 'crypto' &&
                                <article className='big-message'>
                                    <div className='big-message__container'>
                                        <figure className='big-message__image'>
                                            <img src={successIcon} alt='' />
                                        </figure>
                                        <h1 className='big-message__title'>Payment Confirmation</h1>
                                        <div className='big-message__intro'>
                                            <p>{replaceName('Thank you for booking with SHY Aviation')}</p>
                                        </div>
                                        <div className='big-message__text'>
                                            <p>Thank you for your BitPay payment of <strong>{amount}</strong> for the quote <strong>{data.quoteRef}</strong>, we truly appreciate your business. We will take the final steps to confirm your booking. Following this, we will provide you with an initial flight briefing, which will be followed by a final briefing once we have received the names of your designated pilots. Typically, this will occur within 24 to 48 hours prior to your scheduled flight.</p>
                                            <p>If you require any further information please <a href='mailto:sales@shyaviation.com?subject=Payment query'>contact us</a>.</p>
                                        </div>
                                        <dl className='big-message__id'>
                                            <dt>Transaction ID:</dt>
                                            <dd>{data.id}</dd>
                                        </dl>
                                        {data.invoiceUrl &&
                                            <div className='big-message__button-wrapper'>
                                                <a href={data.invoiceUrl} rel='noreferrer' target='_blank' className='button button--secondary'>
                                                    <img src={downloadIcon} alt='' />
                                                    Download invoice
                                                </a>
                                            </div>
                                        }
                                    </div>
                                    <div className='big-message__footer'>
                                        <p>You can see the status of your journey on your Quotes page</p>
                                        <div className='form-button-wrapper wrap'>
                                            <Link to={`/quotes-and-bookings/quotes`}><Button>Quotes</Button></Link>
                                            <Link to='/'><Button classNames={['button--white']}>Home</Button></Link>
                                        </div>
                                    </div>
                                </article>
                            }
                            {paymentMethod === 'bank-transfer' &&
                                <article className='big-message'>
                                    <div className='big-message__container'>
                                        <figure className='big-message__image'>
                                            <img src={pendingIcon} alt='' />
                                        </figure>
                                        <h1 className='big-message__title'>Payment Confirmation Pending</h1>
                                        <div className='big-message__intro'>
                                            <p>{replaceName('Thank you for booking with SHY Aviation')}</p>
                                        </div>
                                        <div className='big-message__text'>
                                            <p>Thank you for submitting your payment, we truly appreciate your business. Once we receive it, we will notify you and take the final steps to confirm your booking. Following this, we will provide you with an initial flight briefing, which will be followed by a final briefing once we have received the names of your designated pilots. Typically, this will occur within 24 to 48 hours prior to your scheduled flight.</p>
                                            <p>If you require any further information please <a href='mailto:sales@shyaviation.com?subject=Payment query'>contact us</a>.</p>
                                        </div>
                                        {data.invoiceUrl &&
                                            <div className='big-message__button-wrapper'>
                                                <a href={data.invoiceUrl} rel='noreferrer' target='_blank' className='button button--secondary'>
                                                    <img src={downloadIcon} alt='' />
                                                    Download invoice
                                                </a>
                                            </div>
                                        }
                                    </div>
                                    <div className='big-message__footer'>
                                        <p>You can see the status of your journey on your Quotes page</p>
                                        <div className='form-button-wrapper wrap'>
                                            <Link to={`/quotes-and-bookings/quotes`}><Button>Quotes</Button></Link>
                                            <Link to='/'><Button classNames={['button--white']}>Home</Button></Link>
                                        </div>
                                    </div>
                                </article>
                            }
                            {paymentMethod === 'card' &&
                                <article className='big-message'>
                                    <div className='big-message__container'>
                                        <figure className='big-message__image'>
                                            <img src={emailIcon} alt='' />
                                        </figure>
                                        <h1 className='big-message__title'>Payment Confirmation Pending</h1>
                                        <div className='big-message__intro'>
                                            <p>{replaceName('Thank you for booking with SHY Aviation')}</p>
                                        </div>
                                        <div className='big-message__text'>
                                            <p>Thank you for initiating your payment, we truly appreciate your business. We will send you a link to pay shortly. Once we receive payment, we will notify you and take the final steps to confirm your booking. Following this, we will provide you with an initial flight briefing, which will be followed by a final briefing once we have received the names of your designated pilots. Typically, this will occur within 24 to 48 hours prior to your scheduled flight.</p>
                                            <p>If you require any further information please <a href='mailto:sales@shyaviation.com?subject=Payment query'>contact us</a>.</p>
                                            <Timeline>
                                                <TimelinePoint offset selected>Card payment selected</TimelinePoint>
                                                <TimelinePoint offset>We will be sending you a link to complete payment</TimelinePoint>
                                            </Timeline>
                                        </div>
                                        {data.invoiceUrl &&
                                            <div className='big-message__button-wrapper'>
                                                <a href={data.invoiceUrl} rel='noreferrer' target='_blank' className='button button--secondary'>
                                                    <img src={downloadIcon} alt='' />
                                                    Download invoice
                                                </a>
                                            </div>
                                        }
                                    </div>
                                    <div className='big-message__footer'>
                                        <p>You can see the status of your journey on your Quotes page</p>
                                        <div className='form-button-wrapper wrap'>
                                            <Link to={`/quotes-and-bookings/quotes`}><Button>Quotes</Button></Link>
                                            <Link to='/'><Button classNames={['button--white']}>Home</Button></Link>
                                        </div>
                                    </div>
                                </article>
                            }
                            {paymentMethod === 'use-credit' &&
                                <article className='big-message'>
                                    <div className='big-message__container'>
                                        <figure className='big-message__image'>
                                            <img src={successIcon} alt='' />
                                        </figure>
                                        <h1 className='big-message__title'>Payment Successful</h1>
                                        <div className='big-message__intro'>
                                            <p>{replaceName('Thank you for booking with SHY Aviation')}</p>
                                        </div>
                                        <div className='big-message__text'>
                                            <p>The payment of <strong>{data.currency}{valueWithThousandSeparator.value}</strong> for the quote <strong>{data.quoteRef}</strong> has been paid on {getPaymentDate(data.paymentDate)[0]} at {getPaymentDate(data.paymentDate)[1]} using your existing credit balance.</p>
                                        </div>
                                        <dl className='big-message__id'>
                                            <dt>Transaction ID:</dt>
                                            <dd>{data.id}</dd>
                                        </dl>
                                        {data.invoiceUrl &&
                                            <div className='big-message__button-wrapper'>
                                                <a rel='noreferrer' href={data.invoiceUrl} target='_blank' className='button button--secondary'>
                                                    <img src={downloadIcon} alt='' />
                                                    Download invoice
                                                </a>
                                            </div>
                                        }
                                    </div>

                                    <div className='big-message__footer'>
                                        <p>You can see the status of your journey on your Quotes page</p>
                                        <div className='form-button-wrapper wrap'>
                                            <Link to={`/quotes-and-bookings/quotes`}><Button>Quotes</Button></Link>
                                            <Link to='/'><Button classNames={['button--white']}>Home</Button></Link>
                                        </div>
                                    </div>
                                </article>
                            }
                            {/*{paymentMethod === 'card' &&*/}
                            {/*    <article className='big-message'>*/}
                            {/*        <div className='big-message__container'>*/}
                            {/*            <figure className='big-message__image'>*/}
                            {/*                <img src={successIcon} alt='' />*/}
                            {/*            </figure>*/}
                            {/*            <h1 className='big-message__title'>Payment Successful</h1>*/}
                            {/*            <div className='big-message__intro'>*/}
                            {/*                <p>Thank you for booking with SHY Aviation</p>*/}
                            {/*            </div>*/}
                            {/*            <div className='big-message__text'>*/}
                            {/*                <p>The payment of <strong>{valueWithThousandSeparator.value}</strong> for the quote <strong>{data.quoteRef}</strong> has been paid on {getPaymentDate(data.paymentDate)[0]} at {getPaymentDate(data.paymentDate)[1]}</p>*/}
                            {/*            </div>*/}
                            {/*            <dl className='big-message__id'>*/}
                            {/*                <dt>Transaction ID:</dt>*/}
                            {/*                <dd>{data.id}</dd>*/}
                            {/*            </dl>*/}
                            {/*            <a href={data.invoiceUrl} className='button button--secondary'>*/}
                            {/*                <img src={downloadIcon} alt='' />*/}
                            {/*                Download invoice*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*<div className='big-message__footer'>*/}
                            {/*    <p>You can see the status of your booking on your Dashboard</p>*/}
                            {/*    <div className='form-button-wrapper wrap'>*/}
                            {/*        <Link to={`/quotes-and-bookings/quotes`}><Button>Quotes</Button></Link>*/}
                            {/*        <Link to='/'><Button classNames={['button--white']}>Home</Button></Link>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*    </article>*/}
                            {/*}*/}
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default PaymentSuccess