import { useState, useEffect } from 'react'

const useIntersection = (element, rootMargin) => {

    const [isVisible, setState] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting)
            }, {rootMargin}
        );

        if (element) {
            element.current && observer.observe(element.current)
        }

        return () => {
            if (element) {
                if (element.current) {
                    observer.unobserve(element.current)
                }
            }
        }
    }, [element, rootMargin])

    return isVisible
}

export default useIntersection