import React, {useEffect, useState} from 'react'

const AuthContext = React.createContext({
    token: null,
    login: null,
    logout: null,
    id: null,
    name: null,
    firstName: null,
    surname: null,
    isActAs: null,
    lastLoginDateTime: null
})

let logoutTimer = false

export const AuthContextProvider = (props) => {

    // Local Storage
    const storedToken = localStorage.getItem('token')
    const storedId = localStorage.getItem('id')
    const storedName = localStorage.getItem('name')
    const storedFirstName = localStorage.getItem('firstName')
    const storedSurname = localStorage.getItem('surname')
    const storedIsActAs = localStorage.getItem('isActAs')
    const storedLastLoginDateTime = localStorage.getItem('lastLoginDateTime')

    // State
    const [token, setToken] = useState(storedToken)
    const [expiry, setExpiry] = useState(false)
    const [id, setId] = useState(storedId)
    const [name, setName] = useState(storedName)
    const [firstName, setFirstName] = useState(storedFirstName)
    const [surname, setSurname] = useState(storedSurname)
    const [isActAs, setIsActAs] = useState((storedIsActAs === 'true'))
    const [lastLoginDateTime, setLastLoginDateTime] = useState(storedLastLoginDateTime)

    // Effect
    useEffect(() => {
        let expirationTime = localStorage.getItem('expirationTime')
        if (expirationTime) {
            const currentTime = new Date().getTime()
            if ((currentTime > expirationTime)) {
                logoutHandler()
            } else {
                autoLogout(expirationTime)
            }
        }
    })

    // Logout
    const logoutHandler = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('expirationTime')
        localStorage.removeItem('id')
        localStorage.removeItem('name')
        localStorage.removeItem('firstName')
        localStorage.removeItem('surname')
        localStorage.removeItem('isActAs')
        localStorage.removeItem('lastLoginDateTime')
        setToken(false)
        setExpiry(false)
        setId(false)
        setName(false)
        setFirstName(false)
        setSurname(false)
        setIsActAs(false)
    }

    // Auto Logout
    const autoLogout = (expirationTime) => {

        if (logoutTimer) {
            clearTimeout(logoutTimer)
        }

        const currentTime = new Date().getTime()
        const duration = expirationTime - currentTime
        
        logoutTimer = setTimeout(() => {
            logoutHandler()
        }, duration)
    }

    // Login
    const loginHandler = (token, expiry, id, name, firstName, surname, lastLoginDateTime, isActAs) => {
        localStorage.setItem('token', token)
        localStorage.setItem('expirationTime', expiry)
        localStorage.setItem('id', id)
        localStorage.setItem('name', name)
        localStorage.setItem('firstName', firstName)
        localStorage.setItem('surname', surname)
        localStorage.setItem('isActAs', isActAs)
        localStorage.setItem('lastLoginDateTime', lastLoginDateTime)
        setToken(token)
        setExpiry(expiry)
        setId(id)
        setName(name)
        setFirstName(firstName)
        setSurname(surname)
        setIsActAs(isActAs)
        setLastLoginDateTime(lastLoginDateTime)
    }

    const Auth = {
        token: token,
        expiry: expiry,
        id: id,
        name: name,
        firstName: firstName,
        surname: surname,
        isActAs: isActAs,
        lastLoginDateTime: lastLoginDateTime,
        login: loginHandler,
        logout: logoutHandler
    }

    return (
        <AuthContext.Provider value={Auth}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext