import React, {useEffect, useState} from 'react'
import useGet from '../hooks/useGet'

const LazyLoadingContext = React.createContext({
    isReady: false
})

export const LazyLoadingContextProvider = (props) => {
    const [pageReady, setPageReady] = useState(false)
    const [header, setHeader] = useState(false)
    const [footer, setFooter] = useState(false)
    const [domainsAndTenants, setDomainsAndTenants] = useState(false)
    const [tenantConfig, setTenantConfig] = useState(false)

    const defaults = {
        home: {
            home: false,
            gethpaircraft: false
        },
        landingPage: {
            landingpages: false
        },
        careers: {
            careersContent: false
        },
        ambassadors: {
            ambassadorsContent: false
        },
        blog: {
            blog: false
        },
        blogArticle: {
            article: false
        },
        privacyPolicy: {
            privacyPolicyContent: false
        },
        termsConditions: {
            termsConditionsContent: false
        },
        movidaTermsConditions: {
            termsConditionsContent: false
        },
        flightBrief: {
            brief: false
        }
    }

    const [pageChecks, setPageChecks] = useState(defaults)

    useEffect(() => {
        let ready = true

        if (header === false) {
            ready = false
        }
        if (footer === false) {
            ready = false
        }
        if (domainsAndTenants === false) {
            ready = false
        }
        if (tenantConfig === false) {
            ready = false
        }

        if (window.location.pathname.indexOf('/flight-brief') === 0) {
            // console.log('check if flight brief page is ready!')
            Object.keys(pageChecks.flightBrief).forEach((key) => {
                if (pageChecks.flightBrief[key] === false) {
                    ready = false
                }
            })
        } else if (window.location.pathname === '/terms-conditions') {
            // console.log('check if terms and conditions page is ready!')
            Object.keys(pageChecks.termsConditions).forEach((key) => {
                if (pageChecks.termsConditions[key] === false) {
                    ready = false
                }
            })
        } else if (window.location.pathname === '/movida-terms-conditions') {
            // console.log('check if movida terms and conditions page is ready!')
            Object.keys(pageChecks.movidaTermsConditions).forEach((key) => {
                if (pageChecks.movidaTermsConditions[key] === false) {
                    ready = false
                }
            })
        } else if (window.location.pathname === '/privacy-policy') {
            // console.log('check if privacy policy page is ready!')
            Object.keys(pageChecks.privacyPolicy).forEach((key) => {
                if (pageChecks.privacyPolicy[key] === false) {
                    ready = false
                }
            })
        } else if (window.location.pathname === '/blog') {
            // console.log('check if blog page is ready!')
            Object.keys(pageChecks.blog).forEach((key) => {
                if (pageChecks.blog[key] === false) {
                    ready = false
                }
            })
        } else if (window.location.pathname.indexOf('/blog/') === 0) {
            // console.log('check if blog article page is ready!')
            Object.keys(pageChecks.blogArticle).forEach((key) => {
                if (pageChecks.blogArticle[key] === false) {
                    ready = false
                }
            })
        } else if (window.location.pathname.indexOf('/ambassador') === 0) {
            // console.log('check if ambassadors page is ready!')
            Object.keys(pageChecks.ambassadors).forEach((key) => {
                if (pageChecks.ambassadors[key] === false) {
                    ready = false
                }
            })
        } else if (window.location.pathname.indexOf('/careers') === 0) {
            // console.log('check if careers page is ready!')
            Object.keys(pageChecks.careers).forEach((key) => {
                if (pageChecks.careers[key] === false) {
                    ready = false
                }
            })
        } else if (window.location.pathname.indexOf('/fly/') === 0) {
            // console.log('check if landing page is ready!')
            Object.keys(pageChecks.landingPage).forEach((key) => {
                if (pageChecks.landingPage[key] === false) {
                    ready = false
                }
            })
        } else if (window.location.pathname === '/') {
            // console.log('check if homepage is ready!')
            Object.keys(pageChecks.home).forEach((key) => {
                if (pageChecks.home[key] === false) {
                    ready = false
                }
            })
        }

        setPageReady(ready)
    }, [header, footer, domainsAndTenants, tenantConfig, pageChecks])

    const reset = (page) => {
        updatePageCheck(page, defaults[page])
    }

    const updateGlobalCheck = (key, value) => {
        if (key === 'header') {
            setHeader(value)
        } else if (key === 'footer') {
            setFooter(value)
        } else if (key === 'domainsAndTenants') {
            setDomainsAndTenants(value)
        } else if (key === 'tenantConfig') {
            setTenantConfig(value)
        }
    }

    const updatePageCheck = (page, obj) => {
        let updatedPageChecks = JSON.parse(JSON.stringify(pageChecks))
        updatedPageChecks[page] = Object.assign(updatedPageChecks[page], obj);

        setPageChecks(updatedPageChecks)
    }

    const status = {
        pageChecks: pageChecks,
        updateGlobalCheck: updateGlobalCheck,
        updatePageCheck: updatePageCheck,
        pageReady: pageReady,
        reset: reset
    }

    return (
        <LazyLoadingContext.Provider value={status}>
            {props.children}
        </LazyLoadingContext.Provider>
    )
}

export default LazyLoadingContext