import {useContext, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation} from 'swiper'
import poweredByContext from '../store/PoweredByContext'
import {getIcon} from '../utilities/icon'
import getClassName from '../utilities/getClassName'
import {isInternalUrl} from '../utilities/url'
import {replaceShyName} from '../utilities/string'
import createHeading from '../utilities/createHeading'

const BlockAircraft = ({ title, subtitle, items, ctaUrl, ctaText, mainHeading, subHeading }) => {

    // State
    const [changeNext, setChangeNext] = useState(false)
    const [changePrev, setChangePrev] = useState(false)

    // Context
    const poweredByCtx = useContext(poweredByContext)

    // Ref
    const indicatorRef = useRef()

    const animationLimit = 7

    /*
    * Replace Shy Aviation with the tenant name, if necessary
    */
    const replaceName = (str) => {
        if (str) {
            return replaceShyName(str, poweredByCtx.isPoweredBy, poweredByCtx?.tenant?.tenantName)
        }
    }

    return (
        <section className='block-aircraft'>
            <div className='wrap'>
                <header className='block-header block-header--light'>
                    {createHeading(subHeading, replaceName(subtitle), true)}
                    {createHeading(mainHeading, replaceName(title), false)}
                </header>
                {items &&
                    <div className='block-aircraft__content'>
                        <button className='block-aircraft__button block-aircraft__button--prev'>
                            <img src={getIcon('chevron-left-gold')} alt='' />
                        </button>
                        <button className='block-aircraft__button block-aircraft__button--next'>
                            <img src={getIcon('chevron-right-gold')} alt='' />
                        </button>
                        <Swiper
                            modules={[Navigation]}
                            className='block-aircraft__swiper'
                            slidesPerView='auto'
                            navigation={{
                                prevEl: '.block-aircraft__button--prev',
                                nextEl: '.block-aircraft__button--next',
                            }}
                            onSlideChange={(swiper) => {
                                if (swiper.realIndex > swiper.previousIndex) {
                                    setChangeNext(true)
                                } else if (swiper.realIndex < swiper.previousIndex) {
                                    setChangePrev(true)
                                }
                                let animationCount = 0
                                indicatorRef.current.addEventListener('animationend', () => {
                                    animationCount++

                                    if (animationCount === animationLimit) {
                                        setChangeNext(false)
                                        setChangePrev(false)
                                    }
                                })
                            }}
                            speed={600}
                        >
                            {items.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <article className='block-aircraft__item'>
                                            {item.props.image?.url &&
                                                <figure className='block-aircraft__item-image'>
                                                    <img src={item.props.image.url} alt='' />
                                                </figure>
                                            }
                                            <div className='block-aircraft__item-content'>
                                                <h3 className='block-aircraft__item-title'>{item.props.title}</h3>
                                                <p className='block-aircraft__item-subtitle'>{item.props.subtitle}</p>
                                                <p className='block-aircraft__item-text'>{item.props.text}</p>
                                            </div>
                                        </article>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                        <div className={getClassName(['block-aircraft__indicator', changeNext ? 'block-aircraft__indicator--next-active' : undefined, changePrev ? 'block-aircraft__indicator--prev-active' : undefined])} ref={indicatorRef}>
                            <div className='block-aircraft__indicator-content'>
                                {Array.from(Array(animationLimit), (e, i) => {
                                    return <span key={i} />
                                })}
                            </div>
                        </div>
                    </div>
                }
                {ctaUrl && ctaText &&
                    <div className='block-aircraft__footer'>
                        {isInternalUrl(ctaUrl)
                            ?
                                <Link to={ctaUrl} className={getClassName(['button', 'button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary'])}>
                                    <span>{replaceName(ctaText)}</span>
                                </Link>
                            :
                                <a href={ctaUrl} className={getClassName(['button', 'button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary'])}>
                                    <span>{replaceName(ctaText)}</span>
                                </a>
                        }
                    </div>
                }
            </div>
        </section>
    )
}

export default BlockAircraft