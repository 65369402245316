const bookingsListData = [
    {
        id: 1,
        bookingRef: 'SHYF-20348168',
        flightFromIata: 'LTN',
        flightFromLong: 'London Luton Airport',
        flightFromCountryCode: 'UK',
        flightToIata: 'CDH',
        flightToLong: 'Paris Charles de Gaulle',
        flightToCountryCode: 'FR',
        departureDate: '2022-11-22',
        departureTime: '13:45',
        departureTimeZone: 'GMT',
        arrivalDate: '2022-11-15',
        arrivalTime: '14:45',
        arrivalTimeZone: 'CET',
        flightTime: '2h 45m',
        distance: '169nm',
        pax: '6',
        passengers: 2,
        luggage: 3,
        pendingDocument: true,
        pendingPayment: true,
        aircraftType: 'Citation XLS',
        carbonEmissions: '250',
        expired: false
    },
    {
        id: 2,
        bookingRef: 'SHYF-20348150',
        requestedDate: '2022-14-08',
        expiryDate: '2022-15-11',
        flightFromIata: 'LTN',
        flightFromLong: 'London Luton Airport',
        flightToIata: 'CDH',
        flightToLong: 'Paris Charles de Gaulle',
        departureDate: '2022-12-10',
        departureTime: '13:45',
        departureTimeZone: 'GMT',
        arrivalDate: '2022-12-10',
        arrivalTime: '14:45',
        arrivalTimeZone: 'CET',
        passengers: 2,
        luggage: 3,
        pendingDocument: true,
        pendingPayment: true,
        expired: true
    },
    {
        id: 3,
        bookingRef: 'SHYF-20384629',
        requestedDate: '2022-07-11',
        expiryDate: '2022-22-12',
        flightFromIata: 'LGW',
        flightFromLong: 'London Gatwick Airport',
        flightToIata: 'PUJ',
        flightToLong: 'Punta Cana International Airport',
        departureDate: '2023-11-01',
        departureTime: '11:30',
        departureTimeZone: 'GMT',
        arrivalDate: '2023-11-02',
        arrivalTime: '18:00',
        arrivalTimeZone: 'CET',
        passengers: 1,
        luggage: 1,
        pendingPayment: true,
        expired: true
    },
    {
        id: 4,
        bookingRef: 'SHYF-20382640',
        requestedDate: '2021-05-03',
        expiryDate: '2022-10-06',
        flightFromIata: 'LGW',
        flightFromLong: 'London Gatwick Airport',
        flightToIata: 'PUJ',
        flightToLong: 'Punta Cana International Airport',
        departureDate: '2023-11-01',
        departureTime: '11:30',
        departureTimeZone: 'GMT',
        arrivalDate: '2023-11-02',
        arrivalTime: '18:00',
        arrivalTimeZone: 'CET',
        passengers: 1,
        luggage: 1,
        pendingPayment: false,
        expired: true
    },
    {
        id: 5,
        bookingRef: 'SHYF-20382640',
        requestedDate: '2021-05-03',
        expiryDate: '2022-10-06',
        flightFromIata: 'LGW',
        flightFromLong: 'London Gatwick Airport',
        flightToIata: 'PUJ',
        flightToLong: 'Punta Cana International Airport',
        departureDate: '2023-11-01',
        departureTime: '11:30',
        departureTimeZone: 'GMT',
        arrivalDate: '2023-11-02',
        arrivalTime: '18:00',
        arrivalTimeZone: 'CET',
        passengers: 1,
        luggage: 1,
        pendingPayment: false,
        expired: true
    },
    {
        id: 6,
        bookingRef: 'SHYF-20382640',
        requestedDate: '2021-05-03',
        expiryDate: '2022-10-06',
        flightFromIata: 'LGW',
        flightFromLong: 'London Gatwick Airport',
        flightToIata: 'PUJ',
        flightToLong: 'Punta Cana International Airport',
        departureDate: '2023-11-01',
        departureTime: '11:30',
        departureTimeZone: 'GMT',
        arrivalDate: '2023-11-02',
        arrivalTime: '18:00',
        arrivalTimeZone: 'CET',
        passengers: 1,
        luggage: 1,
        pendingPayment: false,
        expired: true
    },
    {
        id: 7,
        bookingRef: 'SHYF-20382640',
        requestedDate: '2021-05-03',
        expiryDate: '2022-10-06',
        flightFromIata: 'LGW',
        flightFromLong: 'London Gatwick Airport',
        flightToIata: 'PUJ',
        flightToLong: 'Punta Cana International Airport',
        departureDate: '2023-11-01',
        departureTime: '11:30',
        departureTimeZone: 'GMT',
        arrivalDate: '2023-11-02',
        arrivalTime: '18:00',
        arrivalTimeZone: 'CET',
        passengers: 1,
        luggage: 1,
        expired: true
    }
]

export {bookingsListData}