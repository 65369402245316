import ModalFormWrapper from './ModalFormWrapper'
import Button from './Button'
import {Link} from 'react-router-dom'

const ModalError = ({title, errorText, ctaOptions}) => {
    
    title = title || 'Oops'

    let defaultCtaOptions = {
        intro: 'You can return to the home page here:',
        url: '/',
        text: 'Home Page'
    }

    const cta = {...defaultCtaOptions, ...ctaOptions}

    return (
        <div className='modal-error'>
            <ModalFormWrapper title={title}>
                <div className={'modal-error__inner'}>
                    <p>{errorText}</p>
                    <p>{cta.intro}</p>
                    <Link to={cta.url}><Button>{cta.text}</Button></Link>
                </div>
            </ModalFormWrapper>
        </div>
    )
}

export default ModalError