import {useContext} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import poweredByContext from '../store/PoweredByContext'
import LazyLoadingContext from '../store/LazyLoadingContext'

const Chat = () => {

    // Hooks
    const poweredByCtx = useContext(poweredByContext)
    const lazyLoadingCtx = useContext(LazyLoadingContext)

    const showChat = () => {
    	if (poweredByCtx.isPoweredBy) {
    		return poweredByCtx.tenant?.showChat
    	}

    	return true
    }

    if (showChat() && lazyLoadingCtx.pageReady) {
		return <HelmetProvider>
			<Helmet>
				<link href="https://widget.blockmindai.com/static/css/main.css" rel="stylesheet" />
				<script defer="defer" src="https://widget.blockmindai.com/static/js/main.js" data-license="2b148dca-ebb7-4ea7-9e79-7100997beb1c" />
			</Helmet>
		</HelmetProvider>
	}
}

export default Chat