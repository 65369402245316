import {SlideDown} from 'react-slidedown'
import {useEffect, useState} from 'react'
import tickIcon from '../assets/icons/tick.svg'
import arrowIcon from '../assets/icons/arrow-down.svg'
import useIntersection from '../utilities/useIntersection'

const ContractStatus = ({ buttonRef }) => {

    const [close, setClose] = useState(false)
    const [canSign, setCanSign] = useState(false)
    const [messageShown, setMessageShown] = useState(false)
    const inViewport = useIntersection(buttonRef, '0px')
    const duration = 3000

    // After the component has mounted, hide the first message
    useEffect(() => {
        setTimeout(() => {
            setClose(true)
        }, duration)
    }, [])

    // Once the contract can be signed, hide the second message
    useEffect(() => {
        if (canSign) {
            setTimeout(() => {
                setMessageShown(true)
            }, duration)
        }
    }, [canSign])

    // If the contract button is in the viewport, and the contract was not able to be signed previously, show the second message
    useEffect(() => {
        if (inViewport && !canSign) {
            setCanSign(true)
        }
    })

    const isClosed = () => {
        if (!canSign || messageShown) {
            return close
        }
    }

    const icon = canSign ? tickIcon : arrowIcon

    const text = canSign ? 'You are able to sign your contract now' : 'Scroll to read and sign the contract'

    return (
        <div className='contract-status'>
            <div className='contract-status__outer'>
                <SlideDown className='contract-status__slidedown' closed={isClosed()}>
                    <div className='wrap'>
                        <div className='contract-status__inner'>
                            <div className='contract-status__text'>
                                <img src={icon} alt='' />
                                {text}
                            </div>
                        </div>
                    </div>
                </SlideDown>
            </div>
        </div>
    )
}

export default ContractStatus