import { Outlet } from 'react-router-dom'
import Header from './Header'
import Main from './Main'
import Footer from './Footer'
import {useLocation} from 'react-router'
import Background from './Background'
import Chat from './Chat'
import Loading from './Loading'
import {useContext} from 'react'
import AuthContext from '../store/AuthContext'
import PoweredByContext from '../store/PoweredByContext'

const Layout = ({privateRoutePage}) => {

    // Auth context
    const authCtx = useContext(AuthContext)

    // Powered By context
    const poweredByCtx = useContext(PoweredByContext)

    // Use alternate header on Home page
    const location = useLocation()
    let isHomepage = (location.pathname === '/' || location.pathname.includes('home'))

    // Header style for a private route when user is not logged in
    let altHeader2 = false
    if (privateRoutePage) {
        altHeader2 = true
    }

    return (
        <>
            {!poweredByCtx.isPoweredBy &&
                <Background />
            }
            <div className='layout'>
                {poweredByCtx.siteChecking &&
                    <div className='layout__loading'>
                        <Loading big={true} generic={true} />
                    </div>
                }
                <Header  altHeader2={altHeader2}   />
                <Main>
                    <Outlet />
                </Main>
                <Footer />

                {authCtx.isActAs === true &&
                    <div className='layout__is-act-as-border' />
                }

            </div>
            <Chat />
        </>
    )
}

export default Layout