const separateDateParts = (string) => {
    return string.split(' ')
}

const getDate = (string, switchOrder) => {
    string = string.replace(/\//g,'-')

    if (string !== '?') {
        string = separateDateParts(string)[0]
        string = string.split('-')

        if (switchOrder) {
            return `${string[2]}-${string[0]}-${string[1]}`
        } else {
            return `${string[2]}-${string[1]}-${string[0]}`
        }
    }
}

// Dates with ? values will break the site
const getTime = (string) => {
    if (string !== '?') {
        return separateDateParts(string)[1]
    }
}

const extractHoursAndMinutes = (string) => {
    const parts = string.split(':')

    return `${parts[1]}:${parts[2]}`
}

export {getDate, getTime, extractHoursAndMinutes}