import {useEffect, useState} from 'react'
import getClassName from '../utilities/getClassName'
import Fieldset from './Fieldset'
import pin from '../assets/icons/pin.svg'
import pinRed from '../assets/icons/pin-red.svg'
import person from '../assets/icons/person-dark.svg'

const TextInputAlt = ({value, placeholder, id, icon, required, defaultValue, maxLength, disabled, validateForm}) => {

    // State
    const [currentValue, setCurrentValue] = useState('')
    const [error, setError] = useState(null)

    // Update value
    const updateValueHandler = ({target}) => {
        if (target) {
            setCurrentValue(target.value)
        }
        validationHandler(target.value)
    }

    // Validate input
    const validationHandler = (inputValue) => {
        value(null)
        switch (true) {
            case (required && !inputValue):
                setError(true)
                return
            case (required && inputValue.length === 0):
                setError(true)
                return
            default:
                setError(null)
                value(inputValue)
                return
        }
    }

    // On blur handler
    const onBlurHandler = () => {
        validationHandler(currentValue)
    }

    // Force validation
    useEffect(() => {
        if (validateForm) {
            validationHandler(currentValue)
        }
    })

    // Set default value
    useEffect(() => {
        if (defaultValue) {
            setCurrentValue(defaultValue)
        } else {
            setCurrentValue('')
        }
    }, [defaultValue])


    return (
        <Fieldset marginBottom>
            <div className='text-input-alt'>
                {(icon === 'pin') ?
                    error ? <img alt='' src={pinRed} /> : <img alt='' src={pin} /> : null
                }

                {(icon === 'person') ?
                    error ? <img alt='' src={person} /> : <img alt='' src={person} /> : null
                }

                <input
                    placeholder={(placeholder && !currentValue) ? placeholder : null}
                    value={currentValue}
                    type='text'
                    id={id}
                    name={id}
                    autoComplete='on'
                    maxLength={maxLength}
                    onBlur={onBlurHandler}
                    onChange={updateValueHandler}
                    className={getClassName(['text-input-alt__input', error && 'text-input-alt__input--error'])}
                    disabled={disabled}
                />
            </div>
        </Fieldset>
    )
}

export default TextInputAlt