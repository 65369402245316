import { getDate, getTime } from '../utilities/date'
import format from "date-fns/format"
import parse from "date-fns/parse"
import { parseISO } from "date-fns"
import { extractHoursAndMinutes } from '../utilities/date'

// Icons
import fromIcon from '../assets/icons/departure-time.svg'
import toIcon from '../assets/icons/arrival-time.svg'
import fuelStopIcon from '../assets/icons/fuel-stop-dark.svg'

const FromToTable = ({departureDateTime, arrivalDateTime, legData, duration, fuelStops}) => {

    return (
        <table className='from-to-table'>

            <tbody>
            <tr>
                <th>
                    <span>Date</span>
                </th>
                <th className='from-to-table__to-from-width'>
                    <span>
                        <img src={fromIcon} alt=''/>From
                    </span>
                </th>
                <th>
                    <span>Time</span>
                </th>
                <th className='from-to-table__to-from-width'>
                    <span>
                        <img src={toIcon} alt=''/>To
                    </span>
                </th>
                <th className='from-to-table__time-width'>
                    <span>Time</span>
                </th>
                <th>
                    <span>Duration</span>
                </th>
                <th>
                    <span>Pax</span>
                </th>
            </tr>

            {legData.map((leg, index) => {

                // Set and format departure date
                let departureDate = getDate(departureDateTime[index])
                const parseDeparture = parse(departureDate, "yyyy-MM-dd", new Date())
                const formattedDepartureDate = format(parseDeparture, "dd/MM/yyyy", { awareOfUnicodeTokens: true })

                // Departure time
                const departureTime = getTime(departureDateTime[index])

                // Arrival time
                const arrivalTime = getTime(arrivalDateTime[index])

                return (
                    <tr key={index}>
                        <td>{formattedDepartureDate}</td>
                        <td><span>{leg.departureAirport.longName}</span></td>
                        <td>{departureTime}</td>
                        <td><span>{leg.arrivalAirport.longName}</span></td>
                        <td>{arrivalTime}</td>
                        <td>
                            {fuelStops[index] > 0 && <img className="from-to-table__fuel-stop-icon" src={fuelStopIcon} alt=""/>}

                            {extractHoursAndMinutes(duration[index])} <br/>

                            {(fuelStops[index] === 1) &&
                                <span className="from-to-table__fuel-stop-text">
                                    Fuel stop
                                </span>
                            }

                            {(fuelStops[index] > 1) &&
                                <span className="from-to-table__fuel-stop-text">
                                    {fuelStops[index]} Fuel stops
                                </span>
                            }
                        </td>
                        <td>{leg.passengers}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}
export default FromToTable