import { useContext } from 'react';
import profileButtonIcon from '../assets/icons/profile-light.svg';
import profileButtonPoweredBy from '../assets/icons/profile-powered-by.svg';
import poweredByContext from '../store/PoweredByContext';

const getIcon = (isDarkHeader) => {
    let icon = profileButtonIcon; // Default to light icon

    if (isDarkHeader === 'True' || isDarkHeader === true) {
        icon = profileButtonPoweredBy;
    }

    return icon;
};

const ProfileButton = ({ onClick, name, altHeader2 }) => {
    const poweredByCtx = useContext(poweredByContext);

    const icon = poweredByCtx.tenant ? getIcon(poweredByCtx.tenant.isDarkHeader) : profileButtonIcon;

    return (
        <>
            {!altHeader2 &&
                <button onClick={onClick} className='profile-button'>
                    {name ? name : 'Login'}
                    <img className='profile-button--icon' src={poweredByCtx.isPoweredBy ? icon : profileButtonIcon} alt='' />
                </button>
            }
        </>
    );
};

export default ProfileButton;
