import TitleBar from '../components/TitleBar'
import Button from '../components/Button'
import {useLocation} from 'react-router'
import envVariables from '../data/envVariables'
import React, {useContext, useEffect, useState} from 'react'
import MoreInfoTray from '../components/MoreInfoTray'
import LinkWithIcon from '../components/LinkWithIcon'
import PhotoCarousel from '../components/PhotoCarousel'
import Modal from '../components/Modal'
import Share from '../components/Share'
import chevronDown from '../assets/icons/chevron-down-big.svg'
import chevronUp from '../assets/icons/chevron-up-big.svg'
import tickIcon from '../assets/icons/tick-thick.svg'
import useGet from '../hooks/useGet'
import {mapboxConfig} from '../utilities/settings'
import GeoJSONPolyline from 'geojson-polyline'
import SEO from '../components/SEO'
import ErrorModal from '../components/ErrorModal'
import authContext from '../store/AuthContext'
import LazyLoadingContext from '../store/LazyLoadingContext'

const FlightBrief = () => {

    // Get params
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const view = queryParams.get('view')
    const legId = queryParams.get('lo')
    const isReportView = !!(view && view === 'report')

    // Endpoints
    const briefUrl = `/api/frontend/final-briefing?legId=${legId}`
    const downloadUrl = `${envVariables.documentBase}/api/invoice/final-brief?legId=${legId}&view=report`

    // State
    const [allTrayStatus, setAllTrayStatus] = useState(isReportView ? 'open' : 'closed')
    const [showSharePanel, setShowSharePanel] = useState(false)

    // Hooks
    const {error, loading: briefLoading, returnData: briefData} = useGet(briefUrl)
    const authCtx = useContext(authContext)
    const lazyLoadingCtx = useContext(LazyLoadingContext)

    useEffect(() => {
        
        return () => {
            lazyLoadingCtx.reset('flightBrief')
        }
    }, [])

    useEffect(() => {
        if (briefData !== false && briefData !== '') {
            lazyLoadingCtx.updatePageCheck('flightBrief', {
                brief: true
            })
        }
    }, [briefData])

    // Add PDF view when looking as report
    useEffect(() => {
        if (isReportView) {
            document.body.classList.add('pdf-view')
        }

        // Clean up
        return(() => {
            document.body.classList.remove('pdf-view')
        })
    })

    const getCrewCount = () => {
        let count = 0

        if (briefData.pilotName) {
            count++
        }

        if (briefData.firstOfficerName) {
            count++
        }

        if (briefData.flightAttendantName) {
            count++
        }

        return count
    }

    const getMapImage = () => {
        const {
            lineColour,
            markerColour,
            width,
            height,
            padding,
            departureLabel,
            arrivalLabel,
            accessToken,
            showLogo
        } = mapboxConfig;
    
        const departureLatLng = getDepartureLatLng();
        const arrivalLatLng = getArrivalLatLng();
    
        const lineString = {
            type: 'LineString',
            coordinates: [
                getLatLng(departureLatLng, true),
                getLatLng(arrivalLatLng, true)
            ]
        };
    
        const line = GeoJSONPolyline.encode(lineString);
    
        const encodedPolyline = encodeURIComponent(line.coordinates);
    
        const url = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/path-1+${lineColour}(${encodedPolyline}),pin-s-${departureLabel}+${markerColour}(${getLatLng(departureLatLng)}),pin-s-${arrivalLabel}+${markerColour}(${getLatLng(arrivalLatLng)})/auto/${width}x${height}@2x?padding=${padding}&access_token=${accessToken}&logo=${showLogo}`;

        return url;
    };
    

    const getLatLng = (value, toArray) => {
        let latLng = value.split(',')

        if (toArray) {
            return [latLng[1], latLng[0]]
        }

        return `${latLng[1]},${latLng[0]}`
    }

    const getLocationLatLng = (airport, terminal) => {
        if (terminal.longitude_and_Latitude !== 'TBC') {
            return terminal.longitude_and_Latitude
        } else {
            return `${airport.latitude},${airport.longitude}`
        }
    }

    const getDepartureLatLng = () => {
        return getLocationLatLng(briefData.departureAirport, briefData.departureTerminal)
    }

    const getArrivalLatLng = () => {
        return getLocationLatLng(briefData.arrivalAirport, briefData.arrivalTerminal)
    }

    const PassengerPlaceholders = () => {
        let placeholders = []

        for (let i = 0; i < briefData.numberPax; i++) {
            placeholders.push(`Passenger ${i + 1}`)
        }

        return placeholders.map((placeholder, i) => {
            return <div className='flight-brief__dark-title' key={i}>{placeholder}</div>
        })
    }

    const getAirport = (mode, i) => {
        if (i === 1) {
            if (mode === 'departureAirport') {
                mode = 'arrivalAirport'
            } else if (mode === 'arrivalAirport') {
                mode = 'departureAirport'
            }
        }

        return `${briefData[mode].icao} ${briefData[mode].city}, ${briefData[mode].country}`
    }

    return (
        <>
            {!isReportView &&
                <>
                    <SEO title='Your Brief' />
                    {authCtx.token &&
                        <TitleBar title='Your Brief' cta='Dashboard' url={`/quote-dashboard/booking/${legId}`} />
                    }
                </>
            }
            
            <div className='page'>
                {error
                ?
                    <ErrorModal
                        title='Awaiting VIP terminal information'
                        errorMessage='We are waiting for the Operator to confirm the VIP terminals and will alert you when we have them'
                        ctaOptions={{
                            intro: 'You can return to the dashboard here:',
                            url: `/quote-dashboard/booking/${legId}`,
                            text: 'Dashboard'
                        }}
                    />
                :
                    <>
                        {(!lazyLoadingCtx.pageReady || briefLoading) ?
                            <>
                                <section className='wrap flight-brief flight-brief--skeleton'>
                                    <div className='flight-brief__buttons'>
                                        {!isReportView &&
                                            <Button classNames={['button--secondary']} icon='download' onClick={() => {
                                                window.open(downloadUrl, '_blank', 'noreferrer')
                                            }}>Download</Button>
                                        }
                                    </div>

                                    <div className='flight-brief__top-details'>
                                        <div className='trip-detail'>
                                            <div className='trip-detail__title'>
                                                Trip number:
                                            </div>
                                            <div className='trip-detail__info'>
                                                XXXX-XXXXX
                                            </div>
                                        </div>

                                        <div className='trip-detail'>
                                            <div className='trip-detail__title'>
                                                Date created:
                                            </div>
                                            <div className='trip-detail__info'>
                                                XX/XX/XXXX <span className='trip-detail__info__light'>at</span> XX:XX
                                            </div>
                                        </div>

                                        {!isReportView ?
                                            <>
                                                {(allTrayStatus === 'closed') ?
                                                    <button onClick={() => setAllTrayStatus('open')} className='flight-brief__expand-all'>Show all <img src={chevronDown} alt='' /></button>
                                                    :
                                                    <button onClick={() => setAllTrayStatus('closed')} className='flight-brief__expand-all'>Hide all <img src={chevronUp} alt='' /></button>
                                                }
                                            </> : <div></div>
                                        }
                                    </div>

                                    <MoreInfoTray />
                                    <MoreInfoTray />
                                    <MoreInfoTray />
                                    <MoreInfoTray />
                                    <MoreInfoTray />
                                    <MoreInfoTray />
                                </section>
                            </>
                            :
                            (briefData) &&

                            <section className='wrap flight-brief'>

                                <div className='flight-brief__buttons'>
                                    {/*<Button classNames={['button--secondary']} onClick={() => setShowSharePanel(true)} icon='share-dark'>Share</Button>*/}
                                    {!isReportView &&
                                        <Button classNames={['button--secondary']} icon='download' onClick={() => {
                                            window.open(downloadUrl, '_blank', 'noreferrer')
                                        }}>Download</Button>
                                    }
                                </div>

                                <div className='flight-brief__top-details'>

                                    {briefData.tripNumber &&
                                        <div className='trip-detail'>
                                            <div className='trip-detail__title'>
                                                Trip number:
                                            </div>
                                            <div className='trip-detail__info'>
                                                {briefData.tripNumber}
                                            </div>
                                        </div>
                                    }

                                    {briefData.dateCreated && briefData.timeCreated &&
                                        <div className='trip-detail'>
                                            <div className='trip-detail__title'>
                                                Date created:
                                            </div>
                                            <div className='trip-detail__info'>
                                                {briefData.dateCreated} <span className='trip-detail__info__light'>at</span> {briefData.timeCreated}
                                            </div>
                                        </div>
                                    }

                                    {!isReportView ?
                                        <>
                                            {(allTrayStatus === 'closed') ?
                                                <button onClick={() => setAllTrayStatus('open')} className='flight-brief__expand-all'>Show all <img src={chevronDown} alt='' /></button>
                                                :
                                                <button onClick={() => setAllTrayStatus('closed')} className='flight-brief__expand-all'>Hide all <img src={chevronUp} alt='' /></button>
                                            }
                                        </> : <div></div>
                                    }
                                </div>

                                <MoreInfoTray buttonText='Aircraft' open={allTrayStatus !== 'closed'} isReportView={isReportView}>
                                    {briefData.aircraftTailNumber && briefData.aircraft.aircraftName &&
                                        <>
                                            <div className='flight-brief__plane-type'>Type</div>
                                            {briefData.aircraftTailNumber} ({briefData.aircraft.aircraftName})
                                        </>
                                    }

                                    <div className='flight-brief__aircraft-wrapper'>
                                        {briefData.aircraft.photoData &&
                                            <div className='flight-brief__photos-wrapper'>
                                                <PhotoCarousel photoData={briefData.aircraft.photoData} enabled={!isReportView} />
                                            </div>
                                        }

                                        {briefData.aircraft.floorplan &&
                                            <img src={briefData.aircraft.floorplan[0].url} alt={briefData.aircraft.floorplan[0].alt} className='flight-brief__blueprint' />
                                        }
                                    </div>
                                </MoreInfoTray>

                                <MoreInfoTray buttonText='Trip Itinerary' open={allTrayStatus !== 'closed'} isReportView={isReportView}>
                                    <div className='flight-brief__itinerary-wrapper'>
                                        <figure className='flight-brief__map'>
                                            <img src={getMapImage()} alt='' />
                                        </figure>

                                        <div className='flight-brief__itinerary-content'>
                                            {briefData.legs.map((leg, i) => {
                                                return <>
                                                    {briefData.legs.length > 1 &&
                                                        <p className='flight-brief__group-title'>{i === 0 ? 'Return Trip - Outbound Flight' : 'Return Trip - Inbound Flight'}</p>
                                                    }
                                                    <div className='flight-brief__content-grid' key={i}>
                                                        {briefData.departureAirport &&
                                                            <div>
                                                                <div className='flight-brief__gold-title'>
                                                                    {i === 0
                                                                    ?
                                                                    <>Departure (D)</>
                                                                    :
                                                                    <>Departure</>
                                                                    }
                                                                </div>
                                                                <div className='flight-brief__dark-title'>{getAirport('departureAirport', i)}</div>
                                                                <div className='flight-brief__body-text'>
                                                                    {leg.departureDate}<br />
                                                                    {leg.departureTime}
                                                                </div>
                                                            </div>
                                                        }

                                                        {briefData.arrivalAirport &&
                                                            <div>
                                                                <div className='flight-brief__gold-title'>
                                                                    {i === 0
                                                                    ?
                                                                    <>Arrival (A)</>
                                                                    :
                                                                    <>Arrival</>
                                                                    }
                                                                </div>
                                                                <div className='flight-brief__dark-title'>{getAirport('arrivalAirport', i)}</div>
                                                                <div className='flight-brief__body-text'>
                                                                    {leg.arrivalDate}<br/>
                                                                    {leg.arrivalTime}
                                                                </div>
                                                            </div>
                                                        }

                                                        <div>
                                                            <div className='flight-brief__gold-title'>Details</div>
                                                            <div className='flight-brief__body-text'>
                                                                {briefData.travelTime &&
                                                                    <>Flight time: <b>{leg.flightTime}</b><br /></>
                                                                }
                                                                {briefData.distance &&
                                                                    <>Distance: <b>{briefData.distance}nm</b><br /></>
                                                                }
                                                                {briefData.numberPax &&
                                                                    <>PAX: <b>{briefData.numberPax}</b></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            })}
                                        </div>
                                    </div>
                                </MoreInfoTray>

                                <div className='page-break' />

                                <MoreInfoTray buttonText={`Crew (${getCrewCount()})`} open={allTrayStatus !== 'closed'} isReportView={isReportView}>
                                    <div className='flight-brief__content-grid flight-brief__content-grid--people'>
                                        <div>
                                            <div className='flight-brief__gold-title'>Pilot in command</div>
                                            <div className='flight-brief__dark-title'>{briefData.pilotName ? briefData.pilotName : 'TBC'}</div>
                                            {briefData.pilotName && briefData.pilotPhone &&
                                                <div className='flight-brief__body-text'>
                                                    <LinkWithIcon link='tel:{briefData.pilotPhone}' icon='phone'>{briefData.pilotPhone}</LinkWithIcon>
                                                </div>
                                            }
                                        </div>

                                        <div>
                                            <div className='flight-brief__gold-title'>Second in command</div>
                                            <div className='flight-brief__dark-title'>{briefData.firstOfficerName ? briefData.firstOfficerName : 'TBC'}</div>
                                            {briefData.firstOfficerName && briefData.firstOfficerPhone &&
                                                <div className='flight-brief__body-text'>
                                                    <LinkWithIcon link='tel:{briefData.firstOfficerPhone}' icon='phone'>{briefData.firstOfficerPhone}</LinkWithIcon>
                                                </div>
                                            }
                                        </div>

                                        <div>
                                            <div className='flight-brief__gold-title'>Flight attendant</div>
                                            <div className='flight-brief__dark-title'>{briefData.flightAttendantName ? briefData.flightAttendantName : 'TBC'}</div>
                                            {briefData.flightAttendantName && briefData.flightAttendantPhone &&
                                                <div className='flight-brief__body-text'>
                                                    <LinkWithIcon link='tel:{briefData.flightAttendantPhone}' icon='phone'>{briefData.flightAttendantPhone}</LinkWithIcon>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </MoreInfoTray>

                                <MoreInfoTray buttonText={'Passengers ('+briefData.numberPax+')'} open={allTrayStatus !== 'closed'} isReportView={isReportView}>
                                    <div className='flight-brief__content-grid flight-brief__content-grid--people'>
                                        {briefData.passengerDetails.length > 0 ?
                                            <>
                                                {briefData.passengerDetails.map((item, itemId) => {
                                                    return <div key={itemId}>
                                                        <div className='flight-brief__dark-title'>{item.name}</div>
                                                        <div className='flight-brief__body-text'>
                                                            <p><img src={tickIcon} alt='' /> Details provided</p>
                                                        </div>
                                                    </div>
                                                })}
                                            </>
                                            :
                                            <PassengerPlaceholders />
                                        }
                                    </div>
                                </MoreInfoTray>

                                <MoreInfoTray buttonText='Airport Information' open={allTrayStatus !== 'closed'} isReportView={isReportView}>
                                    <div className='flight-brief__content-grid'>
                                        {briefData.departureAirport && briefData.departureTerminal && briefData.legs &&
                                            <div>
                                                <div className='flight-brief__item'>
                                                    <div className='flight-brief__gold-title'>{briefData.departureAirport.name} - {briefData.legs[0]?.departureDate}</div>
                                                    <div className='flight-brief__dark-title'>{briefData.departureTerminal.name}</div>
                                                    {briefData.departureTerminal.tel_Number === 'TBC'
                                                        ?
                                                            <LinkWithIcon icon='phone'>TBC</LinkWithIcon>
                                                        :
                                                            <LinkWithIcon link={`tel:${briefData.departureTerminal.tel_Number}`} icon='phone'>{briefData.departureTerminal.tel_Number}</LinkWithIcon>
                                                    }
                                                </div>

                                                <div className='flight-brief__item'>
                                                    <div className='flight-brief__body-text--light'>Address: </div>
                                                    {briefData.departureTerminal.address_Line_1 === 'TBC'
                                                            ?
                                                                <span>TBC</span>
                                                            :
                                                                <div className='flight-brief__body-text--dark'>
                                                                    {briefData.departureTerminal.address_Line_1} {briefData.departureTerminal.address_Line_2}, {briefData.departureTerminal.postcode}
                                                                </div>
                                                    }
                                                </div>

                                                <div className='flight-brief__item'>
                                                    <div className='flight-brief__body-text--light'>
                                                        What3Words: <span className='flight-brief__body-text--w3w'>{'///'} </span> 
                                                        {briefData.departureTerminal.what_three_words === 'TBC'
                                                            ?
                                                                <span>TBC</span>
                                                            :
                                                                <a className='flight-brief__body-text--link' href={'https://w3w.co/'+briefData.departureTerminal.what_three_words} target='_blank'>{briefData.departureTerminal.what_three_words}</a>
                                                        }
                                                    </div>
                                                </div>

                                                <div className='flight-brief__item'>
                                                    Directions to airport:&nbsp;
                                                    {briefData.departureTerminal.longitude_and_Latitude === 'TBC'
                                                        ?
                                                            <span>TBC</span>
                                                        :
                                                            <a className='flight-brief__body-text--link' href={'https://maps.google.com?q='+briefData.departureTerminal.longitude_and_Latitude} target='_blank'>view on Google Maps</a>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        {briefData.arrivalAirport && briefData.arrivalTerminal && briefData.legs &&
                                            <div>
                                                <div className='flight-brief__item'>
                                                    <div className='flight-brief__gold-title'>{briefData.arrivalAirport.name} - {briefData.legs[0]?.arrivalDate}</div>
                                                    <div className='flight-brief__dark-title'>{briefData.arrivalTerminal.name}</div>
                                                    {briefData.arrivalTerminal.tel_Number === 'TBC'
                                                        ?
                                                            <LinkWithIcon icon='phone'>TBC</LinkWithIcon>
                                                        :
                                                            <LinkWithIcon link={`tel:${briefData.arrivalTerminal.tel_Number}`} icon='phone'>{briefData.arrivalTerminal.tel_Number}</LinkWithIcon>
                                                    }
                                                </div>

                                                <div className='flight-brief__item'>
                                                    <div className='flight-brief__body-text--light'>Address: </div>
                                                    {briefData.arrivalTerminal.address_Line_1 === 'TBC'
                                                            ?
                                                                <span>TBC</span>
                                                            :
                                                                <div className='flight-brief__body-text--dark'>
                                                                    {briefData.arrivalTerminal.address_Line_1} {briefData.arrivalTerminal.address_Line_2}, {briefData.arrivalTerminal.postcode}
                                                                </div>
                                                    }
                                                </div>

                                                <div className='flight-brief__item'>
                                                    <div className='flight-brief__body-text--light'>
                                                        What3Words: <span className='flight-brief__body-text--w3w'>{'///'} </span> 
                                                        {briefData.arrivalTerminal.what_three_words === 'TBC'
                                                            ?
                                                                <span>TBC</span>
                                                            :
                                                                <a className='flight-brief__body-text--link' href={'https://w3w.co/'+briefData.arrivalTerminal.what_three_words} target='_blank'>{briefData.arrivalTerminal.what_three_words}</a>
                                                        }
                                                    </div>
                                                </div>

                                                <div className='flight-brief__item'>
                                                    Directions to airport:&nbsp;
                                                    {briefData.arrivalTerminal.longitude_and_Latitude === 'TBC'
                                                        ?
                                                            <span>TBC</span>
                                                        :
                                                            <a className='flight-brief__body-text--link' href={'https://maps.google.com?q='+briefData.arrivalTerminal.longitude_and_Latitude} target='_blank'>view on Google Maps</a>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='flight-brief__content-grid'>
                                        {briefData.arrivalAirport && briefData.departureTerminalLeg2 && briefData.legs &&
                                            <div>
                                                <div className='flight-brief__item'>
                                                    <div className='flight-brief__gold-title'>{briefData.arrivalAirport.name} - {briefData.legs[1]?.departureDate}</div>
                                                    <div className='flight-brief__dark-title'>{briefData.departureTerminalLeg2.name}</div>
                                                    {briefData.departureTerminalLeg2.tel_Number === 'TBC'
                                                        ?
                                                            <LinkWithIcon icon='phone'>TBC</LinkWithIcon>
                                                        :
                                                            <LinkWithIcon link={`tel:${briefData.departureTerminalLeg2.tel_Number}`} icon='phone'>{briefData.departureTerminalLeg2.tel_Number}</LinkWithIcon>
                                                    }
                                                </div>

                                                <div className='flight-brief__item'>
                                                    <div className='flight-brief__body-text--light'>Address: </div>
                                                    {briefData.departureTerminalLeg2.address_Line_1 === 'TBC'
                                                            ?
                                                                <span>TBC</span>
                                                            :
                                                                <div className='flight-brief__body-text--dark'>
                                                                    {briefData.departureTerminalLeg2.address_Line_1} {briefData.departureTerminalLeg2.address_Line_2}, {briefData.departureTerminalLeg2.postcode}
                                                                </div>
                                                    }
                                                </div>

                                                <div className='flight-brief__item'>
                                                    <div className='flight-brief__body-text--light'>
                                                        What3Words: <span className='flight-brief__body-text--w3w'>{'///'} </span> 
                                                        {briefData.departureTerminalLeg2.what_three_words === 'TBC'
                                                            ?
                                                                <span>TBC</span>
                                                            :
                                                                <a className='flight-brief__body-text--link' href={'https://w3w.co/'+briefData.departureTerminalLeg2.what_three_words} target='_blank'>{briefData.departureTerminalLeg2.what_three_words}</a>
                                                        }
                                                    </div>
                                                </div>

                                                <div className='flight-brief__item'>
                                                    Directions to airport:&nbsp;
                                                    {briefData.departureTerminalLeg2.longitude_and_Latitude === 'TBC'
                                                        ?
                                                            <span>TBC</span>
                                                        :
                                                            <a className='flight-brief__body-text--link' href={'https://maps.google.com?q='+briefData.departureTerminalLeg2.longitude_and_Latitude} target='_blank'>view on Google Maps</a>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        {briefData.departureAirport && briefData.arrivalTerminalLeg2 && briefData.legs &&
                                            <div>
                                                <div className='flight-brief__item'>
                                                    <div className='flight-brief__gold-title'>{briefData.departureAirport.name} - {briefData.legs[1]?.arrivalDate}</div>
                                                    <div className='flight-brief__dark-title'>{briefData.arrivalTerminalLeg2.name}</div>
                                                    {briefData.arrivalTerminalLeg2.tel_Number === 'TBC'
                                                        ?
                                                            <LinkWithIcon icon='phone'>TBC</LinkWithIcon>
                                                        :
                                                            <LinkWithIcon link={`tel:${briefData.arrivalTerminalLeg2.tel_Number}`} icon='phone'>{briefData.arrivalTerminalLeg2.tel_Number}</LinkWithIcon>
                                                    }
                                                </div>

                                                <div className='flight-brief__item'>
                                                    <div className='flight-brief__body-text--light'>Address: </div>
                                                    {briefData.arrivalTerminalLeg2.address_Line_1 === 'TBC'
                                                            ?
                                                                <span>TBC</span>
                                                            :
                                                                <div className='flight-brief__body-text--dark'>
                                                                    {briefData.arrivalTerminalLeg2.address_Line_1} {briefData.arrivalTerminalLeg2.address_Line_2}, {briefData.arrivalTerminalLeg2.postcode}
                                                                </div>
                                                    }
                                                </div>

                                                <div className='flight-brief__item'>
                                                    <div className='flight-brief__body-text--light'>
                                                        What3Words: <span className='flight-brief__body-text--w3w'>{'///'} </span> 
                                                        {briefData.arrivalTerminalLeg2.what_three_words === 'TBC'
                                                            ?
                                                                <span>TBC</span>
                                                            :
                                                                <a className='flight-brief__body-text--link' href={'https://w3w.co/'+briefData.arrivalTerminalLeg2.what_three_words} target='_blank'>{briefData.arrivalTerminalLeg2.what_three_words}</a>
                                                        }
                                                    </div>
                                                </div>

                                                <div className='flight-brief__item'>
                                                    Directions to airport:&nbsp;
                                                    {briefData.arrivalTerminalLeg2.longitude_and_Latitude === 'TBC'
                                                        ?
                                                            <span>TBC</span>
                                                        :
                                                            <a className='flight-brief__body-text--link' href={'https://maps.google.com?q='+briefData.arrivalTerminalLeg2.longitude_and_Latitude} target='_blank'>view on Google Maps</a>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </MoreInfoTray>

                                <MoreInfoTray buttonText='Contact Details' open={allTrayStatus !== 'closed'} isReportView={isReportView}>
                                    <div className='flight-brief__content-grid'>
                                        <div>
                                            <div className='flight-brief__item'>
                                                <div className='flight-brief__dark-title'>SHY Flight Management team</div>
                                            </div>
                                            <LinkWithIcon link='tel:44+2086168821' icon='phone'>+44 20 8616 8821</LinkWithIcon><br />
                                            <LinkWithIcon link='mailto:ops@shyaviation.com' icon='email'>ops@shyaviation.com</LinkWithIcon><br />
                                            <LinkWithIcon target='_blank' link='https://shyaviation.com' icon='website'>shyaviation.com</LinkWithIcon>
                                        </div>
                                    </div>
                                </MoreInfoTray>

                                {briefData.additionalItems.length > 0 &&
                                    <MoreInfoTray buttonText='Additional Information' open={allTrayStatus !== 'closed'} isReportView={isReportView}>
                                        {briefData.additionalItems.map((item, itemId) => {
                                            return <p key={itemId}>{item}</p>
                                        })}
                                    </MoreInfoTray>
                                }

                            </section>
                        }
                        <Modal
                            classNames={['modal__content--thin']}
                            modalVisible={showSharePanel}
                            closeModal={() => {setShowSharePanel(false)}}
                        >
                            {/* TODO add endpoint & ref below */}
                            <Share
                                endpoint={'/api/share-flight-brief'}
                                shareRef={'some sort of ref'}
                                close={() => {setShowSharePanel(false)}}
                            />
                        </Modal>
                    </>
                }
            </div>
        </>
    )
}

export default FlightBrief