import PaymentAmount from './PaymentAmount'
import Button from './Button'
import SecureReassure from './SecureReassure'
import getDenomination from '../utilities/getDenomination'
import {NumericFormat} from 'react-number-format'
import {Link, useNavigate} from 'react-router-dom'
import LoadingOverlay from './LoadingOverlay'
import usePost from '../hooks/usePost'
import {useEffect, useState} from 'react'
import format from "date-fns/format"

const UseCreditToPay = ({legId, paymentRef, totalOwed, depositData, balance, currency, paymentType, returnMessageHandler, paymentCompleteHandler, subInvoiceNumber, tailNum}) => {

    // State
    const [paymentCompleteData, setPaymentCompleteData] = useState()

    // Hooks
    const {sending, returnMessage, postData} = usePost()
    const navigate = useNavigate()

    // Set denomination
    const denomination = getDenomination(currency)

    useEffect(() => {
        if (totalOwed > balance) {
            navigate(`/${paymentType}/card/${legId}`)
        }
    }, [])

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()

        // Payment data
        let paymentData = {
            paymentMethod: 'credit',
            creditAmount: totalOwed,
            totalAmount: totalOwed,
            currencyCode: currency,
            useCredit: true,
            legId: legId,
            subInvoiceNumber: subInvoiceNumber,
            invoiceReference: paymentRef
        }

        // Post
        postData(paymentData, '/api/frontend/payment-account/', 1000)
    }

    // Send error and success data to parent component
    useEffect(() => {
        if (returnMessage.success) {
            const date = new Date()

            const successData = {
                id: paymentRef,
                currency: currency,
                quoteRef: paymentRef,
                value: totalOwed,
                paymentDate: `${format(date, "dd-MM-yyyy", { awareOfUnicodeTokens: true })} ${format(date, "H:mm", { awareOfUnicodeTokens: true })}`
            }
            setPaymentCompleteData(successData)
        }
        returnMessageHandler(returnMessage)
        paymentCompleteHandler(paymentCompleteData)
    }, [paymentCompleteData, paymentCompleteHandler, returnMessage, returnMessageHandler])

    return (
        <div className='wrap use-credit-to-pay'>

            <SecureReassure>Secure Transaction</SecureReassure>

            <PaymentAmount
                instruction={(paymentType === 'deposit') ? 'Deposit Amount' : 'Payment Amount'}
                totalOwed={(paymentType === 'deposit') ? depositData.depositToPay : totalOwed}
                additionalInstruction={'Paying in full with your balance'}
                depositData={depositData}
                currency={currency}
                paymentType={paymentType}
            />

            <div className='use-credit-to-pay__credit-amount'>You have a credit of <b>
                {denomination}
                <NumericFormat
                    displayType='text'
                    value={balance}
                    thousandSeparator
                />
            </b>
            </div>

            <div className='form-button-wrapper'>
                <Button
                    classNames={['button--small-text']}
                    onClick={submitHandler}
                >Pay {denomination}<NumericFormat
                    displayType='text'
                    value={(paymentType === 'deposit') ? depositData.depositToPay : totalOwed}
                    thousandSeparator
                />using balance
                </Button>
                <Link to={`/payment/bank-transfer/${legId}/${tailNum}`}>
                    <Button classNames={['button--secondary', 'button--small-text']}>Use Card, Transfer or BitPay</Button>
                </Link>
            </div>

            {sending &&
                <LoadingOverlay />
            }

        </div>
    )
}

export default UseCreditToPay