export const isInternalUrl = (url) => {
    return url.indexOf('/') === 0
}

export const getUrl = (poweredByCtx, pathname) => {
    let url = `https://www.shyaviation.com${pathname}`
    
    if (poweredByCtx.isPoweredBy) {
        url = `https://${poweredByCtx.tenantUrl}${pathname}`
    }

    return url.replace(/\/$/, '')
}