import getClassName from '../utilities/getClassName'
import {useState} from 'react'

const Image = ({src, showLoading, fillParent, width, height, alt, caption, classNames}) => {

    const [loading, setLoading] = useState(true)

    let className = ['image']

    if (typeof classNames === 'object') {
        className = className.concat(classNames)
    }

    if (fillParent) {
        className = className.concat(['image--fill-parent'])
    }

    if (showLoading) {
        className = className.concat(['image--loading'])
    }

    return (
        <figure className={getClassName(className)}>

            {showLoading && <div className='image__loading' />}

            <img className={getClassName([!loading ? 'image__loaded' : null])} onLoad={() => setLoading(false)} src={src} width={fillParent ? null : width} height={fillParent ? null : height} alt={alt || ''} />

            {caption &&
                <figcaption>{caption}</figcaption>
            }
        </figure>
    )
}

export default Image