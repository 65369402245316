import crossIcon from '../assets/icons/cross.svg'

const FiltersSummary = ({filterSelections, updateFilterSelections}) => {

    const removeFilter = (filter, option) => {
        updateFilterSelections(filter, option, 'remove')
    }

    const removeAllFilters = () => {
        updateFilterSelections(null, null, 'clear')
    }

    const getLabel = (text) => {
        switch (text) {
            case 'flightFromLong':
                return 'Departure Airport'
            case 'flightToLong':
                return 'Arrival Airport'
            case 'departureDate':
                return 'Departure Date'
            case 'arrivalDate':
                return 'Arrival Date'
            default:
                return text
        }
    }

    if (Object.keys(filterSelections).length > 0) {
        return <div className='filters-summary'>
            <div className='wrap'>
                <div className='filters-summary__selections'>
                    {Object.keys(filterSelections).map((key) => {
                        if (key === 'departureDate' || key === 'arrivalDate') {
                            if (filterSelections[key].from && filterSelections[key].to) {
                                return <button className='filters-summary__selection' onClick={removeFilter.bind(this, key, null)} key={key}><span>{getLabel(key)}:</span> from {filterSelections[key].from} to {filterSelections[key].to} <img src={crossIcon} alt='' /></button>
                            }
                        } else {
                            return filterSelections[key].map((filter, i) => {
                                return <button className='filters-summary__selection' onClick={removeFilter.bind(this, key, filter)} key={i}><span>{getLabel(key)}:</span> {filter} <img src={crossIcon} alt='' /></button>
                            })
                        }
                    })}
                </div>
                <button className='filters-summary__clear' onClick={removeAllFilters}>Clear all</button>
            </div>
        </div>
    }
}

export default FiltersSummary