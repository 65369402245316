import getClassName from '../utilities/getClassName'
import filtersIcon from '../assets/icons/filters.svg'

const FiltersBar = ({filterSelections, updateShowFilters, available, children}) => {

    let filterCount = 0

    Object.keys(filterSelections).map((key) => {
        if (key === 'departureDate' || key === 'arrivalDate') {
            if (filterSelections[key].from && filterSelections[key].to) {
                filterCount++
            }
        } else {
            filterCount += filterSelections[key].length
        }
    })

    return (
        <div className={getClassName(['filters-bar', available ? '' : 'filters-bar--inactive'])}>
            <div className='wrap'>
                <div className='filters-bar__inner'>
                    {filterCount > 0
                        ?
                            <button className='filters-bar__toggle' onClick={updateShowFilters}>
                                <img src={filtersIcon} alt='' />
                                Filter({filterCount}) & Sort
                            </button>
                        :
                            <button className='filters-bar__toggle' onClick={updateShowFilters}>
                                <img src={filtersIcon} alt='' />
                                Filter & Sort
                            </button>
                    }
                    {children}
                </div>
            </div>
        </div>
    )
}

export default FiltersBar