import {useState} from 'react'
import LoginForm from './LoginForm'
import ResetPasswordForm from './ResetPasswordForm'
import ModalFormWrapper from './ModalFormWrapper'
import {Link} from 'react-router-dom'
import CloseCross from './CloseCross'

const LoginForgotPasswordSteps = ({accountName, closePanel, name}) => {

    // State
    const [tab, setTab] = useState('login')

    const tabTitle = () => {
        if (tab === 'login') {
            if (accountName) {
                return `${accountName}, welcome back`
            }
            if (name) {
                return `Welcome back ${name}`
            }
            return 'Login'
        } else {
            return 'Reset Password'
        }
    }

    return (
        <ModalFormWrapper
            title={tabTitle()}
        >
            {(tab === 'login') ?
                <LoginForm forgottenPassword={() => setTab('forgotten-password')} loginComplete={() => closePanel()} />
                :
                <ResetPasswordForm returnToLogin={() => setTab('login')} />
            }
            <Link to='/'><div className='modal__close-cross-wrapper'>
                <CloseCross />
            </div></Link>
        </ModalFormWrapper>
    )
}

export default LoginForgotPasswordSteps