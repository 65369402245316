// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react"

// Import required modules
import {Navigation} from "swiper"

// Import Swiper styles
import "swiper/css"
import 'swiper/css/navigation'
import React, {useCallback, useState} from 'react'

// Import icons
import chevronRight from '../assets/icons/chevron-right.svg'
import chevronLeft from '../assets/icons/chevron-left.svg'

const PhotoCarousel = ({photoData, bannerTitle=null, enabled}) => {

    // State
    const [swiperRef, setSwiperRef] = useState()
    const [counter, setCounter] = useState(1)

    // Prev click handler
    const prevClickHandler = useCallback(() => {
        if (!swiperRef) return
        swiperRef.slidePrev()
    }, [swiperRef])

    // Next click handler
    const nextClickHandler = useCallback(() => {
        if (!swiperRef) return
        swiperRef.slideNext()
    }, [swiperRef])

    // Set counter
    const setCounterHandler = (swiper) => {
        if (swiper) {
            setCounter(swiper.realIndex + 1)
        }
    }

    return (
        <>
            {photoData && photoData.length > 0 &&
                <div className='photo-carousel'>

                    {(enabled !== false) ?
                        <Swiper
                            onActiveIndexChange={() => setCounterHandler(swiperRef)}
                            onSwiper={setSwiperRef}
                            grabCursor={true}
                            speed={400}
                            loop={true}
                            modules={[Navigation]}
                            touchStartPreventDefault={false}
                            className='swiper'
                            slidesPerView='auto'
                        >
                            {photoData.map(photo => {
                                return (
                                    <SwiperSlide key={`slider${photo.id}`}>
                                        <img className='swiper__image' src={photo.url} alt={photo.alt}/>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                        :
                        <img className='photo-carousel__single-image' alt='' src={photoData[0].url} />
                    }

                    {bannerTitle &&
                        <div className='photo-carousel__banner-title'>{bannerTitle}</div>
                    }

                    {(enabled !== false) &&
                        <>
                            <div className='photo-carousel__counter'>{counter}/{photoData.length}</div>
                            <button type='button' onClick={prevClickHandler} className='swiper-nav-button'>Prev<img src={chevronLeft} alt=''/>
                            </button>
                            <button type='button' onClick={nextClickHandler} className='swiper-nav-button swiper-nav-button__next'>Next<img src={chevronRight} alt=''/></button>
                        </>
                    }
                </div>
            }
        </>
    )
}

export default PhotoCarousel