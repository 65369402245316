export const Icon = () => {
	return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#949494">
		<path d="M9.5,4.4L9.1,4.2l0,0L9.5,4.4z M9.1,5.6l0.2,0.5L9.5,6l0.1-0.2L9.1,5.6z M7.9,6.3L7.8,6.8l0.2,0l0.2-0.1
	L7.9,6.3z M6.6,6.1L6.5,6.5L6.6,6.1z M4.5,7l0.4,0.2L4.5,7z M3.9,8L3.5,7.7L3.9,8z M4.2,10.3L4.5,10l0,0L4.2,10.3z M5,11.3l0.5,0
	l0-0.2L5.4,11L5,11.3z M5,12.7L5.4,13l0.1-0.2l0-0.2L5,12.7z M4.2,13.7l-0.4-0.3l0,0L4.2,13.7z M3.9,16l-0.4,0.2L3.9,16z M4.5,17
	l0.4-0.2l0,0L4.5,17z M6.6,17.9l0.1,0.5l0,0L6.6,17.9z M7.9,17.7l0.3-0.4L8,17.1l-0.2,0L7.9,17.7z M9.1,18.4l0.5-0.2L9.5,18
	l-0.2-0.1L9.1,18.4z M9.5,19.6l-0.5,0.2L9.5,19.6z M14.5,19.6l0.5,0.2L14.5,19.6z M14.9,18.4l-0.2-0.5L14.5,18l-0.1,0.2L14.9,18.4z
	 M16.1,17.7l0.1-0.5l-0.2,0l-0.2,0.1L16.1,17.7z M17.4,17.9l0.1-0.5L17.4,17.9z M19.5,17l-0.4-0.2L19.5,17z M20.1,16l0.4,0.2
	L20.1,16z M19.8,13.7L19.5,14l0,0L19.8,13.7z M19,12.7l-0.5,0l0,0.2l0.1,0.2L19,12.7z M19,11.3L18.6,11l-0.1,0.2l0,0.2L19,11.3z
	 M19.8,10.3l0.4,0.3L19.8,10.3z M20.1,8l-0.4,0.2l0,0L20.1,8z M19.5,7l0.4-0.2l0,0L19.5,7z M17.4,6.1l-0.1-0.5L17.4,6.1z M16.1,6.3
	l-0.3,0.4L16,6.9l0.2,0L16.1,6.3z M14.9,5.6l-0.5,0.2L14.5,6l0.2,0.1L14.9,5.6z M14.5,4.4l0.5-0.2l0,0L14.5,4.4z M10,4.5
	c0.2-0.6,0.8-1,1.4-1v-1c-1.1,0-2,0.7-2.4,1.7L10,4.5z M9.6,5.8L10,4.5L9.1,4.2L8.7,5.5L9.6,5.8z M8.2,6.7c0.4-0.3,0.7-0.5,1.1-0.7
	L8.9,5.2C8.5,5.4,8,5.6,7.6,5.9L8.2,6.7z M6.5,6.5l1.3,0.3l0.2-1L6.7,5.6L6.5,6.5z M4.9,7.3c0.3-0.6,1-0.8,1.6-0.7l0.2-1
	C5.7,5.4,4.6,5.8,4.1,6.8L4.9,7.3z M4.4,8.2l0.6-1L4.1,6.8l-0.6,1L4.4,8.2z M4.5,10C4.1,9.5,4,8.8,4.4,8.2L3.5,7.7
	C3,8.7,3.1,9.8,3.8,10.6L4.5,10z M5.4,11l-0.9-1l-0.7,0.7l0.9,1L5.4,11z M5.5,12c0-0.2,0-0.4,0-0.7l-1-0.1c0,0.2,0,0.5,0,0.8H5.5z
	 M5.5,12.7c0-0.2,0-0.4,0-0.7h-1c0,0.3,0,0.5,0,0.8L5.5,12.7z M4.5,14l0.9-1l-0.7-0.7l-0.9,1L4.5,14z M4.4,15.8
	C4,15.2,4.1,14.5,4.5,14l-0.7-0.7c-0.7,0.8-0.8,2-0.3,2.9L4.4,15.8z M4.9,16.7l-0.6-1l-0.9,0.5l0.6,1L4.9,16.7z M6.5,17.5
	c-0.6,0.1-1.3-0.2-1.6-0.7l-0.9,0.5c0.5,0.9,1.6,1.4,2.7,1.2L6.5,17.5z M7.8,17.2l-1.3,0.3l0.2,1L8,18.2L7.8,17.2z M9.3,17.9
	c-0.4-0.2-0.8-0.4-1.1-0.7l-0.6,0.8c0.4,0.3,0.8,0.5,1.3,0.8L9.3,17.9z M10,19.5l-0.4-1.2l-0.9,0.3l0.4,1.2L10,19.5z M11.4,20.5
	c-0.6,0-1.2-0.4-1.4-1l-0.9,0.3c0.3,1,1.3,1.7,2.4,1.7V20.5z M12.6,20.5h-1.1v1h1.1V20.5z M14,19.5c-0.2,0.6-0.8,1-1.4,1v1
	c1.1,0,2-0.7,2.4-1.7L14,19.5z M14.4,18.2L14,19.5l0.9,0.3l0.4-1.2L14.4,18.2z M15.8,17.3c-0.4,0.3-0.7,0.5-1.1,0.7l0.4,0.9
	c0.5-0.2,0.9-0.5,1.3-0.8L15.8,17.3z M17.5,17.5l-1.3-0.3l-0.2,1l1.3,0.3L17.5,17.5z M19.1,16.7c-0.3,0.6-1,0.8-1.6,0.7l-0.2,1
	c1.1,0.2,2.1-0.3,2.7-1.2L19.1,16.7z M19.6,15.8l-0.6,1l0.9,0.5l0.6-1L19.6,15.8z M19.5,14c0.4,0.5,0.5,1.2,0.2,1.7l0.9,0.5
	c0.5-0.9,0.4-2.1-0.3-2.9L19.5,14z M18.6,13l0.9,1l0.7-0.7l-0.9-1L18.6,13z M18.5,12c0,0.2,0,0.4,0,0.7l1,0.1c0-0.2,0-0.5,0-0.8
	H18.5z M18.5,11.3c0,0.2,0,0.4,0,0.7h1c0-0.3,0-0.5,0-0.8L18.5,11.3z M19.5,10l-0.9,1l0.7,0.7l0.9-1L19.5,10z M19.6,8.2
	c0.3,0.6,0.3,1.3-0.2,1.7l0.7,0.7c0.7-0.8,0.8-2,0.3-2.9L19.6,8.2z M19.1,7.3l0.6,1l0.9-0.5l-0.6-1L19.1,7.3z M17.5,6.5
	c0.6-0.1,1.3,0.2,1.6,0.7l0.9-0.5c-0.5-0.9-1.6-1.4-2.7-1.2L17.5,6.5z M16.2,6.8l1.3-0.3l-0.2-1L16,5.8L16.2,6.8z M14.7,6.1
	c0.4,0.2,0.8,0.4,1.1,0.7l0.6-0.8c-0.4-0.3-0.8-0.5-1.3-0.8L14.7,6.1z M14,4.5l0.4,1.2l0.9-0.3l-0.4-1.2L14,4.5z M12.6,3.5
	c0.6,0,1.2,0.4,1.4,1l0.9-0.3c-0.3-1-1.3-1.7-2.4-1.7V3.5z M11.4,3.5h1.1v-1h-1.1V3.5z"/>
	<path d="M12,13c0.6,0,1-0.4,1-1s-0.4-1-1-1s-1,0.4-1,1S11.4,13,12,13z"/>
		</svg>
}