import {useContext, useEffect, useRef, useState} from 'react'
import getClassName from '../utilities/getClassName'
import {getDate, getTime} from '../utilities/date'
import JourneyLeg from './JourneyLeg'
import Background from './Background'
import planeIcon from '../assets/icons/plane-light.svg'
import planeIconDark from '../assets/icons/plane-dark.svg'
import returnTripIcon from '../assets/icons/return-trip-light.svg'
import returnTripIconDark from '../assets/icons/return-trip-dark.svg'
// import editIcon from '../assets/icons/edit-light.svg'
// import editIconDark from '../assets/icons/edit-dark.svg'
import chevronIcon from '../assets/icons/chevron-down-white.svg'
import chevronIconDark from '../assets/icons/chevron-down-dark.svg'
import AnimateHeight from 'react-animate-height'
import poweredByContext from '../store/PoweredByContext'

const QuoteHeader = ({data, filterScrollPosition, isSimple}) => {

    // State
    const [stickyPosition, setStickyPosition] = useState('')
    const [maximisedHeaderOpacity, setMaximisedHeaderOpacity] = useState('')
    const [maximisedExpandedHeaderOpacity, setMaximisedExpandedHeaderOpacity] = useState('')
    const [minimisedHeaderOpacity, setMinimisedHeaderOpacity] = useState('')
    const [showLittleBackground, setShowLittleBackground] = useState(false)
    const [layout, setLayout] = useState('')
    const [isItineraryExpanded, setIsItineraryExpanded] = useState(false)

    const poweredByCtx = useContext(poweredByContext)

    // Ref
    const stickyHeader = useRef(null)
    const maximisedRef = useRef(null)
    const expandedRef = useRef(null)

    // Set height of Header.js component (containing logo and profile icon)
    const headerHeight = 55

    // Set the height of the fixed quote header
    const fixedHeight = 115
    // Set when you would like the quote header to fix
    const fixedPosition = 171

    // Window resize & scroll listener
    useEffect(() => {

        // Set the scroll position for after filters applied
        filterScrollPosition((headerHeight + stickyHeader.current.clientHeight - fixedPosition))

        // Set sticky position on load
        setTimeout(() => {
            resizeHandler()
        }, 1000)

        let resizeTimeout
        const resizeHandler = () => {

            if (stickyHeader.current) {
                // Measure and set the fixed position of the quote header based on its height
                setStickyPosition(-(stickyHeader.current.clientHeight) + fixedPosition)

                clearTimeout(resizeTimeout)
                resizeTimeout = setTimeout(() => {

                    // Choose journey leg layout
                    if (!window.matchMedia('(max-width: 764px)').matches) {
                        setLayout(6)
                    } else {
                        setLayout(4)
                    }
                }, 100)
            }
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        // On scroll
        const scrollListener = () => {
            if (stickyHeader.current) {
                const headerPosition = (stickyHeader.current.getBoundingClientRect().top - headerHeight)
                const endPosition = (stickyPosition - headerHeight)
                const percent = (headerPosition / endPosition) * 100
                setMinimisedHeaderOpacity(((percent - 50) * 2) / 100)
                setMaximisedHeaderOpacity((100 - (percent * 1.2)) / 100)

                if (isItineraryExpanded && layout === 4 && !isSimple) {
                    setMaximisedExpandedHeaderOpacity((100 - (percent * 1.2)) / 100)
                }

                // Add height of quote header message if applicable
                let messageHeight = 0
                if (document.getElementById('header-message'))
                {
                    messageHeight = document.getElementById('header-message').clientHeight
                }

                if (window.scrollY > (headerHeight + messageHeight + stickyHeader.current.clientHeight - fixedHeight)) {
                    setShowLittleBackground(true)
                } else {
                    setShowLittleBackground(false)
                }
            }
        }

        // Set resize listener
        window.addEventListener('resize', resizeHandler)

        // Set scroll listener
        window.addEventListener('scroll', scrollListener)

        // Clean up function
        return () => {
            // Remove resize and scroll listeners
            window.removeEventListener('resize', resizeHandler)
            window.removeEventListener('scroll', scrollListener)
        }
    }, [filterScrollPosition, stickyPosition, isItineraryExpanded, isSimple, layout])

    useEffect(() => {
        if (isItineraryExpanded) {

            if (maximisedRef.current) {
                maximisedRef.current.querySelector('.wrap').style.opacity = 0
            }
            if (expandedRef.current) {
                expandedRef.current.style.opacity = 1
            }
        } else {
            if (maximisedRef.current) {
                stickyHeader.current.classList.remove('quote-header--expanded-itinerary')
                maximisedRef.current.querySelector('.wrap').style.opacity = null
            }
            if (expandedRef.current) {
                expandedRef.current.style.opacity = null
            }
        }
    }, [isItineraryExpanded])

    const expandItinerary = () => {
        setIsItineraryExpanded(true)
    }

    const collapseItinerary = () => {
        setIsItineraryExpanded(false)
    }

    const getHeight = () => {
        if (data.legs.length > 1 && isItineraryExpanded) {
            if (expandedRef.current) {
                return expandedRef.current.clientHeight
            }
        } 

        return 'auto'
    }

    return (
        <div ref={stickyHeader} style={!isSimple ? {top: `${stickyPosition}px`} : null}
             className={getClassName(['quote-header', isSimple ? 'quote-header--is-simple' : null])}
        >

            {isSimple ?
                <div className='quote-header__tail-number-row quote-header__tail-number-row--is-simple wrap'>
                    <div className='quote-header__tail-number'>
                        <img
                            src={
                                data.legs.length > 1 ?
                                (poweredByCtx.isPoweredBy ? returnTripIconDark : returnTripIcon) :
                                poweredByCtx.isPoweredBy ? planeIconDark : planeIcon
                            }
                            alt=''
                        />{data.tripName}
                    </div>
                </div>
                :
                <div style={{height: `${fixedHeight}px`, display: showLittleBackground ? 'block' : 'none'}} className='quote-header__background-image'>
                    <Background position='absolute'/>
                </div>
            }
                
                <AnimateHeight
                    id="example-panel"
                    duration={500}
                    height={getHeight()}
                >
                <div style={{opacity: maximisedHeaderOpacity}} className='quote-header__maximised' ref={maximisedRef}>
                    <div className='wrap'>
                        <div className='quote-header__tail-number-row'>
                            <div className='quote-header__tail-number'>
                                <img
                                    src={
                                        data.legs.length > 1 ?
                                        ((isSimple || poweredByCtx.isPoweredBy) ? returnTripIconDark : returnTripIcon) :
                                        (isSimple || poweredByCtx.isPoweredBy) ? planeIconDark : planeIcon
                                    }
                                    alt=''
                                />{data.tripName}
                            </div>
                            {data.legs.length > 1 &&
                                <p className='quote-header__trip-type'>Return trip</p>
                            }
                            {/*<button className='quote-header__edit-button'><img src={isSimple ? editIconDark : editIcon} alt=''/>Edit</button>*/}
                        </div>

                        <div className='quote-header__journey-row'>
                            {data &&
                                <>
                                    {data.legs &&
                                        <JourneyLeg
                                            journeyType='jets'
                                            layout={layout}
                                            toLong={data.legs[0].arrivalAirport.longName.toLowerCase()}
                                            toShort={data.legs[0].arrivalAirport.shortName}
                                            fromLong={data.legs[0].departureAirport.longName.toLowerCase()}
                                            fromShort={data.legs[0].departureAirport.shortName}
                                            departureDate={getDate(data.legs[0].departureTime)}
                                            departureTime={getTime(data.legs[0].departureTime)}
                                            arrivalDate={getDate(data.legs[0].arrivalTime)}
                                            arrivalTime={getTime(data.legs[0].arrivalTime)}
                                            luggage={data.legs[0].luggage}
                                            passengers={data.legs[0].passengers}
                                            isSimple={isSimple}
                                            subtitle={data.legs.length > 1 ? 'Outbound' : null}
                                        />
                                    }
                                </>
                            }
                        </div>

                        {data.legs.length > 1
                            ?
                            <button className={getClassName(['quote-header__itinerary-button', 'quote-header__itinerary-button--expand', isItineraryExpanded ? 'quote-header__itinerary-button--active' : null])} onClick={expandItinerary}><img src={isSimple ? chevronIconDark : chevronIcon} alt='' /> See full itinerary</button>
                            :
                            <div className='quote-header__small-print'>
                                <p>All times shown in local time. Aircraft is subject to owner’s approval, continued
                                    availability of aircraft and crew, slots and permits.
                                </p>
                            </div>
                        }
                    </div>
                </div>
                </AnimateHeight>

            {data.legs.length > 1 &&
                <div style={{opacity: maximisedExpandedHeaderOpacity}} className={getClassName(['quote-header__maximised-expanded', isItineraryExpanded ? 'quote-header__maximised-expanded--active' : null])} ref={expandedRef}>
                    <div className='wrap'>
                        <div className='quote-header__tail-number-row'>
                            <div className='quote-header__tail-number'>
                                <img
                                   src={
                                       data.legs.length > 1 ?
                                       ((isSimple || poweredByCtx.isPoweredBy) ? returnTripIconDark : returnTripIcon) :
                                       (isSimple || poweredByCtx.isPoweredBy) ? planeIconDark : planeIcon
                                   }
                                   alt=''
                                />{data.tripName}
                            </div>
                            {data.legs.length > 1 &&
                                <p className='quote-header__trip-type'>Return trip</p>
                            }
                            {/*<button className='quote-header__edit-button'><img src={isSimple ? editIconDark : editIcon} alt=''/>Edit</button>*/}
                        </div>

                        <div className='quote-header__journey-row'>
                            {data &&
                                <>
                                    {data.legs &&
                                        <>
                                            {data.legs.map((leg, i) => {
                                                return <JourneyLeg
                                                    key={i}
                                                    journeyType='jets'
                                                    layout={layout}
                                                    toLong={leg.arrivalAirport.longName.toLowerCase()}
                                                    toShort={leg.arrivalAirport.shortName}
                                                    fromLong={leg.departureAirport.longName.toLowerCase()}
                                                    fromShort={leg.departureAirport.shortName}
                                                    departureDate={getDate(leg.departureTime)}
                                                    departureTime={getTime(leg.departureTime)}
                                                    arrivalDate={getDate(leg.arrivalTime)}
                                                    arrivalTime={getTime(leg.arrivalTime)}
                                                    luggage={leg.luggage}
                                                    passengers={leg.passengers}
                                                    isSimple={isSimple}
                                                    hideLuggageAndPassengers={(layout === 6 && i > 0) || (layout === 4 && i < data.legs.length - 1)}
                                                    subtitle={i === 0 ? 'Outbound' : 'Inbound'}
                                                />
                                            })}
                                        </>
                                    }
                                </>
                            }
                        </div>

                        <div className='quote-header__small-print'>
                            <p>All times shown in local time. Aircraft is subject to owner’s approval, continued
                                availability of aircraft and crew, slots and permits.
                            </p>
                        </div>
                        
                        <button className='quote-header__itinerary-button quote-header__itinerary-button--collapse' onClick={collapseItinerary}><img src={isSimple ? chevronIconDark : chevronIcon} alt='' /> Collapse itinerary</button>
                    </div>
                </div>
            }

            <div style={{opacity: minimisedHeaderOpacity}} className='quote-header__minified'>
                <div className='wrap'>
                    {data &&
                        <>
                            {data.legs &&
                                <>
                                    <JourneyLeg
                                        journeyType='jets'
                                        layout={5}
                                        toLong={data.legs[0].arrivalAirport.longName}
                                        toShort={data.legs[0].arrivalAirport.shortName}
                                        fromLong={data.legs[0].departureAirport.longName}
                                        fromShort={data.legs[0].departureAirport.shortName}
                                        departureDate={getDate(data.legs[0].departureTime)}
                                        departureTime={getTime(data.legs[0].departureTime)}
                                        arrivalDate={getDate(data.legs[0].arrivalTime)}
                                        arrivalTime={getTime(data.legs[0].arrivalTime)}
                                        luggage={data.legs[0].luggage}
                                        passengers={data.legs[0].passengers}
                                        isSimple={isSimple}
                                        isReturnTrip={data.legs.length > 1}
                                    />
                                </>
                            }
                            {isSimple && data.legs.length > 1 &&
                                <button className={getClassName(['quote-header__itinerary-button', 'quote-header__itinerary-button--expand', isItineraryExpanded ? 'quote-header__itinerary-button--active' : null])} onClick={expandItinerary}><img src={isSimple ? chevronIconDark : chevronIcon} alt='' /> See full itinerary</button>
                            }
                        </>
                    }
                </div>
            </div>

        </div>
    )
}

export default QuoteHeader