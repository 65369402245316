import {useEffect, useState} from 'react'
import getClassName from '../utilities/getClassName'
import Button from './Button'
import TextInput from './TextInput'
import ReturnMessage from './ReturnMessage'
import ClipboardInput from './ClipboardInput'
import usePost from '../hooks/usePost'
import LoadingOverlay from './LoadingOverlay'

const SharePaymentMethod = ({ shareMethod, id, legId, tailNum, paymentPin, onShare }) => {

    // State
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [surname, setSurname] = useState('')
    const [validateForm, setValidateForm] = useState(false)

    // Hooks
    const {sending, returnMessage, returnData: shareData, postData} = usePost()

    // Submit
    const submitHandler = (e) => {
        e.preventDefault()
        setValidateForm(true)

        // Form complete
        if (email && firstName && surname) {

            const formData = {
                sharerId: id,
                legId: legId,
                tailNumber: tailNum,
                paymentPin: paymentPin,
                sharee: {
                    firstName: firstName,
                    surname: surname,
                    email: email
                }
            }

            postData(formData,'/api/frontend/share-payment')
        }
    }

    // Use effect
    useEffect(() => {
        if (returnMessage.success && shareMethod === 'email') {
            onShare({
                firstName: firstName,
                surname: surname,
                email: email,
                pin: paymentPin
            })
        }
    }, [returnMessage])

    let className = ['share-payment-method']

    if (shareMethod) {
        className.push(`share-payment-method--${shareMethod}`)
    }

    const getTitle = () => {
        switch (shareMethod) {
            case 'email':
                return 'Allow instant Access (via email)'
            case 'link':
                return 'Share via Link (requires authentication)'
        }
    }

    return (
        <article className={getClassName(className)}>
            <div className='share-payment-method__message'>
                <ReturnMessage inline={true} returnMessage={returnMessage} />
            </div>
            <h2 className='share-payment-method__title'>{getTitle()}</h2>
            {shareMethod === 'email' &&
                <>
                    <p>Please provide contact details of the recipient</p>
                    <form className='share-payment-method__form' onSubmit={submitHandler}>
                        <TextInput
                            id='first-name'
                            validateForm={validateForm}
                            label='First name*'
                            required
                            maxLength={200}
                            disabled={sending}
                            value={(value) => setFirstName(value)}
                            defaultValue={firstName}
                        />

                        <TextInput
                            id='last-name'
                            validateForm={validateForm}
                            label='Surname*'
                            required
                            maxLength={200}
                            disabled={sending}
                            value={(value) => setSurname(value)}
                            defaultValue={surname}
                        />

                        <TextInput
                            id='email'
                            validateForm={validateForm}
                            label='Email*'
                            required
                            disabled={sending}
                            value={(value) => setEmail(value)}
                            marginBottom
                            validation='email'
                        />
                        <Button classNames={['button--full']}>Share payment link</Button>
                    </form>

                    {sending && <LoadingOverlay />}
                </>
            }
            {shareMethod === 'link' &&
                <form>
                    <ClipboardInput
                        defaultValue='mw6MrapLJYJunP33ixCqrQtJJG'
                    />
                </form>
            }
        </article>
    )
}

export default SharePaymentMethod