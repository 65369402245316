const Invoice = () => {

    return (
        <div className='invoice'>
            <table className='invoice__header'>
                <tbody>
                    <tr>
                        <td colSpan='2' align='right' className='invoice__logo'>
                            <img src='/icons/invoice-logo.svg' alt='' />
                        </td>
                    </tr>

                    <tr>
                        <td className='invoice__address'>Jane Doe<br /><br />jane.doe@gmail.com<br /><br /> 1234 Whitehall Road<br /> London<br /> W1C2W<br /> United Kingdom</td>
                        <td className='invoice__meta'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Invoice: <span>#8236</span></td>
                                    </tr>
                                    <tr>
                                        <td>Date: <span>12/12/2022</span></td>
                                    </tr>
                                    <tr>
                                        <td>Pay online: <a href='#'>shyaviation.com</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan='2' className='invoice__warning'>Flight not confirmed until funds cleared. Please pay by 22/12/2022.</td>
                    </tr>
                </tbody>
            </table>

            <table className='invoice__details'>
                <tbody>
                    <tr>
                        <td className='invoice__ref'>Booking Reference: <span>SHYF-13456789</span></td>
                    </tr>
                    <tr>
                        <td>
                            <table className='invoice__table'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Price</th>
                                        <th>VAT</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>
                                            PARIS CHARLES DE GAULLE - LONDON LUTON
                                            <span>Citation M2 - 12/01/2023</span>
                                        </th>
                                        <td>£12,990.00</td>
                                        <td>NA</td>
                                        <td>£12,990.00</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            PRIVATE TRANSFER IN LONDON
                                        </th>
                                        <td>£300.00</td>
                                        <td>NA</td>
                                        <td>£300.00</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan='3'>VAT:</th>
                                        <td>£0</td>
                                    </tr>
                                    <tr>
                                        <th colSpan='3'>Total:</th>
                                        <td className='invoice__price'>£13,290.00</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table className='invoice__callout'>
                <tbody>
                    <tr>
                        <td className='invoice__callout-header'>Pay online at <a href='#'>shyaviation.com</a></td>
                    </tr>
                    <tr>
                        <td className='invoice__callout-copy'>Your invoice can be paid online by logging in your account and accessing ‘Your Bookings’. Please note card administration fees of up to 4% will apply depending on card type used.</td>
                    </tr>
                </tbody>
            </table>

            <table className='invoice__payment'>
                <tbody>
                    <tr>
                        <th>Bank Address:</th>
                        <td>Barclays Bank plc, 27 Soho Square, London W1D 3QR</td>
                    </tr>
                    <tr>
                        <th>Account Name:</th>
                        <td>SHY Aviation Ltd</td>
                    </tr>
                    <tr>
                        <th>Account Number:</th>
                        <td>123456789</td>
                    </tr>
                    <tr>
                        <th>Sort Code:</th>
                        <td>12-34-56</td>
                    </tr>
                    <tr>
                        <th>iban:</th>
                        <td>GB12 BUKB 2345 6789 1234 56</td>
                    </tr>
                    <tr>
                        <th>swift:</th>
                        <td>BUKB GB22</td>
                    </tr>
                    <tr>
                        <th>Payment Reference:</th>
                        <td>SHYF-13456789</td>
                    </tr>
                </tbody>
            </table>

            <table className='invoice__footer'>
                <tbody>
                    <tr className='invoice__footer-row-1'>
                        <td>invoice #8234</td>
                        <td>Shy Aviation</td>
                        <td>Page 1 of 1</td>
                    </tr>
                    <tr className='invoice__footer-row-2'>
                        <td colSpan='3'>SHY AVIATION IS A TRADING NAME OF SHY AVIATION LTD. REGISTERED AT 233 HIGH HOLBORN, LONDON WC1V 7DN COMPANY NO.07802103 VAT REG NO.129178985</td>
                    </tr>
                    <tr className='invoice__footer-row-3'>
                        <td colSpan='3'>
                            <a href='mailto:accounts@shyaviation.com'>accounts@shyaviation.com</a> | 
                            <a href='https://www.shyaviation.com'>www.shyaviation.com</a> | 
                            <a href='tel:+442086168821'>+44 020 8616 8821</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Invoice