import {useContext, useRef} from 'react'
import authContext from '../store/AuthContext'
import poweredByContext from '../store/PoweredByContext'
import {CSSTransition} from 'react-transition-group'
import Button from './Button'
import CloseCross from './CloseCross'
import ReactDOM from 'react-dom'
import {Link, useNavigate} from 'react-router-dom'
import Background from './Background'
// import editIcon from '../assets/icons/edit-light.svg'
import {getRawIcon} from '../utilities/icon'

const ProfilePanel = ({showPanel, closePanel}) => {

    // Hooks
    const authCtx = useContext(authContext)
    const poweredByCtx = useContext(poweredByContext)
    const transition = useRef(null)
    const navigate = useNavigate()

    // Build user initials
    function getFirstCharacters(str) {
        try {
            let result = []
            str.split(' ').map(word => word.charAt(0) !== '' ? result.push(word.charAt(0) + '.') : '')
            return result;
        }
        catch(err) {
            console.log(err)
        }
    }
    const initials = getFirstCharacters(authCtx.name)

    // Log out handler
    const logoutHandler = () => {
        navigate('/')
        authCtx.logout()
    }

    return (
        ReactDOM.createPortal (
            <CSSTransition
            nodeRef={transition}
            in={showPanel}
            timeout={500}
            unmountOnExit={true}
            classNames={{
                enterDone: 'profile-panel-transition',
                enterActive: 'profile-panel-transition'
            }}
        >

            <div ref={transition}>
                <div onClick={closePanel} className='profile-panel-overlay' />

                <div className='profile-panel'>
                    {!poweredByCtx.isPoweredBy &&
                        <Background noVideo position='absolute' fullHeight />
                    }

                    <div className='profile-panel__scroll'>
                        <div className='profile-panel__inner'>

                            <div className='profile-panel__inner__close-cross-container'>
                                <CloseCross onClick={closePanel} colour={poweredByCtx.isPoweredBy ? 'black' : 'white'} />
                            </div>
                            <div className='profile-panel__inner__picture'>
                                <div className='profile-panel__inner__picture__text'>{initials}</div>
                                {/*<button className='profile-panel__inner__picture__icon'><img src={editIcon} alt='Edit Profile Picture' /></button>*/}
                            </div>

                            {authCtx.isActAs !== true ?
                                <>
                                    <div className='profile-panel__inner__welcome'>Hi {authCtx.firstName}</div>
                                    <div className='profile-panel__inner__last-login'>{authCtx.lastLoginDateTime}</div>
                                </>
                                :
                                <>
                                    <div className='profile-panel__inner__welcome'>Logged in as {authCtx.name}</div>
                                    <div className='profile-panel__inner__last-login'>You are acting as this user.</div>
                                </>
                            }

                            <div className='profile-panel__inner__buttons'>
                                <Link to='/your-profile'>
                                    {poweredByCtx.isPoweredBy
                                        ?
                                            <Button onClick={closePanel} classNames={['button--nav button--full']}>
                                                <figure class="button__icon">
                                                    {getRawIcon('profile-dark')}
                                                </figure>
                                                Profile
                                            </Button>
                                        :
                                            <Button onClick={closePanel} icon={['profile-dark', 'profile-dark']} classNames={['button--nav button--full']}>Profile</Button>
                                    }
                                </Link>
                                <Link to='/quotes-and-bookings'>
                                    {poweredByCtx.isPoweredBy
                                        ?
                                            <Button onClick={closePanel} classNames={['button--nav button--full']}>
                                                <figure class="button__icon">
                                                    {getRawIcon('quotes-bookings')}
                                                </figure>
                                                Quotes & Bookings
                                            </Button>
                                        :
                                            <Button onClick={closePanel} icon={['quotes-bookings', 'quotes-bookings']} classNames={['button--nav button--full']}>Quotes & Bookings</Button>
                                    }
                                </Link>
                                <Link to='/manage-passengers'>
                                    {poweredByCtx.isPoweredBy
                                        ?
                                            <Button onClick={closePanel} classNames={['button--nav button--full']}>
                                                <figure class="button__icon">
                                                    {getRawIcon('saved-passengers')}
                                                </figure>
                                                Saved Passengers
                                            </Button>
                                        :
                                            <Button onClick={closePanel} icon={['saved-passengers', 'saved-passengers']} classNames={['button--nav button--full']}>Saved Passengers</Button>
                                    }
                                </Link>
                                {/*<Link to='/carbon-emissions'><Button onClick={closePanel} icon='carbon-emissions' classNames={['button--nav button--full']}>Carbon Emissions</Button></Link>*/}
                                <Link to='/settings'>
                                    {poweredByCtx.isPoweredBy
                                        ?
                                            <Button onClick={closePanel} classNames={['button--nav button--full']}>
                                                <figure class="button__icon">
                                                    {getRawIcon('settings')}
                                                </figure>
                                                Settings
                                            </Button>
                                        :
                                            <Button onClick={closePanel} icon={['settings', 'settings']} classNames={['button--nav button--full']}>Settings</Button>
                                    }
                                </Link>
                            </div>

                            <Button onClick={logoutHandler} classNames={['button--full']}>Log Out</Button>
                        </div>
                    </div>
                </div>

            </div>
            </CSSTransition>
            , document.body)
    )
}

export default ProfilePanel