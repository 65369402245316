import {useEffect, useRef, useState} from 'react'
import InputError from './InputError'
import getClassName from '../utilities/getClassName'
import Label from './Label'
import errorCross from '../assets/icons/error-cross.svg'
import tickGreen from '../assets/icons/tick-green.svg'
import Fieldset from './Fieldset'

const TextAreaInput = ({value, label, id, required, validation, defaultValue, maxLength, disabled, validateForm}) => {

    // State
    const [error, setError] = useState('')
    const [focussed, setFocussed] = useState(false)
    const [interacted, setInteracted] = useState(false)
    const [skipAnimation, setSkipAnimation] = useState(false)

    // Hooks
    const input = useRef()

    // Validate input
    const validationHandler = () => {
        if (value) {
            value(false)

            const currentValue = input.current.value.replace(/\s/g, '')

            switch (true) {
                case (required && currentValue.length === 0):
                    setError(`${label.replace('*', '')} is required`)
                    return
                case (required && (validation === 'email') && !/\S+@\S+\.\S+/.test(currentValue)):
                    setError(`Invalid email`)
                    return
                default:
                    setInteracted(true)
                    setError('')
                    value(input.current.value)
                    return
            }
        }
    }

    //On blur handler
    const onBlurHandler = () => {
        validationHandler()
        setSkipAnimation(false)
        const currentValue = input.current.value.replace(/\s/g, '')
        if (currentValue.length === 0) {
            setFocussed(false)
        }
    }

    // Force validation / Check for autofill
    useEffect(() => {
        if (validateForm) {
            validationHandler()
        }
        if (input.current.value) {
            setFocussed(true)
            setSkipAnimation(true)
        }
    }, [defaultValue, validateForm, validationHandler])

    return (
        <Fieldset>
            <div className='text-area-input'>
                {error && <InputError>{error}</InputError>}
                <textarea
                    ref={input}
                    id={id}
                    name={id}
                    autoComplete='on'
                    onFocus={() => setFocussed(true)}
                    maxLength={maxLength}
                    onBlur={onBlurHandler}
                    onChange={validationHandler}
                    className={getClassName(['text-area-input__text-area', error && 'text-area-input__text-area--error', (interacted && !error) && 'text-area-input__text-area--success'])}
                    disabled={disabled}
                >
                    {defaultValue}
                </textarea>

                {error && <img className='text-area-input__error-cross' src={errorCross} alt='' />}
                {(interacted && !error) && <img className='text-area-input__tick' src={tickGreen} alt='' />}
                {label && <Label minimised={focussed} skipAnimation={skipAnimation} htmlFor={id}>{label}</Label>}
            </div>
        </Fieldset>
    )
}

export default TextAreaInput