import React from 'react'
import {CSSTransition} from 'react-transition-group'
import getClassName from '../utilities/getClassName'
import {useRef} from 'react'
import Button from './Button'
import LoadingOverlay from './LoadingOverlay'
import CloseCross from './CloseCross'
import {Link} from 'react-router-dom'

const CompareButtonsBar = ({showCompare, compareBarRef, showCompareBarHandler, numberOfOptions, compareSelection, selectAllHandler, clearAllHandler, closeCompare, sharedPage, backLink}) => {

    let sending = false

    // Hooks
    const transitionRef = useRef(null)

    // Build compare link
    let link = `/quote/compare${backLink}`
    if (sharedPage) {
        link = `/shared-quote/compare${backLink}`
    }

    return (
        <CSSTransition
            nodeRef={transitionRef}
            in={showCompare}
            timeout={500}
            unmountOnExit
            classNames={{
                enterDone: 'compare-buttons-bar--active',
                enterActive: 'compare-buttons-bar--transition-in',
                exitActive: 'compare-buttons-bar--transition-out',
            }}
        >
            <div ref={transitionRef} className={getClassName(['compare-buttons-bar', 'wrap'])}>
                <div className='compare-buttons-bar__inner' ref={compareBarRef}>
                    {sending && <LoadingOverlay hideLoader />}

                    {(compareSelection.length >= 2) ?
                        <>
                            <div className='compare-buttons-bar__inner__title'>{compareSelection.length} option{(compareSelection.length === 1 ? null : 's')} selected</div>
                            <div className='compare-buttons-bar__inner__buttons-wrapper'>
                                <Link to={link}><Button onClick={closeCompare}>Compare</Button></Link>
                                <Button classNames={['button--white', 'button--remove-background-color']} onClick={clearAllHandler}>Clear all</Button>
                            </div>
                        </>
                        :
                        <>
                            <div className='compare-buttons-bar__inner__title'>
                                {(compareSelection.length === 1) ?
                                    <>Please select another option to compare</>
                                    :
                                    <>{numberOfOptions} option{(numberOfOptions === 1 ? null : 's')} available</>
                                }
                            </div>
                            <div className='compare-buttons-bar__inner__buttons-wrapper'>
                                <Button onClick={selectAllHandler}>Select All</Button>
                                {(compareSelection.length === 1) &&
                                    <Button classNames={['button--white', 'button--remove-background-color']} onClick={clearAllHandler}>Clear all</Button>
                                }
                            </div>
                        </>
                    }
                    <div className='compare-buttons-bar__inner__close-cross'><CloseCross onClick={showCompareBarHandler} /></div>
                </div>
            </div>
        </CSSTransition>
    )
}

export default CompareButtonsBar