import parse from 'html-react-parser'
import getClassName from '../utilities/getClassName'
import projectBackground1 from '../assets/images/carbon-project1.jpg'
import projectBackground2 from '../assets/images/carbon-project2.jpg'
import projectBackground3 from '../assets/images/carbon-project3.jpg'
import tick from '../assets/icons/tick-thick-white.svg'
import {project1, project2, project3} from '../data/environmentalProjectData'

const EnvironmentalProject = ({projectNo, currentlySelected, selectable, select}) => {

    // Project 1
    let name = project1.name
    let details = project1.details
    let background = projectBackground1

    // Project 2
    if (projectNo === 2) {
        name = 'Kariba Forest <br />Protection - £12/MT'
        details = project2.details
        background = projectBackground2
    }

    // Project 3
    if (projectNo === 3) {
        name = 'Tropical <br />Conservation - £18/MT'
        details = project3.details
        background = projectBackground3
    }

    let className = ['environmental-project']
    if (selectable) {
        className = className.concat('environmental-project--selectable')
    }
    if(currentlySelected) {
        className = className.concat('environmental-project--selected')
    }

    return (
        <div className={getClassName(className)} style={{backgroundImage: `url(${background})`}}>

            <div className='environmental-project__tint' />

            <div className='environmental-project__inner'>

                <div className='environmental-project__counter'>
                    <b>{projectNo}</b> of 3 projects
                </div>

                <div className='environmental-project__title-wrapper'>
                    <div className='environmental-project__title'>
                        {parse(name)}
                    </div>
                </div>

                <div className='environmental-project__line' />

                <div className='environmental-project__writeup'>
                    {details}
                </div>
            </div>

            {selectable &&
                <button onClick={() => select(projectNo)} className='environmental-project__select-checkbox'>
                    {currentlySelected && <img src={tick} alt='' /> }
                </button>
            }

            {selectable &&
                <button onClick={() => select(projectNo)} className='environmental-project__select-button' />
            }
        </div>
    )
}

export default EnvironmentalProject