import {useContext, useEffect, useState, useRef} from 'react'
import SelectInput from './SelectInput'
import DatePickerInput from './DatePickerInput'
import {numbersList} from '../data/numbersList'
import FromToInput from './FromToInput'
import TimePickerInput from './TimePickerInput'
import TextAreaInput from './TextAreaInput'
import Button from './Button'
import Modal from './Modal'
import useModal from '../hooks/useModal'
import usePost from '../hooks/usePost'
import QuoteQueryPersonalDetails from './QuoteQueryPersonalDetails'
import getClassName from '../utilities/getClassName'
import parse from "date-fns/parse"
import format from "date-fns/format"
import authContext from '../store/AuthContext'
import poweredByContext from '../store/PoweredByContext'
import useGeoLocation from 'react-ipgeolocation'

// Icons
import chevronDown from '../assets/icons/chevron-down-big.svg'
import personIcon from '../assets/icons/person-dark.svg'
import luggageIcon from '../assets/icons/luggage-dark.svg'
import plusDarkIcon from '../assets/icons/plus-dark.svg'
import closeCrossDark from '../assets/icons/close-cross-dark-heavy.svg'
import exclamationIcon from '../assets/icons/exclamation-mark.svg'
import { getIcon, getRawIcon } from '../utilities/icon'

const QuoteQueryJets = ({onFocus, inModal, onPage, closeQuoteModal, onModalSuccess, updateSending, quoteOrBookingToCopy, skeleton}) => {

    // State
    const [journeyCounter, setJourneyCounter] = useState(1)
    const [journeyData, setJourneyData] = useState([{id: journeyCounter, passengers: 1, luggage: 1, journeyType: 'private-jets'}])
    const [validateForm, setValidateForm] = useState(false)
    const [formIsValid, setFormIsValid] = useState(false)
    const [showError, setShowError] = useState(false)
    const [requests, setRequests] = useState('')
    const [valuesUpdated, setValuesUpdated] = useState([])
    const [resetFormRef, setResetFormRef] = useState(0)
    const [isScrolled, setIsScrolled] = useState(false)
    const [mobileIsActive, setMobileIsActive] = useState(false)
    const [quoteQueryMarkerHeight, setQuoteQueryMarkerHeight] = useState(0)

    // Hooks
    const {modalVisible, openModal, closeModal} = useModal()
    const {sending, returnMessage, postData} = usePost()
    const location = useGeoLocation()

    // Auth context
    const authCtx = useContext(authContext)
    const poweredByCtx = useContext(poweredByContext)

    const quoteQueryRef = useRef()
    const quoteQueryMarkerRef = useRef()
    const quoteQueryMarkerRef2 = useRef()

    const offset = (el) => {
        const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop

        return {
            top: rect.top + scrollTop,
            left: rect.left + scrollLeft
        }
    }

    const isBottomInViewport = (elem, offset = 0) => {
        const bounding = elem.getBoundingClientRect();
        return (bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + offset);
    }

    const checkOverflow = () => {
        const root = document.documentElement
        let valid = false

        if (isScrolled && mobileIsActive && !window.matchMedia('(min-width: 765px)').matches) {
            root.style.overflow = 'hidden'
            valid =  true
        } else {
            root.style.overflow = null
        }

        return valid
    }

    const addJourneyHandler = () => {
        setJourneyCounter(journeyCounter + 1)

        // Get last journey
        const lastJourney = journeyData[journeyData.length - 1]

        // Set date to 3 days time
        let newDate
        if (lastJourney['date']) {
            newDate = parse(lastJourney['date'], "dd/MM/yyyy", new Date())
            newDate.setDate(newDate.getDate() + 3)
            newDate = format(newDate, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
        }

        const newJourney = {
            journeyType: 'private-jets',
            id: (journeyCounter + 1),
            flightFrom: lastJourney['flightTo'],
            flightTo: lastJourney['flightFrom'],
            date: newDate,
            time: lastJourney['time'],
            luggage: lastJourney['luggage'],
            passengers: lastJourney['passengers']
        }

        const addJourney = [...journeyData, newJourney]

        setJourneyData(addJourney)
        setValidateForm(false)
        setFormIsValid(false)
    }

    // Remove journey handler
    const removeJourneyHandler = (index) => {
        let removeJourney = []
        for (let i = 0; i < journeyData.length; i++) {
            if (i !== index) {
                removeJourney = [...removeJourney, journeyData[i]]
            }
        }
        setJourneyData(removeJourney)
    }

    // Reset journey handler
    const resetJourneyHandler = () => {
        setValidateForm(false)
        setFormIsValid(false)
        setJourneyCounter(1)
        window.scrollTo(0, 0)
        setResetFormRef(resetFormRef + 1)
        setJourneyData([{id: 1, passengers: 1, luggage: 1}])
    }

    const getTime = (time) => {
        if (location?.country !== 'US') {
            return time
        } else {
            // Format time
            const parseTime = parse(time, "hh:mmaa", new Date())
            return format(parseTime, "HH:mm", { awareOfUnicodeTokens: true })
        }
    }

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        if (inModal) {
            // Format journey data
            let formattedJourneyData = []
            journeyData.map(flight => {

                // Format date
                const parseDate = parse(flight['date'], "dd/MM/yyyy", new Date())
                const formatDate = format(parseDate, "yyyy-MM-dd", { awareOfUnicodeTokens: true })

                const addFlights = {
                    flightFrom: (flight.journeyType === 'helicopters') ? flight['flightFrom'] : flight['flightFrom']['icao'],
                    flightTo: (flight.journeyType === 'helicopters') ? flight['flightTo'] : flight['flightTo']['icao'],
                    date: formatDate,
                    time: getTime(flight['time']),
                    numberOfPassengers: flight['passengers'],
                    itemsOfLuggage: flight['luggage'],
                    journeyType: flight['journeyType']
                }
                formattedJourneyData = [...formattedJourneyData, addFlights]
                return false
            });

            // Set data
            let formData = {}
            if (authCtx.token && formIsValid) {
                formData = {
                    journeys: formattedJourneyData,
                    id: authCtx.id,
                    additionalRequests: requests,
                    tenantID: poweredByCtx.tenantID
                }

                // Post
                postData(formData, '/api/flight/search-flights-by-member', 1000)
            }

        } else {
            if (formIsValid) {
                openModal()
            } else {
                setShowError(true)
            }
        }
    }

    // Validate Form
    const validateFormHandler = () => {

        let errors = false

        journeyData.map((journey) => {

            let dateIsInPast = false

            if (journey.date) {
                const dateParts = journey.date.split('/')
                const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
                const today = new Date

                today.setHours(0, 0, -1)

                dateIsInPast = date < today
            }

            if (!journey.flightFrom) {
                errors ++
            }

            if (!journey.flightTo) {
                errors ++
            }

            if (!journey.date || dateIsInPast) {
                errors ++
            }

            if (!journey.time) {
                errors ++
            }
            return false
        })

        if (!errors) {
            setShowError(false)
            setFormIsValid(true)
        } else {
            setFormIsValid(false)
        }
    }

    // Effect
    useEffect(() => {
        validateFormHandler()
    })

    useEffect(() => {
        if (returnMessage.success) {
            onModalSuccess()
        }
    }, [returnMessage])

    useEffect(() => {
        if (typeof updateSending === 'function') {
            updateSending(sending)
        }
    }, [sending])

    useEffect(() => {
        const header = document.querySelector('.header')

        if (quoteOrBookingToCopy) {
            quoteOrBookingToCopy.legs.forEach((leg, i) => {
                if (i > 0) {
                    addJourneyHandler()
                }
            })
        }

        const resizeHandler = () => {
            checkOverflow()
        }

        const scrollHandler = (event) => {
            if (header && !inModal) {
                const active = (window.scrollY > offset(quoteQueryMarkerRef.current).top - header.clientHeight) && isBottomInViewport(quoteQueryRef.current)

                setIsScrolled(active)

                if (!active) {
                    setMobileIsActive(false)
                }

                if (window.matchMedia('(min-width: 765px)').matches) {
                    setQuoteQueryMarkerHeight(active ? quoteQueryRef.current.clientHeight : 0)
                } else {
                    // we hardcode this to whatever the height of the toggle button is, because we can't get the height dynamically because the button hasn't yet been added
                    setQuoteQueryMarkerHeight(active ? 417 : 0)
                }

                if (active) {
                    header.classList.add('header--quote-query-scrolled')
                } else {
                    header.classList.remove('header--quote-query-scrolled')
                }
            }
        };

        resizeHandler()
        window.addEventListener('resize', resizeHandler)
        window.addEventListener('scroll', scrollHandler, {
            passive: true
        })

        return () => {
            window.removeEventListener('resize', resizeHandler)
            window.removeEventListener('scroll', scrollHandler, {
                passive: true
            })
        }
    }, [])

    // Update input handler
    const updateInputHandler = (index, key, value) => {
        const originalJourney = journeyData[index]
        const updatedJourney = {...originalJourney, [key]: value}
        const addToJourneys = journeyData
        addToJourneys.splice(index, 1, updatedJourney)
        setJourneyData(addToJourneys)
        validateFormHandler()
    }

    const getCopiedProperty = (property, leg) => {
        if (quoteOrBookingToCopy) {
            let dataSource = quoteOrBookingToCopy
            let newLeg = true

            if (typeof leg === 'number' && dataSource.legs) {
                if (dataSource.legs.length > leg) {
                    dataSource = dataSource.legs[leg]
                    newLeg = false
                } else if (dataSource.legs.length === 0 && leg === 0) {
                    newLeg = false
                }
            }

            if (!newLeg) {
                if (property === 'flightFrom') {
                    return {
                        name: dataSource.flightFromLong,
                        city: '',
                        icao: dataSource.flightFromIcao
                    }
                }
                if (property === 'flightTo') {
                    return {
                        name: dataSource.flightToLong,
                        city: '',
                        icao: dataSource.flightToIcao
                    }
                }
                if (property === 'departureDate') {
                    const date = dataSource.departureDate.split('/')

                    return new Date(date[2], date[0] - 1, date[1])
                }
                if (property === 'departureTime') {
                    let time = dataSource.departureTime.split(':')
                    let date = new Date()
                    date.setHours(time[0], time[1], 0)
                    return date
                }
                if (property === 'passengers') {
                    const value = quoteOrBookingToCopy.passengers

                    return {
                        label: value,
                        value: value
                    }
                }
                if (property === 'luggage') {
                    const value = quoteOrBookingToCopy.luggage

                    return {
                        label: value,
                        value: value
                    }
                }
            }
        }
    }

    const getCopiedDate = (i) => {
        let copiedDate = getCopiedProperty('departureDate', i)

        if (!copiedDate && journeyData[i]['date']) {

            const date = journeyData[i]['date'].split('/')

            copiedDate = new Date(date[2], date[1] - 1, date[0])
        }

        return copiedDate
    }

    const getModalCloseButtonColor = () => {
        if (poweredByCtx.isPoweredBy && poweredByCtx.tenant.isDarkQuoteModalHeader) {
            return 'black'
        } else {
            return 'white'
        }
    }

    const toggleForMobile = () => {
        setMobileIsActive(!mobileIsActive)
    }

    const getAddIcon = () => {
        let name = 'add-leg'

        if (poweredByCtx.isPoweredBy) {
            if (onPage) {
                name += '-alt'
            }
            if (isScrolled) {
                name += '-scrolled'
            }
        }

        return name
    }

    const getDeleteIcon = () => {
        let name = 'remove-leg'

        if (poweredByCtx.isPoweredBy) {
            if (onPage) {
                name += '-alt'
            }
            if (isScrolled) {
                name += '-scrolled'
            }
        }

        return name
    }

    return (
        <>
            
            <div
                ref={quoteQueryMarkerRef2}
                className='quote-query-marker'
                style={{position: 'relative', top: -56}}
            />
            <div
                ref={quoteQueryMarkerRef}
                className='quote-query-marker'
                style={{height: quoteQueryMarkerHeight}}
            />
            <div
                ref={quoteQueryRef}
                className={getClassName(['quote-query', inModal ? 'quote-query--in-modal' : '', onPage ? 'quote-query--on-page' : '', isScrolled ? 'quote-query--scrolled': '', checkOverflow() ? 'quote-query--scrolled-overflow' : '', skeleton ? 'quote-query--skeleton' : ''])}
            > 
                {inModal && returnMessage.success
                    ?
                    <>
               
                        <QuoteQueryPersonalDetails
                            journeyData={journeyData}
                            resetJourney={resetJourneyHandler}
                            journeyIsValid={formIsValid}
                            closeModal={closeQuoteModal}
                            inModal={inModal}
                        />
                    </>
                    :
                    <div className={getClassName(['quote-query__content', isScrolled ? 'wrap': '', mobileIsActive ? 'quote-query__content--active' : ''])}>
                         {mobileIsActive &&
                             <div className="quote-query__sticky-title">Private Jets</div>
                         } 
                        <form onSubmit={submitHandler}>
                            {journeyData.map((journey, i) => {

                                return (
                                    <div key={journey.id} className={getClassName(['quote-query__inputs', (journeyData.length > 1) && 'quote-query__inputs--with-delete'])}>
                                        <div className='quote-query__inputs__column'>
                                            <FromToInput
                                                value={(fromValue) => updateInputHandler(i, 'flightFrom', fromValue)}
                                                defaultValue={journeyData[i]['flightFrom']}
                                                direction='from'
                                                label='From'
                                                required={true}
                                                errorOnBlur={false}
                                                altErrorStyle={true}
                                                validateForm={validateForm}
                                                onFocus={onFocus}
                                                copiedProperty={getCopiedProperty('flightFrom', i)}
                                                key={`from-${resetFormRef}`}
                                                skeleton={skeleton}
                                            />
                                        </div>
                                        <div className='quote-query__inputs__column'>
                                            <FromToInput
                                                value={(toValue) => updateInputHandler(i, 'flightTo', toValue)}
                                                defaultValue={journeyData[i]['flightTo']}
                                                direction='to'
                                                label='To'
                                                required={true}
                                                errorOnBlur={false}
                                                validateForm={validateForm}
                                                onFocus={onFocus}
                                                copiedProperty={getCopiedProperty('flightTo', i)}
                                                key={`to-${resetFormRef}`}
                                                skeleton={skeleton}
                                            />
                                        </div>
                                        <div className='quote-query__inputs__column quote-query__inputs__column--medium-width'>
                                            <DatePickerInput
                                                value={(dateValue) => updateInputHandler(i, 'date', dateValue)}
                                                defaultValue={journeyData[i]['date']}
                                                label='Date'
                                                required={true}
                                                errorOnBlur={false}
                                                validateForm={validateForm}
                                                onFocus={onFocus}
                                                copiedProperty={getCopiedDate(i)}
                                                inModal={inModal}
                                                key={`date-${resetFormRef}`}
                                                skeleton={skeleton}
                                            />
                                        </div>
                                        <div className='quote-query__inputs__column quote-query__inputs__column--medium-width'>
                                            <TimePickerInput
                                                is24Hour={location?.country !== 'US'}
                                                value={(timeValue) => updateInputHandler(i, 'time', timeValue)}
                                                defaultValue={journeyData[i]['time']}
                                                label='Time'
                                                required={true}
                                                errorOnBlur={false}
                                                validateForm={validateForm}
                                                onFocus={onFocus}
                                                copiedProperty={getCopiedProperty('departureTime', i)}
                                                key={`time-${resetFormRef}`}
                                                skeleton={skeleton}
                                            />
                                        </div>
                                        <div className='quote-query__inputs__column quote-query__inputs__column--shared'>
                                            <SelectInput
                                                icon={personIcon}
                                                defaultValue={journeyData[i]['passengers']}
                                                options={numbersList}
                                                value={(passengersValue) => updateInputHandler(i, 'passengers', passengersValue)}
                                                chevron={chevronDown}
                                                classNames={['select-input--flight-enquiry', 'select-input--full']}
                                                validateForm={validateForm}
                                                onFocus={onFocus}
                                                copiedProperty={getCopiedProperty('passengers')}
                                                key={`passengers-${resetFormRef}`}
                                                skeleton={skeleton}
                                            />
                                            <SelectInput
                                                icon={luggageIcon}
                                                defaultValue={journeyData[i]['luggage']}
                                                options={numbersList}
                                                value={(luggageValue) => updateInputHandler(i, 'luggage', luggageValue)}
                                                chevron={chevronDown}
                                                classNames={['select-input--flight-enquiry', 'select-input--full']}
                                                validateForm={validateForm}
                                                onFocus={onFocus}
                                                copiedProperty={getCopiedProperty('luggage')}
                                                key={`luggage-${resetFormRef}`}
                                                skeleton={skeleton}
                                            />
                                        </div>
                                        {inModal &&
                                            <TextAreaInput
                                                id='requests'
                                                label='Requests e.g. pets… (optional)'
                                                value={(value) => setRequests(value)}
                                                key={`requests-${resetFormRef}`}
                                            />
                                        }

                                        {(journeyData.length > 1) &&
                                            <button type="button" onClick={() => removeJourneyHandler(i)} className='quote-query__delete-button'>
                                                {inModal
                                                    ?
                                                        <img alt='' src={closeCrossDark} />
                                                    :
                                                        <>
                                                            {poweredByCtx.isPoweredBy
                                                                ?
                                                                    <>
                                                                        {getRawIcon('remove-leg')}
                                                                    </>
                                                                :
                                                                    <img alt='' src={getIcon(getDeleteIcon())}/>
                                                            }
                                                        </>
                                                }
                                                Delete
                                            </button>
                                        }

                                    </div>
                                )
                            })}
                        </form>

                        <div className='quote-query__actions'>
                            <button className='quote-query__actions__add-leg-button' onClick={addJourneyHandler}>
                                {inModal
                                    ?
                                        <img alt='' src={plusDarkIcon} />
                                    :
                                        <>
                                            {poweredByCtx.isPoweredBy
                                                ?
                                                    <>
                                                        {getRawIcon('add-leg')}
                                                    </>
                                                :
                                                    <img alt='' src={getIcon(getAddIcon())}/>
                                            }
                                        </>
                                }
                                Add leg / return
                            </button>

                            {showError &&
                                <div className='quote-query__error'><img src={exclamationIcon} alt='' />Please complete your journey details</div>
                            }

                            <div className='quote-query__submit-container'>
                                <Button onClick={submitHandler} classNames={['button--full']}>{inModal ? 'Submit quote' : 'Get a Quote'}</Button>
                                {inModal &&
                                    <Button onClick={closeQuoteModal} classNames={['button--secondary', 'button--light-border', 'button--full']}>Cancel</Button>
                                }
                            </div>
                        </div>
                    </div>
                }
                {isScrolled &&
                    <Button classNames={['quote-query__expand-button']} onClick={toggleForMobile}>
                        {poweredByCtx.isPoweredBy
                            ?
                                <>
                                    {getRawIcon('chevron-down-sticky-quote')}
                                </>
                            :
                                <img src={getIcon('chevron-down-sticky-quote')} alt='' />
                        }
                        {mobileIsActive ? 'Close quote' : 'Start a quote' }
                    </Button>
                }
            </div>

            {!inModal &&
                <Modal
                    modalVisible={modalVisible}
                    closeCrossColour={getModalCloseButtonColor()}
                    closeModal={closeModal}
                >
                    <QuoteQueryPersonalDetails
                        is24Hour={location?.country !== 'US'}
                        journeyData={journeyData}
                        resetJourney={resetJourneyHandler}
                        journeyIsValid={formIsValid}
                        closeModal={closeModal}
                    />
                </Modal>
            }
        </>
    )
}

export default QuoteQueryJets