import { useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import arrowIcon from '../assets/icons/arrow-right.svg'
import arrowGoldIcon from '../assets/icons/arrow-right-gold.svg'
import getClassName from '../utilities/getClassName'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getIcon } from '../utilities/icon'
import PoweredByContext from '../store/PoweredByContext'

const BlogArticlePreview = ({ data, scrollToArticle, inContentBlock, skeleton }) => {
  const articleRef = useRef(null)
  const poweredByCtx = useContext(PoweredByContext)


  const buildDate = (date) => {
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August','September', 'October', 'November', 'December'];

	const d = new Date(date)

	return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
  }

  useEffect(() => {
	if (scrollToArticle) {
	  window.scrollTo(0, articleRef.current.offsetTop)
	}
  }, [])

  const getAbstract = () => {
	const abstractLength = 200

	if (inContentBlock && data.abstract.length > abstractLength) {
	  return `${data.abstract.slice(0, abstractLength)}...`
	} else {
	  return data.abstract
	}
  }

  if (data) {
	return (
		<article
			className={getClassName([
				'blog-article-preview',
				inContentBlock ? 'blog-article-preview--in-content-block' : undefined,
				skeleton ? 'blog-article-preview--skeleton' : ''
			])}
			ref={articleRef}
		>
			<Link to={`/blog/${data.urlSlug}`}>
				<figure className='blog-article-preview__image'>
					{!skeleton &&
						<LazyLoadImage src={data.imageHolder.url} alt={data.imageHolder.alt} />
					}
				</figure>
				<div className='blog-article-preview__content'>
					<p className='blog-article-preview__date'>{buildDate(data.articleDate)}</p>
					<h2 className='blog-article-preview__title'>{data.title}</h2>
					<p className='blog-article-preview__intro'>{getAbstract()}</p>
					<p className='blog-article-preview__cta' >Read more <img src={inContentBlock ? getIcon('arrow-right-gold') : arrowIcon} alt='' /></p>
				</div>
			</Link>
		</article>
	)
  }
}

export default BlogArticlePreview
