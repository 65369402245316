import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import ReturnMessage from './ReturnMessage'
import useGet from '../hooks/useGet'
import ModalFormWrapper from './ModalFormWrapper'
import TextInput from './TextInput'
import Button from './Button'

const PaymentPinForm = ({ legId, srid, onValid }) => {

    const [validateForm, setValidateForm] = useState(false)
    const [validateUrl, setValidateUrl] = useState(false)
    const [paymentPin, setPaymentPin] = useState('')
    const [error, setError] = useState({})

    const navigate = useNavigate()

    useEffect(() => {
        if (!srid || (srid && srid.length === 0)) {
            navigate('/')
        }
    }, [])

    // Hooks
    const {loading, error: validationError, returnData} = useGet(validateUrl)

    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        // // Form complete
        if (paymentPin) {

            const formData = {
                shareRecipientId: srid,
                legId: legId,
                paymentPin: paymentPin
            }

            setValidateUrl(`/api/frontend/validate-shared-payment-pin?shareRecipientId=${srid}&legId=${legId}&paymentPin=${paymentPin}`)

            // Object { shareRecipientId: "9009d731-a79e-451d-bc55-495b5561de39", paymentPin: "836175" }

            // http://localhost:3000/shared-payment/card/e9bc5278-b528-4688-9047-e49da7de373e/LX-NST?srid=9009d731-a79e-451d-bc55-495b5561de39
        }
    }

    useEffect(() => {
        if (returnData.isValid === true) {
            onValid()
        } else if (returnData.isValid === false) {
            setError({
                message: 'The PIN you entered is invalid. Please try again.',
                error: 'invalid'
            })
        }
    }, [returnData])

    return <ModalFormWrapper title='Restricted access'>
        <div className='payment-pin-form'>
            <ReturnMessage inline={true} returnMessage={error} />
            <div className='payment-pin-form__content'>
                <p className='payment-pin-form__text'>Enter the PIN code given to you by the person who shared this with you to proceed.</p>

                <form onSubmit={submitHandler}>
                    <TextInput
                        id='payment-pin'
                        validateForm={validateForm}
                        label='PIN*'
                        required
                        disabled={loading}
                        value={(value) => setPaymentPin(value)}
                        marginBottom
                    />

                    <div className='payment-pin-form__button'>
                        <Button disabled={loading} classNames={['button--full']}>Submit</Button>
                    </div>

                </form>
            </div>
        </div>
    </ModalFormWrapper>
}

export default PaymentPinForm