import chevronDown from '../assets/icons/chevron-down.svg'
import getClassName from '../utilities/getClassName'

const MoreLessButton = ({onClick, selected}) => {

    return (
        <button className={getClassName(['more-less-button', selected && 'more-less-button--selected'])} onClick={onClick}>
            <img src={chevronDown} alt='' />
            {selected ? 'less' : 'more'}
        </button>
    )
}

export default MoreLessButton