import getClassName from '../utilities/getClassName'

const ModalFormWrapper = ({title, bigTitle, children}) => {

    return (
        <div className='modal-form-wrapper'>
            <div className={getClassName(['modal-form-wrapper__header', bigTitle && 'modal-form-wrapper__header--big-title'])}>{title}</div>
            <div className='modal-form-wrapper__body'>
                {children}
            </div>
        </div>
    )
}

export default ModalFormWrapper