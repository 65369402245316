import {useContext} from 'react'
import parse from 'html-react-parser'

import warningIcon from '../assets/icons/warning.svg'
import PoweredByContext from '../store/PoweredByContext'

const QuoteDashboardArticle = ({date, icon, iconName, title, warning, text, url, buttonUrl, cta, note, noteUrl}) => {

    // Hooks
    const poweredByCtx = useContext(PoweredByContext)

    cta = cta || 'Read more'

    if (poweredByCtx.isPoweredBy) {
        if (!poweredByCtx.tenant.showContactLinkInQuoteDashboard) {
            text = text.replace('<a href="mailto:quotes@shyaviation.com">here</a>', '')
        }
    }

    return (
        <article className='quote-dashboard-article'>
            <time className='quote-dashboard-article__date'>{date}</time>
            <div className='quote-dashboard-article__content'>
                <header className='quote-dashboard-article__header'>
                    {icon &&
                        <img src={icon} alt='' className={`quote-dashboard-article__icon quote-dashboard-article__icon--${iconName}`} />
                    }
                    {title &&
                        <h3 className='quote-dashboard-article__title'>{title}</h3>
                    }
                    {warning &&
                        <img src={warningIcon} alt='' />
                    }
                </header>
                {text &&
                    <div className='quote-dashboard-article__text'>
                        {parse(text)}
                    </div>
                }
                {url &&
                    <a href={url} className='quote-dashboard-article__link'>
                        {cta}
                    </a>
                }
                {buttonUrl &&
                    <a href={buttonUrl} className='button button--full button--gold button--small-text'>{cta}</a>
                }
                {note && noteUrl && title === 'Payment required' &&
                    <a href={noteUrl} className='quote-dashboard-article__note'>{note}</a>
                }
            </div>
        </article>
    )
}

export default QuoteDashboardArticle