import getClassName from '../utilities/getClassName'
import thumbUp from '../assets/icons/thumb-up.svg'
import thumbUpSelected from '../assets/icons/thumb-up-selected.svg'

const Thumb = ({status, updateStatus}) => {

    // const [thumbsStatus, setThumbsStatus] = useState(null)

    // useEffect(() => {
    //     if (status) {
    //         setThumbsStatus(status)
    //     }
    // }, [status])

    return (
        <div className='thumbs'>
            <button
                className={getClassName(['thumb', (status === true) && 'thumb--selected'])}
                onClick={() => updateStatus(status ? null : true)}
            >
                <span />
                {(status === true) ?
                    <img src={thumbUpSelected} alt='thumb up selected' />
                    :
                    <img src={thumbUp} alt='thumb up' />
                }
            </button>
        </div>
    )
}

export default Thumb