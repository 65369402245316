import parse from "date-fns/parse"

const daysFromDate = (startDate, endDate) => {

    // Set today
    const now = new Date()
    let date1 = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()))

    // If there's a start date, use that instead
    if(startDate)
    {
        date1 = new Date(Date.UTC(now.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate()))
    }

    // Set the end date to compare
    const date2 = parse(endDate, "yyyy-MM-dd", new Date())

    let diffDays
    if (
        date1.getTime() === date2.getTime() || // Dates are an exact match
        date1.getTime() - date2.getTime() === 3600000 // Dates are 1 hour apart
    ) {
        diffDays = 'Today'
    } else {
        const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
        diffDays = Math.round(Math.abs((date1 - date2) / oneDay))

        if (diffDays === 1) {
            diffDays = 'Tomorrow'
        } else {
            if (diffDays > 1) {
                diffDays = `in ${diffDays} days`
            }
        }
    }

    return diffDays
}

export default daysFromDate