import React, {useContext, useState} from 'react'
import {useEffect} from 'react'
import Form from './Form'
import Button from './Button'
import usePost from '../hooks/usePost'
import {Link} from 'react-router-dom'
import LoadingOverlay from './LoadingOverlay'
import AddPassengerSteps from './AddPassengerSteps'
import {capitalizeFirstLetter} from '../utilities/string'
import format from "date-fns/format"
import parse from "date-fns/parse"
import Checkbox from './Checkbox'
import authContext from '../store/AuthContext'

const PassengerSummaryForm = ({currentPassengerData, returnMessageHandler, allPassengersComplete, nextPassenger, pagePath, markCompleteHandler, managedSavedPassengers}) => {

    // State
    const [newSavedId, setNewSavedId] = useState(null)
    const [savePostCount, setSavePostCount] = useState(null)

    // Hooks
    const {sending, returnMessage, returnData, postData} = usePost()
    const authCtx = useContext(authContext)

    // Type passenger by default
    const type = currentPassengerData.travellerType || 'passenger'

    // Submit
    const submitHandler = (e) => {
        e.preventDefault()
    }

    // Format dob
    let formattedDob
    if (currentPassengerData.dob) {
        const parseDob = parse(currentPassengerData.dob, "yyyy-MM-dd", new Date())
        formattedDob = format(parseDob, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
    }

    // Format document expiry date
    let formattedDocumentExpiryDate
    if (currentPassengerData.documentExpiryDate) {
        const parseDocumentExpiryDate = parse(currentPassengerData.documentExpiryDate, "yyyy-MM-dd", new Date())
        formattedDocumentExpiryDate = format(parseDocumentExpiryDate, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
    }

    // Format vaccination date
    let formattedVaccinationDate
    if (currentPassengerData.vaccinationDate) {
        const parseVaccinationDate = parse(currentPassengerData.vaccinationDate, "yyyy-MM-dd", new Date())
        formattedVaccinationDate = format(parseVaccinationDate, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
    }

    // Save passenger details
    const saveUnsavePassengerHandler = (value) => {
        if (value) {
            let data = {
                paxId: currentPassengerData.id,
                memberId: parseInt(authCtx.id)
            }
            if (currentPassengerData.newId) {
                data = {...data, paxId: currentPassengerData.newId}
            }
            console.log("Save this passenger:")
            console.log(data)
            postData(data, '/api/frontend/upsert-savedpax-from-pax', 1000, 'save-passenger')
        } else {
            if (newSavedId) {
                let data = [{
                    savedPaxId: newSavedId,
                    memberId: parseInt(authCtx.id)
                }]
                if (currentPassengerData.newId) {
                    data = {...data, savedPaxId: currentPassengerData.newId}
                }
                console.log("Delete this passenger:")
                console.log(data)
                postData(data, '/api/frontend/delete-savedpax', 1000, 'delete-passenger')
            }
        }
    }

    // Send error message to parent component
    useEffect(() => {
        if (returnMessage.postRef === 'save-passenger') {
            if (returnMessage.count !== savePostCount) {
                if (returnMessage.success) {
                    returnMessageHandler({success: true, error: false, message: 'Passenger saved', count: returnMessage.count})
                    if(returnData && returnData.id) {
                        setNewSavedId(returnData.id)
                    }
                } else {
                    returnMessageHandler(returnMessage)
                }
                setSavePostCount(returnMessage.count)
            }
        }
    }, [savePostCount, returnData, returnMessage, returnMessageHandler])

    return (
        <div className='passenger-details-form wrap'>

            <div className='passenger-details-form__title'>{capitalizeFirstLetter(type)} Overview</div>

            <AddPassengerSteps step={4} />

            <div className='passenger-details-form__title passenger-details-form__title--tablet'>{capitalizeFirstLetter(type)} Overview</div>

            <div className='passenger-details-form__inner-container'>
                <dl className='passenger-details-form__data-list'>
                    {type === 'passenger' &&
                        <>
                            <dt>Given names</dt>
                            <dd>{currentPassengerData.firstName}</dd>
                            <dt>Last name</dt>
                            <dd>{currentPassengerData.surname}</dd>
                            <dt>Date of birth</dt>
                            <dd>{formattedDob}</dd>
                            <dt>Gender</dt>
                            <dd>{currentPassengerData.gender}</dd>
                            <dt>Nationality</dt>
                            <dd>{currentPassengerData.nationality}</dd>
                            <dt>Email</dt>
                            <dd>{currentPassengerData.email}</dd>
                            <dt>Phone number</dt>
                            <dd>{currentPassengerData.phoneNumber}</dd>
                            {currentPassengerData.hasOwnProperty('identityDocumentFile') ?
                                (currentPassengerData.identityDocumentFile && currentPassengerData.identityDocumentFile.hasOwnProperty('fileName')) ?
                                    <>
                                        <dt>Travel document uploaded</dt>
                                        <dd>{currentPassengerData.identityDocumentFile.fileName}</dd>
                                    </>
                                    : <>
                                        <dt>Document type</dt>
                                        <dd>{currentPassengerData.documentType}</dd>
                                        <dt>Document expiry date</dt>
                                        <dd>{formattedDocumentExpiryDate}</dd>
                                    </>
                                : null
                            }
                            <dt>Supporting documents</dt>
                            {(currentPassengerData.visaDocuments && currentPassengerData.visaDocuments.length) ?
                                <dd>
                                    {
                                        currentPassengerData.visaDocuments.map(visaDocument => {
                                            return (<React.Fragment key={visaDocument.attachmentId}>{visaDocument.fileName} <br/></React.Fragment>)
                                        })
                                    }
                                </dd>
                                :
                                <dd>None uploaded</dd>
                            }
                        </>
                    }
                    {type === 'pet' &&
                        <>
                            <dt>Given names</dt>
                            <dd>{currentPassengerData.firstName}</dd>
                            <dt>Date of birth</dt>
                            <dd>{formattedDob}</dd>
                            <dt>Species</dt>
                            <dd>{currentPassengerData.species}</dd>
                            <dt>Breed</dt>
                            <dd>{currentPassengerData.breed}</dd>
                            <dt>Microchip number</dt>
                            <dd>{currentPassengerData.microchipNumber}</dd>
                            {currentPassengerData?.species === 'Dog' &&
                                <>
                                    <dt>Latest rabies vaccination date</dt>
                                    <dd>{formattedVaccinationDate}</dd>
                                </>
                            }
                            {currentPassengerData.hasOwnProperty('identityDocumentFile') ?
                                (currentPassengerData.identityDocumentFile && currentPassengerData.identityDocumentFile.hasOwnProperty('fileName')) ?
                                    <>
                                        <dt>Travel document uploaded</dt>
                                        <dd>{currentPassengerData.identityDocumentFile.fileName}</dd>
                                    </>
                                    : <>
                                        <dt>Document type</dt>
                                        <dd>{currentPassengerData.documentType}</dd>
                                        <dt>Document expiry date</dt>
                                        <dd>{formattedDocumentExpiryDate}</dd>
                                    </>
                                : null
                            }
                        </>
                    }
                </dl>

                <Form onSubmit={submitHandler} classNames={['form--full']}>

                    {(!managedSavedPassengers && type === 'passenger') &&
                        <Checkbox value={(value) => saveUnsavePassengerHandler(value)} id='save_details'>{`Save ${(type === 'pet' ? 'pet' : '')} details for future use`}</Checkbox>
                    }

                    <div className='form-button-wrapper'>
                        {!managedSavedPassengers ?
                            <>
                                {allPassengersComplete ?
                                    <Button onClick={markCompleteHandler}>All done</Button>
                                    :
                                    <Link to={`/${pagePath}/add-passenger-details/${nextPassenger}`}><Button>Next Passenger</Button></Link>
                                }
                            </>
                            :
                            <Link to={`/${pagePath}`}><Button>Finish</Button></Link>
                        }

                        {!managedSavedPassengers &&
                            <Link to={`/${pagePath}/add-passenger-details/${currentPassengerData.id}`}><Button classNames={['button--white']}>Edit {type} details</Button></Link>
                        }

                    </div>
                </Form>
            </div>
            {sending && <LoadingOverlay />}

        </div>
    )
}

export default PassengerSummaryForm