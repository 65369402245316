import parse from 'html-react-parser'

import activeIcon from '../assets/icons/status-active.svg'
import inactiveIcon from '../assets/icons/status-inactive.svg'

const QuoteDashboardArticleAlt = ({title, text, url, button, cta, complete, current}) => {

    const getClassname = () => {
        let classNames = ['quote-dashboard-article-alt']

        if (complete) {
            classNames.push('quote-dashboard-article-alt--complete')
        }

        if (current) {
            classNames.push('quote-dashboard-article-alt--current')
        }

        return classNames.join(' ')
    }

    return (
        <article className={getClassname()}>
            <div className='quote-dashboard-article-alt__content'>
                <header className='quote-dashboard-article-alt__header'>
                    <div className='quote-dashboard-article-alt__status'>
                        {(complete || current) ?
                            <img src={activeIcon} alt='' />
                            :
                            <img src={inactiveIcon} alt='' />
                        }
                    </div>
                    {title &&
                        <h3 className='quote-dashboard-article-alt__title'>{title}</h3>
                    }
                </header>
                {text &&
                    <div className='quote-dashboard-article-alt__text'>
                        {parse(text)}
                    </div>
                }
                {url && cta &&
                    <a href={url} className={button ? 'button' : 'quote-dashboard-article-alt__link'}>
                        {cta}
                    </a>
                }
            </div>
        </article>
    )
}

export default QuoteDashboardArticleAlt