import {useContext, useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import useGet from '../hooks/useGet'
import BlogContentBlock from '../components/BlogContentBlock'
import backIcon from '../assets/icons/arrow-left-white.svg'
import previousIcon from '../assets/icons/chevron-left-gold.svg'
import nextIcon from '../assets/icons/chevron-right-gold.svg'
import SEO from '../components/SEO'
import NewsletterForm from '../components/NewsletterForm'
import poweredByContext from '../store/PoweredByContext'
import LazyLoadingContext from '../store/LazyLoadingContext'
import {getUrl} from '../utilities/url'
import QuoteQueryJets from '../components/QuoteQueryJets'
import getClassName from '../utilities/getClassName'

const BlogArticle = () => {
    const articleSlug = useParams().article
    const {error, returnData: article} = useGet(`/api/content/article/${articleSlug}`)

    const poweredByCtx = useContext(poweredByContext)
    const lazyLoadingCtx = useContext(LazyLoadingContext)

    useEffect(() => {

        return () => {
            lazyLoadingCtx.reset('blogArticle')
        }
    }, [])

    useEffect(() => {
        if (article !== false && article !== '') {
            lazyLoadingCtx.updatePageCheck('blogArticle', {
                article: true
            })
        }
    }, [article])

        // Function to dynamically create heading element
        const renderHeading = (title, tag, sub) => {
            const Tag = tag ? tag.toLowerCase() : (sub ? 'h2' : 'h1');
            if (sub) {
                return <Tag className='brochure__subtitle'>{title}</Tag>
            }
            return <Tag className='brochure__title blog-article__title'>{title}</Tag>
        }

    const buildDate = (date) => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

        const d = new Date(date)

        return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
    }

    const navigate = useNavigate()

    useEffect(() => {
        if (error) {
            navigate('/blog')
        }
    }, [error])

    return (
        <>
            {article &&
                <SEO
                    title={article.title}
                    description={article.metaDescription}
                    type='article'
                    image={article.imageHolder.url}
                    date={article.articleDate}
                    canonicalUrl={getUrl(poweredByCtx, window.location.pathname)}
                />
            }

            {(!(lazyLoadingCtx.pageReady && article !== '' && article !== false) || article?.stickyQuoteBool) && (
                <div className='wrap'>
                    <QuoteQueryJets onPage skeleton={!(lazyLoadingCtx.pageReady && article !== '' && article !== false)} />
                </div>
            )}

            <div className={getClassName(['blog-article', lazyLoadingCtx.pageReady && article ? '' : 'blog-article--skeleton'])}>
                {(lazyLoadingCtx.pageReady && article) ?
                    <>
                        <header className='blog-article__header'>
                            <p className='blog-article__date'>{buildDate(article.articleDate)}</p>
                            {renderHeading(article.title, article.headingTitle, false)}
                            <Link to='/blog' className='blog-article__back'><img src={backIcon} alt='' />All posts</Link>
                        </header>
                        
                        <figure className='blog-article__image'>
                            <img src={article.imageHolder.url} alt={article.imageHolder.alt} />
                        </figure>
                        
                        <div className='blog-article__content'>
                            {article.contentBlocks.map((data, index) => {
                                return <BlogContentBlock key={index} data={data} />
                            })}
                            <div className='blog-article__cta'>
                                <p>Do not hestiate to visit our online quote to obtain a price estimate for your next journey.</p>
                                <a href='/' className='button'>Request a quote</a>
                            </div>
                        </div>
                        
                        {(article.prev || article.next) && 
                            <footer className='blog-article__footer'>
                                <nav className='blog-article__navigation'>
                                    {article.prev && 
                                        <Link to={`/blog/${article.prev.urlSlug}`} className='blog-article__link blog-article__link--previous'>
                                            <img src={previousIcon} alt='' />
                                            Previous
                                            <small>{article.prev.title}</small>
                                        </Link>
                                    }

                                    {article.next && 
                                        <Link to={`/blog/${article.next.urlSlug}`} className='blog-article__link blog-article__link--next'>
                                            <img src={nextIcon} alt='' />
                                            Next
                                            <small>{article.next.title}</small>
                                        </Link>
                                    }
                                </nav>
                            </footer>
                        }
                    </>
                :
                    <>
                        <header className='blog-article__header'>
                            <p className='blog-article__date'>Article date</p>
                            <h1 className='blog-article__title'>Article title</h1>
                            <Link to='/blog' className='blog-article__back'><img src={backIcon} alt='' />All posts</Link>
                        </header>
                        
                        <figure className='blog-article__image'>
                        </figure>
                    </>
                }
            </div>

            <NewsletterForm />
        </>
    )
}

export default BlogArticle