import {useEffect, useState} from 'react'
import getClassName from '../utilities/getClassName'

const Switch = ({disabled, classNames, value, callback, isOn = false}) => {

    // State
    const [on, setOn] = useState(isOn)

    // Toggle handler
    const toggleHandler = () => {
        value(!on)
        setOn(!on)

        if (callback) {
            callback(!on)
        }
    }

    let className = ['switch']
    if (typeof classNames === 'object') {
        className = className.concat(classNames)
    }
    if (on) {
        className = className.concat(['switch--on'])
    }

    useEffect(() => {
        if (isOn) {
            setOn(true)
        }
    }, [isOn])

    return (
        <button type='button' disabled={disabled} onClick={toggleHandler} className={getClassName(className)}>
            <span className='switch__knob' />
        </button>
    )
}

export default Switch