import TextInput from './TextInput'
import Button from './Button'
import {useContext, useState} from 'react'
import usePost from '../hooks/usePost'
import LoadingOverlay from './LoadingOverlay'
import recoverPasswordIcon from '../assets/icons/recover-password.svg'
import poweredByContext from '../store/PoweredByContext'

const ResetPasswordForm = ({returnToLogin}) => {

    // State
    const [email, setEmail] = useState('')
    const [validateForm, setValidateForm] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)

    // Hooks
    const {sending, postData} = usePost()
    const poweredByCtx = useContext(poweredByContext)

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        // Form complete
        if (email) {

            const formData = {
                email: email,
                tenantID: poweredByCtx.tenantID
            }

            setFormSubmitted(true)

            // Post
            postData(formData,'/api/auth/send-reset-password-email', 500)
        }
    }

    return (
        <>
            {/* Reset password */}
            {(formSubmitted && !sending) ?
                <div className='login-form'>

                    <div className='login-form__body login-form__body--less-padding'>

                        <img src={recoverPasswordIcon} alt='' className='login-form__body__recover-password-icon' />

                        <div className='login-form__body__recover-password-title'>
                            Recover your password
                        </div>

                        <div className='login-form__body__recover-password-text'>
                            <p>
                                Instructions to reset your password have been sent to:<br />
                                <b>{email}</b>
                            </p>
                            <p>
                                If you didn't receive them, please <a href='mailto: support@shyaviation.com'>contact us</a>.
                            </p>
                        </div>

                        <button onClick={() => returnToLogin()} className='login-form__body__back-to-login'>Back to login</button>
                    </div>
                </div>
                :
                <div className='login-form'>

                    <div className='login-form__body'>

                        <div className='login-form__body__title'>
                            Recover Password
                        </div>
                        <div className='login-form__body__intro'>
                            We'll send you an email with a link so you can reset your password.
                        </div>

                        <form onSubmit={submitHandler}>

                            <TextInput
                                id='email'
                                validateForm={validateForm}
                                label='Email*'
                                required
                                disabled={sending}
                                value={(value) => setEmail(value)}
                                marginBottom
                                validation='email'
                            />

                            <div className='login-form__body__submit-container'>
                                <Button disabled={sending} classNames={['button--full']}>Send</Button>
                            </div>

                        </form>

                        {sending && <LoadingOverlay />}

                    </div>
                </div>
            }
        </>
    )
}

export default ResetPasswordForm