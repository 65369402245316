import {useContext, useEffect, useState} from 'react'
import Button from './Button'
import usePost from '../hooks/usePost'
import {Link, useNavigate} from 'react-router-dom'
import LoadingOverlay from './LoadingOverlay'
import AddPassengerSteps from './AddPassengerSteps'
import pawIcon from '../assets/icons/paw.svg'
import FileUploadInput from './FileUploadInput'
import authContext from '../store/AuthContext'

const PassengerVisaForm = ({currentPassengerData, addPassengerDetailsHandler, returnMessageHandler, legId, managedSavedPassengers, pagePath}) => {

    // State
    const [uploadMoreButton, setUploadMoreButton] = useState(false)
    const [dataSentCounter, setDataSentCounter] = useState(null)
    const [passengerDataToPost, setPassengerDataToPost] = useState(null)

    // Hooks
    const {sending, returnMessage, returnData, postData} = usePost()
    const authCtx = useContext(authContext)
    const navigate = useNavigate()

    // Type passenger by default
    const type = currentPassengerData.travellerType || 'passenger'

    // Do nothing on submit
    const submitHandler = (e) => {
        e.preventDefault()
    }

    // Remove file from server
    const fileRemoveHandler = (attachmentId) => {
        if (currentPassengerData.visaDocuments) {
            let passengerId = currentPassengerData.newId ? currentPassengerData.newId : currentPassengerData.id
            const removeData = {
                paxId: passengerId,
                attachmentId: attachmentId
            }
            console.log("Remove visa doc from server", removeData)

            // Set endpoint (different for delete saved pax)
            let apiDeletePaxDoc = '/api/frontend/delete-pax-entry-doc'
            if (managedSavedPassengers) {
                apiDeletePaxDoc = `/api/frontend/delete-savedpax-entry-doc`
            }

            postData(removeData, apiDeletePaxDoc, 100)

            // Delete from local object
            const updatedVisaDocuments = currentPassengerData.visaDocuments.filter(visaDocuments => visaDocuments.attachmentId !== attachmentId)
            const updatedPassenger = {...currentPassengerData, ...{visaDocuments: updatedVisaDocuments}}
            addPassengerDetailsHandler(updatedPassenger)
        }
    }

    // Upload file
    const uploadFile = (file) => {
        if (file) {
            const headers = {
                'content-type': 'multipart/form-data'
            }

            let passengerId = currentPassengerData.newId ? currentPassengerData.newId : currentPassengerData.id
            let formData = new FormData()
            formData.append('visaFile', file)
            formData.append('paxId', passengerId)
            formData.append('legId', legId)
            console.log("Post passenger entry doc file")

            // Set endpoint (different for upload to saved pax)
            let apiUploadPaxDoc = '/api/frontend/upload-pax-entry-doc'
            if (managedSavedPassengers) {
                apiUploadPaxDoc = '/api/frontend/upload-savedpax-entry-doc'
            }
            postData(formData, apiUploadPaxDoc, 1000, 'post-visa', headers)
        }
    }

    // Mark passenger as complete
    const markPassengerCompleteHandler = () => {

        // Set data
        const formData = {
            informationComplete: true
        }

        let updatedPassenger = {...currentPassengerData, ...formData}
        setPassengerDataToPost(formData)
        if (currentPassengerData.newId) {
            updatedPassenger = {...updatedPassenger, id: currentPassengerData.newId}
        }

        if (currentPassengerData.travellerType === 'pet') {
            console.log("Post pet complete status", updatedPassenger)
            postData(updatedPassenger, '/api/frontend/update-pet', 1000, 'allComplete')
        } else {
            console.log("Post passenger complete status", updatedPassenger)

            // Set endpoint (different for update saved pax)
            let apiUpdatePassenger = '/api/frontend/update-passenger'
            if (managedSavedPassengers) {
                apiUpdatePassenger = `/api/frontend/update-savedpax?memberId=${authCtx.id}`
            }

            postData(updatedPassenger, apiUpdatePassenger, 1000, 'allComplete')
        }
    }

    // Use effect
    useEffect(() => {

        // Success (check it's a new post and not an old one)
        if (returnMessage.count !== dataSentCounter) {

            // Update local visaDocuments data
            if (returnData && returnData.message === 'Document uploaded') {
                const updatedPassenger = {...currentPassengerData, ...{visaDocuments: returnData.data.doc}}
                addPassengerDetailsHandler(updatedPassenger)
                setDataSentCounter(returnMessage.count)
            }
        }

        // Pass return message up the tree after success
        if (returnData && returnMessage.postRef === 'allComplete') {
            addPassengerDetailsHandler(passengerDataToPost)
            returnMessageHandler(returnMessage)
            navigate(`/${pagePath}/add-passenger-summary/${currentPassengerData.id}`)
        }

        // Show Upload More button
        if(currentPassengerData.hasOwnProperty('visaDocuments')) {
            if (currentPassengerData.visaDocuments) {
                if (currentPassengerData.visaDocuments.length === 0 || currentPassengerData.visaDocuments.length <= 4) {
                    setUploadMoreButton(true)
                } else {
                    setUploadMoreButton(false)
                }
            } else {
                setUploadMoreButton(true)
            }
        }

    }, [addPassengerDetailsHandler, currentPassengerData, dataSentCounter, navigate, pagePath, passengerDataToPost, returnData, returnMessage, returnMessageHandler])

    return (
        <div className='passenger-details-form wrap'>

            <div className='passenger-details-form__title'>Entry Requirements</div>

            <AddPassengerSteps step={3} />

            <div className='passenger-details-form__title passenger-details-form__title--tablet'>{type === 'pet' ? 'Pet travel requirements' : 'Entry Requirements'}</div>

            <div className='passenger-details-form__instruction'>
                <p>Check your local government website for more information about your travel destination countries latest entry requirements.</p>
            </div>

            <div className='passenger-details-form__or'><span />Supporting Documents<span /></div>

            <form onSubmit={submitHandler}>

                {type === 'passenger' &&
                    <div className='passenger-details-form__inner-container passenger-details-form__inner-container--wide'>
                        <div className='passenger-details-form__flex-container-intro'>
                            <h3>Upload supporting documents <span>(optional)</span></h3>
                            <p>Include any documents in order to satisfy immigration rules and entry requirements.</p>
                        </div>

                        {currentPassengerData.visaDocuments &&
                            currentPassengerData.visaDocuments.map(visaDoc => {
                                return (
                                    <div key={visaDoc.attachmentId} className='passenger-details-form__visa-row'>
                                        <FileUploadInput
                                            required
                                            acceptedFileTypes={["JPG", "PNG", "PDF"]}
                                            value={(file) => {uploadFile(file)}}
                                            defaultValue={visaDoc.fileName}
                                            fileRemoveHandler={() => fileRemoveHandler(visaDoc.attachmentId)}
                                            classNames={['file-upload-input--inline']}
                                            overwriteText={'Add supporting document (optional)'}
                                        />
                                    </div>
                                )
                            })
                        }
                        {uploadMoreButton &&
                            <FileUploadInput
                                key={dataSentCounter}
                                required
                                acceptedFileTypes={["JPG", "PNG", "PDF"]}
                                value={(file) => {uploadFile(file)}}
                                classNames={['file-upload-input--inline']}
                                overwriteText={'Add supporting document (optional)'}
                            />
                        }
                    </div>
                }
            </form>

            {type === 'pet' &&
                <div className='passenger-details-form__checklist'>
                    <div className='passenger-details-form__checklist-container'>
                        <p>Based on the information you’ve given us:</p>
                        <ul>
                            <li>
                                <img src={pawIcon} alt='' />
                                <h4>Identification document</h4>
                                <p>An animal health certificate has been provided. It'll be checked to see if everything is in order.</p>
                            </li>
                            <li>
                                <img src={pawIcon} alt='' />
                                <h4>Microchip</h4>
                                <p>A microchip number has been provided.</p>
                            </li>
                            {currentPassengerData?.species === 'Dog' &&
                                <li>
                                    <img src={pawIcon} alt='' />
                                    <h4>Vaccinations</h4>
                                    <p>Your pet is up to date with their rabies vaccinations.
                                        Check with the destination country for any schedule limitations</p>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            }

            <div className='form-button-wrapper'>
                <Button onClick={markPassengerCompleteHandler}>Next</Button>
                {managedSavedPassengers ?
                    <Link to={`/manage-passengers`}><Button classNames={['button--white']}>Cancel</Button></Link>
                    :
                    <Link to={`/add-passengers-to-booking/${legId}`}><Button classNames={['button--white']}>Cancel</Button></Link>
                }
            </div>
            {sending && <LoadingOverlay />}

        </div>
    )
}

export default PassengerVisaForm