import icon from '../assets/icons/arrow-left.svg'
import {Link} from 'react-router-dom'

const TitleBar = ({url, cta, title, children, clickOverride}) => {
    cta = cta || 'Back'

    if (url || document.referrer) {
        return (
            <section className='title-bar'>
                <Link to={url || document.referrer} className='title-bar__link' onClick={clickOverride ? clickOverride : null}>
                    <img src={icon} alt=''/>
                    <span className='title-bar__link__text'>{cta}</span>
                </Link>
                <div className='title-bar__title-tabs-wrapper'>
                    {children ? children : <h1 className='title-bar__title'>{title}</h1>}
                </div>
            </section>
        )
    }
}

export default TitleBar