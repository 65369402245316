import SelectInput from './SelectInput'
import {timeOfDayList} from '../data/timeOfDayList'
import TextInput from './TextInput'
import Button from './Button'
import {useEffect, useState} from 'react'
import usePost from '../hooks/usePost'
import Form from './Form'
import ReturnMessage from './ReturnMessage'
import LoadingOverlay from './LoadingOverlay'
import tick from '../assets/icons/tick-big.svg'

const RequestCallForm = () => {

    // State
    const [validateForm, setValidateForm] = useState(false)
    const [fullName, setFullName] = useState('')
    const [phone, setPhone] = useState('')
    const [idealContactTime, setIdealContactTime] = useState('')
    const [reasonForContact, setReasonForContact] = useState('contact')
    const [formSent, setFormSent] = useState(false)

    // Hooks
    const {sending, returnMessage, postData} = usePost()

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        // Form complete
        if (fullName && phone && idealContactTime && reasonForContact) {

            // Set data
            let formData = {
                fullName: fullName,
                phoneNumber: phone,
                idealContactTime: idealContactTime,
                reasonForContact: reasonForContact,
            }

            // Post
            postData(formData, '/api/flight/send-callback-request', 1000)
        }
    }

    useEffect(() => {
        if (returnMessage.success) {
            setFormSent(true)
        }
    }, [returnMessage.success])

    return (
        <>
            <ReturnMessage inline={true} returnMessage={returnMessage} />

            {!formSent ?
                <Form onSubmit={submitHandler} classNames={['form--full']}>

                    <TextInput
                        id='full-name'
                        validateForm={validateForm}
                        label='Full Name'
                        required
                        disabled={sending}
                        value={(value) => setFullName(value)}
                    />

                    <TextInput
                        id='phone'
                        validateForm={validateForm}
                        label={'Phone number'}
                        required
                        disabled={sending}
                        value={(value) => setPhone(value)}
                    />

                    <SelectInput
                        validateForm={validateForm}
                        classNames={['select-input--form', 'select-input--full']}
                        options={timeOfDayList}
                        label='Ideal contact time'
                        required
                        disabled={sending}
                        placeholder='Please Select...'
                        value={(value) => setIdealContactTime(value)}
                    />

                    {/*<SelectInput*/}
                    {/*    validateForm={validateForm}*/}
                    {/*    classNames={['select-input--form', 'select-input--full']}*/}
                    {/*    options={reasonForContactList}*/}
                    {/*    label='Reason for contact'*/}
                    {/*    required*/}
                    {/*    menuPlacement='top'*/}
                    {/*    disabled={sending}*/}
                    {/*    placeholder='Please Select...'*/}
                    {/*    value={(value) => setReasonForContact(value)}*/}
                    {/*/>*/}

                    <Button disabled={sending} classNames={['button--full']}>Request a call</Button>

                    {sending &&
                        <LoadingOverlay />
                    }
                </Form>
                :
                <div className='request-call-form'>
                    <img src={tick} alt=''/>
                    <div className='request-call-form__title'>Thank you!</div>
                    <div className='request-call-form__body'>A SHY Agent will get back to you during our business hours of 8am to 10pm within the hour. If you contact us after 10pm, we will get back to you by 9am the following day.</div>
                </div>
            }
        </>

    )
}

export default RequestCallForm