import SelectInput from '../../components/SelectInput'
import TextInput from '../../components/TextInput'
import TitleBar from '../../components/TitleBar'
import {titleList} from '../../data/titleList'
import {countryList} from '../../data/countryList'
import {useContext, useEffect, useState} from 'react'
import Button from '../../components/Button'
import usePost from '../../hooks/usePost'
import LoadingOverlay from '../../components/LoadingOverlay'
import ReturnMessage from '../../components/ReturnMessage'
import Form from '../../components/Form'
import authContext from '../../store/AuthContext'
import poweredByContext from '../../store/PoweredByContext'
// import cameraIcon from '../../assets/icons/camera.svg'
import SEO from '../../components/SEO'
import useGet from '../../hooks/useGet'

const YourProfile = () => {

    // State
    const [validateForm, setValidateForm] = useState(false)
    const [title, setTitle] = useState(titleList[0]['value'])
    const [companyName, setCompanyName] = useState('')
    const [position, setPosition] = useState('')
    const [location, setLocation] = useState('')
    const [phone, setPhone] = useState('')

    // Hooks
    const {sending, returnMessage, postData} = usePost()
    const authCtx = useContext(authContext)
    const poweredByCtx = useContext(poweredByContext)

    // Get member details
    const {loading, returnData: memberData} = useGet(`/api/member/get-member?MemberId=${authCtx.id}&TenantID=${poweredByCtx.tenantID}`, 800)

    // Update member details state on load
    useEffect(() => {
        if (memberData) {
            setTitle(memberData.title)
            setCompanyName(memberData.companyName)
            setPosition(memberData.position)
            setLocation(memberData.country)

            let phoneNumber = memberData.phoneNumber

            if (phoneNumber.indexOf('+') === -1) {
                phoneNumber = `+${phoneNumber}`
            }

            setPhone(phoneNumber)
        }
    }, [memberData])

    // Build user initials
    function getFirstCharacters(str) {
        try {
            let result = []
            str.split(' ').map(word => word.charAt(0) !== '' ? result.push(word.charAt(0) + '.') : '')
            return result;
        }
        catch(err) {
            console.log(err)
        }
    }
    const initials = getFirstCharacters(authCtx.name)

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        // Form complete
        if (phone) {

            // Set data
            let formData = {
                memberId: authCtx.id,
                title: title,
                company: companyName,
                position: position,
                country: location,
                phone: phone
            }

            // Post
            postData(formData, '/api/member/update-member', 1000, null, false, false, true)
        }
    }

    return (
        <>
            <SEO title='Your Details' />
            <TitleBar title='Your Details' cta='Home' url='/' />
            <ReturnMessage returnMessage={returnMessage} />

            <div className='page'>
                <div className='wrap'>
                    {loading ? <LoadingOverlay /> :
                        <div className='profile'>

                            <div className='profile__picture'>
                                <div className='profile__picture__text'>{initials}</div>
                                {/*<button disabled={sending} className='profile__picture__icon'><img src={cameraIcon} alt='Edit Profile Picture' /></button>*/}
                            </div>

                            <Form onSubmit={submitHandler}>
                                <SelectInput
                                    validateForm={validateForm}
                                    classNames={['select-input--form']}
                                    placeholder={title}
                                    options={titleList}
                                    label='Title'
                                    defaultValue={title}
                                    disabled={sending}
                                    value={(value) => setTitle(value)}
                                    allowChange={true}
                                />

                                <TextInput
                                    id='full-name'
                                    classNames={['']}
                                    validateForm={validateForm}
                                    label='Full Name'
                                    required
                                    defaultValue={`${memberData.firstName} ${memberData.lastName}`}
                                    disabled={true}
                                />

                                <TextInput
                                    id='company-name'
                                    validateForm={validateForm}
                                    label={'Company Name'}
                                    defaultValue={companyName}
                                    disabled={sending}
                                    value={(value) => setCompanyName(value)}
                                />

                                <TextInput
                                    id='position'
                                    validateForm={validateForm}
                                    label={'Position'}
                                    defaultValue={position}
                                    disabled={sending}
                                    value={(value) => setPosition(value)}
                                />

                                <SelectInput
                                    validateForm={validateForm}
                                    classNames={['select-input--form', 'select-input--full']}
                                    options={countryList}
                                    label='Location'
                                    defaultValue={location}
                                    disabled={sending}
                                    value={(value) => setLocation(value)}
                                />

                                <TextInput
                                    id='phone'
                                    validateForm={validateForm}
                                    label={'Phone number'}
                                    required
                                    defaultValue={phone}
                                    disabled={sending}
                                    value={(value) => setPhone(value)}
                                />

                                <TextInput
                                    id='email'
                                    validateForm={validateForm}
                                    label={'Email'}
                                    defaultValue={memberData.email}
                                    disabled={true}
                                />

                                <div className='profile__submit-button-container'>
                                    <Button disabled={sending} classNames={['button--full']}>Save Changes</Button>
                                </div>

                            </Form>

                            {sending && <LoadingOverlay />}
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default YourProfile