import React from 'react'
import PaymentAmount from './PaymentAmount'
import Button from './Button'
import {Link} from 'react-router-dom'
import LoadingOverlay from './LoadingOverlay'
import {useEffect, useState} from 'react'
import {NumericFormat} from 'react-number-format'
import Switch from './Switch'
import getDenomination from '../utilities/getDenomination'
import usePost from '../hooks/usePost'
import useGet from '../hooks/useGet'

const BankTransferInstructions = ({legId, totalOwed, depositData, balance, currency, paymentType, returnMessageHandler, paymentRef, paymentCompleteHandler, subInvoiceNumber}) => {

    // State
    const [baseAmount, setBaseAmount] = useState(totalOwed)
    const [amount, setAmount] = useState(totalOwed)
    const [creditAmount, setCreditAmount] = useState(0)
    const [useCredit, setUseCredit] = useState(false)
    const [additionalInstruction, setAdditionalInstruction] = useState()
    const [transferInstructionsUrl, setTransferInstructionsUrl] = useState(null)
    const [paymentCompleteData, setPaymentCompleteData] = useState()

    // Hooks
    const {sending, returnMessage, postData} = usePost()

    //Get transfer instructions
    const {loading: transferInstructionsLoading, error: transferInstructionsError, returnData: transferInstructions} = useGet(transferInstructionsUrl, 1200)

    // Set denomination
    const denomination = getDenomination(currency)

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()

        // Payment data
        let paymentData = {
            paymentType: paymentType,
            paymentMethod: 'bank-transfer',
            currency: currency,
            baseAmount: baseAmount,
            creditAmount: creditAmount,
            totalAmount: amount,
            useCredit: useCredit,
            legId: legId,
            subInvoiceNumber: subInvoiceNumber,
            invoiceReference: paymentRef
        }

        // Post
        postData(paymentData, '/api/frontend/payment-bank-xfer/', 1000)
    }

    // Use Effect
    useEffect(()=> {

        // Fetch transfer instructions info...
        setTransferInstructionsUrl(`/api/frontend/bank-details?currencyCode=${currency}`)

        setAdditionalInstruction(null)
        setBaseAmount(depositData.depositToPay ? depositData.depositToPay : totalOwed)
        setAmount(depositData.depositToPay ? depositData.depositToPay : totalOwed)

        // Use credit
        if (useCredit) {
            setAdditionalInstruction(`(${denomination}${totalOwed} - ${denomination}${balance} balance)`)
            setAmount(((depositData.depositToPay ? depositData.depositToPay : totalOwed) - balance))
            setCreditAmount(balance)
        } else {
            setCreditAmount(0)
        }

        // Payment successful
        if (returnMessage.success) {
            const successData = {
                value: amount,
                paymentDate: new Date(),
            }
            setPaymentCompleteData(successData)
        }

    }, [amount, balance, currency, denomination, depositData.depositToPay, returnMessage.success, totalOwed, useCredit])

    // Send error and success data to parent component
    useEffect(() => {
        returnMessageHandler(returnMessage)
        paymentCompleteHandler(paymentCompleteData)
    }, [paymentCompleteData, paymentCompleteHandler, returnMessage, returnMessageHandler])

    return (
        <div className='bank-transfer-instructions wrap'>

            <PaymentAmount
                instruction={(paymentType === 'deposit') ? 'Deposit Amount' : 'Payment Amount'}
                totalOwed={amount}
                additionalInstruction={additionalInstruction}
                depositData={depositData}
                currency={currency}
                paymentType={paymentType}
            />

            {(!!balance && (balance > 0) && (balance < totalOwed)) &&
                <div className='use-credit-to-pay__credit-amount--partial-pay'>
                    <div>Use your credit of <b>
                        {denomination}
                        <NumericFormat
                            displayType='text'
                            value={balance}
                            thousandSeparator
                        />
                    </b>
                    </div>
                    <Switch
                        value={() => setUseCredit(!useCredit)}
                    />
                </div>
            }

            <div className='bank-transfer-instructions__intro'>
                Send the indicated amount to the bank account below
            </div>

            <div className='bank-transfer-instructions__grid'>
                {!transferInstructions ? <LoadingOverlay /> :
                    <>
                        {paymentRef &&
                            <>
                                <div>Reference:</div>
                                <b>{paymentRef}</b>
                            </>
                        }
                        <>
                            <div>Name:</div>
                            <b>SHY AVIATION LTD</b>
                            <div>Address:</div>
                            <b>233 High Holborn, London WC1V 7DN, United Kingdom</b>
                        </>
                        {
                            transferInstructions.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div>{item.key}:</div>
                                        <b>{item.value}</b>
                                    </React.Fragment>
                                )
                            })
                        }
                    </>
                }
            </div>

            <div className='form-button-wrapper'>
                <Button onClick={submitHandler}>Confirm Payment</Button>
                <Link to={`/quotes-and-bookings/quotes`}><Button classNames={['button--white']}>Back to Quotes</Button></Link>
            </div>

            {sending &&
                <LoadingOverlay />
            }
        </div>
    )

}

export default BankTransferInstructions