import TitleBar from '../../components/TitleBar'
import pendingIcon from '../../assets/icons/tick-big.svg'
import Button from '../../components/Button'
import {Link, useNavigate} from 'react-router-dom'
import {useContext, useEffect} from 'react'
import {useLocation} from 'react-router'
import SEO from '../../components/SEO'
import poweredByContext from '../../store/PoweredByContext'
import {replaceShyName} from '../../utilities/string'

const BookingSuccess = () => {

    // Hooks
    const location = useLocation()
    const navigate = useNavigate()
    const poweredByCtx = useContext(poweredByContext)

    // Use effect
    useEffect(() => {
        if (location.state === null) {
            navigate('/quotes-and-bookings/quotes')
        }
    })

    // Location state variables
    let legId

    if (location.state) {
        legId = location.state.legId
    }

    const replaceName = (str) => {
        return replaceShyName(str, poweredByCtx.isPoweredBy, poweredByCtx?.tenant?.tenantName)
    }

    return (
        <>
            <SEO title='Booking Status' />
            <TitleBar title='Booking Status' cta='Quotes' url={`/quotes-and-bookings/quotes`}/>
            <div className='page'>
                <div className='wrap'>
                    <article className='big-message'>
                        <div className='big-message__container'>
                            <figure className='big-message__image'>
                                <img src={pendingIcon} alt='' />
                            </figure>
                            <h1 className='big-message__title'>Option successfully selected</h1>
                            <div className='big-message__intro'>
                                <p>{replaceName('Thank you for your reservation with SHY Aviation')}</p>
                            </div>
                            <div className='big-message__text'>
                                <p>Your aviation advisor will now confirm availability of your selected aircraft and send you your contract for signature.</p>
                                <p>Please note that until requested funds have been received the reservation will not become a confirmed booking.</p>
                            </div>
                        </div>
                        <div className='form-button-wrapper wrap'>
                            <Link to={`/quotes-and-bookings/quotes`}><Button>Back to Quotes</Button></Link>
                            <Link to='/'><Button classNames={['button--white']}>Home</Button></Link>
                        </div>
                    </article>
                </div>
            </div>
        </>
    )
}

export default BookingSuccess