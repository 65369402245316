import {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import getClassName from '../../utilities/getClassName'
import useGet from '../../hooks/useGet'
import authContext from '../../store/AuthContext'
import TitleBar from '../../components/TitleBar'
import SharePaymentMethod from '../../components/SharePaymentMethod'
import SharePaymentSuccess from '../../components/SharePaymentSuccess'
import SEO from '../../components/SEO'
import ErrorModal from '../../components/ErrorModal'

const SharePayment = () => {

    // Use parameters
    const params = useParams()
    const legId = params.legId
    const tailNum = params.tailNum

    const {loading: pinLoading, error: pinError, returnData: pinData} = useGet('/api/frontend/shared-payment-pin')

    const authCtx = useContext(authContext)
    const [shareData, setShareData] = useState(null)
    const [paymentPin, setPaymentPin] = useState(null)
    const [error, setError] = useState(false)

    const shareSuccess = (data) => {
        setShareData(data)
    }

    let className = ['share-payment']

    if (shareData) {
        className.push(`share-payment--success`)
    }

    useEffect(() => {
        setPaymentPin(pinData.paymentPin)
    }, [pinData])

    useEffect(() => {
        if (pinError) {
            setError('Shared payments are currently unavailable.')
        }
    }, [pinError])

    return (
        <>
            <SEO title='Share Payment' />
            <TitleBar url='/' cta='Dashboard' title='Share Payment' />

            {
                error
                ?
                    <ErrorModal errorMessage={error} />
                :
                    <div className='page page--flush'>
                        <div className='wrap'>
                            <section className={getClassName(className)}>
                                {shareData &&
                                    <SharePaymentSuccess
                                        data={shareData}
                                    />
                                }
                                {!shareData &&
                                    <>
                                        <SharePaymentMethod shareMethod='email' id={authCtx.id} legId={legId} tailNum={tailNum} paymentPin={paymentPin} onShare={shareSuccess} />
                                    </>
                                }
                            </section>
                        </div>
                    </div>
            }
        </>
    )
}

export default SharePayment