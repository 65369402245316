import React, {useState} from 'react'

const UserContext = React.createContext({
    setUser: null,
    id: null,
    name: null,
    email: null,
    age: null
})

export const UserContextProvider = (props) => {

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [age, setAge] = useState('')

    const setUserHandler = (id, name, email, age) => {
        setId(id)
        setName(name)
        setEmail(email)
        setAge(age)
    }

    const user = {
        setUser: setUserHandler,
        id: id,
        name: name,
        email: email,
        age: age,
    }

    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContext