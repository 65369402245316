// This component checks for a valid auth token and shows / hides content.
// It also looks for a sign-up string and shows the sign-up form.
// It also checks if a user is already signed up and shows the login form.
// Once a new account is created it shows a Welcome message.

import {Outlet} from 'react-router-dom'
import {useContext, useEffect, useState} from 'react'
import authContext from '../store/AuthContext'
import {useLocation} from 'react-router'
import useGet from '../hooks/useGet'
import Modal from './Modal'
import LoginForgotPasswordSteps from './LoginForgotPasswordSteps'
import SignUp from './SignUp'
import LoadingOverlay from './LoadingOverlay'
import ModalError from './ModalError'
import Welcome from './Welcome'

const RouteSwitcher = ({privateRoutePage}) => {

    // State
    const [checkExistingUserApi, setCheckExistingUserApi] = useState(null)
    const [newSignUp, setNewSignUp] = useState(null)
    const [signUpSuccess, setSignUpSuccess] = useState(false)

    // Get
    const {loading, error: existingUserError, returnData: returnExistingUser} = useGet(checkExistingUserApi, 500)

    // Auth context
    const authCtx = useContext(authContext)

    // Get parameters from url
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const name = queryParams.get('name')
    const signUpStringParam = queryParams.get('lo')

    useEffect(() => {

        // If sign-up parameter set the api location
        if (signUpStringParam && !authCtx.token) {
            setCheckExistingUserApi(`/api/member/has-signed-up-by-leg?legId=${signUpStringParam}`)
        }

        // Check for existing user
        if (signUpStringParam && returnExistingUser) {
            if (returnExistingUser.data !== true) {
                setNewSignUp(true)
            } else {
                setNewSignUp(false)
            }
        }

    }, [returnExistingUser, signUpStringParam])

    // Send up the tree info that user is not logged in (for style)
    useEffect(() => {
        if (!authCtx.token) {
            privateRoutePage(true)
        }

        return(() => {
            privateRoutePage(false)
        })

    }, [authCtx.token, privateRoutePage])

    // No token - show the Login / Sign up pages
    if (!authCtx.token) {
        return (
            <section>
                <Modal
                    classNames={['modal__content--medium']}
                    modalVisible={true}
                    restricted={true}
                >
                    {
                        !signUpStringParam ? <LoginForgotPasswordSteps name={name} closePanel={()=>{}} />
                            :
                            loading ? <div style={{height: '250px', position: 'relative'}}><LoadingOverlay /></div> :
                                existingUserError ? <ModalError errorText={existingUserError} /> :
                                    <>
                                        {(newSignUp === true) && <SignUp legId={signUpStringParam} name={name} loginComplete={() => setSignUpSuccess(true)} />}
                                        {(newSignUp === false) && <LoginForgotPasswordSteps name={name} closePanel={()=>{}} />}
                                    </>

                    }
                </Modal>
            </section>
        )
    } else {
        return (
            <>
                <Modal
                    modalClassNames={['modal--welcome']}
                    classNames={['modal__content--medium']}
                    modalVisible={signUpSuccess}
                    closeModal={() => setSignUpSuccess(false)}
                >
                    <Welcome closePanel={() => setSignUpSuccess(false)} />
                </Modal>
                <Outlet />
            </>
        )
    }
}

export default RouteSwitcher