import {useContext, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import parse from 'html-react-parser'
import Collapsible from 'react-collapsible'
import Button from './Button'
import poweredByContext from '../store/PoweredByContext'
import getClassName from '../utilities/getClassName'
import {isInternalUrl} from '../utilities/url'
import {replaceShyName} from '../utilities/string'
import createHeading from '../utilities/createHeading'

const BlockFAQs = ({ buttonOverride, title, subtitle, text, showMoreText, items, ctaUrl, ctaText, mainHeading, subHeading }) => {

    // State
    const [expandable, setExpandable] = useState(true)
    const [allCollapsiblesOpen, setAllCollapsiblesOpen] = useState([])
    const [showMore, setShowMore] = useState(false)

    // Context
    const poweredByCtx = useContext(poweredByContext)

    // Ref
    const containerRef = useRef()
    const contentRef = useRef()

    /*
    * Replace Shy Aviation with the tenant name, if necessary
    */
    const replaceName = (str) => {
        if (str) {
            return replaceShyName(str, poweredByCtx.isPoweredBy, poweredByCtx?.tenant?.tenantName)
        }
    }

    /*
    * Manage which items are open
    */
    const updateCollapsible = (i, open) => {
        let items = JSON.parse(JSON.stringify(allCollapsiblesOpen))
        items[i] = open

        setAllCollapsiblesOpen(items)
    }

    /*
    * Show the full text
    */
    const showText = () => {
        setShowMore(true)
    }

    /*
    * Check if the read more is needed on page load
    */
    useEffect(() => {
        if (text && containerRef.current && contentRef.current) {
            const containerHeight = containerRef.current.clientHeight;
            const contentHeight = contentRef.current.clientHeight;
    
            if (containerHeight >= contentHeight) {
                if (!window.matchMedia('(min-width: 765px)').matches) {
                    setShowMore(true);
                }
            }
        }
    }, [text]);    

    return (
        <section className='block-faqs'>
            <div className='wrap'>
                <header className='block-header block-header--light'>
                    {createHeading(subHeading, replaceName(subtitle), true)}
                    {createHeading(mainHeading, replaceName(title), false)}
                </header>
                {text &&
                    <div className={getClassName(['block-faqs__text', 'block-read-more', showMore ? 'block-read-more--active' : undefined, poweredByCtx?.tenant?.isLandingPageBlackText ? 'block-faqs__text--dark' : undefined])}>
                        <div className='block-read-more__container' ref={containerRef}>
                            <div className='block-read-more__content' ref={contentRef}>
                                {parse(replaceName(text))}
                            </div>
                        </div>
                        <button className='block-read-more__button' onClick={showText}>{replaceName(showMoreText) || 'Show more'}</button>
                    </div>
                }
                {items &&
                    <div className='collapsible-content'>
                        <div className='collapsible-content__items'>
                            {items.map((item, i) => {
                                    return <Collapsible
                                        open={allCollapsiblesOpen[i]}
                                        onTriggerOpening={updateCollapsible.bind(this, i, true)}
                                        onTriggerClosing={updateCollapsible.bind(this, i, false)}
                                        trigger={replaceName(item.question)}
                                        key={i}
                                    >
                                        {parse(replaceName(item.answer))}
                                    </Collapsible>
                                })}
                        </div>
                    </div>
                }
                {ctaUrl && ctaText &&
                    <div className='block-faqs__footer'>
                        {buttonOverride
                            ?
                                <Button
                                    classNames={['button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary']}
                                    onClick={buttonOverride}
                                >
                                    {replaceName(ctaText)}
                                </Button>
                            :
                                <>
                                    {isInternalUrl(ctaUrl)
                                        ?
                                            <Link to={ctaUrl} className={getClassName(['button', 'button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary'])}>
                                                <span>{replaceName(ctaText)}</span>
                                            </Link>
                                        :
                                            <a href={ctaUrl} className={getClassName(['button', 'button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary'])}>
                                                <span>{replaceName(ctaText)}</span>
                                            </a>
                                    }
                                </>
                        }
                    </div>
                }
            </div>
        </section>
    )
}

export default BlockFAQs