import Button from './Button'
import {Link} from 'react-router-dom'
import getClassName from '../utilities/getClassName'
import tick from '../assets/icons/tick-thick-white.svg'
import searchIcon from '../assets/icons/search.svg'
import closeIcon from '../assets/icons/close-cross-black.svg'
import Checkbox from './Checkbox'
import Sort from './Sort'
import format from "date-fns/format"
import parse from "date-fns/parse"
import React, {useEffect, useRef, useState} from 'react'

const PassengerList = ({passengersData, multiSelectPassengersData, selectedPassengerId, passengerListStyle, multiSelectPassengersHandler, legId, allPassengersComplete, markCompleteHandler, checkAll}) => {

    const [sortSelection, setSortSelection] = useState('firstName__a_z')
    const [active, setActive] = useState(null)
    const [sortCount, setSortCount] = useState(0)
    const [search, setSearch] = useState('')
    const [allowSmallSearch, setAllowSmallSearch] = useState(false)
    const [smallSearchActive, setSmallSearchActive] = useState(false)

    const searchRef = useRef()

    // Listen for window resize events
    useEffect(() => {
        const resizeHandler = () => {
            setAllowSmallSearch(!window.matchMedia('(min-width: 765px)').matches || window.matchMedia('(min-width: 1024px)').matches)
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    useEffect(() => {
        if (!allowSmallSearch) {
            setSmallSearchActive(false)
        }
    }, [allowSmallSearch])

    const updateSortSelection = (selection) => {
        setSortSelection(selection)
        setSortCount(sortCount + 1)
    }

    const checkAllBoxes = (checked) => {
        let data = {
            list: [],
            checked: checked
        }
        passengersData.filter(filterResults).map((passenger) => {
            data.list.push(passenger.id)
        })
        multiSelectPassengersHandler(data)
    }

    const filterResults = (result) => {
        const passengername = `${result.firstName} ${result.surname}`

        return passengername.toLowerCase().indexOf(search.toLowerCase()) > -1
    }

    const sortResults = (a, b) => {
        let selectionString = sortSelection.split('__')

        const field = selectionString[0]
        const direction = selectionString[1]

        if (direction === 'a_z') {
            if (a[field] > b[field]) {
                return 1
            }

            if (a[field] < b[field]) {
                return -1
            }
        } else if (direction === 'z_a') {
            if (b[field] > a[field]) {
                return 1
            }

            if (b[field] < a[field]) {
                return -1
            }
        }

        return 0
    }

    return (
        <div className='wrap passengers_list'>

            {(passengerListStyle === 'checkbox') ?
                <>
                    <div className={getClassName(['passengers_list__actions', smallSearchActive ? 'passengers_list__actions--small-search-active' : ''])}>
                        <div className='passengers_list__checkbox_list__passenger__checkbox__container'>
                            <Checkbox value={(checked) => checkAllBoxes(checked)} defaultValue={!checkAll}  />
                        </div>
                        <div className='passengers_list__sort'>
                            <Sort
                                options={[{
                                    value: 'firstName__a_z',
                                    label: 'First name: A - Z'
                                },
                                {
                                    value: 'firstName__z_a',
                                    label: 'First name: z - A'
                                },
                                {
                                    value: 'surname__a_z',
                                    label: 'Last name: A - Z'
                                },
                                {
                                    value: 'surname__z_a',
                                    label: 'Last name: Z - A'
                                }]}
                                sortSelection={sortSelection}
                                updateSortSelection={updateSortSelection}
                                updateActive={(activeItem) => { setActive(activeItem) }}
                                active={active === 'sort'}
                            />
                        </div>
                        <div className='passengers_list__search'>
                            <input
                                className='passengers_list__search-input'
                                ref={searchRef}
                                placeholder={allowSmallSearch ? null : 'Search'}
                                onFocus={() => {
                                    if (allowSmallSearch) {
                                        setSmallSearchActive(true)
                                    }
                                }}
                                onChange={() => {
                                    setSearch(searchRef.current.value)
                                }}
                            />
                            <img src={searchIcon} className='passengers_list__search-icon' alt='' />
                            {smallSearchActive &&
                                <img
                                    src={closeIcon}
                                    className='passengers_list__search-close'
                                    alt=''
                                    onClick={() => {
                                        if (allowSmallSearch) {
                                            setSmallSearchActive(false)
                                        }
                                    }}
                                />
                            }
                        </div>
                    </div>
                    <div className='passengers_list__checkbox_list'>

                        {(passengersData && (passengersData.length) >= 0) &&
                            passengersData.filter(filterResults).sort(sortResults).map((passenger, index) => {

                                    let name = `Passenger ${index + 1}`
                                    if (passenger.firstName && passenger.surname) {
                                        name = `${passenger.firstName} ${passenger.surname}`
                                    }

                                    // Format dob
                                    const parseDob = parse(passenger.dob, "yyyy-MM-dd", new Date())
                                    const formattedDob = format(parseDob, "dd/MM/yyyy", { awareOfUnicodeTokens: true })

                                    return (
                                        <div key={index} className='passengers_list__checkbox_list__passenger'>

                                            <div className='passengers_list__checkbox_list__passenger__checkbox__container'>
                                                <Checkbox value={() => multiSelectPassengersHandler(passenger.id)} defaultValue={multiSelectPassengersData.includes(passenger.id)}  />
                                            </div>

                                            <Link to={`/manage-passengers/add-passenger-details/${passenger['id']}`} className={getClassName(['passengers_list__checkbox_list__passenger__name_and_dob', (multiSelectPassengersData.includes(passenger.id)) ? 'passengers_list__checkbox_list__passenger__name_and_dob--selected' : null, (selectedPassengerId === passenger['id']) ? 'passengers_list__checkbox_list__passenger__name_and_dob--selected' : null])}>
                                                <span className='passengers_list__checkbox_list__passenger__name_and_dob__name'>
                                                    {name}
                                                </span><br />
                                                <span className='passengers_list__checkbox_list__passenger__name_and_dob__dob'>
                                                    {formattedDob}
                                                </span>
                                            </Link>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </>
                :
                <div className='passengers_list__links_list'>
                    {(passengersData && (passengersData.length) >= 0) &&
                        passengersData.map((passenger, index) => {

                                let name = `Passenger ${index + 1}`
                                if (passenger.firstName && passenger.surname) {
                                    name = `${passenger.firstName} ${passenger.surname}`
                                }
                                if (passenger.travellerType === 'pet') {
                                    name = `Pet`
                                    if (passenger.firstName) {
                                        name = `Pet - ${passenger.firstName}`
                                    }
                                    if (passenger.species && passenger.firstName) {
                                        name = `${passenger.firstName} the ${passenger.species}`
                                    }
                                }
                                return (
                                    <Link to={`/add-passengers-to-booking/${legId}/add-passenger-details/${passenger['id']}`} key={passenger['id']} className={getClassName(['passengers_list__links_list__passenger', (selectedPassengerId === passenger['id']) ? 'passengers_list__links_list__passenger--selected' : null])}>

                                        {(passenger.informationComplete !== true)
                                            ?
                                            <span className='passengers_list__links_list__passenger__check-circle' />
                                            :
                                            <span className='passengers_list__links_list__passenger__check-circle passengers_list__links_list__passenger__check-circle--complete'>
                                                <img src={tick} alt='' />
                                            </span>
                                        }

                                        <span>
                                            <span className='passengers_list__links_list__passenger__name'>{name}</span><br/>
                                            <span className='passengers_list__links_list__passenger__status'>{passenger.informationComplete === true ? "Complete" : "Not Completed"}</span>
                                        </span>
                                    </Link>
                                )
                            }
                        )
                    }
                </div>
            }

            {(passengerListStyle === 'checkbox') ?
                <Link to='/'><Button classNames={['button--full']}>Home</Button></Link>
                :
                allPassengersComplete ?
                    <Button onClick={markCompleteHandler}>All done</Button>
                    :
                    <Link to={`/quote-dashboard/booking/${legId}`}><Button classNames={['button--full']}>Dashboard</Button></Link>
            }


        </div>
    )
}

export default PassengerList