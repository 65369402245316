import {useEffect, useState} from 'react'
import getClassName from '../utilities/getClassName'
import {NumericFormat} from 'react-number-format';

// Import Icons
import upChevron from '../assets/icons/chevron-up-grey.svg'
import downChevron from '../assets/icons/chevron-down-grey.svg'

const NumberInputAlt = ({value, decimals, incrementNumber, thousandSeparator, label, id, required, defaultValue, maxLength, disabled, validateForm}) => {

    // State
    const [currentValue, setCurrentValue] = useState(null)

    // Update value
    const updateValueHandler = ({target}) => {
        if (target) {
            setCurrentValue(parseFloat(target.value))
        }
        validationHandler(parseFloat(target.value))
    }

    // Validate input
    const validationHandler = (inputValue) => {
        value(null)
        switch (true) {
            default:
                value(inputValue)
                return
        }
    }

    // Increment handler
    const incrementHandler = () => {
        const newValue = parseFloat(currentValue) + incrementNumber
        validationHandler(newValue)
        setCurrentValue(newValue)
    }

    // Decrement handler
    const decrementHandler = () => {
        const newValue = parseFloat(currentValue) - incrementNumber
        setCurrentValue(newValue)
        validationHandler(newValue)
    }

    // On blur handler
    const onBlurHandler = () => {
        validationHandler(currentValue)
    }

    // Force validation
    useEffect(() => {
        if (validateForm) {
            validationHandler(currentValue)
        }
    })

    // Set default value
    useEffect(() => {
        if (defaultValue) {
            setCurrentValue(defaultValue)
        } else {
            setCurrentValue('')
        }
    }, [defaultValue])

    return (
        <div className='number-input'>

            <div className='number-input__wrapper'>
                <NumericFormat
                    value={currentValue}
                    type='text'
                    id={id}
                    name={id}
                    autoComplete='on'
                    maxLength={maxLength}
                    onBlur={onBlurHandler}
                    onChange={updateValueHandler}
                    className={getClassName(['number-input__input'])}
                    disabled={disabled}
                    allowLeadingZeros={false}
                    decimalScale={decimals}
                    allowNegative={false}
                    thousandSeparator={thousandSeparator}
                />

                <button onClick={incrementHandler} className='number-input__increment'><img src={upChevron} alt=''/>Increment</button>
                <button onClick={decrementHandler} className='number-input__decrement'><img src={downChevron} alt=''/>Decrement</button>
            </div>

            {label && <label className='number-input__label'>{label}</label>}

        </div>
    )
}

export default NumberInputAlt