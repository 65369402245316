import {Link, useParams} from 'react-router-dom'
import TitleBar from '../components/TitleBar'
import usePost from '../hooks/usePost'
import Button from '../components/Button'
import {useContext, useEffect, useState} from 'react'
import LoadingOverlay from '../components/LoadingOverlay'
import successIcon from '../assets/icons/payment-status-success.svg'
import exclamationIcon from '../assets/icons/exclamation-large.svg'
import authContext from '../store/AuthContext'
import poweredByContext from '../store/PoweredByContext'
import format from "date-fns/format"
import SEO from '../components/SEO'

const ActAsUser = () => {

    // State
    const [postAlreadyCalled, setPostAlreadyCalled] = useState(false)
    const [loginAttempted, setLoginAttempted] = useState(false)

    // Hooks
    const {sending, returnMessage, returnData: loginData, postData} = usePost()
    const authCtx = useContext(authContext)
    const poweredByCtx = useContext(poweredByContext)
    const params = useParams()

    // Use effect
    useEffect(() => {
        if (poweredByCtx.siteReady) {
            // Run post once
            if (!postAlreadyCalled) {

                const data = {
                    clientId: params.clientId,
                    mimicValidator: params.mimicValidator,
                    tenantID: poweredByCtx.tenantID
                }

                setPostAlreadyCalled(true)
                postData(data, '/api/auth/validate-mimic-user', 1000)
            }

            // Success
            if (returnMessage.success) {
                if (!loginAttempted) {

                    // Set login date and time
                    const formatDate = format(new Date(), "dd-MM-yyyy", { awareOfUnicodeTokens: true })
                    const formatTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" })
                    const lastLoginDateTime = `Last login on ${formatDate} at ${formatTime}`

                    setLoginAttempted(true)

                    authCtx.login(loginData.token, loginData.tokenValidTo, loginData.memberId, loginData.fullName, loginData.firstName, loginData.surname, lastLoginDateTime, true)
                }
            }
        }
    }, [authCtx, loginAttempted, loginData.fullName, loginData.isActAs, loginData.memberId, loginData.token, loginData.tokenValidTo, params.clientId, params.mimicValidator, postAlreadyCalled, postData, returnMessage.success, poweredByCtx.siteReady])

    return (
        <>
            <SEO title='Act As user' />
            <TitleBar title='Act As User' cta='Home' url={`/`} />
            <div className='page'>
                <div className='wrap'>
                    {sending ?
                        <LoadingOverlay loadingText='Logging In As User' />
                        :
                        returnMessage.success ?
                            <article className='big-message'>
                                <div className='big-message__container'>
                                    <figure className='big-message__image'>
                                        <img src={successIcon} alt='' />
                                    </figure>
                                    <h1 className='big-message__title'>Login Success</h1>
                                    <div className='big-message__intro'>
                                        <p>You are logged in on behalf of a user</p>
                                    </div>
                                    <div className='big-message__text'>
                                        <p>You are now logged in as <b>{authCtx.name}</b>.<br/>
                                            Any changes you make will be saved on their behalf.</p>
                                    </div>
                                </div>
                                <div className='big-message__footer'>
                                    <p>You can manage their journeys from Quotes & Bookings</p>
                                    <div className='form-button-wrapper wrap'>
                                        <Link to={`/quotes-and-bookings`}><Button>Quotes & Bookings</Button></Link>
                                        <Link to='/'><Button classNames={['button--white']}>Home</Button></Link>
                                    </div>
                                </div>
                            </article>

                            :
                            <article className='big-message'>
                                <div className='big-message__container'>
                                    <figure className='big-message__image'>
                                        <img src={exclamationIcon} alt='' />
                                    </figure>
                                    <h1 className='big-message__title'>Login Failed</h1>

                                    <div className='big-message__text'>
                                        <p>We were not able to log you in as that user.<br />
                                            Please return to Zoho and try again.
                                        </p>
                                    </div>
                                </div>
                            </article>
                    }
                </div>
            </div>
        </>
    )
}

export default ActAsUser