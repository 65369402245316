// There are many MANY layouts for a journey.
// Include a "layout" parameter to see the different versions
// You can choose from numbers 2-9

import {useContext} from 'react'
import format from "date-fns/format"
import parse from "date-fns/parse"
import personIconDark from '../assets/icons/person-dark.svg'
import personIcon from '../assets/icons/person-light.svg'
import luggageIconDark from '../assets/icons/luggage-dark.svg'
import luggageIcon from '../assets/icons/luggage-light.svg'
import plane from '../assets/icons/plane.svg'
import returnTripIcon from '../assets/icons/return-trip-dark.svg'
import poweredByContext from '../store/PoweredByContext'

const JourneyLeg = ({
                        journeyType,
                        layout,
                        toLong,
                        toShort,
                        fromLong,
                        fromShort,
                        departureDate,
                        departureTime,
                        arrivalDate,
                        arrivalTime,
                        luggage,
                        passengers,
                        isSimple,
                        arrivalTimeZone,
                        departureTimeZone,
                        hideLuggageAndPassengers,
                        subtitle,
                        isReturnTrip
                    }) => {

    const poweredByCtx = useContext(poweredByContext)

    let className = 'journey-leg'
    if (layout) {
        className += ' journey-leg--' + layout
    }

    if (hideLuggageAndPassengers) {
        className += ' journey-leg--hide-luggage-and-passengers'
    }

    if (subtitle) {
        className += ' journey-leg--has-subtitle'
    }

    if (isReturnTrip) {
        className += ' journey-leg--is-return-trip'
    }

    // Format dates
    let formattedDepartureDate
    if (departureDate) {
        const parseDeparture = parse(departureDate, "yyyy-MM-dd", new Date())
        formattedDepartureDate = format(parseDeparture, "dd/MM/yyyy", { awareOfUnicodeTokens: true })

        // Format date differently for different layouts
        if ((!layout || layout === 2) && departureDate) {
            formattedDepartureDate = formattedDepartureDate.slice(0, -4) + formattedDepartureDate.slice(-2)
        }
    }

    let formattedArrivalDate
    if (arrivalDate) {
        const parseArrival = parse(arrivalDate, "yyyy-MM-dd", new Date())
        formattedArrivalDate = format(parseArrival, "dd/MM/yyyy", { awareOfUnicodeTokens: true })

        // Format date differently for different layouts
        if ((!layout || layout === 2) && arrivalDate) {
            formattedArrivalDate = arrivalDate.slice(0, -4) + arrivalDate.slice(-2);
        }
    }

    if (formattedArrivalDate === formattedDepartureDate) {
        formattedArrivalDate = 'Same day'
    }

    const raqLayouts = [2, 3, 7]

    const getPassengersIcon = () => {
        // This isn't the "best" way to fix the issue of light vs dark, but there are too many variations of this component and this is the only quick way to be sure
        if (window.location.pathname === '/quote') {
            if (poweredByCtx.isPoweredBy) {
                return personIconDark
            } else {
                return personIcon
            }
        } else {
            if (
                isSimple ||
                (poweredByCtx.isPoweredBy && raqLayouts.indexOf(layout) === -1) ||
                (poweredByCtx.isPoweredBy && poweredByCtx.tenant.isDarkQuoteModalHeader && raqLayouts.indexOf(layout) > -1)
            ) {
                return personIconDark
            } else {
                return personIcon
            }
        }
    }

    const getLuggageIcon = () => {
        // This isn't the "best" way to fix the issue of light vs dark, but there are too many variations of this component and this is the only quick way to be sure
        if (window.location.pathname === '/quote') {
            if (poweredByCtx.isPoweredBy) {
                return luggageIconDark
            } else {
                return luggageIcon
            }
        } else {
            if (
                isSimple ||
                (poweredByCtx.isPoweredBy && raqLayouts.indexOf(layout) === -1) ||
                (poweredByCtx.isPoweredBy && poweredByCtx.tenant.isDarkQuoteModalHeader && raqLayouts.indexOf(layout) > -1)
            ) {
                return luggageIconDark
            } else {
                return luggageIcon
            }
        }
    }

    return (
        <div className={className}>
            <div className='journey-leg__locations-wrapper'>

                <div className='journey-leg__location-and-date'>
                    <div className='journey-leg__location'>
                        <div className='journey-leg__long-form-location'>{fromLong}</div>
                        {fromShort && <div className='journey-leg__short-form-location'>{fromShort}</div>}
                    </div>
                    <div className='journey-leg__date-and-time'>
                        <div className='journey-leg__date'>
                            {isReturnTrip &&
                                <p>Out:</p>
                            }
                            {formattedDepartureDate}
                        </div>
                        <div className='journey-leg__time'>{departureTime} {departureTime && departureTimeZone}</div>
                    </div>
                </div>

                <div className='journey-leg__line-container'>
                    {subtitle &&
                        <p>{subtitle}</p>
                    }
                    <div className='journey-leg__location__dot'/>
                    <div className='journey-leg__location__dotted-line'/>
                    <div className='journey-leg__location__dot'/>
                    <img src={isReturnTrip ? returnTripIcon : plane} alt='' className='journey-leg__location__plane' />
                </div>

                <div className='journey-leg__location-and-date'>
                    <div className='journey-leg__location'>
                        <div className='journey-leg__long-form-location'>{toLong}</div>
                        {toShort && <div className='journey-leg__short-form-location'>{toShort}</div>}
                    </div>
                    <div className='journey-leg__date-and-time'>
                        {(arrivalTime !== 'tbc' &&
                            formattedArrivalDate &&
                            <div className='journey-leg__date'>
                                {isReturnTrip &&
                                    <p>In:</p>
                                }
                                {formattedArrivalDate}
                            </div>
                        )}
                        <div className='journey-leg__time'>{(arrivalTime === 'tbc') ? 'TBC' : arrivalTime} {arrivalTime && arrivalTimeZone}</div>
                    </div>
                </div>

            </div>

            {(journeyType !== 'group-charter') &&
                <div className='journey-leg__icons-container'>
                    <div className='journey-leg__people'>
                        <img
                            src={getPassengersIcon()}
                            alt=''
                        />{passengers}
                        </div>
                    <div className='journey-leg__luggage'>
                        <img
                            src={getLuggageIcon()}
                            alt=''
                        />{luggage}
                    </div>
                </div>
            }
        </div>
    )

}

export default JourneyLeg