import {useEffect, useState} from 'react'
import getClassName from '../utilities/getClassName'
import InputError from './InputError'

const RadioButton = ({defaultValue, children, disabled, classNames, required, validateForm, value, id}) => {

    // State
    const [error, setError] = useState('')
    const [checked, setChecked] = useState(false)

    const toggleCheckedHandler = () => {
        value(!checked)
        setChecked(!checked)
    }

    // Validate input
    const validationHandler = () => {
        if (value) {
            value(false)

            switch (true) {
                case (required && !checked):
                    setError(`Please confirm`)
                    return
                default:
                    setError('')
                    value(checked)
                    return
            }
        }
    }

    // Force validation
    useEffect(() => {
        if (validateForm) {
            validationHandler()
        }
    })

    // Set default value
    useEffect(() => {
        if (defaultValue) {
            setChecked(defaultValue)
        } else {
            setChecked(false)
        }
    }, [defaultValue])

    let className = ['radio-button']
    if (typeof classNames === 'object') {
        className = className.concat(classNames)
    }

    return (
        <div className={getClassName(className)}>
            <button
                disabled={disabled}
                type='button'
                onClick={toggleCheckedHandler}
                className={getClassName(['radio-button__button', error && 'button__button--error'])}
                id={id}>
                {checked && <div className='radio-button__selected' />}
            </button>
            <label className='radio-button__label' htmlFor={id}>{children}</label>
            {error && <InputError>{error}</InputError>}
        </div>
    )
}

export default RadioButton