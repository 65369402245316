import {useCallback, useEffect, useRef, useState} from 'react'
import InputError from './InputError'
import getClassName from '../utilities/getClassName'
import Fieldset from './Fieldset'
import {stringsMatch, deconstructString} from '../utilities/string'

const SignatureInput = ({value, label, id, required, requiredName, defaultValue, maxLength, disabled, validateForm}) => {

    // State
    const [error, setError] = useState('')

    // Hooks
    const input = useRef()

    // Validate input
    const validationHandler = useCallback((blurOccurred) => {

        if (value) {
            value(null)

            switch (true) {
                case (required && input.current.value.length === 0):
                    setError(`Please add your name above`)
                    return
                case ((blurOccurred === true) && required && requiredName && (!stringsMatch(requiredName, input.current.value))):
                    setError(`Signature must match the name on the contract`)
                    return
                default:
                    setError('')
                    value(input.current.value)
                    return
            }
        }
    }, [required, requiredName, value])

    // On blur handler
    const onBlurHandler = () => {
        validationHandler(true)
    }

    // Force validation
    useEffect(() => {
        if (validateForm) {
            validationHandler(true)
        }
    })

    return (
        <Fieldset marginBottom>
            <div className={getClassName(['signature-input', error && 'signature-input--error'])}>
                {error && <InputError>{error}</InputError>}
                <input
                    defaultValue={defaultValue}
                    ref={input}
                    id={id}
                    name={id}
                    autoComplete='on'
                    maxLength={maxLength}
                    onBlur={onBlurHandler}
                    onChange={validationHandler}
                    className={getClassName(['signature-input__input'])}
                    disabled={disabled}
                />
                <div className='signature__line'/>

                {label && <label className='signature__label' htmlFor={id}>{label}</label>}
            </div>
        </Fieldset>
    )
}

export default SignatureInput