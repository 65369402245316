import {useContext, useEffect, useState} from 'react'
import Form from './Form'
import Button from './Button'
import usePost from '../hooks/usePost'
import {Link, useNavigate} from 'react-router-dom'
import LoadingOverlay from './LoadingOverlay'
import AddPassengerSteps from './AddPassengerSteps'
import TextInput from './TextInput'
import {identityDocumentList} from '../data/identityDocumentList'
import chevronDown from '../assets/icons/chevron-down-big.svg'
import SelectInput from './SelectInput'
import {countryList} from '../data/countryList'
import DatePickerInput from './DatePickerInput'
import format from "date-fns/format"
import parse from "date-fns/parse"
import FileUploadInput from './FileUploadInput'
import authContext from '../store/AuthContext'

const PassengerDocumentForm = ({currentPassengerData, returnMessageHandler, addPassengerDetailsHandler, legId, managedSavedPassengers, pagePath}) => {

    // State
    const [validateForm, setValidateForm] = useState(false)
    const [validateFile, setValidateFile] = useState(false)
    const [file, setFile] = useState(currentPassengerData.identityDocumentFile)
    const [documentType, setDocumentType] = useState(currentPassengerData.documentType)
    const [countryOfIssue, setCountryOfIssue] = useState(currentPassengerData.countryOfIssue)
    const [documentNumber, setDocumentNumber] = useState(currentPassengerData.documentNumber)
    const [documentExpiryDate, setDocumentExpiryDate] = useState(null)
    const [passengerDataToPost, setPassengerDataToPost] = useState(null)

    // Hooks
    const {sending, returnMessage, returnData, postData} = usePost()
    const authCtx = useContext(authContext)
    const navigate = useNavigate()

    // Type passenger by default
    const type = currentPassengerData.travellerType || 'passenger'

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        let formData

        // No inputs, validate the file
        if (!file && !documentType && !countryOfIssue && !documentNumber && !documentExpiryDate) {
            setValidateFile(true)
            setValidateForm(false)
        }

        // Some inputs, validate the form
        if (!file && (documentType || countryOfIssue || documentNumber || documentExpiryDate)) {
            setValidateFile(false)
            setValidateForm(true)
        }

        // Valid file
        if (file) {

            // Check for new file
            if (file !== currentPassengerData.identityDocumentFile) {
                const headers = {
                    'content-type': 'multipart/form-data'
                }

                let passengerId = currentPassengerData.newId ? currentPassengerData.newId : currentPassengerData.id
                formData = new FormData()
                formData.append('identDocFile', file)
                formData.append('paxId', passengerId)
                formData.append('petId', passengerId)
                formData.append('legId', legId)

                if (currentPassengerData.travellerType === 'pet') {
                    console.log("Post pet passport file", formData)
                    postData(formData, '/api/frontend/upload-pet-ident', 1000, 'post-document', headers)
                } else {
                    console.log("Post passenger passport file")

                    // Set endpoint (different for upload to saved pax)
                    let apiUploadPaxDoc = '/api/frontend/upload-pax-ident'
                    if (managedSavedPassengers) {
                        apiUploadPaxDoc = `/api/frontend/upload-savedpax-ident`
                    }
                    postData(formData, apiUploadPaxDoc, 1000, 'post-document', headers)
                }
            } else {
                // No file change, move to next step
                console.log("File is the same, move on")
                navigate(`/${pagePath}/add-passenger-visa/${currentPassengerData.id}`)
            }
        } else {
            // Valid form
            if (documentType && countryOfIssue && documentNumber && documentExpiryDate) {

                // Format document expiry date
                const parseDocumentExpiryDate = parse(documentExpiryDate, "dd/MM/yyyy", new Date())
                const formattedDocumentExpiryDate = format(parseDocumentExpiryDate, "yyyy-MM-dd", { awareOfUnicodeTokens: true })

                // Set data
                formData = {
                    documentType: documentType,
                    countryOfIssue: countryOfIssue,
                    documentNumber: documentNumber,
                    documentExpiryDate: formattedDocumentExpiryDate
                }

                let updatedPassenger = {...currentPassengerData, ...formData}
                setPassengerDataToPost(formData)
                if (currentPassengerData.newId) {
                    updatedPassenger = {...updatedPassenger, id: currentPassengerData.newId}
                }
                if (currentPassengerData.travellerType === 'pet') {
                    console.log("Post pet passport form data")
                    postData(updatedPassenger, '/api/frontend/update-pet', 1000, 'post-document')
                } else {

                    // Set endpoint (different for update saved pax)
                    let apiUpdatePassenger = '/api/frontend/update-passenger'
                    if (managedSavedPassengers) {
                        apiUpdatePassenger = `/api/frontend/update-savedpax?memberId=${authCtx.id}`
                    }

                    postData(updatedPassenger, apiUpdatePassenger, 1000, 'post-document')
                }
            }
        }
    }

    // Remove file from server
    const fileRemoveHandler = () => {
        if (currentPassengerData.identityDocumentFile) {
            let passengerId = currentPassengerData.newId ? currentPassengerData.newId : currentPassengerData.id
            const removeData = {
                paxId: passengerId,
                petId: passengerId,
                attachmentId: currentPassengerData.identityDocumentFile.attachmentId
            }

            // Set endpoint (different for delete saved pax)
            let apiDeletePaxDoc = '/api/frontend/delete-pax-ident'
            if (managedSavedPassengers) {
                apiDeletePaxDoc = `/api/frontend/delete-savedpax-ident`
            }
            if (currentPassengerData.travellerType === 'pet') {
                apiDeletePaxDoc = '/api/frontend/delete-pet-ident'
            }

            console.log("Remove Identity doc from server", removeData)
            postData(removeData, apiDeletePaxDoc, 100)
            let removedFile = {...currentPassengerData, ...{identityDocumentFile: ""}}
            addPassengerDetailsHandler(removedFile)
        }
    }

    // Use effect
    useEffect(() => {

        // Format document expiry date on load
        if (currentPassengerData.documentExpiryDate) {
            const parseDocumentExpiryDate = parse(currentPassengerData.documentExpiryDate, "yyyy-MM-dd", new Date())
            const formattedDocumentExpiryDate = format(parseDocumentExpiryDate, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
            setDocumentExpiryDate(formattedDocumentExpiryDate)
        }

        // Add file data to passenger data
        let updatedPassenger = passengerDataToPost
        if(returnData.hasOwnProperty('data')) {
            if(returnData.data && returnData.data.hasOwnProperty('doc')) {
                updatedPassenger = {...updatedPassenger, ...{identityDocumentFile: returnData.data.doc}}
            }
        }

        // Pass return message up the tree after success
        if (returnMessage.success && returnData) {
            addPassengerDetailsHandler(updatedPassenger)
            navigate(`/${pagePath}/add-passenger-visa/${currentPassengerData.id}`)
        }

        returnMessageHandler(returnMessage)

    }, [addPassengerDetailsHandler, currentPassengerData.documentExpiryDate, currentPassengerData.id, navigate, pagePath, passengerDataToPost, returnData, returnMessage, returnMessageHandler])


    return (
        <div className='passenger-details-form wrap'>

            <div className='passenger-details-form__title'>Identification Document</div>

            <AddPassengerSteps step={2} />

            <div className='passenger-details-form__title passenger-details-form__title--tablet'>Identification Document</div>

            <div className='passenger-details-form__instruction'>Upload a scan of your Identification Document</div>

            <div className='passenger-details-form__doc-upload-wrapper'>
                <FileUploadInput
                    required
                    acceptedFileTypes={["JPG", "JPEG", "PNG", "PDF"]}
                    value={(file) => {setFile(file)}}
                    validateForm={validateFile}
                    defaultValue={file ? file.name ? file.name : file.fileName : null}
                    fileRemoveHandler={() => fileRemoveHandler()}
                />
            </div>

            <div className='passenger-details-form__or'><span />or<span /></div>

            <div className='passenger-details-form__instruction'>Fill out the {type} information below</div>

            <Form onSubmit={submitHandler}>
                <SelectInput
                    options={identityDocumentList}
                    value={(value) => {setDocumentType(value)}}
                    label='Document Type'
                    chevron={chevronDown}
                    disabled={sending}
                    validateForm={validateForm}
                    defaultValue={documentType}
                    classNames={['select-input--full select-input--form']}
                />

                <SelectInput
                    options={countryList}
                    value={(value) => {setCountryOfIssue(value)}}
                    label='Country of Issue'
                    required
                    chevron={chevronDown}
                    disabled={sending}
                    validateForm={validateForm}
                    defaultValue={countryOfIssue}
                    classNames={['select-input--full select-input--form']}
                />

                <TextInput
                    id='document-number'
                    validateForm={validateForm}
                    label='Document number'
                    required
                    maxLength={400}
                    disabled={sending}
                    defaultValue={documentNumber}
                    value={(value) => {setDocumentNumber(value)}}
                />

                <DatePickerInput
                    altLayout
                    required
                    year
                    validateForm={validateForm}
                    label='Expiry Date'
                    disabled={sending}
                    value={(value) => {setDocumentExpiryDate(value)}}
                    defaultValue={documentExpiryDate}
                    allowYearsIntoFuture={25}
                />

                <div className='form-button-wrapper'>
                    <Button>Next</Button>
                    {managedSavedPassengers ?
                        <Link to={`/manage-passengers`}><Button classNames={['button--white']}>Cancel</Button></Link>
                        :
                        <Link to={`/add-passengers-to-booking/${legId}`}><Button classNames={['button--white']}>Cancel</Button></Link>
                    }
                </div>
            </Form>
            {sending && <LoadingOverlay />}

        </div>
    )
}

export default PassengerDocumentForm