import {useEffect, useRef, useState} from 'react'
import getClassName from '../utilities/getClassName'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Pagination} from "swiper";
import Slider from 'rc-slider'

const AircraftBrowserFilters = ({ filters, onUpdate, changeCategoryTo }) => {

    // State
    const [active, setActive] = useState(filters[0])
    const [percentage, setPercentage] = useState(0)
    const [showSlider, setShowSlider] = useState(false)
    const [resetInitialSlide, setResetInitialSlide] = useState(false)
    const [initialSlide, setInitialSlide] = useState(0)

    // Hooks

    // Effect
    // When a selection is made update the parent component
    useEffect(() => {
        onUpdate(active, resetInitialSlide)
    }, [active, resetInitialSlide])

    useEffect(() => {
        update(active, null, true, false)
    }, [])

    // Listen for window resize events
    useEffect(() => {
        const resizeHandler = () => {
            setShowSlider(window.matchMedia('(min-width: 1024px)').matches)
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    // If the slider now needs to be shown, update again (most noticeably when switching from mobile to desktop - the slider may become out of sync with the filters)
    useEffect(() => {
        if (showSlider) {
            if (active) {
                setTimeout(() => {
                    update(active, null, true, false)
                }, 1)
            }
        }
    }, [showSlider])

    useEffect(() => {
        if (changeCategoryTo) {
            const index = filters.indexOf(active)

            if (changeCategoryTo === 'previous') {
                if (index > 0) {
                    update(filters[index - 1], index - 1, false, true)
                } else {
                    update(filters[filters.length - 1], filters.length - 1, false, true)
                }
            }

            if (changeCategoryTo === 'next') {
                if (index < filters.length - 1) {
                    update(filters[index + 1], index + 1, false, true)
                } else {
                    update(filters[0], 0, false, true)
                }
            }
        }
    }, [changeCategoryTo])

    const update = (active, id, resetInitialSlide, updateMobileSlider) => {
        setResetInitialSlide(resetInitialSlide)
        setActive(active)

        if (showSlider) {
            if (active) {
                if (active.ref) {
                    let value = getButtonPosition(active.ref.current) * 10

                    if (id === 0) {
                        value = 0
                    }

                    if (id === filters.length - 1) {
                        value = 1000
                    }

                    setPercentage(value)
                }
            }
        } else {
            if (updateMobileSlider) {
                setInitialSlide(filters.indexOf(active))
                
                const bullet = filtersRef.current.querySelectorAll('.swiper-pagination-bullet')
                const percentage = ((filters.indexOf(active) + 1) / filters.length) * 100
                const bulletIndex = bullet.length / 100 * percentage
                let index = Math.floor(bulletIndex)

                if (index >= bullet.length) {
                    index = bullet.length - 1
                }

                bullet[index].click()
            }
        }
    }

    const snap = (value) => {
        setPercentage(value)

        let closest = null
        let target = 0
        let activeFilter = null
        let activeID = null

        filters.forEach((filter, i) => {
            const percentage = getButtonPosition(filter.ref.current)
            const valueA = (percentage * 10) - value
            const valueB = value - (percentage * 10)

            if (closest != null) {
                if (valueA > 0 && valueA < closest) {
                    closest = valueA
                    target = percentage * 10
                    activeFilter = filter
                    activeID = i
                }
                if (valueB > 0 && valueB < closest) {
                    closest = valueB
                    target = percentage * 10
                    activeFilter = filter
                    activeID = i
                }
            } else {
                if (valueA > 0) {
                    closest = valueA
                    target = percentage * 10
                    activeFilter = filter
                    activeID = i
                }
                if (valueB > 0) {
                    closest = valueB
                    target = percentage * 10
                    activeFilter = filter
                    activeID = i
                }
            }

            if (activeID === 0) {
                target = 0
            }

            if (activeID === filters.length - 1) {
                target = 1000
            }

            setPercentage(target)
            setActive(activeFilter)
        })
    }

    const getButtonPosition = (button) => {
        const buttonPosition = button.getBoundingClientRect()
        const swiperPosition = swiperRef.current.getBoundingClientRect()

        const swiperWidth = swiperRef.current.clientWidth
        const swiperChildWidth = swiperRef.current.children[0].clientWidth
        const swiperWidthDiff = (swiperWidth - swiperChildWidth) / 2

        const sliderWidth = sliderRef.current.clientWidth
        const sliderOffset = sliderOffsetRef.current.clientWidth

        const left = buttonPosition.left - swiperPosition.left + (buttonPosition.width / 2) - swiperWidthDiff - sliderOffset
        
        return (left / sliderWidth) * 100
    }

    const getButtonClassname = (slug) => {
        let classNames = ['aircraft-browser-filters__button']

        if (active) {
            if (slug === active.slug) {
                classNames.push('aircraft-browser-filters__button--active')
            }
        }

        return getClassName(classNames)
    }

    const filtersRef = useRef()
    const sliderOffsetRef = useRef()
    const sliderRef = useRef()
    const swiperRef = useRef()

    return <form className='aircraft-browser-filters' ref={filtersRef}>
        {showSlider &&
            <div className='aircraft-browser-filters__slider-container'>
                <div className='aircraft-browser-filters__slider-offset' onClick={update.bind(this, filters[0], 0, true, false)} ref={sliderOffsetRef}></div>
                <div className='aircraft-browser-filters__slider' ref={sliderRef}>
                    <Slider
                        step={1}
                        defaultValue={percentage}
                        value={percentage}
                        max={1000}
                        onChange={(value) => {
                            snap(value)
                        }}
                    />
                </div>
                <div className='aircraft-browser-filters__slider-offset' onClick={update.bind(this, filters[filters.length - 1, true, false], filters.length - 1)}></div>
            </div>
        }
        <Swiper
            ref={swiperRef}
            className='aircraft-browser-filters__swiper'
            slidesPerView={'auto'}
            slideToClickedSlide={true}
            initialSlide={initialSlide}
            modules={[Pagination]}
            pagination={{
                clickable: true,
                dot: true
            }}
        >
            {filters.map((filter, i) => {
                return <SwiperSlide className={getButtonClassname(filter.slug)} key={filter.slug}>
                    <button type='button' onClick={update.bind(this, filter, i, true, false)} ref={filter.ref}>{filter.title}</button>
                </SwiperSlide>
            })}
        </Swiper>
    </form>
}

export default AircraftBrowserFilters