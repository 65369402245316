import cloudsBackground from '../assets/images/shy-clouds-background.png'
import backgroundVideo from '../assets/video/shy-clouds-background.mp4'
import getClassName from '../utilities/getClassName'
import {useState} from 'react'

const BackgroundVideo = ({position, fullHeight, noVideo}) => {

    // State
    const [videoCanPlay, setVideoCanPlay] = useState(false)

    // Background style
    let backgroundStyle = ['background']

    if (position === 'absolute') {
        backgroundStyle.push('background--absolute')
    }

    if (fullHeight) {
        backgroundStyle.push('background--full-height')
    }

    // Video style
    let videoStyle = ['background__video']
    if (videoCanPlay) {
        videoStyle.push('background__video--loaded')
    }

    return (
        <div className={getClassName(backgroundStyle)}>

            <img className='background__image' src={cloudsBackground} alt='' />

            {!noVideo &&
                <div className={getClassName(videoStyle)}>
                    <video
                        playsInline
                        loop
                        muted
                        autoPlay
                        preload='true'
                        onCanPlayThrough={() => setVideoCanPlay(true)}>
                        >
                        <source src={backgroundVideo} type="video/mp4" />
                    </video>
                </div>
            }

            <div className='background__cover' />
        </div>
    )
}

export default BackgroundVideo