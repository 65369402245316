import closeCrossBlack from '../assets/icons/close-cross-black.svg'
import closeCrossWhite from '../assets/icons/close-cross-white.svg'
import closeCrossBlackHeavy from '../assets/icons/close-cross-black-heavy.svg'

const CloseCross = ({onClick, colour, heavy}) => {

    // Black as default
    let cross = closeCrossBlack

    // Black as default
    if (colour === 'white') {
        cross = closeCrossWhite
    }

    // Heavy weight
    if (heavy) {
        cross = closeCrossBlackHeavy
    }

    return (
        <button style={{backgroundImage: `url(${cross})`}} onClick={onClick} className='close-cross'>Close</button>
    )
}

export default CloseCross