import TitleBar from '../../components/TitleBar'
import React, {useEffect} from 'react'
import Timeline from '../../components/Timeline'
import TimelinePoint from '../../components/TimelinePoint'
import Button from '../../components/Button'
import {Link, useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router'
import {NumericFormat} from 'react-number-format'
import getDenomination from '../../utilities/getDenomination'
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import SEO from '../../components/SEO'

const ContractSigned = () => {

    // Hooks
    const location = useLocation()
    const navigate = useNavigate()

    // Use effect
    useEffect(() => {
        if (location.state === null) {
            navigate('/quotes-and-bookings/quotes')
        }
    })

    // Location state variables
    let quoteRef
    let denomination
    let totalToPay
    let signedDateTime
    let legId
    let tailNum

    if (location.state) {
        quoteRef = location.state.quoteRef
        denomination = getDenomination(location.state.currency)
        totalToPay = location.state.totalToPay
        legId = location.state.legId
        tailNum = location.state.tailNum

        // Format signed date and time
        const parseSignedDate = parseISO(location.state.signedDateTime)
        signedDateTime = format(parseSignedDate, "dd/MM/yyyy 'at' HH:MM", { awareOfUnicodeTokens: true })
    }

    return (
        <>
            <SEO title='Contract Signed' />
            <TitleBar title='Contract Signed' cta='Quotes' url={`/quotes-and-bookings/quotes`} />

            <div className='page'>
                <div className='wrap contract-signed'>

                    <div className='contract-signed__title'>Great News!</div>
                    <div className='contract-signed__intro'>
                        It won’t be long now until you are in the air. Here is a reminder of the progress of your booking.
                    </div>

                    <Timeline>
                        <TimelinePoint selected>The contract for the quote {quoteRef} has been signed on {signedDateTime}.</TimelinePoint>
                        <TimelinePoint>To finalise the purchase, you must make a payment of {denomination}<NumericFormat
                            displayType='text'
                            value={totalToPay}
                            thousandSeparator
                            decimalScale={0}
                        />.</TimelinePoint>
                    </Timeline>

                    <div className='form-button-wrapper'>
                        <Link to={`/payment/bank-transfer/${legId}/${tailNum}`}><Button>Pay Securely Now</Button></Link>
                        <Link to={`/quotes-and-bookings/quotes`}><Button classNames={['button--white']}>Quotes</Button></Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContractSigned