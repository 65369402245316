import ReturnMessage from './ReturnMessage'
import TextInput from './TextInput'
import Button from './Button'
import {useContext, useEffect, useState} from 'react'
import usePost from '../hooks/usePost'
import authContext from '../store/AuthContext'
import poweredByContext from '../store/PoweredByContext'
import LoadingOverlay from './LoadingOverlay'
import format from "date-fns/format"
import PasswordStrengthBar from 'react-password-strength-bar'

import ModalFormWrapper from './ModalFormWrapper'

const SignUp = ({name, legId, loginComplete}) => {

    // State
    const [password, setPassword] = useState('')
    const [validateForm, setValidateForm] = useState(false)

    // Hooks
    const {sending, returnMessage, returnData: loginData, postData} = usePost()
    const authCtx = useContext(authContext)
    const poweredByCtx = useContext(poweredByContext)

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        // Form complete
        if (password) {

            const formData = {
                legId: legId,
                password: password,
                tenantID: poweredByCtx.tenantID
            }

            console.log('formData', formData)

            // Post
            postData(formData,'/api/auth/set-password-and-login', 500)
        }
    }

    // Use effect
    useEffect(() => {
        if (loginData) {

            // Set login date and time
            const formatDate = format(new Date(), "dd-MM-yyyy", { awareOfUnicodeTokens: true })
            const formatTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" })
            const lastLoginDateTime = `Last login on ${formatDate} at ${formatTime}`

            // Login context
            authCtx.login(loginData.token, loginData.tokenValidTo, loginData.memberId, loginData.fullName, loginData.firstName, loginData.surname, lastLoginDateTime, loginData.isActAs)

            // Login complete
            loginComplete()
        }
    }, [authCtx, loginComplete, loginData])

    return (
        <ModalFormWrapper title='Sign up'>
            <>
                <div className='login-form'>
                    <ReturnMessage inline={true} returnMessage={returnMessage} />

                    <div className='login-form__body'>

                        <div className='login-form__body__title'>
                            <>{name}, sign up to view your quote</>
                        </div>
                        <div className='login-form__body__intro'>
                            To keep your private data safe, we ask you to provide a password to secure your account. This should be at least 10 characters long.
                        </div>

                            <form onSubmit={submitHandler}>
                                <TextInput
                                    id='password'
                                    validateForm={validateForm}
                                    label='Password*'
                                    required
                                    type='password'
                                    maxLength='30'
                                    disabled={sending}
                                    value={(value) => setPassword(value)}
                                />

                                <PasswordStrengthBar
                                    className='login-form__password-strength'
                                    password={password ?password : ''}
                                    barColors={['transparent', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']}
                                    scoreWords={['Strength', 'Strength', 'Strength', 'Strength', 'Strength']}
                                    shortScoreWord='Strength'
                                />

                                <div className='login-form__body__submit-container'>
                                    <Button disabled={sending} classNames={['button--full']}>Sign Up</Button>
                                </div>

                            </form>
                    </div>
                </div>

                {sending && <LoadingOverlay />}
            </>
        </ModalFormWrapper>
    )
}

export default SignUp