import PaymentAmount from './PaymentAmount'
import LoadingOverlay from './LoadingOverlay'
import usePost from '../hooks/usePost'
import React, {useEffect, useState} from 'react'
import exclamation from '../assets/icons/exclamation-mark-dark-grey.svg'
import {NumericFormat} from 'react-number-format'
import Switch from './Switch'
import getDenomination from '../utilities/getDenomination'
import Button from './Button'
import {Link, useNavigate} from 'react-router-dom'
import envVariables from '../data/envVariables'

const CryptoPayment = ({legId, paymentRef, balance, totalOwed, depositData, currency, paymentType, subInvoiceNumber}) => {

    // State
    const [baseAmount, setBaseAmount] = useState(totalOwed)
    const [amount, setAmount] = useState(totalOwed)
    const [creditAmount, setCreditAmount] = useState(0)
    const [useCredit, setUseCredit] = useState(false)
    const [additionalInstruction, setAdditionalInstruction] = useState()
    const [loadingModal, setLoadingModal] = useState(false)
    const [bitPayIsOpen, setBitPayIsOpen] = useState(false)

    // Post hooks
    const {
        sending: sendingCreateBitPayInvoice,
        returnMessage: createBitPayInvoiceReturnMessage,
        returnData: createBitPayInvoiceReturnData,
        postData: postCreateBitPayInvoice
    } = usePost()

    // Set denomination
    const denomination = getDenomination(currency)

    const navigate = useNavigate()

    // Submit handler
    const submitHandler = (e) => {

        e.preventDefault()
        setLoadingModal(true)

        // BitPay data
        let bitPayData = {
            paymentType: 'payment',
            paymentMethod: 'bitpay',
            currency: currency,
            baseAmount: baseAmount,
            creditAmount: creditAmount,
            totalAmount: amount,
            useCredit: useCredit,
            legId: legId,
            subInvoiceNumber: subInvoiceNumber,
            invoiceReference: paymentRef
        }

        // Post
        postCreateBitPayInvoice(bitPayData, '/api/frontend/create-bitpay-invoice', 1000)
    }

    // Use Effect
    useEffect(() => {

        // Use credit
        setAdditionalInstruction(null)
        setBaseAmount(depositData.depositToPay ? depositData.depositToPay : totalOwed)
        setAmount(depositData.depositToPay ? depositData.depositToPay : totalOwed)

        if (useCredit) {
            setAdditionalInstruction(`(${denomination}${totalOwed} - ${denomination}${balance} balance)`)
            setAmount(((depositData.depositToPay ? depositData.depositToPay : totalOwed) - balance))
            setCreditAmount(balance)
        } else {
            setCreditAmount(0)
        }

        // BitPay invoice successfully created
        if (createBitPayInvoiceReturnMessage.success) {
            window.open(`${envVariables.bitpayUrl}/invoice?id=${createBitPayInvoiceReturnData.invoiceId}`)

            setLoadingModal(false)
            setBitPayIsOpen(true)

            // This is the code needed when we need to show the payment success page
            /*
            const date = new Date()

            navigate(`/payment-success/crypto/${legId}`, {
                state: {
                    id: paymentRef,
                    currency: currency,
                    quoteRef: paymentRef,
                    value: totalOwed,
                    paymentDate: `${format(date, "dd-MM-yyyy", { awareOfUnicodeTokens: true })} ${format(date, "H:mm", { awareOfUnicodeTokens: true })}`
                }
            })
            */
        }

        // Hide loading when error shows
        if (createBitPayInvoiceReturnMessage.error) {
            setLoadingModal(false)
            setBitPayIsOpen(false)
        }

    }, [balance, createBitPayInvoiceReturnData, createBitPayInvoiceReturnMessage.error, createBitPayInvoiceReturnMessage.success, denomination, depositData.depositToPay, totalOwed, useCredit])

    return (
        <div className='crypto-payment wrap'>

            <PaymentAmount
                instruction={(paymentType === 'deposit') ? 'Deposit Amount' : 'Payment Amount'}
                totalOwed={amount}
                additionalInstruction={additionalInstruction}
                depositData={depositData}
                currency={currency}
                paymentType={paymentType}
            />

            {(!!balance && (balance > 0) && (balance < totalOwed) && !createBitPayInvoiceReturnMessage.error) &&
                <div className='use-credit-to-pay__credit-amount--partial-pay'>
                    <div>Use your credit of <b>
                        {denomination}
                        <NumericFormat
                            displayType='text'
                            value={balance}
                            thousandSeparator
                        />
                    </b>
                    </div>
                    <Switch
                        value={() => setUseCredit(!useCredit)}
                    />
                </div>
            }

            <div className='crypto-payment__response-wrapper'>

                {bitPayIsOpen &&
                    <div className='crypto-payment__response-wrapper__error'>
                        <img alt='' src={exclamation} />
                        <p>A new window has been opened so you can pay with BitPay. Please follow the instructions. Once you’re done you’ll be redirected back to SHY.</p>
                    </div>
                }

                {/* BitPay available */}
                {(!createBitPayInvoiceReturnMessage.error) &&
                    <div className='form-button-wrapper'>
                        {bitPayIsOpen &&
                            <Button onClick={submitHandler}>Open BitPay again</Button>
                        }
                        {!bitPayIsOpen &&
                            <Button onClick={submitHandler}>Pay {denomination}<NumericFormat
                                displayType='text'
                                value={amount.toFixed(2)}
                                thousandSeparator
                            /> with BitPay</Button>
                        }
                        <Link to={`/quotes-and-bookings/quotes`}><Button classNames={['button--white']}>Back to Quotes</Button></Link>
                    </div>
                }

                {createBitPayInvoiceReturnMessage.error &&
                    <div className='crypto-payment__response-wrapper__error'>
                        <img alt='' src={exclamation} />
                        <p>Unfortunately, BitPay is not available for this transaction. Please select an alternative payment method or contact your SHY Broker.</p>
                    </div>
                }

            </div>
            {(sendingCreateBitPayInvoice || loadingModal) &&
                <LoadingOverlay />
            }
        </div>
    )
}

export default CryptoPayment