import TitleBar from '../../components/TitleBar'
import OrderSummary from '../../components/OrderSummary'
import TabBar from '../../components/TabBar'
import Tab from '../../components/Tab'
import React, {useContext, useEffect, useState} from 'react'
import CardPayment from '../../components/CardPayment'
import BankTransferInstructions from '../../components/BankTransferInstructions'
import {Link, useNavigate, useParams} from 'react-router-dom'
import CryptoPayment from '../../components/CryptoPayment'
import Modal from '../../components/Modal'
import PaymentPinForm from '../../components/PaymentPinForm'
import useModal from '../../hooks/useModal'
import useGet from '../../hooks/useGet'
import usePost from '../../hooks/usePost'
import usePrevious from '../../hooks/usePrevious'
import LoadingOverlay from '../../components/LoadingOverlay'
import UseCreditToPay from '../../components/useCreditToPay'
import {useLocation} from 'react-router'
import ReturnMessage from '../../components/ReturnMessage'
import ErrorModal from '../../components/ErrorModal'
import authContext from '../../store/AuthContext'
import envVariables from '../../data/envVariables'
import exclamationIcon from '../../assets/icons/exclamation-large.svg'
import SEO from '../../components/SEO'

const Payment = ({paymentType, shared}) => {

    // Use parameters
    const params = useParams()
    const paymentMethod = params.paymentMethod
    const legId = params.legId
    const tailNum = params.tailNum
    const subInvoiceNumber = params.subInvoiceNumber ? params.subInvoiceNumber : 0

    // State
    const [returnMessage, setReturnMessage] = useState({error: false, message: ''})
    const [cart, setCart] = useState(null)
    const [balance, setBalance] = useState(null)
    const [lookupAdditionalProduct, setLookupAdditionalProduct] = useState(false)
    const [paymentTab, setPaymentTab] = useState('card')
    const [depositData, setDepositData] = useState(0)
    const [loading, setLoading] = useState(true)
    const [loadingData, setLoadingData] = useState(true)
    const [paymentCompleteData, setPaymentCompleteData] = useState(null)
    const [paymentInfoUrl, setPaymentInfoUrl] = useState(null)
    const [balanceUrl, setBalanceUrl] = useState(null)
    const [arfqUrl, setArfqUrl] = useState(null)
    const [detailsUrl, setDetailsUrl] = useState(null)
    const [error, setError] = useState(false)
    const [paymentPinValid, setPaymentPinValid] = useState(false)

    // Hooks
    const authCtx = useContext(authContext)
    const {sending, returnMessage: postReturnMessage, postData} = usePost()

    const {loading: paymentInfoLoading, error: paymentInfoError, returnData: paymentInfo} = useGet(paymentInfoUrl)

    const {loading: balanceLoading, error: balanceError, returnData: balanceData} = useGet(balanceUrl)

    const {loading: arfqLoading, error: arfqError, returnData: arfq} = useGet(arfqUrl)

    const {loading: detailsLoading, error: detailsError, returnData: details} = useGet(detailsUrl)

    let totalOwed = paymentInfo.hasExistingInvoice ? paymentInfo.amountDue : paymentInfo.flightPrice + paymentInfo.servicePrice

    if (paymentInfo.addVAT) {
        totalOwed += (totalOwed / 100) * 20
    }

    const navigate = useNavigate()
    const { pathname, search } = useLocation()
    const prevPaymentType = usePrevious(paymentType)
    const {modalVisible, closeModal, openModal} = useModal()
    const queryParams = new URLSearchParams(search)
    const srid = queryParams.get('srid')

    const balanceFetched = () => {
        return (balanceData !== false && balanceData !== '')
    }

    // Get current cart / existing balance
    useEffect(() => {

        if (paymentInfo.expired) {
            setError('The page you have clicked to view has now expired or you have already completed this step.')
            setLoadingData(false)
        } else if (paymentInfoError || balanceError || arfqError || detailsError) {
            setError('There was an error fetching your payment information. Please try again.')
            setLoadingData(false)
        }

        // Fetch payment info...
        setPaymentInfoUrl(`/api/frontend/get-payment-info?legId=${legId}&subInvoiceNumber=${subInvoiceNumber}`)
        // ...then fetch the balance...
        if (paymentInfo) {
            if (shared) {
                if (!paymentPinValid) {
                    openModal()
                }
            } else {
                setBalanceUrl(`/api/frontend/get-balance?memberId=${authCtx.id}`)
            }
        }
        // ...then fetch the arfq
        if (balanceFetched() || paymentPinValid) {
            setArfqUrl(`/api/frontend/get-id-from-legid?lo=${legId}`)
        }
        // ...then fetch the details
        if (arfq) {
            setDetailsUrl(`/api/frontend/get-price-confirmation-details?legid=${legId}&arfq=${arfq}&tailNum=${tailNum}`)
        }
        // ...then show everything
        if (details) {
            setLoadingData(false)
        }
        
        let paymentTypeChanged = false

        if (prevPaymentType) {
            if (paymentType !== prevPaymentType) {
                paymentTypeChanged = true
            }
        }

        if (paymentTypeChanged) {
            // If the user pays by bank transfer then they have to go through
            // process of a "hold", whereby they go through a card payment to
            // hold the value until the bank transfer is complete.
            // Because we are staying within the <Payment /> component, we need
            // to reset the states
            setReturnMessage({error: false, message: ''})
            setCart(null)
            setBalance(null)
            setLookupAdditionalProduct(false)
            setPaymentTab('card')
            setDepositData(0)
            setLoading(true)
        } else {
            setCart(paymentInfo)
            // setDepositData(cartData.deposit)
            setBalance(balanceData)

            // Check if a payment payment method has been chosen
            if (paymentMethod) {
                setPaymentTab(paymentMethod)
            }

            // Success redirect
            if (returnMessage.success && paymentCompleteData) {
                if (paymentMethod === 'bank-transfer') {
                    if (cart.holdRequested) {
                        navigate(getPaymentHoldUrl())
                    } else {
                        postData({legId: legId}, '/api/frontend/mark-payment-complete')
                    }
                } else {
                    if(paymentMethod === 'card') {
                        postData({legId: legId}, '/api/frontend/mark-payment-complete')
                    } else {
                        navigate(getPaymentSuccessUrl(), {
                            state: {
                                id: cart.paymentRef,
                                currency: cart.currencyCode,
                                quoteRef: cart.paymentRef,
                                cardType: paymentCompleteData.cardType,
                                value: paymentCompleteData.value,
                                paymentDate: paymentCompleteData.paymentDate,
                                invoiceUrl: `${envVariables.documentBase}/api/invoice/download-invoice-jsr?legId=${legId}&subInvoiceNumber=${subInvoiceNumber}`,
                                wasHoldPayment: (paymentType === 'hold')
                            }
                        })
                    }
                }
            }

            setLoading(false)
        }
    }, [paymentCompleteData, paymentMethod, paymentType, prevPaymentType, returnMessage.success, paymentInfo, balanceData, arfq, details, paymentInfoError, balanceError, arfqError, detailsError, paymentPinValid])

    useEffect(() => {
        if (postReturnMessage.success) {
            navigate(getPaymentSuccessUrl(), {
                state: {
                    id: cart.paymentRef,
                    currency: cart.currencyCode,
                    quoteRef: cart.paymentRef,
                    cardType: paymentCompleteData.cardType,
                    value: paymentCompleteData.value,
                    paymentDate: paymentCompleteData.paymentDate,
                    invoiceUrl: `${envVariables.documentBase}/api/invoice/download-invoice-jsr?legId=${legId}&subInvoiceNumber=${subInvoiceNumber}`
                }
            })
        }
    }, [postReturnMessage])

    const getTitle = () => {
        switch (paymentType) {
            case 'deposit':
                return 'Deposit'
            case 'hold':
                return 'Card Hold'
            default:
                return 'Make Payment'
        }
    }

    const showBar = () => {
        return pathname !== `/${paymentType}/use-credit/${legId}/${tailNum}` && paymentType !== 'hold'
    }

    const getTabUrl = (method) => {
        let slug = `${paymentType}`

        if (shared) {
            slug = `shared-${slug}`
        }

        let url = `/${slug}/${method}/${legId}/${tailNum}/${subInvoiceNumber}`

        if (srid) {
            url = `${url}?srid=${srid}`
        }

        return url
    }

    const getTitleBarCta = () => {
        let cta = 'Quotes'

        if (shared) {
            cta = 'Home'
        }

        return cta
    }

    const getTitleBarUrl = () => {
        let url = '/quotes-and-bookings/quotes'

        if (shared) {
            url = '/'
        }

        return url
    }

    const getPaymentSuccessUrl = () => {
        let slug = `payment-success`

        if (shared) {
            slug = `shared-${slug}`
        }

        let url = `/${slug}/${paymentType === 'hold' ? 'bank-transfer' : paymentMethod}/${legId}`

        return url
    }

    const getPaymentHoldUrl = () => {
        let slug = `hold`

        if (shared) {
            slug = `shared-${slug}`
        }

        let url = `/${slug}/${legId}/${tailNum}`

        return url
    }

    return (
        <>
            <SEO title={getTitle()} />
            <TitleBar title={getTitle()} cta={getTitleBarCta()} url={getTitleBarUrl()} />

            <ReturnMessage returnMessage={returnMessage} />

            {loading || loadingData ?
                <div className='page'>
                    <LoadingOverlay/>
                    <Modal
                        modalVisible={modalVisible}
                        closeModal={closeModal}
                        classNames={['modal__content--thin']}
                        restricted={true}
                    >
                        <PaymentPinForm legId={legId} srid={srid} onValid={() => {
                            setPaymentPinValid(true)
                            closeModal()
                        }} />
                    </Modal>
                </div>
                :
                error ? <ErrorModal errorMessage={error} /> :
                <>
                    {showBar() &&
                        <TabBar>
                            <Link to={getTabUrl('bank-transfer')}><Tab selected={paymentTab === 'bank-transfer'}>Bank Transfer</Tab></Link>
                            <Link to={getTabUrl('card')}><Tab selected={paymentTab === 'card'}>Card</Tab></Link>
                            <Link to={getTabUrl('crypto')}><Tab selected={paymentTab === 'crypto'}>BitPay</Tab></Link>
                        </TabBar>
                    }
                    <div className='page'>
                        <div className='payment'>
                            <div className='payment__body'>

                                {authCtx.isActAs === true ?
                                    <article className='big-message'>
                                        <div className='big-message__container'>
                                            <figure className='big-message__image'>
                                                <img src={exclamationIcon} alt='' />
                                            </figure>
                                            <h1 className='big-message__title'>Cannot Pay</h1>

                                            <div className='big-message__text'>
                                                <p>You cannot pay on behalf of this user.<br />
                                                    If {authCtx.name} wishes to share payment they can come to this page themselves and click "Share Payment".
                                                </p>
                                            </div>
                                        </div>
                                    </article>
                                    :
                                    <>
                                        {(paymentTab === 'card') &&
                                            <CardPayment
                                                legId={legId}
                                                paymentRef={cart.paymentRef}
                                                balance={balance}
                                                totalOwed={totalOwed}
                                                currency={cart.currencyCode}
                                                paymentType={paymentType}
                                                depositData={depositData}
                                                returnMessageHandler={(message) => setReturnMessage(message)}
                                                paymentCompleteHandler={(data) => setPaymentCompleteData(data)}
                                                isHoldingPayment={paymentType === 'hold'}
                                                tailNum={tailNum}
                                            />
                                        }

                                        {(paymentTab === 'bank-transfer') &&
                                            <BankTransferInstructions
                                                legId={legId}
                                                paymentRef={cart.paymentRef}
                                                balance={balance}
                                                totalOwed={totalOwed}
                                                currency={cart.currencyCode}
                                                paymentType={paymentType}
                                                depositData={paymentType === 'deposit' ? depositData : {}}
                                                returnMessageHandler={(message) => setReturnMessage(message)}
                                                paymentCompleteHandler={(data) => setPaymentCompleteData(data)}
                                                subInvoiceNumber={cart.subInvoiceNumber}
                                            />
                                        }

                                        {(paymentTab === 'crypto') &&
                                            <CryptoPayment
                                                legId={legId}
                                                paymentRef={cart.paymentRef}
                                                balance={balance}
                                                totalOwed={totalOwed}
                                                currency={cart.currencyCode}
                                                paymentType={paymentType}
                                                depositData={paymentType === 'deposit' ? depositData : {}}
                                                returnMessageHandler={(message) => setReturnMessage(message)}
                                                paymentCompleteHandler={(data) => setPaymentCompleteData(data)}
                                                subInvoiceNumber={cart.subInvoiceNumber}
                                            />
                                        }

                                        {(paymentTab === 'use-credit') &&
                                            <UseCreditToPay
                                                legId={legId}
                                                paymentRef={cart.paymentRef}
                                                balance={balance}
                                                totalOwed={totalOwed}
                                                currency={cart.currencyCode}
                                                paymentType={paymentType}
                                                depositData={depositData}
                                                returnMessageHandler={(message) => setReturnMessage(message)}
                                                paymentCompleteHandler={(data) => setPaymentCompleteData(data)}
                                                subInvoiceNumber={cart.subInvoiceNumber}
                                                tailNum={tailNum}
                                            />
                                        }
                                    </>
                                }



                            </div>

                            <div className='payment__aside'>
                                <OrderSummary
                                    template='flight-booking'
                                    productInfo={details}
                                    showExtraContent={true}
                                    invoiceUrl={`${envVariables.documentBase}/api/invoice/download-invoice-jsr?legId=${legId}&subInvoiceNumber=${subInvoiceNumber}`}
                                    classNames={['wrap']}
                                />
                            </div>

                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Payment