import getClassName from '../utilities/getClassName'
import {SlideDown} from 'react-slidedown'
import {useEffect, useState} from 'react'
import tick from '../assets/icons/tick.svg'
import alert from '../assets/icons/alert.svg'
import CloseCross from './CloseCross'

const ReturnMessage = ({returnMessage, inline}) => {

    const [rememberError, setRememberError] = useState()
    const [rememberMessage, setRememberMessage] = useState()
    const [close, setClose] = useState(false)

    let className = ['return-message']
    if (rememberError) {
        className.push(['return-message--error'])
    }
    if (inline) {
        className.push(['return-message--inline'])
    }

    // Remember the message and error status whilst the tray closes
    useEffect(() => {
        if(returnMessage.message) {
            setClose(false)
            setRememberMessage(returnMessage.message)
            setRememberError(returnMessage.error)
        }
    }, [returnMessage, rememberError, rememberMessage])

    return (
        <div className={getClassName(className)}>
            <SlideDown className='return-message-slidedown' closed={!returnMessage.message || close}>
                <div className='wrap'>
                    <div className='return-message__inner'>
                        <div className='return-message__inner__text'>
                            {rememberError && <img className='return-message__inner__alert' src={alert} alt='' />}
                            {!rememberError && <img className='return-message__inner__tick' src={tick} alt='' />}
                            {rememberMessage}
                        </div>
                        <div className='return-message__inner__close-cross-container'>
                            <CloseCross onClick={() => setClose(true)} heavy={true} />
                        </div>
                    </div>
                </div>
            </SlideDown>
        </div>
    )
}

export default ReturnMessage