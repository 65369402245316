import Switch from './Switch'

const SwitchItem = ({children, title, disabled, classNames, value, callback, isOn = false}) => {

    return (
        <div className='switch-item'>

            <div>
                <div className='switch-item__title'>{title}</div>
                <div className='switch-item__detail'>
                    {children}
                </div>
            </div>

            <div className='switch-item__switch_container'>
                <Switch
                    disabled={disabled}
                    classNames={classNames}
                    value={value}
                    callback={callback}
                    isOn={isOn}
                />
            </div>
        </div>
    )

}

export default SwitchItem