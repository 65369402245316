import {useNavigate, useParams} from 'react-router-dom'
import {useContext, useEffect, useState} from 'react'
import useGet from '../../hooks/useGet'
import LoadingOverlay from '../../components/LoadingOverlay'
import ManagePassengerLayout from '../../components/ManagePassengerLayout'
import TitleBar from '../../components/TitleBar'
import ReturnMessage from '../../components/ReturnMessage'
import PassengerDetailsForm from '../../components/PassengerDetailsForm'
import PassengerDocumentForm from '../../components/PassengerDocumentForm'
import PassengerVisaForm from '../../components/PassengerVisaForm'
import PassengerSummaryForm from '../../components/PassengerSummaryForm'
import {useLocation} from 'react-router'
import Button from '../../components/Button'
import CloseCross from '../../components/CloseCross'
import usePost from '../../hooks/usePost'
import authContext from '../../store/AuthContext'
import SEO from '../../components/SEO'

const ManagePassengers = ({step, managedSavedPassengers, passengerListStyle}) => {

    // State
    const [passengers, setPassengers] = useState(null)
    const [savedPassengers, setSavedPassengers] = useState(null)
    const [successErrorMessage, setSuccessErrorMessage] = useState({error: false, message: ''})
    const [currentPassenger, setCurrentPassenger] = useState(false)
    const [multiSelectedPassengers, setMultiSelectedPassengers] = useState([])
    const [allPassengersComplete, setAllPassengersComplete] = useState(false)
    const [updatedPassengersAfterDelete, setUpdatedPassengersAfterDelete] = useState([])
    const [nextPassenger, setNextPassenger] = useState(null)
    const [titleBarCta, setTitleBarCta] = useState()
    const [titleBarUrl, setTitleBarUrl] = useState()
    const [checkAll, setCheckAll] = useState(true)

    // Hooks
    const {sending, postData, returnMessage} = usePost()
    const authCtx = useContext(authContext)

    // Params
    const params = useParams()
    let legId = params.legId
    const selectedPassengerId = params.passengerId

    // Router
    const navigate = useNavigate()
    const location = useLocation()
    let pagePath = `add-passengers-to-booking/${location.pathname.split('/')[2]}`
    if (managedSavedPassengers) {
        pagePath = `manage-passengers`
    }

    // Set endpoints
    let ApiGetPassengersOnFlight = null
    if (legId) {
        ApiGetPassengersOnFlight = `/api/frontend/get-passengers-and-pets-for-flight?lo=${legId}`
    }
    let ApiGetSavedPassengers = `/api/frontend/get-savedpax-by-member?memberId=${authCtx.id}`

    // Get pre-existing passenger details
    const {loading: loadingPassengerData, returnData: passengerData} = useGet(ApiGetPassengersOnFlight, 1000)

    // Get saved passengers
    const {loading: loadingSavedPassengers, returnData: savedPassengerData} = useGet(ApiGetSavedPassengers, 1000)

    // Add passenger detail
    const addPassengerDetailHandler = (passengerDetail) => {
        const removePassenger = passengers.filter((passenger) => passenger.id !== selectedPassengerId)
        const updatePassenger = passengers.filter((passenger) => passenger.id === selectedPassengerId)[0]
        const newPassenger = {...updatePassenger, ...passengerDetail}
        const addNewPassenger = [...removePassenger, newPassenger]
        addNewPassenger.sort(sortByOrder)
        setPassengers(addNewPassenger)
    }

    // Update selected passenger
    useEffect(() => {
        if (passengers && passengers.length >= 1) {
            if (passengers.filter(passenger => passenger.id === selectedPassengerId)[0]) {
                setCurrentPassenger(passengers.filter(passenger => passenger.id === selectedPassengerId)[0])
            } else {
                if (!managedSavedPassengers) {
                    navigate(`/${pagePath}`)
                }
            }
        }
    }, [legId, managedSavedPassengers, navigate, pagePath, passengers, selectedPassengerId])

    // Collate initial data
    useEffect(() => {

        // Collate passenger data
        const collatePassengerData = (data) => {

            // Set id's when they are missing
            let passengersWithIds = []
            let counter = 0

            // Add id to empty passengers
            if (data.passengers && data.passengers.length) {
                data.passengers.map(passenger => {
                    let addId = {...passenger, ...{order: counter}}
                    if (!passenger.id) {
                        addId = {...passenger, ...{
                                id: `${counter}`,
                                travellerType: 'passenger',
                                order: counter,
                                newPassenger: true
                            }
                        }
                    }
                    counter++
                    passengersWithIds.push(addId)
                    return false
                })
            }

            // Add id to empty pets
            if (data.pets && data.pets.length) {
                data.pets.map(passenger => {
                    let addId = {...passenger, ...{order: counter}}
                    if (!passenger.id) {
                        addId = {...passenger, ...{
                            id: `${counter}`,
                                travellerType: 'pet',
                                order: counter,
                                newPassenger: true
                        }}
                    }
                    counter++
                    passengersWithIds.push(addId)
                    return false
                })
            }

            // Save the new array
            passengersWithIds.sort(sortByOrder)

            return passengersWithIds
        }

        if (!loadingPassengerData && !loadingSavedPassengers) {
            if (passengerData) {
                const collatePassengers = collatePassengerData(passengerData)
                setPassengers(collatePassengers)
            }

            if (savedPassengerData) {
                const collateSavedPassengers = collatePassengerData({passengers: savedPassengerData})
                setSavedPassengers(collateSavedPassengers)
                if(managedSavedPassengers) {
                    setPassengers(collateSavedPassengers)
                }
            }
        }
    },[loadingPassengerData, loadingSavedPassengers, managedSavedPassengers, passengerData, savedPassengerData])

    // Check next passenger and if all details complete
    useEffect(() => {
        if (passengers) {
            let completeCounter = 0
            for (var i = 0; i < passengers.length; i++) {
                if (passengers[i].informationComplete !== true) {
                    setNextPassenger(passengers[i].id)
                    break
                } else {
                    completeCounter++
                }
            }
            if ((passengers.length > 0) && (completeCounter === passengers.length)) {
                setAllPassengersComplete(true)
            }
        }
    }, [passengers])

    // Set title bar
    useEffect(() => {
        if (step === 'intro' || step === 'manage-passengers') {
            if (!managedSavedPassengers) {
                setTitleBarCta('Dashboard')
                setTitleBarUrl(`/quote-dashboard/booking/${legId}`)
            } else {
                setTitleBarCta('Home')
                setTitleBarUrl('/')
            }
        }
        if (step === 'passenger-details') {
            if (!managedSavedPassengers) {
                setTitleBarCta('Add Passengers')
                setTitleBarUrl(`/add-passengers-to-booking/${legId}`)
            } else {
                setTitleBarCta('Manage Passengers')
                setTitleBarUrl(`/manage-passengers`)
            }
        }
        if (step === 'passenger-document') {
            setTitleBarCta('Passenger Details')
            setTitleBarUrl(`/${pagePath}/add-passenger-details/${selectedPassengerId}`)
        }
        if (step === 'passenger-visa') {
            setTitleBarCta('Travel Document')
            setTitleBarUrl(`/${pagePath}/add-passenger-document/${selectedPassengerId}`)

        }
        if (step === 'passenger-summary') {
            setTitleBarCta('Passenger Visa')
            setTitleBarUrl(`/${pagePath}/add-passenger-visa/${selectedPassengerId}`)
        }
    }, [legId, managedSavedPassengers, pagePath, selectedPassengerId, step])

    // Handle return messages
    useEffect(() => {
        if(returnMessage.success && returnMessage.postRef === 'markComplete')
        {
            navigate(`/quote-dashboard/booking/${legId}`)
        }

        if(returnMessage.success && returnMessage.postRef === 'deleteSavedPassengers')
        {
            setMultiSelectedPassengers([])
            setPassengers(updatedPassengersAfterDelete)
        }

    }, [legId, navigate, returnMessage.postRef, returnMessage.success, updatedPassengersAfterDelete])

    // Sort by order
    const sortByOrder = (a, b) => {
        let comparison = 0;
        if (a.order > b.order) {
            comparison = 1;
        } else if (a.order < b.order) {
            comparison = -1;
        }
        return comparison;
    }

    // Select multiple passengers
    const multiSelectPassengersHandler = (id) => {
        if (typeof id === 'object') {
            setMultiSelectedPassengers(id.checked ? id.list : [])
            setCheckAll(!id.checked)
        } else {
            let newStatus = multiSelectedPassengers.includes(id)
            let passengerList = multiSelectedPassengers.filter(passenger => passenger !== id)
            if (!newStatus) {
                passengerList.push(id)
            }
            if (passengerList.length === 0) {
                navigate(`/manage-passengers`)
            }

            setCheckAll(passengerList.length !== passengers.length)
            setMultiSelectedPassengers(passengerList)
        }
    }

    // Deselect all passengers
    const deselectPassengersHandler = () => {
        navigate('/manage-passengers')
        setMultiSelectedPassengers([])
        setCheckAll(true)
    }

    // Mark complete handler
    const markCompleteHandler = () => {
        const data = {
            legId: legId
        }
        postData(data, '/api/frontend/mark-passengers-complete', 1000, 'markComplete')
    }

    // Delete saved passengers
    const deleteSavedPassengers = () => {

        let deleteArray = []
        let updatedPassengers = passengers

        multiSelectedPassengers.map(deleteItem => {
            const data = {
                savedPaxId: deleteItem,
                memberId: parseInt(authCtx.id)
            }
            deleteArray.push(data)
            updatedPassengers = updatedPassengers.filter(removePassenger => removePassenger.id !== deleteItem)
            return false
        })

        console.log(deleteArray)

        setUpdatedPassengersAfterDelete(updatedPassengers)
        postData(deleteArray, '/api/frontend/delete-savedpax', 1000, 'deleteSavedPassengers')
    }

    return (
        <>
            <SEO title={managedSavedPassengers ? `Manage Passengers` : `Add Passengers`} />
            <TitleBar title={managedSavedPassengers ? `Manage Passengers` : `Add Passengers`} cta={titleBarCta} url={titleBarUrl} />
            <ReturnMessage returnMessage={successErrorMessage} />

            <div className='page'>

                {(loadingPassengerData || loadingSavedPassengers) ? <LoadingOverlay /> :
                    <>
                        {multiSelectedPassengers.length >= 1 &&
                            <div className='manage-passengers__multi-select'>
                                {sending && <LoadingOverlay hideLoader />}
                                <div className='manage-passengers__multi-select__title'>{multiSelectedPassengers.length} Passengers Selected</div>
                                <Button onClick={deleteSavedPassengers}>Delete Passengers</Button>
                                <Button onClick={deselectPassengersHandler} classNames={['button--white']}>Cancel</Button>
                                <div className='manage-passengers__multi-select__close-cross'><CloseCross onClick={deselectPassengersHandler} /></div>
                            </div>
                        }

                        <ManagePassengerLayout
                            selectedPassengerId={selectedPassengerId}
                            passengersData={passengers}
                            legId={legId}
                            keepBodyOnMobile={(!((step === 'intro') || (step === 'manage-passengers')))}
                            passengerListStyle={passengerListStyle}
                            multiSelectPassengersHandler={(id) => multiSelectPassengersHandler(id)}
                            multiSelectPassengersData={multiSelectedPassengers}
                            allPassengersComplete={allPassengersComplete}
                            markCompleteHandler={markCompleteHandler}
                            checkAll={checkAll}
                        >
                            {multiSelectedPassengers.length < 2 &&
                                <>
                                    {(step === 'intro') ?
                                        <>
                                            <div className='manage-passengers__title'>
                                                Select a passenger on the left to start adding information.
                                            </div>
                                            <div className='manage-passengers__sub-title'>
                                                You will be able also to use existing data on the next step.
                                            </div>
                                        </>
                                        : null
                                    }

                                    {(step === 'manage-passengers') ?
                                        <>
                                            <div className='manage-passengers__title'>
                                                Select a passenger on the left to see their information
                                            </div>
                                            <div className='manage-passengers__sub-title'>
                                                You will be also able to edit or delete their information.
                                            </div>
                                        </>
                                        : null
                                    }

                                    {(step === 'passenger-details' && passengers && currentPassenger) ?
                                        <PassengerDetailsForm
                                            key={currentPassenger.id}
                                            currentPassengerData={currentPassenger}
                                            returnMessageHandler={returnMessage => setSuccessErrorMessage(returnMessage)}
                                            addPassengerDetailsHandler={passengerDetail => addPassengerDetailHandler(passengerDetail)}
                                            legId={legId}
                                            savedPassengers={savedPassengers}
                                            managedSavedPassengers={managedSavedPassengers}
                                            pagePath={pagePath}
                                        />
                                        : null
                                    }

                                    {(step === 'passenger-document' && passengers && currentPassenger) ?
                                        <PassengerDocumentForm
                                            key={currentPassenger.id}
                                            currentPassengerData={currentPassenger}
                                            returnMessageHandler={returnMessage => setSuccessErrorMessage(returnMessage)}
                                            addPassengerDetailsHandler={passengerDetail => addPassengerDetailHandler(passengerDetail)}
                                            legId={legId}
                                            managedSavedPassengers={managedSavedPassengers}
                                            pagePath={pagePath}
                                        />
                                        : null
                                    }

                                    {(step === 'passenger-visa' && passengers && currentPassenger) ?
                                        <PassengerVisaForm
                                            key={currentPassenger.id}
                                            currentPassengerData={currentPassenger}
                                            returnMessageHandler={returnMessage => setSuccessErrorMessage(returnMessage)}
                                            addPassengerDetailsHandler={passengerDetail => addPassengerDetailHandler(passengerDetail)}
                                            legId={legId}
                                            managedSavedPassengers={managedSavedPassengers}
                                            pagePath={pagePath}
                                        />
                                        : null
                                    }

                                    {(step === 'passenger-summary' && passengers && currentPassenger) ?
                                        <PassengerSummaryForm
                                            key={currentPassenger.id}
                                            currentPassengerData={currentPassenger}
                                            returnMessageHandler={returnMessage => setSuccessErrorMessage(returnMessage)}
                                            allPassengersComplete={allPassengersComplete}
                                            nextPassenger={nextPassenger}
                                            markCompleteHandler={markCompleteHandler}
                                            pagePath={pagePath}
                                            managedSavedPassengers={managedSavedPassengers}
                                        />
                                        : null
                                    }
                                </>
                            }
                            {sending && <LoadingOverlay />}
                        </ManagePassengerLayout>
                    </>
                }
            </div>
        </>
    )
}

export default ManagePassengers