import React, {useContext, useEffect} from 'react'
import TitleBar from '../../components/TitleBar'
import Contract from '../../components/Contract'
import useGet from '../../hooks/useGet'
import Button from '../../components/Button'
import LoadingOverlay from '../../components/LoadingOverlay'
import ErrorModal from '../../components/ErrorModal'
import {useParams} from 'react-router-dom'
import SEO from '../../components/SEO'
import parse from 'html-react-parser'
import CarrierTerms from '../../components/CarrierTerms'
import poweredByContext from '../../store/PoweredByContext'
import {isManchesterUnited} from '../../utilities/poweredBy'
import manchesterUnitedTerms from '../../data/manchesterUnitedTerms'
import envVariables from '../../data/envVariables'

const ViewContract = () => {

    // Hooks
    const legId = useParams().legId
    const {loading, returnData, error} = useGet(`/api/frontend/contract-details?lo=${legId}`)
    const isReportView = useParams().view === 'report'
    const downloadUrl = `${envVariables.documentBase}/api/invoice/view-contract?legId=${legId}`

    const poweredByCtx = useContext(poweredByContext)

    // Add PDF view when looking as report
    useEffect(() => {
        if (isReportView) {
            document.body.classList.add('pdf-view')
        }

        // Clean up
        return(() => {
            document.body.classList.remove('pdf-view')
        })
    })

    return (
        <>
            {!isReportView &&
                <>
                    <SEO title='View Contract' />
                    <TitleBar title='View Contract' cta='Dashboard' url={`/quote-dashboard/quote/${legId}`} />
                </>
            }

            <div className='page'>
                {loading ? <LoadingOverlay/> :
                    error ? <ErrorModal errorMessage={'Sorry, we were unable to find this contract. Please contact your SHY broker.'}/>
                        :
                        <>
                            <div className='wrap'>
                                <div className='contract-download'>
                                    {!isReportView &&
                                        <Button classNames={['button--secondary']} icon='download' onClick={() => {
                                            window.open(downloadUrl, '_blank', 'noreferrer')
                                        }}>Download</Button>
                                    }
                                </div>
                                <Contract
                                    mode='view'
                                    isReportView={isReportView}
                                    contractData={returnData}
                                />

                                {(isReportView && returnData.carrierTerms) &&
                                    <CarrierTerms>{parse(returnData.carrierTerms)}</CarrierTerms>
                                }

                                {(poweredByCtx.isPoweredBy && isManchesterUnited(poweredByCtx)) &&
                                    <CarrierTerms>{parse(manchesterUnitedTerms)}</CarrierTerms>
                                }
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default ViewContract