import GetClassName from '../utilities/getClassName'
import useClickOutside from '../hooks/useClickOutside'
import {useRef, useState} from 'react'

const ThreeDots = ({children, buttonText}) => {

    // State
    const [showContent, setShowContent] = useState(false)

    // Ref
    const contentRef = useRef(null)
    const dotsRef = useRef(null)

    // Focus trap
    const FocusTrap = require('focus-trap-react')

    // Three dots content handler
    const threeDotsSelectedHandler = () => {
        if (!showContent) {
            setShowContent(true)
        } else{
            setShowContent(false)
        }
    }

    // Click outside
    useClickOutside([contentRef, dotsRef], () => {
        if(showContent) {
            setShowContent(false)
        }
    })

    return (
        <FocusTrap active={showContent}>
            <div className='three-dots'>
                <button ref={dotsRef} onClick={threeDotsSelectedHandler} className={GetClassName(['three-dots__button', showContent && 'three-dots__button--active'])}>
                    {buttonText}
                    <span className='three-dots__button__dots-wrapper'>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>

                <div className={GetClassName(['three-dots__content', showContent && 'three-dots__content--visible'])}>
                    <div className='three-dots__content__tail-container' />
                    {children}
                </div>

            </div>
        </FocusTrap>
    )
}

export default ThreeDots