const getDenomination = (currency) => {

    let denomination = '£'
    let input = currency.toLowerCase()

    if (input === 'usd') {
        denomination = '$'
    }
    if (input === 'eur') {
        denomination = '€'
    }

    return denomination
}

export default getDenomination