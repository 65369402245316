const manchesterUnitedTerms = `<p><strong>1. Effect of the Terms and Definitions</strong></p>
<p>1.1 For the purpose of these Terms, the definitions and rules of interpretation in <strong>Schedule 1</strong> shall apply.</p>
<p>1.2 These Terms shall amend and vary the Original Agreement. In the event of any inconsistency or conflict between the Original Agreement and these Terms, these Terms shall prevail to the extent of such inconsistency or conflict.</p>
<p><strong>2. Service Performance Key Functionalities</strong></p>
<p>2.1 The Supplier confirms and agrees that the Agreement and the Services provided under the Agreement are provided for the benefit of MU, MU's Affiliates and any other MU Users</p>
<p>2.2 To the extent that the Supplier is providing Goods under the Agreement, Part A of Schedule 2 shall apply. To the extent that the Supplier is providing Services under the Agreement, Part B of Schedule 2 shall apply.</p>
<p><strong>3. Warranties</strong></p>
<p>3.1 The Supplier warrants, represents and undertakes to MU, each MU Affiliate and any other MU User that: (a) the Supplier shall and shall procure that its Affiliates and its and their sub-contractors perform its and their obligations under the Agreement and operate its and their businesses and supply the Services and Goods in a manner that complies with all Applicable Laws and shall not do or omit to do anything that would cause MU, any MU Affiliate or any other MU User to be in breach of any Applicable Laws and shall immediately notify MU if it/they become aware of any non&shy; compliance with any Applicable Laws by any person; 3.1 and (b) the Supplier shall cause its Affiliates and its and their suppliers and permitted sub-contractors to operate their business in compliance with all Applicable Laws.</p>
<p>3.2 The representations, warranties and undertakings granted by the Supplier under these Terms are in addition to and without prejudice to all other representations, warranties and undertakings granted by the Supplier under the terms of the Agreement and those implied by law.</p>
<p><strong>4. Liabilities</strong></p>
<p>4.1 Subject to <strong>clause 4.3</strong> notwithstanding any other term, condition, exclusion, limitation and/or disclaimer in the l Agreement each party&rsquo;s liability in respect of itself and its Affiliates:</p>
<p>4.1.1 in respect of clause 8 (Confidentiality) and clause 9 (Use of Name and Publicity) shall be unlimited in nature and value as shall the indemnities given by the Supplier in clause 5 (Indemnities); and</p>
<p>4.1.2 in respect of all other losses, shall be limited to the higher of &pound;25,000 or 100% of the fees and charges that will be paid by MU and its Affiliates over the Term.</p>
<p>4.2 Neither party nor, in each case, its Affiliates shall limit their liability for:</p>
<p>4.2.1 fraud or fraudulent misrepresentation;</p>
<p>4.2.2 death or personal injury caused by its negligence or the negligence of its Affiliates; or</p>
<p>4.2.3 any other loss it is not permitted to limit in law.</p>
<p>4.3 Neither party shall be liable for indirect losses.</p>
<p><strong>5. Indemnities</strong></p>
<p>5.1 Subject to <strong>clause 4.1</strong>, the Supplier shall (both during and after the Term) indemnify MU and its Affiliates and keep MU and its Affiliates fully and effectively indemnified against any and all loss, damage, claims, demands, actions, Costs, charges, expenses and liabilities of whatsoever nature incurred by MU, MU&rsquo;s Affiliates and any other MU Users, notwithstanding any other provision in the Agreement, arising directly or indirectly out of or in connection with any claim by a third party that the use and/or possession by MU, MU&rsquo;s Affiliates and any other MU Users of any aspect of the Services, equipment or materials provided by Supplier, infringes at any time the Intellectual Property Rights of any third party wheresoever and howsoever arising. Nothing in the Agreement shall prevent MU from seeking indemnification or otherwise exclude the Supplier&rsquo;s liability, if a loss arises as a result of any voluntary reports made by MU to Regulators.</p>
<p>5.2 With respect to any claim by a third party arising out of or in connection with the indemnity provided by the Supplier to MU and its Affiliates pursuant to <strong>clause 5.1</strong>:</p>
<p>5.2.1 MU shall inform the Supplier in writing of any such claim as soon as reasonably practicable upon becoming aware of the same;</p>
<p>5.2.2 MU and its Affiliates shall neither make any comment or admission to any third party in relation to any such claim, nor agree to any settlement or compromise of such claim, in each case without the prior written consent of the Supplier (such consent not to be unreasonably withheld);</p>
<p>5.2.3 if MU agrees to allow the Supplier to have conduct of the relevant claim, MU and its Affiliates shall give the Supplier reasonable assistance (at the Supplier&rsquo;s request and expense) to enable the Supplier to defend or settle such claim.</p>
<p>5.3 Any indemnities provided by MU, MU&rsquo;s Affiliates and/or any other MU Users that are contained in the Original Agreement shall be deemed to be deleted in full.</p>
<p><strong>6. Insurances</strong></p>
<p>6.1 The Supplier shall throughout the Term and for a period of 2 years thereafter maintain, at its own expense, insurance coverage for the Supplier and its Affiliates at least commensurate with its liabilities under the Agreement.</p>
<p>6.2 The Supplier shall, on written request, promptly provide MU with certificates evidencing the existence and extent of its insurance coverage together with copies of any written notice of its intention not to renew such policies, or of any material adverse change relating to such policies, at least 30 days prior to the expiration of the term of each insurance policy. Copies of all certificates and notices shall be retained by the Supplier during the Term and for a period of 6 years thereafter.</p>
<p>6.3 None of the requirements contained in this clause 6 as to types, limits and approvals of insurance policies to be maintained by the Supplier shall in any manner limit any liability or obligation assumed by the Supplier under the Agreement.</p>
<p><strong>7. Affiliates, Licences and Third Party Rights</strong></p>
<p>7.1 Except where expressly provided under the Agreement, a person who is not a party to the Agreement has no rights under the Contracts (Rights of Third Parties) Act 1999 to rely upon or enforce any term of the Agreement.</p>
<p>7.2 Each MU Affiliate will be entitled to enforce its rights and the indemnities granted to it under the Agreement subject to and in accordance with the Contracts (Rights of Third Parties) Act 1999.</p>
<p>7.3 MU is entering into the Agreement for itself and for its benefit and for the benefit of MU&rsquo;s Affiliates and any rights, licences or indemnities granted to MU under the Agreement shall be deemed to also be granted to MU&rsquo;s Affiliates.</p>
<p>7.4 Without prejudice to <strong>clauses 7.2</strong> and <strong>7.3</strong>, MU and its Affiliates shall not be entitled to any element of &ldquo;double recovery&rdquo; and accordingly neither MU nor any of its Affiliates shall be entitled to bring a claim against the Supplier under or in connection with the Agreement in respect of any loss or damage which MU or any Affiliate has already recovered from the Supplier.</p>
<p>7.5 The parties agree that: (i) the Agreement may be varied by MU from time to time or terminated by MU in accordance with its terms without the consent of any MU Affiliate and sections 2(1)(a) to (c) of the Contracts (Rights of Third Parties) Act 1999 do not apply to the Agreement; (ii) any consents, approvals or notices to be issued under the Agreement only require to be issued by MU and not by any MU Affiliate; and (iii) the Supplier shall only be entitled to enforce the terms of the Agreement against MU and/or make any claim in relation thereto against MU (and not against any MU Affiliate or MU User).</p>
<p><strong>8. Confidentiality</strong></p>
<p>8.1 In this <strong>clause 88</strong>, the &ldquo;<strong>Disclosing Party</strong>&rdquo; means the party making a disclosure of Confidential Information to the other party (&ldquo;<strong>Receiving Party</strong>&rdquo;).</p>
<p>8.2 The Receiving Party shall, and shall procure that its Affiliates and its and their sub-contractors shall: (a) keep the Confidential Information confidential; (b) not disclose the Confidential Information to any person, other than in accordance with <strong>clauses 8.3</strong> to <strong>8.4</strong> (inclusive), <strong>clause 8.6</strong> or <strong>clause 8.9</strong>Error! Reference source not found., unless it first obtains the written consent of the Disclosing Party; and (c) not use the Confidential Information other than for the Permitted Purpose, provided that the Receiving Party is not permitted to disclose the Agreement to any third party except as otherwise provided under this <strong>clause 8</strong>.</p>
<p>8.3 Each party and its Affiliates and its and their sub-contractors may disclose Confidential Information to: (a) its Authorised Persons; and (b) its auditors to the extent reasonably required for such appointment and the exercise of the auditor of its audit obligations, provided that prior to any such disclosure the party making the disclosure ensures that the recipient is aware of the fact that the Confidential Information is confidential and the obligations of confidentiality in this <strong>clause 86</strong> and procures that such recipients and their Affiliates and sub-contractors will be under confidentiality obligations substantially equivalent to those in this <strong>clause 8</strong>.</p>
<p>8.4 Subject to <strong>clause 8.3</strong>, the Receiving Party may disclose Confidential Information to third parties only if the Receiving Party makes the third party aware of the fact that the Confidential Information is confidential and the obligations of confidentiality in this <strong>clause 8</strong> and procures that any third party, and such third party&rsquo;s Affiliates and sub-contractors shall: (a) keep the Confidential Information confidential; (b) not disclose the Confidential Information to any person other than in accordance with <strong>clause 8.3</strong>, this <strong>8.4</strong>, <strong>8.6</strong> or <strong>8.10</strong>Error! Reference source not found.; and (c) not use the Confidential Information other than for the Permitted Purpose.</p>
<p>8.5 The Receiving Party shall be liable to the Disclosing Party if any person to whom the Receiving Party disclosed Confidential Information does not comply with this <strong>clause 8</strong>.</p>
<p>8.6 The Receiving Party may disclose any information (including Confidential Information) where disclosure is required by Applicable Law, by a court of competent jurisdiction, by a Regulator or by a stock exchange with authority over its business or securities, provided that, where permitted by Applicable Law, the Receiving Party gives the Disclosing Party as much notice of the disclosure as is practicable, uses reasonable endeavours to maintain the confidentiality of the information and discloses only what it must. MU may disclose any information (including the Supplier&rsquo;s Confidential Information) pursuant to any disclosure process, procedure or obligation under United States security laws, or the listing rules of the New York Stock Exchange or any other securities exchange on which the capital stock of MU and/or any of its Affiliates may be listed from time to time.</p>
<p>8.7 The obligations contained in <strong>clauses 8.2</strong> to <strong>8.6</strong> (inclusive) do not apply to any Confidential Information which:</p>
<p>8.7.1 is at the Effective Date, or at any time after the Effective Date comes into, the public domain other than through the Receiving Party&rsquo;s breach of the Agreement;</p>
<p>8.7.2 can be shown by the Receiving Party to the reasonable satisfaction of the Disclosing Party to have been known without any obligation of confidentiality to the Disclosing Party by the Receiving Party before disclosure to the Receiving Party;</p>
<p>8.7.3 has been developed by the Receiving Party independently, without reference to any Confidential Information provided by or otherwise obtained from the Disclosing Party (or any Affiliate of the Disclosing Party or its sub-contractors); or</p>
<p>8.7.4 subsequently comes lawfully into the possession of the Receiving Party from a third party without restriction as to disclosure or use.</p>
<p>8.8 Nothing in this <strong>clause 8</strong> or any other obligation of confidence arising under the Agreement or at law or in equity shall restrict in any way the use (including disclosure) or enjoyment by MU, its Affiliates and any other MU Users of any Intellectual Property Rights of the Supplier (in accordance with any licence terms) that MU, its Affiliates and any other MU Users are authorised to use or enjoy under the Agreement.</p>
<p>8.9 Each party may disclose to any Regulator a breach by the other of the Agreement where such party has an obligation to disclose that breach to a Regulator.</p>
<p>8.10 The Receiving Party acknowledges and agrees that damages alone would not be an adequate remedy for breach of this <strong>clause 8</strong> by the Receiving Party. Accordingly, the Disclosing Party will be entitled, without having to prove special damages, to equitable relief (including injunction and specific performance) for any breach or threatened breach of this <strong>clause 8</strong> by the Receiving Party.</p>
<p><strong>9. Use of Name and Publicity</strong></p>
<p>9.1 Notwithstanding any other provisions of the Agreement, unless expressly agreed otherwise by MU (in writing), the Supplier shall not:</p>
<p>9.1.1 use any trade marks, images, designs or other Intellectual Property Rights which are either owned by or which relate to MU or any of its Affiliates, in each case for any reason whatsoever;</p>
<p>9.1.2 inform any third party that it is a supplier of MU or any of its Affiliates and/or hold itself out as having any association with MU or any of its Affiliates (for example, by calling itself a supplier or service provider (or similar) of MU or any of its Affiliates in any pitch document or presentation or including a reference to MU or any of its Affiliates as being a customer on any media including the Supplier&rsquo;s website); and/or</p>
<p>9.1.3 at any time during or after the Term, make any announcement and/or press release in connection with the Agreement. 10. Counterparts 10.1 These Terms may be executed in any number of counterparts, signature each of which will constitute an original, but which will together constitute one agreement (including copies received via email and PDF) and will constitute both parties acceptance of the Agreement.</p>
<p><strong>10. Counterparts</strong></p>
<p>10.1 These Terms may be executed in any number of counterparts, signature each of which will constitute an original, but which will together constitute one agreement (including copies received via email and PDF) and will constitute both parties acceptance of the Agreement.</p>

<p><strong>SIGNED BY</strong> or on behalf of the parties on the date stated at the beginning of these Terms indicating their agreement that the Original Agreement shall be varied and overridden to the extent of any conflict or consistency by these Terms.</p>

<p>Signed by )  ) for and on behalf of ) <strong>Manchester United Football Club Limited</strong> )</p>

<p>Signature of director/officer</p>

<p>Signed by )  ) for and on behalf of ) <strong>SHY Aviation Limited</strong> )</p>

<p>Signature of director/officer</p>

<p><strong>Schedule 1</strong></p>

<p><strong>Definitions and Interpretation</strong></p>

<p>For the purpose of these Terms, the following definitions and rules of interpretation shall apply:</p>

<p><strong>Definitions</strong></p>

<p>&ldquo;<strong>Affiliate</strong>&rdquo; shall mean in the case of any company (including Manchester United Football Club Limited and/or the Supplier), that company&rsquo;s holding company and ultimate holding company and each of its subsidiary companies and its holding company's and ultimate holding company's subsidiary companies from time to time (with &ldquo;holding company&rdquo; and &ldquo;subsidiary&rdquo; having the meanings given to them in section 1159 of the Companies Act 2006);</p>

<p>&ldquo;<strong>Agreement</strong>&rdquo; shall mean the Original Agreement as varied by these Terms in accordance with <strong>clause 1.2</strong>;</p>

<p>&ldquo;<strong>Applicable Law</strong>&rdquo; means any (a) statute, statutory instrument, bye-law, order, regulation, directive, treaty, decree, decision of the European Council or law (including any common law or civil law judgment, demand, order or decision of any court, Regulator or tribunal), including without limitation the Bribery Act 2010, the Modern Slavery Act 2015, the Fraud Act 2006 and including without limitation any other legislation relating to bribery, corruption, modern slavery or fraud existing at the date of the Agreement or coming into force thereafter; (b) legally binding rule, policy, guidance or recommendation issued by any governmental or statutory body or any Regulator; and/or (c) legally binding industry code of conduct or guideline, which in each case, relates to the Agreement and/or the Services and/or the activities which are comprised in all or some of the Services, the use or application of the output from any part of the Services, the performance of the Supplier&rsquo;s obligations under the Agreement and/or MU&rsquo;s business or the business of any other MU Affiliate and/or MU User; &ldquo;Authorised Person&rdquo; shall mean in relation to either party (and/or their Affiliates), any director, officer, employee, representative and/or professional advisor (including lawyers, accountants and consultants) of a party (or their Affiliate), to whom disclosure of Confidential Information is necessary to fulfil the Permitted Purpose and in relation to professional advisers only those who have entered into agreements containing confidentiality terms no less stringent than those set out in the Agreement and in the case of MU shall include the MU Users;</p>

<p>&ldquo;<strong>Confidential Information</strong>&rdquo; means information which: (a) is marked as confidential; or (b) should, by its nature be reasonably assumed to be confidential information, including (i) information relating to a party&rsquo;s business, products, developments, trade secrets, Intellectual Property Rights, know-how, processes, methodologies, personnel, suppliers and customers (which in the case of MU shall include the Customer Information); (ii) the terms of the Agreement; and (iii) any information that relates to a party (or any of its Affiliates or businesses) and which is disclosed to the other party (in any form whatsoever) in connection with the Agreement, but excluding information that: (1) is at the relevant time in the public domain (other than by virtue of a breach of <strong>clause 8</strong>); (2) was received by the other party from a third party who did not acquire it in confidence; or (3) is developed by the other party without any breach of the Agreement;</p>

<p>&ldquo;<strong>Costs</strong>&rdquo; means all costs (on a full indemnity basis) including legal, defence and other professional costs and costs of enforcement;</p>

<p>&ldquo;<strong>Customer Information</strong>&rdquo; shall mean all data, (including Agreement Personal Data), records, studies, diagrams, charts, plans, methodologies, specifications, systems, process, procedures, documents, databases, papers, know-how and/or materials of MU, MU&rsquo;s Affiliates and any other MU Users whether conveyed to the Supplier or its Affiliates by or on behalf of MU, MU&rsquo;s Affiliates or any other MU Users or which the Supplier or its Affiliates is able to access as a result of performing the Services or is otherwise received by the Supplier, including MU&rsquo;s, MU&rsquo;s Affiliates&rsquo; and any other MU Users Confidential Information;</p>

<p>&ldquo;<strong>Effective Date</strong>&rdquo; means the date that the Agreement takes effect from.</p>

<p>&ldquo;<strong>Fees</strong>&rdquo; means any fees, expenses, sums, charges or other amounts payable by MU and/or its Affiliates under or in relation to the Agreement;</p>

<p>&ldquo;<strong>Intellectual Property Rights</strong>&rdquo; shall mean patents, trademarks, service marks, trade names, design rights, copyright, database rights, rights in know-how and other intellectual property rights or equivalent forms of protection of whatever nature arising anywhere in the world, whether registered or unregistered and including applications for the grant of any such rights;</p>

<p>&ldquo;<strong>MU Users</strong>&rdquo; and &ldquo;<strong>MU User</strong>&rdquo; means MU, its Affiliates, and, in each case, any and all contractors contracted to work on their behalf;</p>

<p>&ldquo;<strong>MU</strong>&rdquo; shall mean the Manchester United party that is a party to the Agreement (being Manchester United Football Club Limited (company number 00095489);</p>

<p>&ldquo;<strong>Open Source Software</strong>&rdquo; means third party software code which is licensed upon terms which materially conform to either (i) the open source definition laid down by the body known as the Open Source Initiative or (ii) any generally accepted replacement for or alternative to such open source definition;</p>

<p>&ldquo;<strong>Original Agreement</strong>&rdquo; means the Supplier's proposed agreement or terms and conditions consisting of the Aircraft Charter Agreement, the Booking Conditions, Booking Agreement and the Multiflight Limited standard terms and conditions for the Charter Aircraft that are agreed by the parties to be incorporated in to the Agreement excluding these Terms;</p>

<p>&ldquo;<strong>Permitted Purpose</strong>&rdquo; means solely for the performance of the Receiving Party&rsquo;s (as defined in <strong>clause 8.1</strong> above) obligations and exercising its rights under the Agreement and, where the relevant person is MU, it also means the receipt and use of the Services by MU and its Affiliates and other MU Users and the procurement of services and goods from third parties;</p>

<p>&ldquo;<strong>Proposal</strong>&rdquo; means any technical, design, performance, specification or description of the attributes or components of the Services, as communicated by the Supplier to MU or its Affiliates or by MU or its Affiliates to the Supplier, including (but not limited to) any proposals: (a) agreed between the Supplier and MU during any trial period; (b) provided herein within the Agreement; (c) or communicated to MU via any other means;</p>

<p>&ldquo;<strong>Regulator</strong>&rdquo; means any person (including without limitation all governmental, statutory and other regulatory bodies) having regulatory or supervisory authority over (i) any part of the Supplier&rsquo;s obligations under the Agreement, (ii) the output of any of the Supplier&rsquo;s obligations under the Agreement, (iii) MU, (iv) any of MU&rsquo;s Affiliates, (v) any MU Users and (vi) the Supplier, or any of the Supplier&rsquo;s Affiliates, including without limitation the ICO and its successors;</p>

<p>&ldquo;<strong>Services</strong>&rdquo; shall mean the services (if any) to be provided by the Supplier pursuant to the Agreement including the charter of helicopters as set out in the aircraft charter agreement;;</p>

<p>&ldquo;<strong>Supplier</strong>&rdquo; shall mean the supplier to MU under the Agreement being Shy Aviation Ltd and Multiflight Ltd;;</p>

<p>&ldquo;<strong>Term</strong>&rdquo; shall mean the time from the Effective Date until the termination or expiry of the Agreement; and</p>

<p>&ldquo;<strong>Terms</strong>&rdquo; shall mean these Core Requirements including the Schedules.</p>

<p><strong>Interpretation</strong></p>

<p>Reference to clauses and Schedules are to the clauses and schedules of this Agreement.</p>

<p>Unless the context otherwise requires, words importing the singular include the plural and vice versa, references to any gender include every gender and references to persons include an individual, company, corporation, firm, partnership, unincorporated association or body of persons.</p>

<p>The headings to Clauses, Schedules and Paragraphs are inserted for convenience only, have no legal effect and shall not affect the interpretation of this Agreement.</p>

<p>References to a &ldquo;person&rdquo; include any individual, body corporate, association, partnership, firm, trust, organisation, joint venture, government, local or municipal authority, governmental or supra-governmental agency or department, state or agency of state or any other entity (in each case whether or not having separate legal personality).</p>

<p>References in these Terms to amounts in pounds or &pound; are to amounts in pounds sterling.</p>

<p>Reference to &ldquo;days&rdquo; means calendar days unless specified to be otherwise.</p>

<p>In these Terms, any words following the &ldquo;include&rdquo;, &ldquo;includes&rdquo;, &ldquo;including&rdquo;, &ldquo;in particular&rdquo; or any similar words or expressions will be construed without limitation and accordingly will not limit the meaning of the words preceding them.</p>

<p>Unless the context otherwise requires, references to any statute or statutory provision will include any subordinate legislation made under it and will be construed as references to such statute, statutory provision and/or subordinate legislation as modified, amended, extended, consolidated, re-enacted and/or replaced and in force from time to time.</p>

<p><strong>Schedule 2 - Warranties</strong></p>

<p><strong>Part A</strong></p>

<p>1.1 The Supplier warrants, represents and undertakes that the Goods supplied to MU or its Affiliates by the Supplier, the Supplier's Affiliates and its and their sub-contractors (&quot;Providing Parties&quot;) pursuant to the Agreement shall at all times:</p>

<p>(a) be fit for any purpose held out by a Providing Party or made known to a Providing Party expressly or by implication;</p>

<p>(b) conform to the Proposal;</p>

<p>(c) be free from defects in design, materials and workmanship;</p>

<p>(d) comply with all relevant Applicable Laws and British Standards and the requirements of any Regulators; and</p>

<p>(e) be so formulated, designed, constructed, finished and packaged as to be safe and without risk to health.</p>

<p><strong>Part B</strong></p>

<p>1.1 The Supplier warrants, represents and undertakes that the Supplier, the Supplier's Affiliates and its and their sub-contractors shall in providing the Services:</p>

<p>(a) use the degree of skill, care, prudence, supervision, diligence, foresight, quality control and quality management which would be adopted by a leading professional provider of the Services;</p>

<p>(b) fulfil all requirements set out in the Proposal;</p>

<p>(c) fully co-operate with MU's agents, representatives and contractors;</p>

<p>(d) comply with all relevant Applicable Laws, British Standards, requirements of any relevant statutory and Regulators and health and safety and security policies and obey all of MU's lawful and reasonable directions; and</p>

<p>(e) act and perform its obligations in accordance with Good Industry Practice.</p>`

export default manchesterUnitedTerms