import {useEffect, useState} from 'react'
import getClassName from '../../utilities/getClassName'
import TitleBar from '../../components/TitleBar'
import CreditBar from '../../components/CreditBar'
import TransactionPreview from '../../components/TransactionPreview'
import TransactionHistory from '../../components/TransactionHistory'
import Button from '../../components/Button'
import OrderSummary from '../../components/OrderSummary'
import Loading from '../../components/Loading'
import SEO from '../../components/SEO'

import {bookingsListData} from '../../dummyData/bookingsListData'

const _transactionsData = [{
    ref: 'SHY-1234567',
    departureAirport: 'CDG',
    arrivalAirport: 'LTN',
    amount: 1600,
    amountPaid: 1200,
    currency: 'gbp'
},{
    ref: 'SHY-9484567',
    departureAirport: 'LAX',
    arrivalAirport: 'CDG',
    amount: 2200,
    amountPaid: 2200,
    currency: 'gbp'
}]

const _transactionHistoryData = [{
    ref: 'Payment 1234567',
    amount: 600,
    method: 'Bank transfer',
    date: '07/11/2022',
    currency: 'gbp'
},{
    ref: 'Payment 1234567',
    amount: 600,
    method: 'Visa ****-1234',
    date: '06/10/2022',
    currency: 'gbp'
}]

const Transactions = () => {

    const [showContent, setShowContent] = useState(false)
    const [viewingTransaction, setViewingTransaction] = useState(false)
    const [transactionIsPending, setTransactionIsPending] = useState(false)
    const [transactionsData, setTransactionsData] = useState(false)
    const [additionalProductInfo, setAdditionalProductInfo] = useState(false)
    const [transactionHistoryData, setTransactionHistoryData] = useState(false)

    let className = ['transactions']

    if (viewingTransaction) {
        className.push(`transactions--viewing`)
    }

    useEffect(() => {
        setTimeout(() => {
            setTransactionsData(false)
        }, 100)
        setTimeout(() => {
            setTransactionsData(_transactionsData)
        }, 1000)
    }, [])

    useEffect(() => {
        if (viewingTransaction) {
            setTimeout(() => {
                setAdditionalProductInfo(false)
                setTransactionHistoryData(false)
            }, 100)
            setTimeout(() => {
                setAdditionalProductInfo(bookingsListData[0])
                setTransactionHistoryData(_transactionHistoryData)
            }, 1000)
        }
    }, [viewingTransaction])

    // Listen for window resize events
    useEffect(() => {
        const resizeHandler = () => {
            setShowContent(window.matchMedia('(min-width: 765px)').matches)
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    const showTransaction = (data) => {
        setViewingTransaction(data)
        setTransactionIsPending(data.amountPaid < data.amount)
    }

    const getBackUrl = () => {
        if (!showContent && viewingTransaction) {
            return window.location
        }
        return '/'
    }

    const resetState = (e) => {
        e.preventDefault()
        setViewingTransaction(false)
    }

    return (
        <>
            <SEO title='Transactions & Balance' />
            <TitleBar url={getBackUrl()} cta='Dashboard' title='Transactions & Balance' clickOverride={resetState} />
            {((!showContent && !viewingTransaction) || showContent) &&
                <CreditBar />
            }

            <div className='page page--flush page--flex'>
                <section className={getClassName(className)}>
                    <div className='wrap'>
                        {!transactionsData &&
                            <Loading />
                        }
                        {transactionsData &&
                            <>
                                {((!showContent && !viewingTransaction) || showContent) &&
                                    <div className='transactions__list'>
                                        <p className='transactions__title'>Transactions</p>
                                        {transactionsData &&
                                            <>
                                                {transactionsData.map((data, i) => {
                                                    return <TransactionPreview data={data} key={i} showTransaction={showTransaction} active={viewingTransaction.ref === data.ref} />
                                                })}
                                            </>
                                        }
                                    </div>
                                }
                                {(showContent || viewingTransaction) &&
                                    <div className='transactions__content'>
                                        {!viewingTransaction &&
                                            <p className='transactions__intro'>Select a transaction on the left for more details</p>
                                        }
                                        {viewingTransaction &&
                                            <>
                                                <div className='transactions__column transactions__column--1'>
                                                    <TransactionPreview data={viewingTransaction} keyData='amount' large={true} />
                                                    {transactionIsPending &&
                                                        <div className='transactions__payment'>
                                                            <Button classNames={['button--full']}>Make Payment</Button>
                                                        </div>
                                                    }
                                                    {!transactionHistoryData &&
                                                        <Loading />
                                                    }
                                                    {transactionHistoryData &&
                                                        <TransactionHistory data={transactionHistoryData} isOpenOnLoad={true} />
                                                    }
                                                </div>
                                                <div className='transactions__column transactions__column--2'>
                                                    {!additionalProductInfo &&
                                                        <Loading />
                                                    }
                                                    {additionalProductInfo &&
                                                        <OrderSummary
                                                            template='flight-booking'
                                                            productInfo={additionalProductInfo}
                                                            classNames={['order-summary--flush']}
                                                            isOpenOnLoad={true}
                                                        />
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                }
                            </>
                        }
                    </div>
                </section>
            </div>
        </>
    )
}

export default Transactions