import {useContext, useEffect, useRef, useState} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation, Pagination} from "swiper";
import Loading from './Loading'
import getClassName from '../utilities/getClassName'
import {getIcon, getRawIcon} from '../utilities/icon'
import PoweredByContext from '../store/PoweredByContext'

const AircraftBrowserResults = ({ onUpdate, description, results, changeCategory, initialSlide }) => {

    // State
    const [active, setActive] = useState(null)
    const [showImageSlider, setShowImageSlider] = useState(false)
    const [showResults, setShowResults] = useState(false)
    const [atStart, setAtStart] = useState(false)
    const [atEnd, setAtEnd] = useState(false)
    const [showPreviousCategory, setShowPreviousCategory] = useState(false)
    const [showNextCategory, setShowNextCategory] = useState(false)
    const [swiperInit, setSwiperInit] = useState(false)
    const [changed, setChanged] = useState(false)
    const [swipeDirection, setSwipeDirection] = useState(null)
    const [swiping, setSwiping] = useState(false)

    // Hooks
    const poweredByCtx = useContext(PoweredByContext)

    // Effect

    // When the results changes, if there are results,set the first one as the active one
    // otherwise unset the active state
    useEffect(() => {
        setAtStart(initialSlide === 0)
        setShowPreviousCategory(initialSlide === 0)
        setAtEnd(initialSlide !== 0)
        setShowNextCategory(initialSlide !== 0)
        changeCategory(null)

        if (results) {
            setActive(results[0])
            setTimeout(() => {
                setShowResults(true)
            }, 100)
        } else {
            setActive(null)
            setShowResults(false)
        }
    }, [results])

    // Listen for window resize events
    useEffect(() => {
        const resizeHandler = () => {
            setShowImageSlider(!window.matchMedia('(min-width: 1024px)').matches)
        }

        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    useEffect(() => {
        if (swiperInit) {
            setShowPreviousCategory(true)
        }
    }, [swiperInit])

    window.swipeTimeout = window.swipeTimeout || null

    useEffect(() => {
        if (changed) {
            setChanged(false)
            setSwiping(false)
            setSwipeDirection(null)
            clearTimeout(window.swipeTimeout)
        }
    }, [changed])

    useEffect(() => {
        if (swiping) {
            window.swipeTimeout = setTimeout(() => {
                if (swipeDirection === 'prev' && atStart) {
                    changeCategory('previous')
                }
                if (swipeDirection === 'next' && atEnd) {
                    changeCategory('next')
                }
                setSwiping(false)
                setSwipeDirection(null)
            }, 500)
        }
    }, [swiping])

    const navigationPrevRef = useRef()
    const navigationNextRef = useRef()

    const getResultsClassname = () => {
        let classNames = ['aircraft-browser-results']

        if (showResults) {
            classNames.push('aircraft-browser-results--active')
        }

        return getClassName(classNames)
    }

    const handlePreviousClick = () => {
        if (!changed) {
            changeCategory('previous')
        } else {
            changeCategory(null)
        }
    }

    const handleNextClick = () => {
        if (!changed) {
            changeCategory('next')
        } else {
            changeCategory(null)
        }
    }

    return <div className={getResultsClassname()}>
        <div className='aircraft-browser-results__text'>
            <p>{description}</p>
        </div>
        {results &&
            <div className='aircraft-browser-results__swiper-container'>
                <div className='aircraft-browser-results__swiper-nav aircraft-browser-results__swiper-nav--previous' ref={navigationPrevRef} onClick={handlePreviousClick}>
                    {poweredByCtx.isPoweredBy
                        ?
                            <>
                                {getRawIcon('chevron-left-gold')}
                            </>
                        :
                            <img src={getIcon('chevron-left-gold')} alt='' />
                    }
                </div>
                <div className='aircraft-browser-results__swiper-nav aircraft-browser-results__swiper-nav--next' ref={navigationNextRef} onClick={handleNextClick}>
                    {poweredByCtx.isPoweredBy
                        ?
                            <>
                                {getRawIcon('chevron-right-gold')}
                            </>
                        :
                            <img src={getIcon('chevron-right-gold')} alt='' />
                    }
                </div>
                <Swiper
                    modules={[Navigation, Pagination]}
                    className='aircraft-browser-results__swiper'
                    slidesPerView={1}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    onBeforeInit={(swiper) => {
                        setSwiperInit(true)
                        setAtStart(swiper.activeIndex === 0)
                        setAtEnd(swiper.activeIndex + 1 === swiper.slides.length)
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                    }}
                    onSlideChange={(swiper) => {
                        setAtEnd(swiper.activeIndex + 1 === swiper.slides.length)
                        setActive(results[swiper.activeIndex])
                        setChanged(true)
                        setSwiping(false)
                    }}
                    onSliderMove={(e) => {
                        if (e.swipeDirection) {
                            if (swipeDirection !== e.swipeDirection) {
                                setSwipeDirection(e.swipeDirection)
                                setSwiping(true)
                            }
                        }
                    }}
                    speed={600}
                    pagination={{
                        dot: true
                    }}
                    initialSlide={initialSlide}
                >
                    {results.map((result, resultID) => {
                        if (resultID < 3) {
                        return <SwiperSlide key={resultID}>
                            <article className='aircraft-browser-results__item'>
                                {!showImageSlider &&
                                    <div className='aircraft-browser-results__images'>
                                        {result.images.map((image, i) => {
                                            if (i < 2) {
                                                return <figure className='aircraft-browser-results__image' data-loading={true} key={i}>
                                                    <img
                                                        src={image}
                                                        onLoad={(e) => {
                                                            setTimeout(() => {
                                                               e.target.style.opacity = 1
                                                               delete e.target.parentNode.dataset.loading
                                                            }, 100)
                                                        }}
                                                        alt={image.alt}
                                                    />
                                                </figure>
                                            }
                                        })}
                                    </div>
                                }
                                {showImageSlider &&
                                    <Swiper
                                        modules={[Navigation, Pagination]}
                                        className='aircraft-browser-results__image-swiper'
                                        slidesPerView={1}
                                        navigation={true}
                                        allowTouchMove={false}
                                        speed={600}
                                        pagination={{
                                            type: 'fraction',
                                        }}
                                    >
                                        {result.images.map((image, i) => {
                                            if (i < 2) {
                                                return <SwiperSlide key={i}>
                                                    <figure className='aircraft-browser-results__image' data-loading={true}>
                                                        <img
                                                            src={image}
                                                            onLoad={(e) => {
                                                                setTimeout(() => {
                                                                    e.target.style.opacity = 1
                                                                    delete e.target.parentNode.dataset.loading
                                                                }, 100)
                                                            }}
                                                            alt={image.alt}
                                                        />
                                                    </figure>
                                                </SwiperSlide>
                                            }
                                        })}
                                    </Swiper>
                                }
                                <div className='aircraft-browser-results__content'>
                                    <h3 className='aircraft-browser-results__title'>{result.aircraftName}</h3>
                                    <ul className='aircraft-browser-results__list'>
                                        {result.headRoom &&
                                            <li>Head room: {result.headRoom}M</li>
                                        }
                                        {result.speed &&
                                            <li>Speed (MPH): {result.speed}</li>
                                        }
                                        {result.passengerscount &&
                                            <li>Passengers: {result.passengerscount}</li>
                                        }
                                        {result.range &&
                                            <li>Range: {result.range}</li>
                                        }
                                    </ul>
                                </div>
                            </article>
                        </SwiperSlide>
                            
                        }
                    })}
                </Swiper>
            </div>
        }
        {!results &&
            <div className='aircraft-browser-results__placeholder-container'>
                <div className='aircraft-browser-results__placeholder-outer'>
                    <div className='aircraft-browser-results__placeholder-inner'>
                        {showImageSlider &&
                            <Loading />
                        }
                    </div>
                </div>
                {!showImageSlider &&
                    <Loading />
                }
            </div>
        }
    </div>
}

export default AircraftBrowserResults